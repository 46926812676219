import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { TabTitle } from "../../../utils/TabTitle";
import { useForm } from '../../../hooks/useForm';
import RequestLoginBanner from "../../../components/RequestLoginBanner/RequestLoginBanner";
import ForgotCredentials from "../../../components/ForgotCredentials/ForgotCredentials";
import './ForgotUsername.scss';

function ForgotUsername({title}) {
  TabTitle(title  || 'KFC LSM');

  const { onChange, onSubmit, values } = useForm(forgotUsernameCallback, {
    email: ''
  });

  const [forgotUsername, { loading }] = useMutation(FORGOT_USERNAME, {
    update(_, { data: { forgotUsername: userData } }) {
      if (userData) {
        toast.success("Username sent to email address", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'forgot-username-success'
        });
        toast.info("Check your email address", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'forgot-username-info'
        });
      }
    },
    onError(err) {
      let errMsg = err.graphQLErrors[0].message;

      toast.error(errMsg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'forgot-username-error'
      });
    },
    variables: values
  });

  async function forgotUsernameCallback() {
    await forgotUsername();
  }

  return (
    <div className={'forgot-username'}>
      <form onSubmit={onSubmit} className={'forgot-username__form'}>
        <RequestLoginBanner />
        <h3 style={{marginBottom: '21px'}}>Forgot Username</h3>
        <input type={'email'} id={'email'} name={'email'} placeholder={'Add Email'} value={values.email} onChange={onChange} />
        <button type={'submit'}>Enter</button>
        <ForgotCredentials />
      </form>
    </div>
  )
}

const FORGOT_USERNAME = gql`
  mutation forgotUsername($email: String!) {
    forgotUsername(email: $email)
  }
`;

export default ForgotUsername