import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './Stores.scss';
import { Link, useLocation, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import DataTable from "react-data-table-component";
import { PageDescription } from '../../../utils/PageDescription';
import TestMarket from '../TestMarket';

const styleFiltration = {
  container: base => ({
    ...base,
    width: '100%',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    maxWidth: '233px',
    padding: 0,
    marginBottom: 0,
    marginTop: '0',
    boxShadow: 'none',
    border: '1px solid #EEEEEE',
    borderTop: '0',
    fontWeight: '700',
    fontSize: '16px',
    transition: 'all .2s ease-in-out',
    borderRadius: '0',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: '0',
    borderRadius: '0',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: '#fff',
    padding: '0 20px',
    cursor: 'pointer',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#F40027'
    }
  }),
  control: (base, state) => ({
    ...base,
    padding: '0 20px',
    border: '1px solid #EEEEEE',
    borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
    boxShadow: '0 !important',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '233px',
    minHeight: '60px',
    height: '60px',
    borderRadius: '10px',
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '10px',
    borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '10px',
    fontWeight: '700',
    fontSize: '16px',
    transition: '.2s easy-in-out',
    '&:hover': {
      borderColor: '1px solid #EEEEEE !important',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
    }
  }),
  valueContainer: base => ({
    ...base,
    padding: '0',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '0'
  }),
  singleValue: base => ({
    ...base,
    margin: 0
  })
};

function Store({ title }) {
  const location = useLocation();
  const [stores, setStores] = useState([]);
  let priceZone = location.pathname.split("/")[7].replace(/_/g, " ");
  let { zoneId, podsId, storeId } = useParams();
  let strZone = zoneId.replaceAll('-', ' ');
  let strStore = storeId.replaceAll('-', ' ');

  PageDescription('STORE', 'Addtional information');

  let customSortIcon = <div className="custom-sort-icons">
    <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.49951 18.75L8.99951 23.25L13.4995 18.75" fill="#171B1E" />
      <path d="M13.4995 11.25L8.99951 6.75L4.49951 11.25" fill="#171B1E" />
    </svg>
  </div>

const optionsPods = [
  { value: 'POD-01', label: 'POD-01' },
  { value: 'POD-02', label: 'POD-02' },
  { value: 'POD-03', label: 'POD-03' },
  { value: 'POD-04', label: 'POD-04' },
  { value: 'POD-05', label: 'POD-05' },
  { value: 'POD-06', label: 'POD-06' },
  { value: 'POD-07', label: 'POD-07' },
  { value: 'POD-08', label: 'POD-08' },
  { value: 'POD-09', label: 'POD-09' },
  { value: 'POD-10', label: 'POD-10' },
  { value: 'POD-11', label: 'POD-11' },
  { value: 'POD-12', label: 'POD-12' },
  { value: 'POD-13', label: 'POD-13' }
]

const optionsZones = [
  { value: 'Broken Hill', label: 'Broken Hill' },
  { value: 'Yum Zone 1', label: 'Yum Zone 1' },
  { value: 'Yum Zone 3', label: 'Yum Zone 3' },
  { value: 'ZC1', label: 'ZC1' },
  { value: 'ZC2', label: 'ZC2' },
  { value: 'ZC3', label: 'ZC3' },
  { value: 'ZC5', label: 'ZC5' },
  { value: 'ZC6', label: 'ZC6' },
  { value: 'ZC11', label: 'ZC11' },
  { value: 'ZC12', label: 'ZC12' },
  { value: 'ZC13', label: 'ZC13' }
]

const optionsStores = [
  {
    "label": "401 Swanston Street",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Aberfoyle Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Acacia Ridge",
    "priceZone": "ZC1"
  },
  {
    "label": "Airport West",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Albany",
    "priceZone": "ZC12"
  },
  {
    "label": "Albany Creek",
    "priceZone": "ZC1"
  },
  {
    "label": "Albion Park 2",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Albury",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Alice Springs",
    "priceZone": "ZC11"
  },
  {
    "label": "Altona North",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Annandale",
    "priceZone": "ZC3"
  },
  {
    "label": "Annerley",
    "priceZone": "ZC1"
  },
  {
    "label": "Ararat",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Armadale",
    "priceZone": "ZC11"
  },
  {
    "label": "Armidale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Arndale Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Artarmon",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Arundel",
    "priceZone": "ZC2"
  },
  {
    "label": "Ascot",
    "priceZone": "ZC11"
  },
  {
    "label": "Ashburton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ashfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ashfield Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ashmore",
    "priceZone": "ZC2"
  },
  {
    "label": "Aspley",
    "priceZone": "ZC1"
  },
  {
    "label": "Asquith",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Atherton",
    "priceZone": "ZC3"
  },
  {
    "label": "Atwell",
    "priceZone": "ZC11"
  },
  {
    "label": "Australia Fair",
    "priceZone": "ZC6"
  },
  {
    "label": "Ayr",
    "priceZone": "ZC3"
  },
  {
    "label": "Bacchus Marsh",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bagot Road",
    "priceZone": "ZC13"
  },
  {
    "label": "Bairnsdale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Baldivis",
    "priceZone": "ZC11"
  },
  {
    "label": "Ballan Service Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ballarat",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ballina",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ballina Travel Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Banksia Grove",
    "priceZone": "ZC11"
  },
  {
    "label": "Bankstown South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bankstown Square Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bateau Bay",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Batemans Bay",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bathurst",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Baulkham Hills",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bayswater",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Beaudesert",
    "priceZone": "ZC2"
  },
  {
    "label": "Beechboro",
    "priceZone": "ZC11"
  },
  {
    "label": "Beenleigh",
    "priceZone": "ZC1"
  },
  {
    "label": "Bega",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Belconnen Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Beldon",
    "priceZone": "ZC11"
  },
  {
    "label": "Belmont",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Belmont Forum",
    "priceZone": "ZC11"
  },
  {
    "label": "Belmont VIC",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Benalla",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bendigo",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bendigo Marketplace",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bennets Green",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Benowa",
    "priceZone": "ZC2"
  },
  {
    "label": "Berrinba",
    "priceZone": "ZC1"
  },
  {
    "label": "Berwick South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bethania",
    "priceZone": "ZC1"
  },
  {
    "label": "Biloela",
    "priceZone": "ZC3"
  },
  {
    "label": "Birkdale",
    "priceZone": "ZC1"
  },
  {
    "label": "Blacktown",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Blacktown Mega Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bligh Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bomaderry",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bondi Junction Eastgate",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Bonnyrigg",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Booval",
    "priceZone": "ZC1"
  },
  {
    "label": "Bourke Street Melbourne",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "BP Caboolture North",
    "priceZone": "ZC6"
  },
  {
    "label": "BP Caboolture South",
    "priceZone": "ZC6"
  },
  {
    "label": "Brassall",
    "priceZone": "ZC1"
  },
  {
    "label": "Bridgewater",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Brighton TAS",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Brimbank",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Brisbane Airport",
    "priceZone": "ZC1"
  },
  {
    "label": "Broadmeadow (Drive Thru Only)",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Broadmeadows",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Broadmeadows Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Broadway",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Broken Hill",
    "priceZone": "Broken Hill"
  },
  {
    "label": "Browns Plains",
    "priceZone": "ZC1"
  },
  {
    "label": "Buderim",
    "priceZone": "ZC2"
  },
  {
    "label": "Bunbury Forum",
    "priceZone": "ZC12"
  },
  {
    "label": "Bunbury South",
    "priceZone": "ZC12"
  },
  {
    "label": "Bundaberg",
    "priceZone": "ZC3"
  },
  {
    "label": "Bundaberg East",
    "priceZone": "ZC3"
  },
  {
    "label": "Bundoora",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Burleigh Waters",
    "priceZone": "ZC2"
  },
  {
    "label": "Burnie",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Burnley",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Burpengary",
    "priceZone": "ZC2"
  },
  {
    "label": "Burwood East",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Burwood Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Busselton",
    "priceZone": "ZC12"
  },
  {
    "label": "Butler",
    "priceZone": "ZC11"
  },
  {
    "label": "Byford",
    "priceZone": "ZC11"
  },
  {
    "label": "Caboolture",
    "priceZone": "ZC2"
  },
  {
    "label": "Caboolture City",
    "priceZone": "ZC2"
  },
  {
    "label": "Cairns",
    "priceZone": "ZC3"
  },
  {
    "label": "Cairns Central Food Court",
    "priceZone": "ZC3"
  },
  {
    "label": "Calamvale",
    "priceZone": "ZC1"
  },
  {
    "label": "Calder Inbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Calder Outbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Caloundra",
    "priceZone": "ZC2"
  },
  {
    "label": "Cameron Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Campbelltown",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Campbelltown Mall Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Campsie",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Canadian",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Canberra City",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Capalaba",
    "priceZone": "ZC1"
  },
  {
    "label": "Capalaba Central Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Capalaba Park Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Carindale",
    "priceZone": "ZC1"
  },
  {
    "label": "Carindale Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Carlingford Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Carlton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Carnes Hill",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Caroline Springs",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Carrum Downs",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Casino",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Castle Towers Level 1",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Casuarina",
    "priceZone": "ZC13"
  },
  {
    "label": "Casuarina Food Court",
    "priceZone": "ZC13"
  },
  {
    "label": "Caulfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Centenary",
    "priceZone": "ZC1"
  },
  {
    "label": "Cessnock",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chadstone Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Charlestown Square",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chatswood Chase",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chatswood Interchange",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chatswood Westfield Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chelsea Heights",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chermside Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Chinchilla",
    "priceZone": "ZC5"
  },
  {
    "label": "Chinderah",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chinderah North",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Chirnside Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "City Cross",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Claremont",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Clarkson",
    "priceZone": "ZC11"
  },
  {
    "label": "Clayfield",
    "priceZone": "ZC1"
  },
  {
    "label": "Clayton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Cleveland",
    "priceZone": "ZC1"
  },
  {
    "label": "Coburg",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Coffs Harbour Plaza",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Coffs Harbour South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Colac",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Colonnades",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Concord",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Coolalinga",
    "priceZone": "ZC13"
  },
  {
    "label": "Cooma",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Coomera Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Corio",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Cowra",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Craigieburn",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Cranbourne",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Cranbourne North",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Cranbrook",
    "priceZone": "ZC3"
  },
  {
    "label": "Cranebrook",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Crossroads",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Crown Casino Melbourne",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dalby",
    "priceZone": "ZC5"
  },
  {
    "label": "Dandenong Market",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dandenong Plaza",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dandenong South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dapto",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Darling Harbour Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Darlinghurst",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Deagon",
    "priceZone": "ZC1"
  },
  {
    "label": "Deception Bay",
    "priceZone": "ZC1"
  },
  {
    "label": "Dee Why",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Deer Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Derwent Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Devonport",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dickson",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dogswamp",
    "priceZone": "ZC11"
  },
  {
    "label": "Doncaster",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Doreen",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dubbo",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dubbo East",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dulwich Hill",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Dural",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eagleby",
    "priceZone": "ZC1"
  },
  {
    "label": "Eaglehawk",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Earlville",
    "priceZone": "ZC3"
  },
  {
    "label": "Earlville Food Court",
    "priceZone": "ZC3"
  },
  {
    "label": "Earlwood",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "East Brighton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "East Lismore",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "East Maitland",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "East Wodonga",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eastgardens Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eastlands Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eastlink Northbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eastlink Southbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eastwood",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Echuca",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Edmonton",
    "priceZone": "ZC3"
  },
  {
    "label": "Edwardstown",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Eight Mile Plains",
    "priceZone": "ZC1"
  },
  {
    "label": "Elizabeth",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Elizabeth Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Elizabeth Street Melbourne",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ellenbrook",
    "priceZone": "ZC11"
  },
  {
    "label": "Eltham",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Emerald",
    "priceZone": "ZC5"
  },
  {
    "label": "Emerton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Emu Plains",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Endeavour Hills",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Enfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Engadine",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Epping",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Epping Plaza Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Erina",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Erina Fair",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ermington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Everard Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Everton Park",
    "priceZone": "ZC1"
  },
  {
    "label": "Fairfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Fairy Meadow",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Falcon",
    "priceZone": "ZC11"
  },
  {
    "label": "Fawkner",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ferntree Gully",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Five Dock",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Flemington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Flowerdale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Footscray",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Forbes",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Forrestfield",
    "priceZone": "ZC11"
  },
  {
    "label": "Forster",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Fountain Gate Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Frankston Bayside Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Frankston South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Frenchs Forest",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Fulham Gardens",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Fyshwick",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Garbutt",
    "priceZone": "ZC3"
  },
  {
    "label": "Garden City FC Qld",
    "priceZone": "ZC6"
  },
  {
    "label": "Garden City FC WA",
    "priceZone": "ZC11"
  },
  {
    "label": "Gatton",
    "priceZone": "ZC2"
  },
  {
    "label": "Gawler",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "George Street Sydney",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Geraldton",
    "priceZone": "ZC12"
  },
  {
    "label": "Gladesville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Gladstone",
    "priceZone": "ZC3"
  },
  {
    "label": "Glen Innes",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Glen Waverley",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Glen Waverley Central",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Glendale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Glenelg",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Glenmore Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Golden Grove",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Goulburn",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Goulburn South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Grafton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Grafton Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Grand Central",
    "priceZone": "ZC2"
  },
  {
    "label": "Grand Plaza Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Granville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Green Hills Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Green Square",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Greenacre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Greensborough",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Greensborough Plaza Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Greenslopes",
    "priceZone": "ZC1"
  },
  {
    "label": "Griffith",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Grovedale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Grovely",
    "priceZone": "ZC1"
  },
  {
    "label": "Gundagai",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Gungahlin",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Gunnedah",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Gympie",
    "priceZone": "ZC3"
  },
  {
    "label": "Hallam",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hamilton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Harrisdale",
    "priceZone": "ZC11"
  },
  {
    "label": "Hastings",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hawker",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hawthorn",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Haymarket",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Haynes",
    "priceZone": "ZC11"
  },
  {
    "label": "Heidelberg",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Helensvale",
    "priceZone": "ZC2"
  },
  {
    "label": "Helensvale Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Hermit Park",
    "priceZone": "ZC3"
  },
  {
    "label": "Hervey Bay",
    "priceZone": "ZC3"
  },
  {
    "label": "Highett",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Highfields",
    "priceZone": "ZC2"
  },
  {
    "label": "Highpoint - Level 3",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Highpoint II",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hillcrest",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hindley Street",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hinkler Place Food Court",
    "priceZone": "ZC3"
  },
  {
    "label": "Hoppers Crossing",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hoppers Crossing 2",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hornsby Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Horsham",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hoxton Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hurstville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Hurstville Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Inala",
    "priceZone": "ZC1"
  },
  {
    "label": "Indooroopilly",
    "priceZone": "ZC1"
  },
  {
    "label": "Indooroopilly Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Ingham",
    "priceZone": "ZC3"
  },
  {
    "label": "Ingleburn",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Innaloo",
    "priceZone": "ZC11"
  },
  {
    "label": "Innisfail",
    "priceZone": "ZC3"
  },
  {
    "label": "Inverell",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Irymple",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Jerrabomberra",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Jesmond",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Jimboomba",
    "priceZone": "ZC2"
  },
  {
    "label": "Joondalup",
    "priceZone": "ZC11"
  },
  {
    "label": "Kalgoorlie",
    "priceZone": "ZC12"
  },
  {
    "label": "Kallangur",
    "priceZone": "ZC1"
  },
  {
    "label": "Kangaroo Flat",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kangaroo Point",
    "priceZone": "ZC1"
  },
  {
    "label": "Karawara",
    "priceZone": "ZC11"
  },
  {
    "label": "Karingal",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Karratha",
    "priceZone": "Yum Zone 3"
  },
  {
    "label": "Karrinyup",
    "priceZone": "ZC11"
  },
  {
    "label": "Kawana Food Court",
    "priceZone": "ZC2"
  },
  {
    "label": "Kedron",
    "priceZone": "ZC1"
  },
  {
    "label": "Keilor Downs",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kelmscott",
    "priceZone": "ZC11"
  },
  {
    "label": "Kelvin Grove",
    "priceZone": "ZC1"
  },
  {
    "label": "Kempsey",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Keperra",
    "priceZone": "ZC1"
  },
  {
    "label": "Kew",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Keysborough",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kilburn",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kilmore",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kincumber",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kingaroy",
    "priceZone": "ZC2"
  },
  {
    "label": "Kings Cross",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kings Meadow",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kings Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kingston",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kingsway",
    "priceZone": "ZC11"
  },
  {
    "label": "Kirwan",
    "priceZone": "ZC3"
  },
  {
    "label": "Knox City",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Knox City Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kogarah",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kooragang",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kotara",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kurri Kurri",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Kwinana",
    "priceZone": "ZC11"
  },
  {
    "label": "Lakehaven",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lakehaven Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lakelands",
    "priceZone": "ZC11"
  },
  {
    "label": "Lakemba",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lakes Entrance",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Launceston",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Laverton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lavington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lavington Square Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Legana",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Leongatha",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Leppington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Leppington Central",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lidcombe",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lilydale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lindfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lithgow",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Liverpool",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Liverpool Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Livingston",
    "priceZone": "ZC11"
  },
  {
    "label": "Logan Hyperdome",
    "priceZone": "ZC6"
  },
  {
    "label": "Loganholme",
    "priceZone": "ZC1"
  },
  {
    "label": "Lucas",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Lynbrook",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "MacArthur Central Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Macarthur Shopping Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mackay",
    "priceZone": "ZC3"
  },
  {
    "label": "Mackay Food Court",
    "priceZone": "ZC3"
  },
  {
    "label": "Mackay North",
    "priceZone": "ZC3"
  },
  {
    "label": "Macquarie Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mandurah",
    "priceZone": "ZC11"
  },
  {
    "label": "Manly Vale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Marangaroo",
    "priceZone": "ZC11"
  },
  {
    "label": "Marden",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mareeba",
    "priceZone": "ZC3"
  },
  {
    "label": "Marion",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Marion Mall Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Maroochydore",
    "priceZone": "ZC2"
  },
  {
    "label": "Marsden",
    "priceZone": "ZC1"
  },
  {
    "label": "Marsden Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Marulan",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Maryborough QLD",
    "priceZone": "ZC3"
  },
  {
    "label": "Maryborough VIC",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mawson Lakes",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mayfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Maylands",
    "priceZone": "ZC11"
  },
  {
    "label": "Mcgraths Hill",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Meadowbrook",
    "priceZone": "ZC1"
  },
  {
    "label": "Mean Fiddler",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Melbourne Central Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Melton Caltex",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Melton Phoenix",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Melton South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Melville",
    "priceZone": "ZC11"
  },
  {
    "label": "Menai",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mentone",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mermaid Beach",
    "priceZone": "ZC2"
  },
  {
    "label": "Merrylands",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Merrylands Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Miami",
    "priceZone": "ZC2"
  },
  {
    "label": "Midland",
    "priceZone": "ZC11"
  },
  {
    "label": "Midvale",
    "priceZone": "ZC11"
  },
  {
    "label": "Mildura",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mill Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Milperra",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Minchinbury",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mingara",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Minto",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Miranda",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Miranda Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mirrabooka",
    "priceZone": "ZC11"
  },
  {
    "label": "Mitcham",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mittagong Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Modbury",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Moe",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mona Vale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Moonee Ponds",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mooroolbark",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Moranbah",
    "priceZone": "ZC5"
  },
  {
    "label": "Morayfield Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Moree",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Morisset",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Morley",
    "priceZone": "ZC11"
  },
  {
    "label": "Morningside",
    "priceZone": "ZC1"
  },
  {
    "label": "Mornington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Morwell",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mowbray",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Annan",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Barker",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Druitt",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Druitt Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Gambier City",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Gambier Market Place (Drive Thru Only)",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mt Isa",
    "priceZone": "ZC5"
  },
  {
    "label": "Mt Pleasant",
    "priceZone": "ZC3"
  },
  {
    "label": "Mudgee",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Mundaring",
    "priceZone": "ZC11"
  },
  {
    "label": "Munno Para",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Murray Bridge",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Murwillumbah",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Muswellbrook",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Myer Centre",
    "priceZone": "ZC6"
  },
  {
    "label": "Myer Centre Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Nambour",
    "priceZone": "ZC2"
  },
  {
    "label": "Nambucca Heads",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Narellan",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Narellan Town Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Narrabri",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Narre Warren",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Nathan Plaza",
    "priceZone": "ZC3"
  },
  {
    "label": "Nerang",
    "priceZone": "ZC2"
  },
  {
    "label": "Newcomb",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Nicholls",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Niddrie",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Noarlunga",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Noble Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Noosaville",
    "priceZone": "ZC2"
  },
  {
    "label": "North Geelong",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "North Lakes",
    "priceZone": "ZC1"
  },
  {
    "label": "North Lakes Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "North Ryde",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "North Wagga",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "North Wyong",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Northam",
    "priceZone": "ZC11"
  },
  {
    "label": "Northgate",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Northland Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Northmead",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Nowra",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Nunawading",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Oakleigh",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ocean Grove",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Officer Arena",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Officer Inbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Officer Outbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Orange",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ormeau",
    "priceZone": "ZC2"
  },
  {
    "label": "Ormond",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Oxenford",
    "priceZone": "ZC2"
  },
  {
    "label": "Pacific Fair",
    "priceZone": "ZC6"
  },
  {
    "label": "Pagewood",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Pakenham",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Palais",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Palmerston",
    "priceZone": "ZC13"
  },
  {
    "label": "Paralowie",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Park Ridge",
    "priceZone": "ZC1"
  },
  {
    "label": "Parkes",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Parklea",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Parramatta L1 Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Parramatta L5 Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Penlink Inbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Penlink Outbound",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Pennant Hills",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Penrith Leagues",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Penrith Plaza Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Penrith South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Pimpama",
    "priceZone": "ZC2"
  },
  {
    "label": "Pinjarra North",
    "priceZone": "ZC11"
  },
  {
    "label": "Pinjarra South",
    "priceZone": "ZC11"
  },
  {
    "label": "Plainland",
    "priceZone": "ZC2"
  },
  {
    "label": "Playford",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Plenty Valley Westfield",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Plumpton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Point Cook",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Port Adelaide",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Port Augusta",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Port Lincoln",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Port Macquarie",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Port Macquarie II",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Port Pirie",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Portland",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Prahran",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Preston",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Prospect",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Pulteney Street",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Punchbowl",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Queanbeyan",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Randwick",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Rasmussen",
    "priceZone": "ZC2"
  },
  {
    "label": "Raymond Terrace",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Redbank",
    "priceZone": "ZC1"
  },
  {
    "label": "Redbank Plains",
    "priceZone": "ZC1"
  },
  {
    "label": "Redcliffe",
    "priceZone": "ZC1"
  },
  {
    "label": "Redlynch",
    "priceZone": "ZC3"
  },
  {
    "label": "Renmark",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Reservoir",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Revesby",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Reynella",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Richmond South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ringwood",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Riverdale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Riverlink Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Riverstone",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Riverwood",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Robina Drive Thru",
    "priceZone": "ZC2"
  },
  {
    "label": "Robina Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Rockdale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Rockhampton Food Court",
    "priceZone": "ZC3"
  },
  {
    "label": "Rockhampton North",
    "priceZone": "ZC3"
  },
  {
    "label": "Rockhampton South",
    "priceZone": "ZC3"
  },
  {
    "label": "Rockingham Beach",
    "priceZone": "ZC11"
  },
  {
    "label": "Roma",
    "priceZone": "ZC5"
  },
  {
    "label": "Rosebud",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Rosehill",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Roselands Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Rosny Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Rouse Hill Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Rowville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Roxburgh Park",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Runaway Bay",
    "priceZone": "ZC2"
  },
  {
    "label": "Rutherford",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Salamander Bay",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Salisbury",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sans Souci",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Seaford Rise",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sebastopol",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Seven Hills",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Seymour",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sheidow Park Hallets Cove",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Shellharbour",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Shellharbour Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Shepparton City",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Shepparton North",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Shepparton South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Singleton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sippy Downs",
    "priceZone": "ZC2"
  },
  {
    "label": "Smithfield",
    "priceZone": "ZC3"
  },
  {
    "label": "Sorell",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "South Lake",
    "priceZone": "ZC11"
  },
  {
    "label": "South Perth",
    "priceZone": "ZC11"
  },
  {
    "label": "Southland Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Southport",
    "priceZone": "ZC2"
  },
  {
    "label": "Spearwood",
    "priceZone": "ZC11"
  },
  {
    "label": "Spring Farm",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Springfield",
    "priceZone": "ZC1"
  },
  {
    "label": "Springfield Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Springfield Parkway",
    "priceZone": "ZC1"
  },
  {
    "label": "Springvale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Springwood",
    "priceZone": "ZC1"
  },
  {
    "label": "St Clair",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "St Kilda",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "St Leonards",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "St Peters",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Strath Village",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Strathpine",
    "priceZone": "ZC1"
  },
  {
    "label": "Strathpine Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Sturt Mall Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Subiaco",
    "priceZone": "ZC11"
  },
  {
    "label": "Sunbury",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sunnybank",
    "priceZone": "ZC1"
  },
  {
    "label": "Sunshine",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sunshine Market Place",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Surfers Paradise",
    "priceZone": "ZC2"
  },
  {
    "label": "Swan Hill",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sydenham",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sydney Airport",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Sydney Central",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tahmoor",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tamworth",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tamworth South",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tannum Sands",
    "priceZone": "ZC3"
  },
  {
    "label": "Taree",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Taree Service Centre",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tarneit",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Taylors Hill",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tea Tree Plaza Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "The Entrance",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "The Pines",
    "priceZone": "ZC2"
  },
  {
    "label": "Thomastown",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Thornbury",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Thornlie",
    "priceZone": "ZC11"
  },
  {
    "label": "Thornton",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tingalpa",
    "priceZone": "ZC1"
  },
  {
    "label": "Toormina",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Toowoomba",
    "priceZone": "ZC2"
  },
  {
    "label": "Toowoomba East",
    "priceZone": "ZC2"
  },
  {
    "label": "Top Ryde Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Toronto",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Torrensville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Traralgon",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Traralgon East",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Treendale",
    "priceZone": "ZC12"
  },
  {
    "label": "Truganina",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tuggerah Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tuggeranong",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tullamarine",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tumut",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Tweed City Food Court",
    "priceZone": "ZC6"
  },
  {
    "label": "Tweed Heads",
    "priceZone": "ZC2"
  },
  {
    "label": "Ulladulla",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Ulverstone",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Unanderra",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Upper Coomera",
    "priceZone": "ZC2"
  },
  {
    "label": "Urangan",
    "priceZone": "ZC3"
  },
  {
    "label": "Valley Metro",
    "priceZone": "ZC6"
  },
  {
    "label": "Vermont",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Victor Harbor",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Victoria Gardens Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Victoria Point",
    "priceZone": "ZC1"
  },
  {
    "label": "Villawood",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wagga Homebase",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wallan",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wallan 1 (Southbound)",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wallan 2 (Northbound)",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wangaratta",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wantirna",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warnbro",
    "priceZone": "ZC11"
  },
  {
    "label": "Warner",
    "priceZone": "ZC1"
  },
  {
    "label": "Warners Bay",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warragul",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warragul Queen Street",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warrawong",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warrnambool City",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warwick",
    "priceZone": "ZC2"
  },
  {
    "label": "Warwick Farm",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Warwick WA",
    "priceZone": "ZC11"
  },
  {
    "label": "Waterfront City Docklands",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Watergardens",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Welland Plaza",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wellington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wendouree",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wentworthville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Werribee",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Werribee Plaza",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Werrington",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "West End Plaza Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "West Gosford",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "West Lakes",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Westfield Geelong",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Westgate Port Melbourne",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Westpoint Food Court",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wetherill Park (Outside)",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wetherill Park Mall",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Whitfords",
    "priceZone": "ZC11"
  },
  {
    "label": "Whyalla",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Willetton",
    "priceZone": "ZC11"
  },
  {
    "label": "William Street",
    "priceZone": "ZC11"
  },
  {
    "label": "Wilsonton",
    "priceZone": "ZC2"
  },
  {
    "label": "Woden",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wodonga",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wollongong Central",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wonthaggi",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Woodbine",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Woodgrove",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Woodlands",
    "priceZone": "ZC3"
  },
  {
    "label": "Woodridge",
    "priceZone": "ZC1"
  },
  {
    "label": "Woy Woy",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wurtulla",
    "priceZone": "ZC2"
  },
  {
    "label": "Wyndham Vale",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Wynnum",
    "priceZone": "ZC1"
  },
  {
    "label": "Wyoming",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Yagoona",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Yamanto",
    "priceZone": "ZC1"
  },
  {
    "label": "Yarraville",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Yarrawonga",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Yass",
    "priceZone": "Yum Zone 1"
  },
  {
    "label": "Yeppoon",
    "priceZone": "ZC3"
  },
  {
    "label": "Young",
    "priceZone": "Yum Zone 1"
  }
]

  const tableCustomStyle = {
    responsiveWrapper: {
      style: {
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      }
    },
    head: {
      style: {
        minHeight: '90px',
        borderRadius: '20px'
      }
    },
    rows: {
      style: {
        paddingLeft: '25px',
        minHeight: '90px',
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#000000',
      },
    },
    cells: {
      style: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#000000',
      },
    },
  };


  let tablePreloader = <div style={{ height: '600px', position: 'relative' }} className="loadingPosition">
    <div style={{ position: 'absolute', top: '50%' }} id="loader" />
  </div>;

  const DropdownStyle = {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '10px 20px',
      cursor: 'pointer',
      height: '33px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#FA3535'
      }
    }),
    control: (base, state) => ({
      ...base,
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '180px',
      height: '33px',
      borderRadius: '10px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '10px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '10px',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  let storeName = location.pathname.split("/")[7].replace(/_/g, " ");

  useEffect(() => {
    fetch('https://3.137.141.92:3525/store', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        restaurantName: toTitleCase(currentLocation[7].replaceAll('-', ' '))
      })
    })
      .then(response => response.json())
      .then(data => {
        setStores(data.result);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  let podsName = window.location.href.split("/")[4];
  let timePeriod = window.location.href.split("/")[5];
  let zonesName = window.location.href.split("/")[7];

  const CustomStoresTitle = ({ row }) => (
    <Link className='stores-title'
      to={{ pathname: location.pathname + row.restaurantName.replace(/ /g, "_") }}>
      {row.restaurantName}
    </Link>
  );

  const CustomTimeOfDay = ({ row }) => (
    <div className="store-radio">
      <div className={'row'}>
        <div className={'col'}>
          <input type="checkbox" id={`store-radio-btn__all-${row._id}`} />
          <label htmlFor={`store-radio-btn__all-${row._id}`}>All Day</label>
        </div>
        <div className={'col'}>
          <input type="checkbox" id={`store-radio-btn__peak-${row._id}`} />
          <label htmlFor={`store-radio-btn__peak-${row._id}`}>Peak</label>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col'}>
          <input type="checkbox" id={`store-radio-btn__late-night-${row._id}`} />
          <label htmlFor={`store-radio-btn__late-night-${row._id}`}>Late Night</label>
        </div>
        <div className={'col'}>
          <input type="checkbox" id={`store-radio-btn__off-peak-${row._id}`} />
          <label htmlFor={`store-radio-btn__off-peak-${row._id}`}>Off-Peak</label>
        </div>
      </div>
    </div>
  );

  let openFirstElement = () => {
    let menuDayTime = document.querySelector('.menu-boards--day-time');
    if (menuDayTime.classList.contains('active')) {
      document.querySelector('.store__section--menu-boards .menu-boards--day-time .col:first-child .file-container > div:first-child').click()
    } else {
      document.querySelector('.store__section--menu-boards .menu-boards--last-night .col:first-child .file-container > div:first-child').click()
    }
  }
  let openSecondElement = () => {
    let menuDayTime = document.querySelector('.menu-boards--day-time');
    if (menuDayTime.classList.contains('active')) {
      document.querySelector('.store__section--menu-boards .menu-boards--day-time .col:nth-child(2) .file-container > div:first-child').click()
    } else {
      document.querySelector('.store__section--menu-boards .menu-boards--last-night .col:nth-child(2) .file-container > div:first-child').click()
    }
  }
  let openThirdElement = () => {
    let menuDayTime = document.querySelector('.menu-boards--day-time');
    if (menuDayTime.classList.contains('active')) {
      document.querySelector('.store__section--menu-boards .menu-boards--day-time .col:nth-child(3) .file-container > div:first-child').click()
    } else {
      document.querySelector('.store__section--menu-boards .menu-boards--last-night .col:nth-child(3) .file-container > div:first-child').click()
    }
  }
  let openFourthElement = () => {
    let menuDayTime = document.querySelector('.menu-boards--day-time');
    if (menuDayTime.classList.contains('active')) {
      document.querySelector('.store__section--menu-boards .menu-boards--day-time .col:nth-child(4) .file-container > div:first-child').click()
    } else {
      document.querySelector('.store__section--menu-boards .menu-boards--last-night .col:nth-child(4) .file-container > div:first-child').click()
    }
  }
  let openFifthElement = () => {
    let menuDayTime = document.querySelector('.menu-boards--day-time');
    if (menuDayTime.classList.contains('active')) {
      document.querySelector('.store__section--menu-boards .menu-boards--day-time .col:nth-child(5) .file-container > div:first-child').click()
    } else {
      document.querySelector('.store__section--menu-boards .menu-boards--last-night .col:nth-child(5) .file-container > div:first-child').click()
    }
  }



  const CustomNumberOfTest = ({ row }) => (
    <div className="stores-layout">
      <div className="col">
        {(() => {
          if (row.InternalMenuLayout === '█ █ █ █ █') {
            return (
              <div className={'large'}>
                <span onClick={openFirstElement} />
                <span onClick={openSecondElement} />
                <span onClick={openThirdElement} />
                <span onClick={openFourthElement} />
                <span onClick={openFifthElement} />
              </div>
            )
          }
          if (row.InternalMenuLayout === '▀ ▀ ▀ ▀ ▀') {
            return (
              <div className={'short'}>
                <span onClick={openFirstElement} />
                <span onClick={openSecondElement} />
                <span onClick={openThirdElement} />
                <span onClick={openFourthElement} />
                <span onClick={openFifthElement} />
              </div>
            )
          } else if (row.InternalMenuLayout === '▀ ▀ ▀') {
            return (
              <div className={'short'}>
                <span onClick={openFirstElement} />
                <span onClick={openSecondElement} />
                <span onClick={openThirdElement} />
              </div>
            )
          } else if (row.InternalMenuLayout === '▀ ▀ ▀ ▀') {
            return (
              <div className={'short'}>
                <span onClick={openFirstElement} />
                <span onClick={openSecondElement} />
                <span onClick={openThirdElement} />
                <span onClick={openFourthElement} />
              </div>
            )
          } else {
            return (
              <span> </span>
            )
          }
        })()}
        {row.StoreTypeMenu}
      </div>
      <div className="col">
        <div>
          <span />
          <span />
          <span />
        </div>
        <span>DTM</span>
      </div>
      <div className="col">
        <div>
          <span />
          <span>P1</span>
        </div>
        <div>
          <span />
          <span>P2</span>
        </div>
      </div>
      <div className="col">
        <span />
        <span>K</span>
      </div>
      <div className="col">
        <div>
          <span />
          <span>1500</span>
        </div>
        <div>
          <span />
          <span>700</span>
        </div>
      </div>
    </div>
  );

  const CustomStores = ({ row }) => (
    <Link className='stores-title stores-title--lsm'
      to={{ pathname: location.pathname + row.restaurantName.replace(/ /g, "_") }}>
      <span>LSM</span>
      <a>Visit location</a>
    </Link>
  );

  const CustomPromotion = ({ row }) => (
    <Link className='stores-title stores-title--lsm'
      to={{ pathname: location.pathname + row.restaurantName.replace(/ /g, "_") }}>
      <span>Notes</span>
      <a>Additional Information</a>
    </Link>
  );

  const CustomStatus = ({ row }) => (
    <div className="store-test store-test">
      <div>
        <span>Test running: 5</span>
        <Select
          defaultValue={{ value: 'store_test_01', label: 'Name of the test' }}
          className="dropdown dropdown--zone"
          isSearchable={false}
          styles={DropdownStyle}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
      </div>
      <Popup className="store-status__popup" trigger={<button>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.99976 12C9.99976 13.1046 10.8952 14 11.9998 14C13.1043 14 13.9998 13.1046 13.9998 12C13.9998 10.8954 13.1043 10 11.9998 10C10.8952 10 9.99976 10.8954 9.99976 12Z"
            fill="black" />
          <path
            d="M9.99976 4C9.99976 5.10457 10.8952 6 11.9998 6C13.1043 6 13.9998 5.10457 13.9998 4C13.9998 2.89543 13.1043 2 11.9998 2C10.8952 2 9.99976 2.89543 9.99976 4Z"
            fill="black" />
          <path
            d="M9.99976 20C9.99976 21.1046 10.8952 22 11.9998 22C13.1043 22 13.9998 21.1046 13.9998 20C13.9998 18.8954 13.1043 18 11.9998 18C10.8952 18 9.99976 18.8954 9.99976 20Z"
            fill="black" />
        </svg>
      </button>} position="bottom right">
        <div className="store-status__popup__container">
          <span>Zone Report</span>
          <span>Delete Zone</span>
          <span>Edit Zone</span>
        </div>
      </Popup>
    </div>
  );

  const ZoneRadio = ({ row }) => {
    return (
      <div className='stores-radio'>
        <div className='row'>
          <div className='col'>
            <input type="checkbox" id={`stores-radio-btn-all-${row.id}`} />
            <label className='hover-white' htmlFor={`stores-radio-btn-all-${row.id}`}>All Day</label>
          </div>
          <div className='col'>
            <input type="checkbox" id={`stores-radio-btn-peak-${row.id}`} />
            <label className='hover-white' htmlFor={`stores-radio-btn-peak-${row.id}`}>Peak</label>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <input type="checkbox" id={`stores-radio-btn-late-night-${row.id}`} />
            <label className='hover-white' htmlFor={`stores-radio-btn-late-night-${row.id}`}>Late Night</label>
          </div>
          <div className='col'>
            <input type="checkbox" id={`stores-radio-btn-off-peak-${row.id}`} />
            <label className='hover-white' htmlFor={`stores-radio-btn-off-peak-${row.id}`}>Off-Peak</label>
          </div>
        </div>
      </div>
    )
  };

  let options = [
    { value: 'Name of the test', label: 'Name of the test' }
  ]

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '180px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '400',
      fontSize: '14px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 10px',
      cursor: 'pointer',
      height: '33px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 10px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '180px',
      minHeight: '33px',
      height: '33px',
      borderRadius: '4px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      fontWeight: '400',
      fontSize: '14px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    }),
    singleValue: base => ({
      ...base,
      margin: 0
    })
  };

  const openPopup = (e) => {
    e.target.parentElement.querySelector('.info-popup').classList.toggle('active')
  }

  const Status = ({ row }) => {
    return (
      <div className='stores__status'>
        <Select
          options={options}
          styles={style}
          defaultValue={options[0]}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
        <button onClick={openPopup}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z" fill="black" />
            <path d="M10 4C10 5.10457 10.8954 6 12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4Z" fill="black" />
            <path d="M10 20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20Z" fill="black" />
          </svg>
        </button>
        <div className='info-popup'>
          <button>Edit Location/Store</button>
          <button>Tag Loaction/Store</button>
          <button>Download All Files</button>
          <button>Make Report</button>
        </div>
      </div>
    )
  };




  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Stores',
      selector: row => <h6 className='pods__title hover-white'>{row.restaurantName}</h6>,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Time of day',
      selector: row => <ZoneRadio row={row} />,
      sortable: true,
      maxWidth: '176px'
    },
    {
      name: 'Layout',
      selector: row =>
        <div className="stores__layout">
          <div className="col">
            <div className="short">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span className='hover-white'>{row.contentPlanSchedule}</span>
          </div>
          <div className="col">
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <span className='hover-white'>DTM</span>
          </div>
          <div className="col">
            <div>
              <span></span>
              <span className='hover-white'>P1</span>
            </div>
            <div>
              <span></span>
              <span className='hover-white'>P2</span>
            </div>
          </div>
          <div className="col">
            <span></span>
            <span className='hover-white'>K</span>
          </div>
          <div className="col">
            <div>
              <span></span>
              <span className='hover-white'>1500</span>
            </div>
            <div>
              <span></span>
              <span className='hover-white'>700</span>
            </div>
          </div>
        </div>,
      sortable: true,
      minWidth: '412px'
    },
    {
      name: 'DAM',
      selector: row => <div className='stores__info'>
        <h6 className='hover-white'>LSM</h6>
        <a className='hover-white' href="/">Addtional Information</a>
      </div>,
      sortable: true
    },
    {
      name: 'Notes',
      selector: row => <div className='stores__info'>
        <h6 className='hover-white'>Notes</h6>
        <a className='hover-white' href="/">Addtional Information</a>
      </div>,
      sortable: true
    },
    {
      name: 'File Status',
      selector: row => <Status row={row} />,
      sortable: true,
      minWidth: '230px'
    }
  ];

  let currentLocation = window.location.pathname.split('/');

  const handleClick = (event) => {
    event.target.parentElement.querySelector('.crud-btn-popup').classList.toggle('active')
  };

  const handleClickPopup = (event) => {
    event.target.parentElement.querySelector('.crud-btn-popup').classList.toggle('active')
  };


  const [storeFile, setStoreFile] = useState([]);
  const [storeFolder, setStoreFolder] = useState([]);

  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  console.log(currentLocation[3])
  console.log(currentLocation[2].toUpperCase())
  console.log(toTitleCase(currentLocation[5].replaceAll('-', ' ')).replaceAll(' ', '_'))
  console.log(toTitleCase(currentLocation[7].replaceAll('-', ' ')).replaceAll(' ', '_'))

  useEffect(() => {
    fetch('https://3.137.141.92:3525/store-files-list', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        timePeriod: currentLocation[3],
        podName: currentLocation[2].toUpperCase(),
        zoneName: toTitleCase(currentLocation[5].replaceAll('-', ' ')).replaceAll(' ', '-'),
        storeName: toTitleCase(currentLocation[7].replaceAll('-', ' ')).replaceAll(' ', '-')
      }),
    })
      .then(response => response.json())
      .then(result => {
        setStoreFile(result);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  console.log(storeFile)

  useEffect(() => {
    fetch('https://3.137.141.92:3525/last-modificate-folders', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        timePeriod: currentLocation[3],
        podName: currentLocation[2].toUpperCase(),
        zoneName: toTitleCase(currentLocation[5].replaceAll('-', ' ')).replaceAll(' ', '-'),
        storeName: toTitleCase(currentLocation[7].replaceAll('-', ' ')).replaceAll(' ', '-')
      }),
    })
      .then(response => response.json())
      .then(result => {
        setStoreFolder(result);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  let FileContainer = (props) => {
    return (
      <div className={'file-container'}>
        <Popup
          trigger={
            (() => {
              if (props.item.url.split('/').pop().replace('.', ' ').split(" ").pop() === 'jpg' || props.item.url.split('/').pop().replace('.', ' ').split(" ").pop() === 'png') {
                return (
                  <div>
                    <img src={props.item.url} />
                  </div>
                )
              } else {
                return (
                  <div>
                    <video
                      autoPlay
                      loop
                      src={props.item.url}
                    />
                  </div>
                )
              }
            })()
          }
          modal
          nested
          className={'store-content__popup'}
        >
          {close => (
            <div className="store-content__popup__content">
              <h5>{props.item.fileName}</h5>
              <p><strong>Modified
                date:</strong> {props.item.month} {ordinal_suffix_of(props.item.day)} {props.item.year}
              </p>
              <button onClick={close} className={'btn-close'}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.9181 0.00138263C13.6856 0.0116368 13.4669 0.114174 13.3114 0.286781C13.1541 0.457678 13.0738 0.684977 13.0841 0.917394C13.0943 1.14981 13.1969 1.36854 13.3695 1.52408C13.5404 1.6813 13.7677 1.76162 14.0001 1.75137C20.7761 1.75137 26.2501 7.22537 26.2501 14.0014C26.2501 20.7774 20.7761 26.2514 14.0001 26.2514C7.22409 26.2514 1.75009 20.7774 1.75009 14.0014C1.75009 8.21806 5.74057 3.38674 11.1152 2.09306C11.5869 1.98368 11.8808 1.51201 11.7715 1.04034C11.6621 0.568675 11.1904 0.274719 10.7188 0.384094C4.57144 1.86407 0 7.41122 0 14.0013C0 21.7223 6.279 28.0013 14 28.0013C21.721 28.0013 28 21.7223 28 14.0013C28 6.28028 21.721 0.00128166 14 0.00128166C13.9727 -0.000427219 13.9453 -0.000427219 13.918 0.00128166L13.9181 0.00138263ZM9.09199 8.30032C8.7519 8.33279 8.46138 8.5618 8.3503 8.88478C8.2375 9.20949 8.32466 9.56837 8.57246 9.80423L12.7697 14.0015L8.57246 18.1988C8.37935 18.3543 8.26143 18.585 8.24775 18.8328C8.23408 19.0806 8.32637 19.3233 8.50239 19.4993C8.67842 19.6753 8.92109 19.7676 9.16888 19.754C9.41668 19.7403 9.64742 19.6224 9.8029 19.4292L14.0002 15.232L18.1975 19.4292C18.353 19.6224 18.5837 19.7403 18.8315 19.754C19.0793 19.7676 19.322 19.6753 19.498 19.4993C19.674 19.3233 19.7663 19.0806 19.7526 18.8328C19.739 18.585 19.621 18.3543 19.4279 18.1988L15.2306 14.0015L19.4279 9.80423C19.6877 9.55301 19.7663 9.1702 19.6262 8.83696C19.4877 8.50541 19.1596 8.29179 18.799 8.30032C18.57 8.30887 18.3547 8.40799 18.1975 8.57376L14.0002 12.771L9.80289 8.57376C9.63883 8.40115 9.41153 8.30203 9.17398 8.30032C9.14664 8.29861 9.11929 8.29861 9.09195 8.30032H9.09199Z"
                    fill="black" />
                </svg>
              </button>
              <div className={'row'}>
                <div className={'col'}>
                  {/*<img src={props.item.url} alt={props.item.fileName}/>*/}
                  {
                    (() => {
                      if (props.item.url.split('/').pop().replace('.', ' ').split(" ").pop() === 'jpg' || props.item.url.split('/').pop().replace('.', ' ').split(" ").pop() === 'png') {
                        return (
                          <div>
                            <img src={props.item.url} />
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <video
                              autoPlay
                              loop
                              src={props.item.url}
                            />
                          </div>
                        )
                      }
                    })()
                  }
                  <button className={'crud-file'} onClick={handleClickPopup}>
                    <svg width="31" height="32" viewBox="0 0 31 32" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15.5" cy="16.0107" r="15" fill="white" stroke="#E3E3E3" />
                      <path
                        d="M13.6944 16.3706C13.6944 17.1669 14.3399 17.8124 15.1363 17.8124C15.9326 17.8124 16.5781 17.1669 16.5781 16.3706C16.5781 15.5743 15.9326 14.9287 15.1363 14.9287C14.3399 14.9287 13.6944 15.5743 13.6944 16.3706Z"
                        fill="black" />
                      <path
                        d="M13.6944 10.603C13.6944 11.3993 14.34 12.0449 15.1363 12.0449C15.9326 12.0449 16.5781 11.3993 16.5781 10.603C16.5781 9.80668 15.9326 9.16113 15.1363 9.16113C14.34 9.16113 13.6944 9.80668 13.6944 10.603Z"
                        fill="black" />
                      <path
                        d="M13.6944 22.1381C13.6944 22.9345 14.34 23.58 15.1363 23.58C15.9326 23.58 16.5781 22.9345 16.5781 22.1381C16.5781 21.3418 15.9326 20.6963 15.1363 20.6963C14.34 20.6963 13.6944 21.3418 13.6944 22.1381Z"
                        fill="black" />
                    </svg>
                  </button>
                  <div className={'crud-btn-popup'}>
                    <button>Upload File</button>
                    <button>Edit Files</button>
                    <button>Tag file</button>
                    <button>Download files</button>
                    <button>Make Report</button>
                    <button>Edit all files - Identical</button>
                  </div>
                  <a href={props.item.url} className={'download-file'} download>DOWNLOAD FILE</a>
                </div>
                <div className={'col'}>
                  <h6>Title of file</h6>
                  <p>Further details if needed</p>
                  <hr />
                  {/* <AddTags
                    podsName={podsName}
                    timePeriod={timePeriod}
                    zonesName={zonesName}
                    storeName={storeName}
                    fileName={props.item.fileName}
                    dayTime={props.item.url.split("/")[10]}
                    screenNumber={props.item.url.split("/")[11]}
                  /> */}
                </div>
              </div>
            </div>
          )}
        </Popup>
        <button className={'crud-btn'} onClick={handleClick}>
          <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.5" cy="16.0107" r="15" fill="white" stroke="#E3E3E3" />
            <path
              d="M13.6944 16.3706C13.6944 17.1669 14.3399 17.8124 15.1363 17.8124C15.9326 17.8124 16.5781 17.1669 16.5781 16.3706C16.5781 15.5743 15.9326 14.9287 15.1363 14.9287C14.3399 14.9287 13.6944 15.5743 13.6944 16.3706Z"
              fill="black" />
            <path
              d="M13.6944 10.603C13.6944 11.3993 14.34 12.0449 15.1363 12.0449C15.9326 12.0449 16.5781 11.3993 16.5781 10.603C16.5781 9.80668 15.9326 9.16113 15.1363 9.16113C14.34 9.16113 13.6944 9.80668 13.6944 10.603Z"
              fill="black" />
            <path
              d="M13.6944 22.1381C13.6944 22.9345 14.34 23.58 15.1363 23.58C15.9326 23.58 16.5781 22.9345 16.5781 22.1381C16.5781 21.3418 15.9326 20.6963 15.1363 20.6963C14.34 20.6963 13.6944 21.3418 13.6944 22.1381Z"
              fill="black" />
          </svg>
        </button>
        <div className={'crud-btn-popup'}>
          <button>Upload File</button>
          <button>Edit Files</button>
          <button>Tag file</button>
          <button>Download files</button>
          <button>Make Report</button>
          <button>Edit all files - Identical</button>
        </div>
      </div>
    )
  }

  let chengeImageSize = () => {
    let firstMenuBoard = document.querySelectorAll('.menu-boards--day-time .col:nth-child(2) > div')
    let lastMenuBoard = document.querySelectorAll('.menu-boards--day-time .col:nth-child(3) > div')
    if (firstMenuBoard.length >= 2) {
      document.querySelector('.menu-boards--day-time .col:nth-child(2)').classList.add('double')
    }
    if (lastMenuBoard.length >= 2) {
      document.querySelector('.menu-boards--day-time .col:nth-child(3)').classList.add('double')
    }
  }

  chengeImageSize()



  let onChangeMenuBoards = (e) => {
    if (e.target.checked) {
      document.querySelector('.menu-boards--day-time').classList.remove('active')
      document.querySelector('.menu-boards--last-night').classList.add('active')
    } else {
      document.querySelector('.menu-boards--day-time').classList.add('active')
      document.querySelector('.menu-boards--last-night').classList.remove('active')
    }
  }

  let onChangeDriveThrough = (e) => {
    if (e.target.checked) {
      document.querySelector('.drive-through--day-time').classList.remove('active')
      document.querySelector('.drive-through--last-night').classList.add('active')
    } else {
      document.querySelector('.drive-through--day-time').classList.add('active')
      document.querySelector('.drive-through--last-night').classList.remove('active')
    }
  }

  const changePod = (e) => {
    const location = window.location;
    const locationOrigin = location.origin;
    const locationPathname = location.pathname;
    const locationState = locationPathname.split('/').slice(3).join('/');
    const pods = e.value.toLowerCase();

    window.location.href = `${locationOrigin}/pods/${pods}/${locationState}`;
  }

  const changeZone = (e) => {
    const location = window.location;
    const locationOrigin = location.origin;
    const locationPathname = location.pathname.split('/');
    const zone = e.value.toLowerCase().replaceAll(' ', '-');

    window.location.href = `${locationOrigin}/pods/${locationPathname[2]}/${locationPathname[3]}/zones/${zone}/stores`;
  }

  const changeStore = (e) => {
    const location = window.location;
    const locationOrigin = location.origin;
    const locationPathname = location.pathname.split('/');
    const store = e.label.toLowerCase().replaceAll(' ', '-');
    const priceZone = e.priceZone.toLowerCase().replaceAll(' ', '-');

    window.location.href = `${locationOrigin}/pods/${locationPathname[2]}/${locationPathname[3]}/zones/${priceZone}/stores/${store}`;
  }

  return (
    <div className={'store'}>
      <div className='stores'>
        <div style={{ marginBottom: '22px' }} className='stores__bar'>
          <div className='stores__bar--selectors'>
            <Select
              options={optionsPods}
              styles={styleFiltration}
              isSearchable={false}
              defaultValue={{ label: podsId.toUpperCase() }}
              onChange={changePod}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
              }}
            />
            <Select
              options={optionsZones}
              styles={styleFiltration}
              isSearchable={false}
              defaultValue={{ label: toTitleCase(strZone) }}
              onChange={changeZone}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
              }}
            />
            <Select
              options={optionsStores}
              styles={styleFiltration}
              isSearchable={false}
              defaultValue={{ label: toTitleCase(strStore) }}
              onChange={changeStore}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
              }}
            />
          </div>
          <div className='stores__bar__settings'>
            <TestMarket />
            <button className='btn__upload-files'>
              <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.5 19H7C3.685 19 1 16.315 1 13C1 9.685 3.685 7 7 7H8.065C9.055 3.535 12.22 1 16 1C20.56 1 24.25 4.69 24.25 9.25V10H26.5C28.99 10 31 12.01 31 14.5C31 16.99 28.99 19 26.5 19Z" fill="white" stroke="#707579" strokeWidth="1.5"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={'store-table store-table'}>
        <DataTable
          noDataComponent={tablePreloader}
          noHeader={true}
          columns={columns}
          sortIcon={customSortIcon}
          data={stores}
          customStyles={tableCustomStyle}
        />
      </div>
      <div style={{ marginTop: '55px' }} className={'store__section store__section--menu-boards'}>
        <div className={'store__section__row'}>
          {storeFolder.filter(
            item => item.section.includes("Menu-Boards")).map(item => (
              <>
                <h4>Menu
                  Boards <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
                </h4>
              </>
            ))}
          {/* <FormGroup className="default-switch">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Daytime</Grid>
              <Grid item>
                <Switch
                  onChange={onChangeMenuBoards}
                  value="checked"
                />
              </Grid>
              <Grid item>Late Night</Grid>
            </Grid>
          </FormGroup> */}
        </div>
        <div className={'row menu-boards--day-time active'}>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Daytime/Screen-01")).map(item => (
                <div>
                  <FileContainer item={item} props={1} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Daytime/Screen-02")).map(item => (
                <div>
                  <FileContainer item={item} props={2} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Daytime/Screen-03")).map(item => (
                <div>
                  <FileContainer item={item} props={3} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Daytime/Screen-04")).map(item => (
                <div>
                  <FileContainer item={item} props={4} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Daytime/Screen-05")).map(item => (
                <div>
                  <FileContainer item={item} props={5} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
        </div>
        <div className={'row menu-boards--last-night'}>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Late-Night/Screen-01")).map(item => (
                <div>
                  <FileContainer item={item} props={1} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Late-Night/Screen-02")).map(item => (
                <div>
                  <FileContainer item={item} props={2} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Late-Night/Screen-03")).map(item => (
                <div>
                  <FileContainer item={item} props={3} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Late-Night/Screen-04")).map(item => (
                <div>
                  <FileContainer item={item} props={4} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Menu-Boards/Late-Night/Screen-05")).map(item => (
                <div>
                  <FileContainer item={item} props={5} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--drive-through'}>
        <div className={'store__section__row'}>
          {storeFolder.filter(
            item => item.section.includes("Drive-Through")).map(item => (
              <>
                <h4>Drive
                  Through <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
                </h4>
              </>
            ))}
          {/* <FormGroup className="default-switch">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Daytime</Grid>
              <Grid item>
                <Switch
                  onChange={onChangeDriveThrough}
                  value="checked"
                />
              </Grid>
              <Grid item>Late Night</Grid>
            </Grid>
          </FormGroup> */}
        </div>
        <div className={'row drive-through--day-time active'}>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Daytime/Screen-01")).map(item => (
                <div>
                  <FileContainer item={item} props={1} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col three-cards'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Daytime/Screen-02")).map(item => (
                <div>
                  <FileContainer item={item} props={2} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>

          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Daytime/Screen-03")).map(item => (
                <div>
                  <FileContainer item={item} props={3} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Daytime/Screen-04")).map(item => (
                <div>
                  <FileContainer item={item} props={4} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Daytime/Screen-05")).map(item => (
                <div>
                  <FileContainer item={item} props={5} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
        </div>
        <div className={'row drive-through--last-night'}>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Late-Night/Screen-01")).map(item => (
                <div>
                  <FileContainer item={item} props={1} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Late-Night/Screen-02")).map(item => (
                <div>
                  <FileContainer item={item} props={2} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Late-Night/Screen-03")).map(item => (
                <div>
                  <FileContainer item={item} props={3} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Late-Night/Screen-04")).map(item => (
                <div>
                  <FileContainer item={item} props={4} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
          <div className={'col'}>
            {storeFile.filter(
              item => item.url.includes("Drive-Through/Late-Night/Screen-05")).map(item => (
                <div>
                  <FileContainer item={item} props={5} />
                  <h6>{item.fileName}</h6>
                </div>
              ))}
          </div>
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--pre-readers'}>
        {storeFolder.filter(
          item => item.section.includes("Pre-Readers")).map(item => (
            <>
              <h4>Pre
                Readers <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
              </h4>
            </>
          ))}
        <div className={'row'}>
          {storeFile.filter(
            item => item.section.includes("Pre-Readers")).map(item => (
              <div className={'col'}>
                <div>
                  <FileContainer item={item} />
                  <h6>{item.fileName}</h6>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--aloha'}>
        {storeFolder.filter(
          item => item.section.includes("Aloha")).map(item => (
            <>
              <h4>Aloha <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
              </h4>
            </>
          ))}
        <div className={'row'}>
          {storeFile.filter(
            item => item.section.includes("Aloha")).map(item => (
              <div className={'col'}>
                <div>
                  <FileContainer item={item} />
                  <h6>{item.fileName}</h6>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--blades'}>
        {storeFolder.filter(
          item => item.section.includes("Krusher-Blades")).map(item => (
            <>
              <h4>Krusher
                Blades <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
              </h4>
            </>
          ))}
        <div className={'row'}>
          {storeFile.filter(
            item => item.section.includes("Blades")).map(item => (
              <div className={'col'}>
                <div>
                  <FileContainer item={item} />
                  <h6>{item.fileName}</h6>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--posters'}>
        {storeFolder.filter(
          item => item.section.includes("Posters")).map(item => (
            <>
              <h4>Posters <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
              </h4>
            </>
          ))}
        <div className={'row'}>
          {storeFile.filter(
            item => item.section.includes("Posters")).map(item => (
              <div className={'col'}>
                <div>
                  <FileContainer item={item} />
                  <h6>{item.fileName}</h6>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--banners'}>
        {storeFolder.filter(
          item => item.section.includes("Banners")).map(item => (
            <>
              <h4>Banners <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
              </h4>
            </>
          ))}
        <div className={'row'}>
          {storeFile.filter(
            item => item.section.includes("Banners")).map(item => (
              <div className={'col'}>
                <div>
                  <FileContainer item={item} />
                  <h6>{item.fileName}</h6>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>
      <div className={'store__section store__section--table-tags'}>
        {storeFolder.filter(
          item => item.section.includes("Table-Tags")).map(item => (
            <>
              <h4>Table-Tags <span>Last Update: {(item.day >= 10) ? `${item.day}` : `0${item.day}`}/{(item.month >= 10) ? `${item.month}` : `0${item.month}`}/{item.year}</span>
              </h4>
            </>
          ))}
        <div className={'row'}>
          {storeFile.filter(
            item => item.section.includes("Table-Tags")).map(item => (
              <div className={'col'}>
                <div>
                  <FileContainer item={item} />
                  <h6>{item.fileName}</h6>
                </div>
              </div>
            ))}
        </div>
        <hr />
      </div>
    </div>
  )
}

export default Store