import React from 'react';
import Select, { components } from 'react-select';

import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import './General.scss';

function General({ title }) {
  PageDescription('General', 'Settings');
  TabTitle(title);

  let options = [
    { value: 'intoto', label: 'Inototo' },
    { value: 'kfc', label: 'KFC' },
    { value: 'krispy-kreme', label: 'Krispy Kreme' }
  ]

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '379px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '400',
      fontSize: '14px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 10px',
      cursor: 'pointer',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 10px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '379px',
      height: '36px',
      borderRadius: '4px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      fontWeight: '400',
      fontSize: '14px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    })
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  return (
    <div className='settings__general'>
      <h2>General</h2>
      <p>Here you global swap out the logo for a Group of users.</p>
      <div className='settings__general--selector'>
        <h4>Change General site logo</h4>
        <p>Logos (If you do not see your logo, <a href="/">please contact dexter</a>)</p>
        <Select
          defaultValue={{ "value": "Select logo", "label": "Select logo" }}
          options={options}
          styles={style}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
      </div>
      <div className='settings__general--selector'>
        <h4>Change Business owner site logo</h4>
        <p>Logos (If you do not see your logo, <a href="/">please contact dexter</a>)</p>
        <Select
          defaultValue={{ "value": "Select logo", "label": "Select logo" }}
          options={options}
          styles={style}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
      </div>
      <div className='settings__general--selector'>
        <h4>Change Marketing site logo</h4>
        <p>Logos (If you do not see your logo, <a href="/">please contact dexter</a>)</p>
        <Select
          defaultValue={{ "value": "Select logo", "label": "Select logo" }}
          options={options}
          styles={style}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
        <button>SAVE</button>
      </div>
    </div>
  )
}

export default General