import React from 'react';
import './style.scss';
import { ReactComponent as ClosePopupIcon } from '../../../../assets/icons/ClosePopupIcon.svg';

function UserInfoPopup({ children, trigger, setTrigger, style }) {

    let body = document.querySelector('body');
    body.addEventListener('click', (e) => {
        let btnUserInfoPopup = document.querySelector('.btn-user-info-popup');

        if (e.target !== btnUserInfoPopup) {
            setTrigger(false);
        }
    })
    return (trigger) ? (
        <div className='user-info-popup'>
            <div style={style} className='user-info-popup-inner'>
                <button className='close-btn' onClick={() => setTrigger(false)}><ClosePopupIcon /></button>
                <div className='user-info-popup-content'>
                    {children}
                </div>
            </div>
        </div>
    ) : null;
}

export default UserInfoPopup