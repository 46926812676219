import { useContext } from 'react';
import Logo from './Logo/Logo';
import PageDescription from './PageDescription/PageDescription';
import SearchBar from './SearchBar/SearchBar';
import UserInfo from './UserInfo/UserInfo';
import './style.scss';
import { AuthContext } from '../../context/auth';

function Header() {
    const { user } = useContext(AuthContext);
    return (
        <header>
            {user ? (
                <>
                    <Logo />
                    <SearchBar />
                    <UserInfo />
                </>
            ) : (
                <Logo />
            )}
        </header>
    )
}

export default Header