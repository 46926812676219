import React, { useState } from 'react';
import axios from 'axios';
import Popup from './Popup';
import { toast } from 'react-toastify';
import previewFile from './preview-file.png';
import TagField from '../TagField/TagField';
import { useLocation, useNavigate } from 'react-router-dom';

import './FileUpload.scss';

const notifyError = () => toast.error('Fill in the required fields', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const notifySuccess = () => toast.success('Success', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const makeNiceName = (name) => {
	//Is it a file?
	if (name.substr(-4,1) === '.')
	{
		name = name.substr(0,name.length -4);
	}
	
    return name .replace('&','and')
				.replace(/[^a-zA-Z0-9$\.]/g, ' ')
                .replace(/\s{2,}/g, ' ')
                .trim();
}

export default function UploadPopup(props) {
    const navigateTo = useNavigate();
    const location = useLocation();

    const { trigger, setTrigger, style, popupStyle, closeBtn, customClass, category, onUpload } = props;
    const popupProps = {trigger, setTrigger, style, popupStyle, closeBtn, customClass};
    
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [previewFileSrc, setPreviewFileSrc] = useState(previewFile);
    const [tags, setTags] = useState([]);
    const [weight, setWeight] = useState(0);
    const [pinned, setPinned] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    
    // const [cardName, setCardName] = useState('');

    const onSubmit = async e => {
        e.preventDefault();

        if (file) {
            const data = new FormData();
            data.append('file', file);
            data.append('fileName', fileName);
            data.append('category', category.id);
            data.append('fileType', file.type);
            data.append('tags', tags);
            data.append('weight', weight);
            data.append('painned', pinned);
            data.append('isEditable', isEditable?1:0);

            try {
                //TODO change to mutation.
                const res = await fetch(process.env.REACT_APP_API_SERVER + 'lsm-upload-files', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json'
                    },
                    body: data
                });
                const uri = (await res.json()).data.uri;
                notifySuccess();
                onUpload();
                setTrigger(false);
                navigateTo(uri);
                setFile('');
                setFileName('');
                setPreviewFileSrc(previewFile);
                setTags([]);
                setWeight(0);
                setIsEditable(false);
            } catch (err) {
                console.log(err);
                notifyError(err);
            }
        } else {
            notifyError("Select a file first.");
        }
    };


    const onSelectFile = (e) =>
    {
        const file = e.target.files[0];
        const fileName = file.name;
        const niceName = makeNiceName(fileName);
        setFile(file);
        setFileName(niceName);
        e.target.parentElement.querySelector('label').innerText = fileName;

        // PDF
        if (e.target.files[0].type === 'application/pdf')
        {
            let iframe = document.createElement('iframe');
            iframe.src = e.target.files[0];
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function (e)
            {
                // The file reader gives us an ArrayBuffer:
                let buffer = e.target.result;

                // We have to convert the buffer to a blob:
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'application/pdf' });

                // The blob gives us a URL to the video file:
                let url = window.URL.createObjectURL(videoBlob);

                iframe.src = url + '#view=fit&toolbar=0&navpanes=0&scrollbar=0';
            }

            let container = document.querySelector('.file-preview');
            container.innerHTML = '';
            container.appendChild(iframe);
        }
        // VIDEO
        else if (e.target.files[0].type === 'video/mp4')
        {
            let video = document.createElement('video');
            video.src = e.target.files[0];
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function (e) {
                // The file reader gives us an ArrayBuffer:
                let buffer = e.target.result;

                // We have to convert the buffer to a blob:
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });

                // The blob gives us a URL to the video file:
                let url = window.URL.createObjectURL(videoBlob);

                video.src = url;
                video.style.width = '100%';
                // video.width = "400";
                video.controls = true;
                video.autoplay = true;
                video.muted = true;
                video.loop = true;

                let container = document.querySelector('.file-preview');
                container.innerHTML = '';
                container.appendChild(video);
            }
        }
        // AUDIO
        else if (e.target.files[0].type === 'audio/mpeg'
        || e.target.files[0].type === 'audio/wav')
        {
            let audio = document.createElement('iframe');

            audio.src = e.target.files[0];
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function (e)
            {
                // The file reader gives us an ArrayBuffer:
                let buffer = e.target.result;

                // We have to convert the buffer to a blob:
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'audio/mpeg' });

                // The blob gives us a URL to the video file:
                let url = window.URL.createObjectURL(videoBlob);

                audio.src = url;
            }
            let container = document.querySelector('.file-preview');
            container.innerHTML = '';
            container.appendChild(audio);
        }
        // IMG
        else
        {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function (e)
            {
                // The file reader gives us an ArrayBuffer:
                let buffer = e.target.result;

                // We have to convert the buffer to a blob:
                let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'application/pdf' });

                // The blob gives us a URL to the video file:
                let url = window.URL.createObjectURL(videoBlob);

                setPreviewFileSrc(url);
            }
        }
    }

    const onCancel = () => {
        if (typeof setTrigger == 'function')
        {
            setTrigger(false);
        }
    }

    const categoryName = category ? category.name : '';

    return (
        <Popup {...popupProps} title="Upload Asset" style={{maxWidth:'950px'}}>
            <div className='file-container'>
                <div className='file-preview'>
                    <img src={previewFileSrc} alt={'preview'} />
                </div>
                <form onSubmit={onSubmit}>
                {/* <form> */}
                    <div className='file-upload'>
                        <div className='field'>
                            <h6>Category</h6>
                            <input value={categoryName} type="text" readOnly />
                        </div>
                        <div className='field'>
                            <h6>Selected File</h6>
                            <p>You can upload one asset at a time.</p>
                            <div className='file-input'>
                                <input type='file' onChange={onSelectFile} />
                                <label className='label' data-js-label>No asset selected</label>
                            </div>
                        </div>
                        <div className='field'>
                            <h6>Asset Name</h6>
                            <input onChange={(e) => { setFileName(e.target.value)} } value={fileName} type="text" placeholder='Asset name...' />
                        </div>
                        <div className='field'>
                            <h6>Tags</h6>
                            <TagField tagsState={tags} setTagsState={setTags} />
                        </div>
                        <div className='field'>
                            <h6>Is Editable?</h6>
                            <input id="uploadIsEditable" onChange={(e) => { setIsEditable(!isEditable)} } value={1} type="checkbox" checked={isEditable} />
                            <label htmlFor="uploadIsEditable">Yes</label>
                        </div>
                        <div className='field'>
                            <h6>Pinned?</h6>
                            <input id="uploadPinned" onChange={(e) => { setPinned(!pinned)} } value={1} type="checkbox" checked={pinned} />
                            <label htmlFor="uploadPinned">Yes</label>
                        </div>
                        <button type='submit' className='upload-btn'>Upload</button>
                    </div>
                </form>
            </div>
        </Popup>
    );
}
