import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { PDFDocument, rgb } from 'pdf-lib';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const wrapperWidthHeight = {width: 680, height: 650};
const WrapperContainer = styled.div`
  width: ${wrapperWidthHeight.width}px;
  height: ${wrapperWidthHeight.height}px;
  overflow: hidden;
  overflow-y: auto;
`;

const wrapperRatio = wrapperWidthHeight.width / wrapperWidthHeight.height;

export default function({url, bytes, onBytesLoaded}) {
    // const [renders, setRenders] = useState(0);
    const [pdfDocument, setPdfDocument] = useState();
    const [canvas, setCanvas] = useState();
    const [viewport, setViewport] = useState();
    const [annotationEditor, setAnnotationEditor] = useState();
    const [numPages, setNumPages] = useState();
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [currentPage, setCurrentPage] = useState(null);
    const [computedRatio, setComputedRatio] = useState(null);
    const [scale, setScale] = useState(1);
    const [lockScale, setLockScale] = useState(false);
    
    const canvasRef = useRef();

    let fetchDocument = useCallback(async () =>
    {
      const canvas = canvasRef.current;
      let doc;
      let numPages = 0;
      setCanvas(canvas);
      if (bytes) {
        const loader = await pdfjs.getDocument({data: bytes});
        doc = await loader.promise;
        numPages = doc._pdfInfo.numPages;
      }
      else {
        const arrayBuffer = await fetch(url).then(res => res.arrayBuffer());
        const loader = await pdfjs.getDocument({data: arrayBuffer});
        onBytesLoaded(arrayBuffer);
        doc = await loader.promise;
        numPages = doc._pdfInfo.numPages;
      }
      
      if (numPages > 0)
      {
          setNumPages(doc._pdfInfo.numPages);
          const page = await doc.getPage(currentPageNum);
          setCurrentPage(page);

          let viewport;
          let thisScale = scale;
          if (!lockScale) {
            viewport = page.getViewport({ scale: 1 });
            if (viewport.width > 650)
            {
              thisScale = 650/viewport.width;
              setScale(thisScale);
              viewport = page.getViewport({scale: thisScale});
            }
            setLockScale(true);
          }
          else {
            viewport = page.getViewport({ scale });
          }

          const canvasContext = canvas.getContext('2d');
          let {width, height} = viewport;
          
          width = Math.floor(width);
          height = Math.floor(height);

          canvas.height = height;
          canvas.width = width;

          // renderCurrentPage();
          const renderer = page.render({
            canvasContext,
            viewport
          });
          await renderer.promise;
      }
      setPdfDocument(doc);
    }, [bytes,scale]);
  
    useEffect(() => {
        fetchDocument().catch(console.error);
  
    },[fetchDocument]);
  
    // useEffect(() =>{
    //   console.log('bytes changed');
    //   fetchDocument().catch(console.error);
    // },[bytes]);
    
    // const renderCurrentPage = () => {
    //   if (canvas && viewport)
    //   {
    //     console.log('render current page');
    //     const canvasContext = canvas.getContext('2d');
    //     // canvasContext.clearRect(0,0,viewport.width,viewport.height);
    //     currentPage.render({
    //       canvasContext,
    //       viewport
    //     });
    //   }
    // }
  
    console.log('REDRAWING PDF');
    return (
      <WrapperContainer>
        <canvas ref={canvasRef} />
      </WrapperContainer>
    );
  
  }