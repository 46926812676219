import React, { Component, Fragment } from "react";
import Select, { components } from 'react-select';
import { toast, ToastContainer } from "react-toastify";

const passwordChecknotify = () => toast.error('Empty value', {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const passwordNotify = () => toast.error('Empty value', {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export class CompaingFormThird extends Component {
  continue = e => {
    e.preventDefault();
    let titleCompany = document.querySelector('.new-compaign-popup__popup .dropdownSecond').style.opacity

    if (titleCompany === '1') {
      document.querySelector('.new-compaign-popup__popup .dropdown').classList.remove('invalid-selector');
      if (document.querySelector('.new-compaign-popup__popup .dropdownSecond > div > div:first-child > div').innerText === 'Select - Market') {
        document.querySelector('.new-compaign-popup__popup .dropdownSecond').classList.add('invalid-selector');
        document.querySelector('.hiddenNotifyBtnPasswordLength').click();

      } else {
        document.querySelector('.new-compaign-popup__popup .dropdownSecond').classList.remove('invalid-selector');
        this.props.nextStep();
      }
    } else {
      document.querySelector('.new-compaign-popup__popup .dropdown').classList.add('invalid-selector');
      document.querySelector('.hiddenNotifyBtnPassword').click();
    }
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  constructor() {
    super();
    this.state = {
      disabled: false
    };
  }



  handleChange = selectedOption => {
    this.setState({ selectedOption });
    document.querySelector('.new-compaign-popup__popup .dropdownSecond').style.opacity = "1";
    document.querySelector('.new-compaign-popup__popup .dropdownSecond > div').style.cursor = "pointer";
  };


  render() {
    const { isOpen, haveText } = this.state;
    const { values, handleChange } = this.props;
    const options = [
      { value: 'select_notional', label: 'Select - None National' },
    ];


    const optionsSecond = [
      { value: 'select_notional', label: 'Select - Market' },
      { value: 'select_new_market', label: 'Create New Market' },
      { value: 'select_market_2', label: 'Market 02' },
      { value: 'select_market_3', label: 'Market 03' },
      { value: 'select_market_4', label: 'Market 04' }
    ];


    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
          </svg>
        </components.DropdownIndicator>
      );
    };

    const style = {
      menu: (provided, state) => ({
        ...provided,
        width: '100%',
        padding: 0,
        marginBottom: 0,
        marginTop: '0',
        boxShadow: 'none',
        border: '1px solid #B3B3B3',
        borderTop: '0',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        transition: 'all .2s ease-in-out',
        borderRadius: '0',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      }),
      menuList: (provided, state) => ({
        ...provided,
        padding: '0',
        borderRadius: '0',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      }),
      option: (provided, state) => ({
        ...provided,
        color: 'black',
        backgroundColor: '#fff',
        padding: '10px 10px',
        cursor: 'pointer',
        height: '33px',
        display: 'flex',
        alignItems: 'center',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#FA3535'
        }
      }),
      control: (base, state) => ({
        ...base,
        border: '1px solid #B3B3B3',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #B3B3B3',
        boxShadow: '0 !important',
        cursor: 'pointer',
        width: '100%',
        height: '60px',
        borderRadius: '4px',
        borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
        borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        transition: '.2s easy-in-out',
        '&:hover': {
          borderColor: '1px solid #EEEEEE !important',
          borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #B3B3B3',
        }
      }),
    };




    return (
      <Fragment>

        <div className="third-step">
          <h1>03 / 05</h1>
          <h4>What type of Promotion / Campaign / Test is this?</h4>
          <p>Select the filds need to refine your selection.</p>

          <h6>Step 03. Type</h6>
          <span>If your campaign run across PODS, save and duplicate.</span>
          <div
            className={isOpen ? "dropdown active" : "dropdown"}
            onClick={this.handleClick} >
            <Select
              defaultValue={options[0]}
              className="dropdown"
              onChange={this.handleChange}
              options={options}
              styles={style}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
              }}
            />
            <Select
              defaultValue={optionsSecond[0]}
              className="dropdown dropdownSecond"
              onChange={this.handleChange}
              options={optionsSecond}
              styles={style}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
              }}
            />
          </div>
        </div>
        <button className="hiddenNotifyBtnPassword" onClick={passwordChecknotify}>Notify!</button>
        <button className="hiddenNotifyBtnPasswordLength" onClick={passwordNotify}>Notify!</button>
  
        <div className="button-group">
          <a
            label="Back"
            onClick={this.back}
            className="cancel-btn"
          >
            Back
          </a>
          <a
            label="Next"
            onClick={this.continue}
            className="next-btn"
          >
            Next
          </a>
        </div>
      </Fragment>
    );
  }
  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleText = (ev) => {
    this.setState({
      haveText: ev.currentTarget.textContent
    })
  }
}

export default CompaingFormThird;
