import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../../context/auth';
import { ReactComponent as IntotoLogo } from './icons/logo.svg';
import { ReactComponent as KFCLogo } from './icons/KFCLogo.svg';
import './style.scss';

function Logo() {
  const { user } = useContext(AuthContext);

  return (
    <div className={'header__logo'}>
      <Link to={'/'}>
        <KFCLogo />
      </Link>
    </div>
  )
}

export default Logo