import React, { useState, useEffect, useContext } from 'react';
import Select, { components } from "react-select";
import { useQuery } from '@apollo/client';
import { DropdownStyle, DropdownIndicator } from '../../components/LSM/LSMCard/EditableSidebar';
import GQL from '../../gql';

export default function(props)
{
    const { loading, error, data } = useQuery
    (
        GQL.Store.GET_ALL_STORES
    );
    const [stores, setStores] = useState();

    useEffect(() => {
        if (!data)
        {
            return;
        }
        const stores = data.getStores;
        setStores(stores.map(record => {
            const rec = {...record};
            rec.label = rec.name;
            rec.value = rec.id;
            return rec;
        }));
    },[data]);

    if (loading)
    {
      return <p>Loading...</p>;
    }
    if (error)
    {
      return <p>{`Error! ${error}`}</p>
    }

    return (
        <Select
            defaultValue={{
                value: 'Store_Name',
                label: 'Select Your Store'
            }}
            value={props.value}
            isSearchable={true}
            styles={DropdownStyle}
            options={stores}
            onChange={props.onChange}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
            }}
        />
    );
}