import { gql } from '@apollo/client';

export const GET_ASSET_BY_CATEGORY_URI = gql`
query GetAssetsByCategoryURI($uri: String!) {
    getAssetsByCategoryURI(URI: $uri) {
      id
      name
      fileName
      safeName
      category
      tags
      file
      editorType
      uri
      pinned
      weight
      data
    }
  }
`;

export const SAVE_ASSET = gql`
mutation UpdateAsset($updateAssetInput: UpdateAssetInput) {
  updateAsset(updateAssetInput: $updateAssetInput) {
    id,
    name
    fileName
    safeName
    category
    tags
    file
    editorType
    uri
    pinned
    weight
    data
  }
}
`;

export const DELETE_ASSET = gql`
mutation DeleteAsset($assetId: ID!) {
  deleteAsset(assetId: $assetId)
}
`;