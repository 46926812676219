import { useState, useContext } from 'react';
// import Popup from '../../Popup/Popup';
import Select, { components } from "react-select";
import {successNotify, errorNotify, emailProgress } from '../../../pages/LSM/Categories/notifications';
import EditableSidebar, { DropdownStyle, DropdownIndicator } from '../../../components/LSM/LSMCard/EditableSidebar';
import SendEmailModal from '../../../components/Modal/SendEmailModal';
import styled from '@emotion/styled';
import TabContainer, { Tab } from '../../TabContainer/TabContainer';
import { USER_TYPE } from '../../../defs/User';
import { AuthContext } from '../../../context/auth';

import storeNameOptions from '../../../data/Stores.json';

import FrontPage from './FrontPage';
import BackPage from './BackPage';

export default function()
{
    const [buttonPopupEmail, setButtonPopupEmail] = useState(false);
    const [selectedStore, setSelectedStore] = useState(storeNameOptions[0]);
    const [storeNameFontSize, setStoreNameFontSize] = useState(35);
    const [expiryDate, setExpiryDate] = useState();

    const { user } = useContext(AuthContext);

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
                </svg>
            </components.DropdownIndicator>
        );
    };

    const couponPostition = [
        { value: "Front", label: "Front" },
        { value: "Back", label: "Back" },
    ]

    let onChangeView = (e) => {
        if (e.value === 'Back') {
            document.querySelector('.lsm__content__header__coupon--dnd').classList.remove('disable');
            document.querySelector('.lsm__content__header__coupon--pdf').classList.add('disable');

            document.querySelector('.lsm_coupon_editor').classList.remove('hidden');
            document.querySelector('.lsm-popup__sidebar--front-view').classList.add('disable');
        } else {
            document.querySelector('.lsm__content__header__coupon--dnd').classList.add('disable');
            document.querySelector('.lsm__content__header__coupon--pdf').classList.remove('disable');

            document.querySelector('.lsm_coupon_editor').classList.add('hidden');
            document.querySelector('.lsm-popup__sidebar--front-view').classList.remove('disable');
        }
    };

    const onStoreChange = (e) => {
        const store = {
            option: e,
            name: e.label,
            address1: e.address,
            address2: `${e.suburb}, ${e.state}, ${e.postcode}`,
        };

        //20 character limit.
        const storeNameEl = document.querySelector('.coupon-store-name');
        storeNameEl.value = e.label;
        if (storeNameEl.value.length < 20)
        {
            setStoreNameFontSize(35);
        }
        else if (storeNameEl.value.length < 29)
        {
            setStoreNameFontSize(28);
        }
        else if (storeNameEl.value.length < 32)
        {
            setStoreNameFontSize(26);
        }
        else if (storeNameEl.value.length >= 32)
        {
            setStoreNameFontSize(23);
        }
        
        document.querySelector('.coupon-store-address-1').value = e.address;
        document.querySelector('.editable-store-address-line-1').value = e.address;

        document.querySelector('.coupon-store-address-2').value = `${e.suburb}, ${e.state}, ${e.postcode}`;
        document.querySelector('.editable-store-address-line-2').value = e.address;
        setSelectedStore(store);
    }

    const getDropGrid = () => {
        return [
            document.querySelector('[data-rbd-droppable-id="coupon-1.1"] img')?.src,
            document.querySelector('[data-rbd-droppable-id="coupon-1.2"] img')?.src,
            document.querySelector('[data-rbd-droppable-id="coupon-2.1"] img')?.src,
            document.querySelector('[data-rbd-droppable-id="coupon-2.2"] img')?.src
        ];
    }

    const sendEmailFile = (modal,e) => {
        //Validate first.


        let to = e.target.parentElement.parentElement.querySelector('input');
        let storeName = document.querySelector('.coupon-store-name').value;
        let firstAddress = document.querySelector('.coupon-store-address-1').value;
        let secondAddress = document.querySelector('.coupon-store-address-2').value;

        const grid = getDropGrid();

        if(to.value !== '') {
            modal.close();
            emailProgress
            (
                fetch(process.env.REACT_APP_API_SERVER + 'send-coupon', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        storeName,
                        firstAddress,
                        secondAddress,
                        firstArea: grid[0],
                        secondArea: grid[1],
                        thirdArea: grid[2],
                        fourthArea: grid[3],
                        expiryDate: expiryDate,
                        to: to.value,
                    }),
                })
            );
            setButtonPopupEmail(false);
        } else {
            errorNotify('The email address field must not be empty', 'lsm_error')
        }
    }

    const FlexWrapper = styled.div`
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    `;

    const onExpiryDateChange = (date) => {
        setExpiryDate(date);
    }

    const validateBeforeEmail = () => {
        const storeName = document.querySelector('.coupon-store-name').value;
        const grid = getDropGrid();

        if (!storeName) {
            errorNotify('Please Select a Store from the front of the coupon.', 'lsm_error');
            return false;
        }

        if (!expiryDate) {
            errorNotify('Please Select an Expiry Date from the front of the coupon.', 'lsm_error');
            return false;
        }
        
        if (!grid[0] || !grid[1] || !grid[2] || !grid[3])
        {
            errorNotify('Please fill all voucher slots on the the back of the coupon.', 'lsm_error');
            return false;
        }
        return true;
    }

    return (
        <div className='lsm-popup'>
            <div className='lsm-popup__content'> 
                <div className='lsm-popup__content__description'>
                        <h5>Create Your Coupon</h5>
                </div>
                <TabContainer>
                    <Tab title="Front" active={true}>
                        <FlexWrapper style={{gap:'10px'}}>
                            <FrontPage
                                onSendEmail={sendEmailFile}
                                selectedStore={selectedStore}
                                onStoreChange={onStoreChange}
                                storeNameFontSize={storeNameFontSize}
                                expiryDate={expiryDate}
                                onExpiryDateChange={onExpiryDateChange}
                            />
                        </FlexWrapper>
                    </Tab>
                    <Tab title="Back">
                        <FlexWrapper>
                            <BackPage
                                onSendEmail={sendEmailFile}
                                selectedStore={selectedStore}
                                expiryDate={expiryDate}
                            />
                        </FlexWrapper>
                    </Tab>
                </TabContainer>
                <div class="footer">
                    {/* {user.userRole !== USER_TYPE.BUSINESS_OWNER && (
                        <button className='btn__save'>SAVE</button>
                    )} */}
                    <SendEmailModal buttonText="EMAIL FILE" onSend={sendEmailFile} onOpen={validateBeforeEmail} userRole={user.userRole} />
                </div>
            </div>
        </div>
    );
}
