import React from 'react';
import './style.scss';
import {ReactComponent as ClosePopupIcon} from '../../assets/icons/ClosePopupIcon.svg'

function Popup({ children, title, trigger, setTrigger, style, popupStyle, closeBtn, customClass, onClose }) {
    // console.log('typeof onClose',typeof onClose);
    // if (typeof onClose != ' function')
    // {
    //     onClose = () => {};
    // }
    const closePopup = (e) => {
        if (typeof onClose === 'function')
        {
            if (onClose() !== false)
            {
                setTrigger(false);
            }
        }
        else
        {
            setTrigger(false);
        }
    }
    
    return (trigger) ? (
        <div style={popupStyle} className="popup">
            <div style={style} className={`popup-inner ${customClass ? customClass : ''}`}>
                {title && (<><h2>{title}</h2><hr /></>)}
                
                {closeBtn ?? <button className='close-btn' onClick={closePopup}><ClosePopupIcon /></button>}
                <div className='popup-content'>
                    {children}
                </div>
            </div>
        </div>
    ) : null;
}

export default Popup