import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  PDFDocument,
  StandardFonts,
  drawTextField,
  layoutSinglelineText,
  layoutMultilineText,
  layoutCombedText,
  adjustDimsForRotation,
  reduceRotation,
  rotateInPlace,
  rgb,
  grayscale,
  cmyk,
  componentsToColor,
  TextAlignment,
  findLastMatch
} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import styled from '@emotion/styled';
import StoreSelector from '../Select/StoreSelector';

const fontURL = 'https://www.kfc.com.au/static/media/National2Condensed-Bold.a091dde9.otf';

const FieldContainer = styled.div`
    margin-bottom: 10px;

    label {
        font-weight: 700;
        font-size: 16px;
        color: #171b1e;
        text-align: left;
        margin-bottom: 5px;
    }
    input {
        border: 1px solid #eee;
        border-radius: 10px;
        height: 40px;
        box-sizing: border-box;
        padding: 10px 15px 10px 10px;
        outline: none;
        font-size: 16px;
        color: #171b1e;
        width: 98%;
        margin-left: 1px;
        &:focus {
            outline: 1px solid #4a4e51;
        }
    }
`

const Form = styled.form`
    margin-top: 20px;
    width: 100%;
`;

const Fields = styled.div`
    max-height: 270px;
    overflow-y: auto;
`;

const tfRegex = /\/([^\0\t\n\f\r\ ]+)[\0\t\n\f\r\ ]+(\d*\.\d+|\d+)[\0\t\n\f\r\ ]+Tf/;
const getDefaultFontSize = (field) => {
  const da = field.getDefaultAppearance() ?? '';
  const daMatch = findLastMatch(da, tfRegex).match ?? [];
  const defaultFontSize = Number(daMatch[2]);
  return isFinite(defaultFontSize) ? defaultFontSize : undefined;
};

const colorRegex = /(\d*\.\d+|\d+)[\0\t\n\f\r\ ]*(\d*\.\d+|\d+)?[\0\t\n\f\r\ ]*(\d*\.\d+|\d+)?[\0\t\n\f\r\ ]*(\d*\.\d+|\d+)?[\0\t\n\f\r\ ]+(g|rg|k)/;

const getDefaultColor = (field) => {
  const da = field.getDefaultAppearance() ?? '';
  const daMatch = findLastMatch(da, colorRegex).match;

  const [, c1, c2, c3, c4, colorSpace] = daMatch ?? [];

  if (colorSpace === 'g' && c1) {
    return grayscale(Number(c1));
  }
  if (colorSpace === 'rg' && c1 && c2 && c3) {
    return rgb(Number(c1), Number(c2), Number(c3));
  }
  if (colorSpace === 'k' && c1 && c2 && c3 && c4) {
    return cmyk(Number(c1), Number(c2), Number(c3), Number(c4));
  }

  return undefined;
};

const PDFForm = function({children}) {

    const onSubmit = (e) => {
      e.preventDefault();
    }
  
    return (
      <Form onSubmit={onSubmit}>
        {children}
      </Form>
    );
}

const PDFField = function ({type, label, defaultValue, onEditField}) {
    const [inputText, setInputText] = useState(defaultValue);
  
    useEffect(() => {
      onEditField(inputText);
    },[inputText]);
  
    return (
      <FieldContainer>
        <label>{label}: </label>
        <input type={type} onChange={(e) => setInputText(e.currentTarget.value)} value={inputText} />
      </FieldContainer>
    );
}

const PDFTextField = function (props) {
  return <PDFField type="text" {...props} />;
}

const PDFDateField = function (props) {
  return <PDFField type="date" {...props} />;
}

const PDFSelectField = function ({label, defaultValue, onEditField}) {
    const [selected, setSelected] = useState(defaultValue);
    const [value, setValue] = useState(defaultValue);
  
    // useEffect(() => {
    //   onEditField(inputText);
    // },[inputText]);

    const onChange = (e) => {
      setSelected(e.option);
      setValue(e.label);
      onEditField(e.label);
    }
  
    return (
      <FieldContainer>
        <label>{label}: </label>
        <StoreSelector
          value={selected}
          onChange={onChange}
        />
      </FieldContainer>
    );
}

const customProvider = (field, widget, font) => {
  const widgetColor = getDefaultColor(widget);
  const fieldColor = getDefaultColor(field.acroField);

  const widgetFontSize = getDefaultFontSize(widget);
  const fieldFontSize = getDefaultFontSize(field.acroField);
  const ap = widget.getAppearanceCharacteristics();
  const bs = widget.getBorderStyle();
  const text = field.getText() ?? '';
  const rectangle = widget.getRectangle();
  const rotation = reduceRotation(ap?.getRotation());
  const borderWidth = bs?.getWidth() ?? 0;
  const { width, height } = adjustDimsForRotation(rectangle, rotation);

  const rotate = rotateInPlace({ ...rectangle, rotation });

  const black = rgb(0, 0, 0);

  const borderColor = componentsToColor(ap?.getBorderColor());
  const normalBackgroundColor = componentsToColor(ap?.getBackgroundColor());

  const padding = field.isCombed() ? 0 : 1;
  const bounds = {
    x: borderWidth + padding,
    y: borderWidth + padding,
    width: width - (borderWidth + padding) * 2,
    height: (height + 10) - (borderWidth + padding) * 2,
  };

  let textLines;
  let fontSize;

  if (field.isMultiline()) {
    const layout = layoutMultilineText(text, {
      alignment: field.getAlignment(),
      fontSize: widgetFontSize ?? fieldFontSize,
      font,
      bounds,
    });
    textLines = layout.lines;
    fontSize = layout.fontSize;
  } else if (field.isCombed()) {
    const layout = layoutCombedText(text, {
      fontSize: widgetFontSize ?? fieldFontSize,
      font,
      bounds,
      cellCount: field.getMaxLength() ?? 0,
    });
    textLines = layout.cells;
    fontSize = layout.fontSize;
  }
  else {
    const layout = layoutSinglelineText(text, {
      alignment: field.getAlignment(),
      fontSize: widgetFontSize ?? fieldFontSize,
      font,
      bounds,
    });
    textLines = [layout.line];
    fontSize = layout.fontSize;
  }
  const textColor = widgetColor ?? fieldColor ?? black;
  const options = {
    x: 0 + borderWidth / 2,
    y: 0 + borderWidth / 2,
    width: width - borderWidth,
    height: (height + 10) - borderWidth,
    borderWidth: borderWidth ?? 0,
    borderColor,
    textColor,
    font: font.name,
    fontSize,
    color: normalBackgroundColor,
    textLines,
    padding
  };
  return drawTextField(options);
}

export default function PDFAutoFieldEditor({startBytes,onEdit}) {

    const [pdfDocument, setPdfDocument] = useState();
    const [pages, setPages] = useState();
    const [form, setForm] = useState();
    const [fields, setFields] = useState([]);
    const [editedBytes, setEditedBytes] = useState();
    const [font, setFont] = useState();
    // const [viewport, setViewport] = useState();
    // const [numPages, setNumPages] = useState();
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [currentPage, setCurrentPage] = useState(null);
    // const [scale, setScale] = useState(1);
    // const [annotations, setAnnotations] = useState();
    const [inputText, setInputText] = useState('Mon 10am - 10pm');

    const canvasRef = useRef();

    const fetchDocument = useCallback(async () => {
        if (!startBytes)
        {
            return;
        }
      const doc = await PDFDocument.load(startBytes);
      const pages = doc.getPages();
      const form = doc.getForm();
      const fields = form.getFields();
      const currentPage = pages[0];

      doc.registerFontkit(fontkit);
      const fontBytes = await fetch(fontURL).then(res => res.arrayBuffer());
      const font = await doc.embedFont(fontBytes);
      // const font = await doc.embedFont(StandardFonts.TimesRomanItalic);
      pages[0].setFont(font);

      setPdfDocument(doc);
      setPages(pages);
      setCurrentPage(currentPage);
      setForm(form);
      setFields(fields);
      setFont(font);

      form.updateFieldAppearances(font);

      const pdfBytes = await doc.save();
      setEditedBytes(pdfBytes);
    }, []);
    
    useEffect(() => {
        fetchDocument().catch(console.error);
    },[fetchDocument]);
    
    useEffect(() => {
        if (currentPage && fields && fields.length) {
          fields.forEach(field => {
            field.updateAppearances(font, customProvider);
          });
        }
    },[fields]);

    const onEditField = async (i,value) => {
      console.log('onEditField',i,value);
      fields[i].setText(value);
      fields[i].updateAppearances(font, customProvider);
      const pdfBytes = await pdfDocument.save();
      setEditedBytes(pdfBytes);
      onEdit(pdfBytes);
    }

    const onEditDateField = (i, value) => {
      console.log('onEditDateField',i, value);
      if (value)
      {
        const date = new Date(value);
        onEditField(i, date.toLocaleDateString('en-AU').replace(/\//g,'-'));
      }
    }

    const renderField = (field, i) =>{

      console.log(`Rendering field with field name: ${field.getName()}`);
      const regex = /(\w+):{?([\w\ \.]+)}?/;
      const matches = regex.exec(field.getName());
      let fieldType = 'text';
      let label = field.getName();
      if (matches?.length)
      {
        fieldType = matches[1];
        label = matches[2];
      }

      console.log(`Using field type "${fieldType}" for field "${label}".`);

      switch (fieldType)
      {
        case 'store':
        {
          return <PDFSelectField key={i} label={label} defaultValue={field.getText()} onEditField={(value) => {onEditField(i, value)}} />
        }
        case 'date':
        {
          return <PDFDateField key={i} label={label} defaultValue={field.getText()} onEditField={(value) => {onEditDateField(i, value)}} />;
        }
        case 'text':
        default:
        {
          return <PDFTextField key={i} label={label} defaultValue={field.getText()} onEditField={(value) => {onEditField(i, value)}} />;
        }
      }
    }

    return (
        <PDFForm>
            <h3>Editable Fields (Not Saved)</h3>
            <Fields>
              {!fields?.length && (
                <p>No editable fields were found in this PDF.</p>
              )}
                {fields && fields.map(renderField)}
            </Fields>
        </PDFForm>
    );
}