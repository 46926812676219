import { Link } from 'react-router-dom';

import './Footer.scss';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <p>© {currentYear} Design Intoto. All Rights Reserved.</p>
      <p><Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-and-conditions">Terms &#38; Conditions</Link></p>
      <p>IntotoX version <strong>{process.env.REACT_APP_VERSION}</strong> running in <strong>{process.env.REACT_APP_MODE}</strong> mode.</p>
    </footer>
  )
}

export default Footer