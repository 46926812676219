import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/auth';
import { TabTitle } from '../../utils/TabTitle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Main from '../../components/Main/Main';
import './NoMatch.scss';


function NoMatch({ title }) {
  TabTitle(title || 'KFC LSM');
  const { user } = useContext(AuthContext);

  return (
    <>
      <Header />
      <Main>
        <div className="no-match">
          <h1>404</h1>
          <p>Page not found</p>
          <Link style={{ marginTop: '20px' }} className='btn' to={'/'}>{user ? 'Back to Dashboard' : 'Back to Login'}</Link>
        </div>
      </Main>
      <Footer />
    </>
  )
}

export default NoMatch