import React from 'react';
import Select, { components } from "react-select";
import {successNotify, errorNotify, emailProgress } from '../../../pages/LSM/Categories/notifications';
import SendEmailModal from '../../../components/Modal/SendEmailModal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "/node_modules/react-datepicker/dist/react-datepicker.css";
import StoreSelector from '../../Select/StoreSelector';

export default function(props)
{
    return (
        <>
            <div className='lsm-popup__sidebar lsm-popup__sidebar front-view'>
                <h5>Instructions</h5>
                <p>For the <strong>Front</strong> side of the coupon, select your store name from the drop down below. Type the first letter to filter the store names.</p>
                <p>Then set an expiry date, keeping in mind that these coupons can only be offered with one month validity.</p>
                <p>Next, select the <strong>Back</strong> tab above the coupon preview where you can simply drag and drop four LSM offers to finish building your coupon.</p>
                <p>Stores in South Australia should not use the below offers:</p>
                <ul>
                    <li>Zing Pop Chips</li>
                    <li>Zinger Lunch For Two</li>
                </ul>
                <p>The below offers are available in South Australia only::</p>
                <ul>
                    <li>$8 Original Crispy Pop Chips</li>
                    <li>$33.95 Original Crispy Feast</li>
                </ul>
                <h5>Select your Business</h5>
                <div className='lsm-coupons__inputs'>
                    <label htmlFor="businessName">Select your business</label>
                    <StoreSelector
                        value={props.selectedStore.option}
                        onChange={props.onStoreChange}
                    />
                    <div style={{display:'none'}}>
                        <label htmlFor="store-address-line-1">Store Address Line 1</label>
                        <input
                            type="text"
                            name="store-address-line-1"
                            className='editable-store-address-line-1'
                            placeholder='Store Address Line 1'
                            value={props.selectedStore.address1}
                            disabled
                        />
                    </div>
                    <div style={{display:'none'}}>
                        <label htmlFor="store-address-line-2">Store Address Line 2</label>
                        <input
                            type="text"
                            name='store-address-line-2'
                            className='editable-store-address-line-2'
                            placeholder='Store Address Line 2'
                            value={props.selectedStore.address2}
                            disabled
                        />
                    </div>
                    <div>
                        <label htmlFor="expiryDate">Expiry Date</label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            minDate={moment().toDate()}
                            maxDate={moment().add('3','months').toDate()}
                            selected={props.expiryDate}
                            onSelect={props.onExpiryDateChange}
                        />
                        {/* <input
                            type="date"
                            name="expiryDate"
                            min={moment().format('YY-MM-DD')}
                            max={moment().add('3','months').format('YY-MM-DD')}
                            value={props.expirzDate}
                            onChange={props.onExpiryDateChange}
                        /> */}
                    </div>
                </div>
                    
            </div>
            <div className='lsm__content__header__coupon--pdf'>
                <img alt="LSM" src="/static/General/coupon_front.png" />
                <input
                    className={'coupon-store-name'}
                    placeholder="KFC <STORE NAME>"
                    value={props.selectedStore.name}
                    disabled
                    style={{fontSize: `${props.storeNameFontSize}px`}}
                />
                <input
                    className={'coupon-store-address-1'}
                    placeholder="<Store Address Line 1>"
                    value={props.selectedStore.address1}
                    disabled
                />
                <input
                    className={'coupon-store-address-2'}
                    placeholder="<Store Address Line 2>"
                    value={props.selectedStore.address2}
                    disabled
                />
            </div>
        </>
    );
}
