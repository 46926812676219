import { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth';
import NavList from './NavList/NavList';
import NavItem from './NavItem/NavItem';
import { ReactComponent as DashboardIcon } from './icons/DashboardIcon.svg';
import { ReactComponent as SearchIcon } from './icons/SearchIcon.svg';
import { ReactComponent as PodsIcon } from './icons/PodsIcon.svg';
import { ReactComponent as LSMIcon } from './icons/LSMIcon.svg';
import { ReactComponent as AddEditStoresIcon } from './icons/AddEditStoresIcon.svg';
import { ReactComponent as SettingsIcon } from './icons/SettingsIcon.svg';
import { ReactComponent as LSMDashboardIcon } from './icons/LSMDashboardIcon.svg';
import { ReactComponent as InvestmentGuidelinesIcon } from './icons/InvestmentGuidelinesIcon.svg';
import { ReactComponent as CleanStorePolicyIcon } from './icons/CleanStorePolicyIcon.svg';
import { ReactComponent as AdvertisingAndSignage } from './icons/AdvertisingAndSignage.svg';
import { ReactComponent as BrandAssetsIcon } from './icons/BrandAssetsIcon.svg';
import { ReactComponent as ProductImagesIcon } from './icons/ProductImagesIcon.svg';
import { ReactComponent as BriefingTemplatesIcon } from './icons/BriefingTemplatesIcon.svg';
import { ReactComponent as EmailSettingsIcon } from './icons/EmailSettingsIcon.svg';
import { ReactComponent as KeyContactsIcon } from './icons/KeyContactsIcon.svg';
import { ReactComponent as SettingUsers } from './icons/SettingUsers.svg';
import { ReactComponent as GeneralSettingsIcon } from './icons/GeneralSettingsIcon.svg';
import { ReactComponent as SettingRequestLogin } from './icons/settingRequestLogin.svg';
import { ReactComponent as BackArrowIcon } from "./icons/BackArrowIcon.svg";
import { ReactComponent as NextArrowIcon } from './icons/NextArrowIcon.svg';
import { ReactComponent as RecruitmentIcon } from './icons/RecruitmentIcon.svg';
import { USER_TYPE } from '../../defs/User';

function Sidebar() {
    const { user } = useContext(AuthContext);

    let lsmMenu = () => {
        let parentSidebar = document.querySelector('.sidebar-parent');
        let lsmSidebar = document.querySelector('.sidebar-lsm');
        parentSidebar.classList.add('disable');
        lsmSidebar.classList.add('active');
        document.querySelector('.sidebar').style.height = '650px';
    }

    let settingsMenu = () => {
        let parentSidebar = document.querySelector('.sidebar-parent');
        let settingsMenu = document.querySelector('.sidebar-settings');
        parentSidebar.classList.add('disable');
        settingsMenu.classList.add('active')
    }

    let advertisingAndSignageMenu = () => {
        let lsmSidebar = document.querySelector('.sidebar-lsm');
        let advertisingAndSignageSidebar = document.querySelector('.sidebar-lsm-advertising-and-signage');
        lsmSidebar.classList.remove('active');
        advertisingAndSignageSidebar.classList.add('active');
        document.querySelector('.sidebar').style.height = '800px';
    }

    let productImagesMenu = () => {
        let lsmSidebar = document.querySelector('.sidebar-lsm');
        let productImagesSidebar = document.querySelector('.sidebar-lsm-product-images');
        lsmSidebar.classList.remove('active');
        productImagesSidebar.classList.add('active')
    }

    let backToMainMenu = (e) => {
        let parentSidebar = document.querySelector('.sidebar-parent');
        let activeMenu = e.target.closest('nav');
        parentSidebar.classList.remove('disable');
        activeMenu.classList.remove('active')
    }

    let backToLSMMenu = (e) => {
        let lsmSidebar = document.querySelector('.sidebar-lsm');
        let activeMenu = e.target.closest('nav');
        lsmSidebar.classList.add('active');
        activeMenu.classList.remove('active')
    }

    if (user) {
        return (
            <div className='sidebar'>
                {(() => {
                    if (user.userRole === USER_TYPE.ADMIN) {
                        return (
                            <>
                                <NavList>
                                    <NavItem to={'/'}>
                                        <DashboardIcon />
                                        Dashboard
                                    </NavItem>
                                    <NavItem to={'/search'}>
                                        <SearchIcon />
                                        Search
                                    </NavItem>
                                    <NavItem to={'/pods'}>
                                        <PodsIcon />
                                        Pods
                                    </NavItem>
                                    <NavItem open={lsmMenu} to={'/lsm'}>
                                        <LSMIcon />
                                        LSM
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/stores'}>
                                        <AddEditStoresIcon />
                                        Stores
                                    </NavItem>
                                    <NavItem to={'/categories'}>
                                        <BrandAssetsIcon />
                                        Categories
                                    </NavItem>
                                    <NavItem open={settingsMenu} to={'/settings/general'}>
                                        <SettingsIcon />
                                        Settings
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>  
                                </NavList>
                                <NavList parent={'lsm'}>
                                    <NavItem open={backToMainMenu} to={'/'}>
                                        <BackArrowIcon />
                                        Main Menu
                                    </NavItem>
                                    <NavItem to={'/lsm'}>
                                        <LSMDashboardIcon />
                                        Dashboard
                                    </NavItem>
                                    <NavItem to={'/lsm/investment-guidelines'}>
                                        <InvestmentGuidelinesIcon />
                                        Investment Guidelines
                                    </NavItem>
                                    <NavItem to={'/lsm/clean-store-policy'}>
                                        <CleanStorePolicyIcon />
                                        Clean Store Policy
                                    </NavItem>
                                    <NavItem open={advertisingAndSignageMenu} to={'/lsm/advertising-and-signage'}>
                                        <AdvertisingAndSignage />
                                        Advertising & Signage
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/lsm/brand-assets'}>
                                        <BrandAssetsIcon />
                                        Brand Assets
                                    </NavItem>
                                    <NavItem open={productImagesMenu} to={'/lsm/product-images'}>
                                        <ProductImagesIcon />
                                        Product Images
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/lsm/recruitment'}>
                                        <RecruitmentIcon />
                                        Recruitment
                                    </NavItem>
                                    <NavItem to={'/lsm/send-us-a-brief'}>
                                        <BriefingTemplatesIcon />
                                        Send Us A Brief
                                    </NavItem>
                                    <NavItem to={'/lsm/key-contacts'}>
                                        <KeyContactsIcon />
                                        Key Contacts
                                    </NavItem>
                                </NavList>
                                <NavList parent={'lsm-advertising-and-signage'}>
                                    <NavItem open={backToLSMMenu} to={'/'}>
                                        <BackArrowIcon />
                                        Back
                                    </NavItem>
                                    <NavItem style={{ paddingLeft: '56px' }} to={'/lsm/advertising-and-signage/current-national-promos'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Current National Promos
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/lsm-offers'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        LSM offers
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/core'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Core
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/app'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        App
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/delivery'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Delivery
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/store-openings-and-closures'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Store openings & closures
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/sponsorship'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Sponsorship
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/catering'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Catering
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/youth-foundation'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Youth Foundation
                                    </NavItem>
                                    {/* <NavItem to={'/lsm/advertising-and-signage/christmas'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Christmas
                                    </NavItem> */}
                                    <NavItem to={'/lsm/advertising-and-signage/covid-19'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Covid-19
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/other'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Other
                                    </NavItem>
                                </NavList>
                                <NavList parent={'lsm-product-images'}>
                                    <NavItem open={backToLSMMenu} to={'/lsm'}>
                                        <BackArrowIcon />
                                        Back
                                    </NavItem>
                                    <NavItem style={{ paddingLeft: '56px' }} to={'/lsm/product-images/boxed-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Boxed Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/burgers'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Burgers
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/chicken'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Chicken
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/drinks'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Drinks
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/twisters-and-bowls'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Twisters & Bowls
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/shared-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Shared Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/snacks-and-kids-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Snacks & Kids Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/sides'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Sides
                                    </NavItem>
                                </NavList>
                                <NavList parent={'settings'}>
                                    <NavItem open={backToMainMenu} to={'/lsm'}>
                                        <BackArrowIcon />
                                        Main Menu
                                    </NavItem>
                                    <NavItem to={'/settings/general'}>
                                        <GeneralSettingsIcon />
                                        General
                                    </NavItem>
                                    <NavItem to={'/settings/users'}>
                                        <SettingUsers />
                                        Users
                                    </NavItem>
                                    <NavItem to={'/settings/request-login'}>
                                        <SettingRequestLogin />
                                        Request Login
                                    </NavItem>
                                    <NavItem to={'/settings/email'}>
                                        <EmailSettingsIcon />
                                        Email
                                    </NavItem>
                                    <NavItem to={'/settings/assets'}>
                                        <GeneralSettingsIcon />
                                        Assets
                                    </NavItem>
                                </NavList>
                            </>
                        )
                    } if (user.userRole === USER_TYPE.MARKETER) {
                        return (
                            <>
                                <NavList>
                                    <NavItem to={'/'}>
                                        <DashboardIcon />
                                        Dashboard
                                    </NavItem>
                                    <NavItem to={'/search'}>
                                        <SearchIcon />
                                        Search
                                    </NavItem>
                                    <NavItem to={'/pods'}>
                                        <PodsIcon />
                                        Pods
                                    </NavItem>
                                    <NavItem open={lsmMenu} to={'/lsm'}>
                                        <LSMIcon />
                                        LSM
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    {/* <NavItem to={'/stores'}>
                                        <AddEditStoresIcon />
                                        Stores
                                    </NavItem> */}
                                    {/* <NavItem open={settingsMenu} to={'/settings/general'}>
                                        <SettingsIcon />
                                        Settings
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem> */}
                                </NavList>
                                <NavList parent={'lsm'}>
                                    <NavItem open={backToMainMenu} to={'/'}>
                                        <BackArrowIcon />
                                        Main Menu
                                    </NavItem>
                                    <NavItem to={'/lsm'}>
                                        <LSMDashboardIcon />
                                        Dashboard
                                    </NavItem>
                                    <NavItem to={'/lsm/investment-guidelines'}>
                                        <InvestmentGuidelinesIcon />
                                        Investment Guidelines
                                    </NavItem>
                                    <NavItem to={'/lsm/clean-store-policy'}>
                                        <CleanStorePolicyIcon />
                                        Clean Store Policy
                                    </NavItem>
                                    <NavItem open={advertisingAndSignageMenu} to={'/lsm/advertising-and-signage'}>
                                        <AdvertisingAndSignage />
                                        Advertising & Signage
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/lsm/brand-assets'}>
                                        <BrandAssetsIcon />
                                        Brand Assets
                                    </NavItem>
                                    <NavItem open={productImagesMenu} to={'/lsm/product-images'}>
                                        <ProductImagesIcon />
                                        Product Images
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/lsm/recruitment'}>
                                        <RecruitmentIcon />
                                        Recruitment
                                    </NavItem>
                                    <NavItem to={'/lsm/send-us-a-brief'}>
                                        <BriefingTemplatesIcon />
                                        Send Us A Brief
                                    </NavItem>
                                    <NavItem to={'/lsm/key-contacts'}>
                                        <KeyContactsIcon />
                                        Key Contacts
                                    </NavItem>
                                </NavList>
                                <NavList parent={'lsm-advertising-and-signage'}>
                                    <NavItem open={backToLSMMenu} to={'/'}>
                                        <BackArrowIcon />
                                        Back
                                    </NavItem>
                                    <NavItem style={{ paddingLeft: '56px' }} to={'/lsm/advertising-and-signage/current-national-promos'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Current National Promos
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/lsm-offers'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        LSM offers
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/core'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Core
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/app'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        App
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/delivery'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Delivery
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/store-openings-and-closures'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Store openings & closures
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/sponsorship'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Sponsorship
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/catering'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Catering
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/youth-foundation'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Youth Foundation
                                    </NavItem>
                                    {/* <NavItem to={'/lsm/advertising-and-signage/christmas'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Christmas
                                    </NavItem> */}
                                    <NavItem to={'/lsm/advertising-and-signage/covid-19'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Covid-19
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/other'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Other
                                    </NavItem>
                                </NavList>
                                <NavList parent={'lsm-product-images'}>
                                    <NavItem open={backToLSMMenu} to={'/lsm'}>
                                        <BackArrowIcon />
                                        Back
                                    </NavItem>
                                    <NavItem style={{ paddingLeft: '56px' }} to={'/lsm/product-images/boxed-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Boxed Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/burgers'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Burgers
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/chicken'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Chicken
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/drinks'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Drinks
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/twisters-and-bowls'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Twisters & Bowls
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/shared-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Shared Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/snacks-and-kids-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Snacks & Kids Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/sides'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Sides
                                    </NavItem>
                                </NavList>
                                <NavList parent={'settings'}>
                                    <NavItem open={backToMainMenu} to={'/lsm'}>
                                        <BackArrowIcon />
                                        Main Menu
                                    </NavItem>
                                    <NavItem to={'/settings/general'}>
                                        <GeneralSettingsIcon />
                                        General
                                    </NavItem>
                                    <NavItem to={'/settings/users'}>
                                        <SettingUsers />
                                        Users
                                    </NavItem>
                                    <NavItem to={'/settings/request-login'}>
                                        <SettingRequestLogin />
                                        Request Login
                                    </NavItem>
                                    <NavItem to={'/settings/email'}>
                                        <EmailSettingsIcon />
                                        Email
                                    </NavItem>
                                    <NavItem to={'/settings/assets'}>
                                        <GeneralSettingsIcon />
                                        Assets
                                    </NavItem>
                                </NavList>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <NavList status={'active'} parent={'lsm'}>
                                    <NavItem to={'/'}>
                                        <LSMDashboardIcon />
                                        Dashboard
                                    </NavItem>
                                    <NavItem to={'/lsm/investment-guidelines'}>
                                        <InvestmentGuidelinesIcon />
                                        Investment Guidelines
                                    </NavItem>
                                    <NavItem to={'/lsm/clean-store-policy'}>
                                        <CleanStorePolicyIcon />
                                        Clean Store Policy
                                    </NavItem>
                                    <NavItem open={advertisingAndSignageMenu} to={'/lsm/advertising-and-signage'}>
                                        <AdvertisingAndSignage />
                                        Advertising & Signage
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/lsm/brand-assets'}>
                                        <BrandAssetsIcon />
                                        Brand Assets
                                    </NavItem>
                                    <NavItem open={productImagesMenu} to={'/lsm/product-images'}>
                                        <ProductImagesIcon />
                                        Product Images
                                        <NextArrowIcon style={{ marginLeft: 'auto', marginRight: '20px' }} />
                                    </NavItem>
                                    <NavItem to={'/lsm/recruitment'}>
                                        <RecruitmentIcon />
                                        Recruitment
                                    </NavItem>
                                    <NavItem to={'/lsm/send-us-a-brief'}>
                                        <BriefingTemplatesIcon />
                                        Send Us A Brief
                                    </NavItem>
                                    <NavItem to={'/lsm/key-contacts'}>
                                        <KeyContactsIcon />
                                        Key Contacts
                                    </NavItem>
                                </NavList>
                                <NavList parent={'lsm-advertising-and-signage'}>
                                    <NavItem open={backToLSMMenu} to={'/'}>
                                        <BackArrowIcon />
                                        Back
                                    </NavItem>
                                    <NavItem style={{ paddingLeft: '56px' }} to={'/lsm/advertising-and-signage/current-national-promos'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Current National Promos
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/lsm-offers'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        LSM offers
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/core'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Core
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/app'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        App
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/delivery'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Delivery
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/store-openings-and-closures'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Store openings & closures
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/sponsorship'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Sponsorship
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/catering'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Catering
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/youth-foundation'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Youth Foundation
                                    </NavItem>
                                    {/* <NavItem to={'/lsm/advertising-and-signage/christmas'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Christmas
                                    </NavItem> */}
                                    <NavItem to={'/lsm/advertising-and-signage/covid-19'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Covid-19
                                    </NavItem>
                                    <NavItem to={'/lsm/advertising-and-signage/other'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Other
                                    </NavItem>
                                </NavList>
                                <NavList parent={'lsm-product-images'}>
                                    <NavItem open={backToLSMMenu} to={'/'}>
                                        <BackArrowIcon />
                                        Back
                                    </NavItem>
                                    <NavItem style={{ paddingLeft: '56px' }} to={'/lsm/product-images/boxed-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Boxed Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/burgers'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Burgers
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/chicken'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Chicken
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/drinks'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Drinks
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/twisters-and-bowls'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Twisters & Bowls
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/shared-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Shared Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/snacks-and-kids-meals'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Snacks & Kids Meals
                                    </NavItem>
                                    <NavItem to={'/lsm/product-images/sides'}>
                                        <BackArrowIcon style={{ visibility: 'hidden', marginLeft: 0 }} />
                                        Sides
                                    </NavItem>
                                </NavList>
                            </>
                        )
                    }
                })()}
            </div>
        )
    } else {
        return null
    }
}

export default Sidebar