import React, { useState } from 'react';
import Popup from './Popup';
import styled from '@emotion/styled';
import Button from '../Button/Button';

const ReplaceInput = styled.div`
  border: 1px solid #a4a4a4;
  border-radius: 4px;
  background: transparent;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  color: #4f4f4f;
  cursor: pointer;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;

  label {
    display: block;
    width: 100%;
    cursor: pointer;
    padding: 5px;
  }

  &:hover {
      background-color: #fa3535;
      color: #fff;
  }
  &.small {
      width: 40px;
      font-size: 14px;
  }
`;

const InfoPanel = styled.div`
    border-top: 1px solid #ccc;
    padding: 5px 0;
`;


export default function ReplacePopup(props) {

    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const { trigger, setTrigger, style, popupStyle, closeBtn, customClass } = props;
    const popupProps = {trigger, setTrigger, style, popupStyle, closeBtn, customClass};

    const onCancel = () => {
        if (typeof setTrigger == 'function')
        {
            setTrigger(false);
        }
    }

    const onUpload = () => {
        if (isSelected)
        {
            props.onUploadClick(selectedFile);
        }
        else
        {
            alert('Please select a file first!');
        }
    }

    const onSelectReplacementFile = (e) => {
        setSelectedFile(e.target.files[0]);
		setIsSelected(true);
    }

    return (
         <Popup  {...popupProps} style={{maxWidth:'500px'}}>
            <h5>Replace Asset</h5>
            <ReplaceInput>
                <label htmlFor="replaceAssetButton">CHOOSE FILE</label>
                <input type="file" name="file" id="replaceAssetButton" onChange={onSelectReplacementFile} hidden />
            </ReplaceInput>
            <InfoPanel>
                {isSelected ? (
                    <div className="file-details">
                        <p><strong>Filename:</strong> {selectedFile.name}</p>
                        <p><strong>Filetype:</strong> {selectedFile.type}</p>
                    </div>
                ) : (
                    <p>Select a file from your computer.</p>
                )}
            </InfoPanel>
            <div
                className="button-group end">
                <Button onClick={onCancel}>Cancel</Button>
                <Button className="highlight" type="upload" onClick={onUpload} disabled={!isSelected}>UPLOAD</Button>
            </div>
       </Popup>
    );
}
