import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery, gql } from '@apollo/client';

import RequestLoginRemove from './RequestLoginRemove';
import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import './RequestLoginList.scss';
import RequestLoginApprove from './RequestLoginApprove';

function RequestLoginList({ title }) {
  const [selected, setSelected] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  PageDescription('Request Login', 'Settings');
  TabTitle(title)

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'First Names',
      selector: row => row.firstName,
      sortable: true
    },
    {
      name: 'Family Name',
      selector: row => row.lastName,
      sortable: true
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true
    },
    {
      name: 'CreatedAt',
      selector: row => row.createdAt,
      sortable: true
    }
  ];

  const customStyles = {
    responsiveWrapper: {
      style: {
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      }
    },
    head: {
      style: {
        minHeight: '90px',
        borderRadius: '20px'
      }
    },
    rows: {
      style: {
        paddingLeft: '25px',
        minHeight: '90px',
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#000000'
      },
    },
    cells: {
      style: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#000000'
      },
    },
  };

  let customSortIcon = (
    <div className="custom-sort-icons">
      <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.49951 18.75L8.99951 23.25L13.4995 18.75" fill="#171B1E" />
        <path d="M13.4995 11.25L8.99951 6.75L4.49951 11.25" fill="#171B1E" />
      </svg>
    </div>
  );

  const handleChange = ({ selectedRows }) => {
    setSelected(selectedRows)
  };

  const FETCH_REQUEST_LOGIN_QUERY = gql`
    query {
        getRequestLogins {
          id
          firstName
          lastName
          email
          createdAt
        }
      }
  `;

  const { loading, error, data } = useQuery(FETCH_REQUEST_LOGIN_QUERY, {onCompleted: (data) => setDataTable(data.getRequestLogins)});

  if (loading) return <div className='preloader'><div id="loader" /></div>;
  if (error) return <p>Error :</p>;

  return (
    <div className='settings__request-login'>
      <div className='btn-group'>
        <RequestLoginApprove dataTable={dataTable} setDataTable={setDataTable} userData={selected} />
        <RequestLoginRemove dataTable={dataTable} setDataTable={setDataTable} userData={selected} />
      </div>
      <DataTable
        columns={columns}
        data={dataTable}
        selectableRows
        onSelectedRowsChange={handleChange}
        customStyles={customStyles}
        sortIcon={customSortIcon}
        noDataComponent={(<div style={{padding: '24px'}}>Nothing found</div>)}
      />
    </div>
  )
}

export default RequestLoginList