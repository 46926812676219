import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import Description from '../components/Description/Description';
import LSMList from '../../../components/LSM/LSMList/LSMList';
import '../Categories/Categories.scss';

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function Contacts() {
  let location = useLocation();
  let locationState = location.state;

  let locationPathname = location.pathname.split('/')[2];

  TabTitle(toTitleCase(locationPathname.replaceAll('-', ' ').replaceAll('and', '&')));
  PageDescription(toTitleCase(locationPathname.replaceAll('-', ' ').replaceAll('and', '&')), '');
  const [lsmCard, setLsmCard] = useState([]);


  useEffect(() => {
    fetch(process.env.REACT_APP_API_SERVER + 'lsm-list', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        section: 'Key-Contacts'
      })
    })
      .then(response => response.json())
      .then(result => {
        setLsmCard(result)
        console.log(result)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [locationState])

  return (
    <div className="lsm">
      <Description locationState={locationPathname.replaceAll('-', ' ').replaceAll('and', '&')} />
      <LSMList
        enablePopup={true}
        enableTags={false}
        enableEmailFile={false}
        enableEmailPrintQuote={false}
        enableHires={false}
        enableLowRes={false}
        data={lsmCard}
      />
    </div >
  )
}

export default Contacts;