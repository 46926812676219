import React from 'react';
import Button from '../../../components/Button/Button';
import useFetch from '../../../hooks/useFetch';


export default function AssetSettings() {
    const { fetcher:fixThumbnailsFetcher } = useFetch(`${process.env.REACT_APP_API_SERVER}dev/repair-thumbnail`);
    const { fetcher:testOneFetcher } = useFetch(`${process.env.REACT_APP_API_SERVER}dev/test-one`);

    const onFixThumbnails = async () => {
        console.log('Running thumbnail fix...');
        await fixThumbnailsFetcher({
            method: 'POST'
        });
    }

    const onTestOne = async () => {
        console.log('Running thumbnail fix...');
        await testOneFetcher({
            method: 'POST'
        });
    }


    return (
        <div className='settings__general'>
            <h1>Repair Tools</h1>
            <Button onClick={onFixThumbnails}>Fix Thumbnails</Button>
            <Button onClick={onTestOne}>Test One</Button>
        </div>
    );
}