import { Link } from "react-router-dom";

import { ReactComponent as ForgotPasswordIcon } from "../../assets/icons/ForgotCredentials/ForgotPassword.svg";
import { ReactComponent as ForgotUsernameIcon } from "../../assets/icons/ForgotCredentials/ForgotUsername.svg";
import './ForgotCredentials.scss';

function ForgotCredentials() {
    return (
        <div className={'forgot-credentials'}>
            <Link to={'/forgot-username'}>
                <ForgotUsernameIcon />
            </Link>
            <Link to={'/forgot-password'}>
                <ForgotPasswordIcon />
            </Link>
        </div>
    )
}

export default ForgotCredentials