import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Description from '../../pages/LSM/components/Description/Description';
import LSMList from '../LSM/LSMList/LSMList';

const abortController = new AbortController();

export default function SearchResults(params) {
    const { searchQuery } = useParams();
    const [searchResults, setSearchResults] = useState([]);

    console.log('searchQuery',searchQuery);

    const doSearch = () => {
        fetch(process.env.REACT_APP_API_SERVER + 'lsm-search-asset', {
            method: 'POST',
            signal: abortController.signal,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                searchQuery: searchQuery
            })
        })
        .then(response => response.json())
        .then(results => {
            setSearchResults(results);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    useEffect(() =>
    {
        doSearch();
    },[searchQuery]);

    return (
        <div className="lsm">
            <Description
                title="Search Results"
                // category={data.getCategoryByURI}
                description={`Showing search results for "${searchQuery}".`}
                // onUpload={refetch}
            />
            <LSMList
                refresh={doSearch}
                enablePopup={true}
                enableTags={true}
                enableSave={true}
                enableEmailFile={true}
                enableEmailPrintQuote={true}
                enableHires={true}
                enableReplace={true}
                enableDelete={true}
                data={searchResults}
            />
        </div>
    );
}