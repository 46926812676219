import { useState } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { ReactComponent as DonwloadCloud } from '../../../assets/icons/donwloadCloud.svg';
import { ReactComponent as PDFIcon } from '../../../assets/icons/PDFIcon.svg'

import './style.scss';
import { ReactComponent as Icon } from './icon.svg';
import Popup from '../../Popup/Popup';

function PromosWidget({ data }) {
  const [buttonPopup, setButtonPopup] = useState(false);

  let openPopup = () => {
    setButtonPopup(true)
  }

  const dataChartFirst = [
    { name: 'Group A', value: 250 },
    { name: 'Group B', value: 300 }
  ];

  const dataChartSecond = [
    { name: 'Group A', value: 450 },
    { name: 'Group B', value: 200 }
  ];

  const dataChartThird = [
    { name: 'Group A', value: 150 },
    { name: 'Group B', value: 600 }
  ];

  const COLORS_POPUP = ['#DB1A1A', '#A72C2C'];

  return (
    <div className='widget__promos'>
      <div className='widget__promos__header'>
        <div>
          <h6>Promos: {data.length}</h6>
          <span>Live</span>
        </div>
        <Icon />
      </div>
      <div className='widget__promos__body'>
        <ul>
          {data.map((element, key) => {
            return element ? <li onClick={openPopup} key={key}>{element.item}</li> : '2'
          })}
        </ul>
        <Popup style={{ maxWidth: '1125px' }} trigger={buttonPopup} setTrigger={setButtonPopup}>
          <div className={'widget__promos__popup'}>
            <div className='widget__promos__popup__header'>
              <h3>Frozen Pepsi</h3>
              <span>Live</span>
            </div>
            <hr />
            <div className='widget__promos__popup__content'>
              <div className={'row'}>
                <div className="col">
                  <h5>How many stores?</h5>
                  <span>500/750</span>
                  <div className={'widget__promos__popup__content--widget'}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={200} height={190}>
                        <Pie
                          data={dataChartFirst}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={false}
                          outerRadius={85}
                          fill="#8884d8"
                          blendStroke
                          dataKey="value"
                        >
                          {dataChartFirst.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                              fill={COLORS_POPUP[index % COLORS_POPUP.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="col">
                  <h5>How many screens?</h5>
                  <span>11,278/35,000</span>
                  <div className={'widget__promos__popup__content--widget'}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={200} height={190}>
                        <Pie
                          data={dataChartSecond}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={false}
                          outerRadius={85}
                          fill="#8884d8"
                          blendStroke
                          dataKey="value"
                        >
                          {dataChartSecond.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                              fill={COLORS_POPUP[index % COLORS_POPUP.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="col">
                  <h5>How many price zones?</h5>
                  <span>5/12</span>
                  <div className={'widget__promos__popup__content--widget'}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={200} height={190}>
                        <Pie
                          data={dataChartThird}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          label={false}
                          outerRadius={85}
                          fill="#8884d8"
                          blendStroke
                          dataKey="value"
                        >
                          {dataChartThird.map((entry, index) => (
                            <Cell key={`cell-${index}`}
                              fill={COLORS_POPUP[index % COLORS_POPUP.length]} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className="col">
                  <div className={'widget__promos__popup__content--widget--live'}>
                    <h5>Live Menus</h5>
                    <span>11,278/35,000</span>
                    <ul>
                      <li>5PL - 524</li>
                      <li>4PP - 4000</li>
                      <li>4PL - 5000</li>
                      <li>3PP - 239</li>
                      <li>3PL - 1285</li>
                      <li>2PP - 1200</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='widget__promos__popup__footer'>
              <button className={'download__pdf'}>
                <DonwloadCloud />
                Download
                <PDFIcon />
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  )
}

export default PromosWidget