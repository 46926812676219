import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 400px;
    margin: 50px;
`

export default function() {
    const doImport = () => {
        fetch(process.env.REACT_APP_API_SERVER + 'importer/stores')
        .then(response => response.json())
        .then(data => {
            if (data.success)
            {
                console.log(data.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    return (
        <Container>
            <h1>Store Importer</h1>
            <button onClick={doImport}>Import All Stores</button>
        </Container>
    )
}