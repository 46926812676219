import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useQuery, gql } from '@apollo/client';
import Select, { components } from 'react-select';
import { USER_TYPE } from '../../../defs/User';

import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import './style.scss'

function UserList({ title }) {
  const [selected, setSelected] = useState([]);
  PageDescription('Users', 'Settings');
  TabTitle(title)

  let options = [
    { value: USER_TYPE.ADMIN, label: 'Super Admin' },
    { value: USER_TYPE.MARKETER, label: 'Marketing' },
    { value: USER_TYPE.BUSINESS_OWNER, label: 'Business Owner' },
    { value: USER_TYPE.DEVELOPER, label: 'Developer' },
  ]

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '163px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '400',
      fontSize: '14px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 10px',
      cursor: 'pointer',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 10px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '163px',
      maxWidth: '163px',
      height: '36px',
      borderRadius: '4px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      fontWeight: '400',
      fontSize: '14px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    })
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const openPopup = (e) => {
    let popupArr = document.querySelectorAll('.info-popup');
    popupArr.forEach(element => {
      element.classList.remove('active')
    });
    e.target.parentElement.querySelector('.info-popup').classList.toggle('active')
  }

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Name',
      selector: row => (
        <div className='full-name hover-white'>
          {row.firstName || '-'} {row.lastName || ''}
        </div>
      ),
      sortable: true
    },
    {
      name: 'Telephone',
      selector: row => <div className='hover-white'>{row.phone || '-'}</div>,
      sortable: true
    },
    {
      name: 'Company',
      selector: row => <div className='hover-white'>{row.company || '-'}</div>,
      sortable: true
    },
    {
      name: 'Address',
      selector: row => <div className='hover-white'>{row.address || '-'}</div>,
      sortable: true
    },
    {
      name: 'Email',
      selector: row => <div className='hover-white'>{row.email || '-'}</div>,
      sortable: true
    },
    {
      name:
        <button className='btn__new-user'>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 0C5.61067 0 0 5.60261 0 12.5C0 19.3893 5.60261 25 12.5 25C19.3961 25 25 19.3893 25 12.5C25 5.61067 19.3893 0 12.5 0ZM6.25339 20.8104V20.7541C6.25339 17.3475 9.02405 14.5788 12.4288 14.5788H12.5778C15.9844 14.5788 18.7532 17.3494 18.7532 20.7541V20.8104C17.0077 22.1254 14.8531 22.9153 12.5066 22.9153C10.1616 22.9153 7.99228 22.1237 6.2532 20.8104H6.25339ZM12.5 12.5C10.776 12.5 9.37661 11.1006 9.37661 9.37661C9.37661 7.65263 10.776 6.25322 12.5 6.25322C14.224 6.25322 15.6234 7.65263 15.6234 9.37661C15.6234 11.1006 14.224 12.5 12.5 12.5ZM20.6332 18.987C20.0685 16.4217 18.3081 14.3086 15.9765 13.2419C17.0364 12.288 17.7071 10.9102 17.7071 9.37661C17.7071 6.50828 15.3687 4.16828 12.4987 4.16828C9.63039 4.16828 7.2904 6.50666 7.2904 9.37661C7.2904 10.9102 7.9611 12.288 9.02099 13.2419C6.68261 14.3018 4.92216 16.4216 4.36423 18.987C2.94329 17.2067 2.08215 14.9528 2.08215 12.5C2.08215 6.75493 6.75247 2.08503 12.4971 2.08503C18.2456 2.08503 22.9155 6.75536 22.9155 12.5C22.9155 14.9527 22.0609 17.2068 20.6334 18.987H20.6332Z" fill="#717579" />
          </svg>
          Add New User
        </button>,
      selector: row => <div className='selector__user-role'>
        <Select
          defaultValue={{
            "value": row.userRole,
            "label": row.userRole === USER_TYPE.ADMIN ? 'Super Admin'
                : row.userRole === USER_TYPE.MARKETER ? 'Marketing'
                : row.userRole === USER_TYPE.BUSINESS_OWNER ? 'Bussiness Owner'
                : 'Developer' }}
          options={options}
          styles={style}
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
        <button onClick={openPopup}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z" fill="black" />
            <path d="M10 4C10 5.10457 10.8954 6 12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4Z" fill="black" />
            <path d="M10 20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20Z" fill="black" />
          </svg>
        </button>
        <div className='info-popup'>
          <button>Edit</button>
          <button>Delete</button>
          <button>Switch User Type</button>
        </div>
      </div>,
      minWidth: '230px',
    }
  ];

  const customStyles = {
    responsiveWrapper: {
      style: {
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      }
    },
    head: {
      style: {
        minHeight: '90px',
        borderRadius: '20px'
      }
    },
    rows: {
      style: {
        paddingLeft: '25px',
        minHeight: '90px',
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#000000'
      },
    },
    cells: {
      style: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#000000'
      },
    },
  };

  let customSortIcon = (
    <div className="custom-sort-icons">
      <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.49951 18.75L8.99951 23.25L13.4995 18.75" fill="#171B1E" />
        <path d="M13.4995 11.25L8.99951 6.75L4.49951 11.25" fill="#171B1E" />
      </svg>
    </div>
  );

  const handleChange = ({ selectedRows }) => {
    setSelected(selectedRows)
  };

  const FETCH_USERS_QUERY = gql`
    query getUsers {
        getUsers {
          id
          firstName
          lastName
          company
          address
          userRole
          email
          phone
        }
      }
  `;

  const { loading, error, data } = useQuery(FETCH_USERS_QUERY);

  if (loading) return <div className='preloader'><div id="loader" /></div>;
  if (error) return <p>Error :</p>;

  let approveUser = (e) => {

  };

  return (
    <div className='settings__request-login'>
      <div className='btn-group'>
        <button onClick={approveUser}>Approve</button>
        <button onClick={approveUser}>Approve All</button>
      </div>
      <DataTable
        columns={columns}
        data={data.getUsers}
        selectableRows
        onSelectedRowsChange={handleChange}
        customStyles={customStyles}
        sortIcon={customSortIcon}
      />
    </div>
  )
}

export default UserList