import { useEffect, useState } from 'react';
import Select, { components } from "react-select";

import './style.scss'

function StoreOwners() {
    const [storeOwners, setStoreOwners] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_API_SERVER + 'list-business-owner', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setStoreOwners(data);
                console.log(data)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [])

    const style = {
        container: base => ({
            ...base,
            width: '100%',
            maxWidth: '250px',
            zIndex: 98
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            padding: 0,
            marginBottom: 0,
            marginTop: '0',
            boxShadow: 'none',
            border: '1px solid #E0E0E0',
            borderTop: '0',
            fontWeight: '400',
            fontSize: '14px',
            transition: 'all .2s ease-in-out',
            borderRadius: '0',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: '0',
            borderRadius: '0',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'black',
            backgroundColor: '#fff',
            padding: '0 10px',
            cursor: 'pointer',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#F40027'
            }
        }),
        control: (base, state) => ({
            ...base,
            padding: '0 10px',
            border: '1px solid #E0E0E0',
            borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #E0E0E0',
            boxShadow: '0 !important',
            cursor: 'pointer',
            width: '100%',
            maxWidth: '250px',
            height: '36px',
            borderRadius: '4px',
            borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
            borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
            fontWeight: '400',
            fontSize: '14px',
            transition: '.2s easy-in-out',
            '&:hover': {
                borderColor: '1px solid #EEEEEE !important',
                borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #E0E0E0',
            }
        }),
        valueContainer: base => ({
            ...base,
            padding: '0',
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '0'
        })
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
                </svg>
            </components.DropdownIndicator>
        );
    };
    return (
        <div className='add-edit-stores__store-owners'>
            <h3>Store Owners</h3>
            <p>Deatils to be added</p>
            <hr />
            <h4>Find Business Owner</h4>
            <Select
                defaultValue={{label: 'Add Name'}}
                options={storeOwners.users}
                styles={style}
                id={"storeOwners"}
                isSearchable={false}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: DropdownIndicator
                }}
            />
        </div>
    )
}

export default StoreOwners