import React from "react";
import styled from '@emotion/styled';

const Button = styled.button`
  border: 1px solid #a4a4a4;
  border-radius: 4px;
  background: transparent;
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  color: #4f4f4f;
  height: 36px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;

  svg {
    width: 28px;
    height: 28px;
  }

  &.highlight {
    background-color: #fa3535;
    color: #fff;
    &:hover {
      background: #bc3c3c;
      color: #fff;
    }s
  }

  &:hover {
      background-color: #fa3535;
      color: #fff;
  }
  &.small {
      width: 40px;
      font-size: 14px;
  }
`;

export default Button;