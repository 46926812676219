import { Component, Fragment } from "react";
import FileUpload from "./UploadFiles";
import { toast } from "react-toastify";

const passwordChecknotify = () => toast.error('Choose a POD', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const passwordChecknotifySecond = () => toast.error('Choose a Zone', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const passwordChecknotifyFourth = () => toast.error('Choose one of the stores', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const passwordChecknotifyThird = () => toast.error('Choose a Store', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const validationDirectoryTrue = () => toast.success('Section successfully created', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const validationDirectoryFalse = () => toast.error('Enter section name (min length 5 characters)', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

const validationDirectoryFalseWhiteSpace = () => toast.error('Space character is not allowed', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

export class PopupWindows extends Component {
    render() {
        const onChangeDirectory = (event) => {
            let switchMenuBoards = document.querySelector('.upload-new-directory_inner .MuiSwitch-track');
            if (event.target.checked === true) {
                switchMenuBoards.classList.add('active');
            } else {
                switchMenuBoards.classList.remove('active');
            }
        }


        function createNewSection() {
            let uploadNewDirectoryInner = document.querySelector('.upload-new-directory_inner .MuiSwitch-track').classList.contains('active');
            let createNewSection = document.querySelector('.createNewSection').value;
            let activePodsItem = document.querySelector('.active-pods-item').textContent;
            let activeZoneItem = document.querySelector('.active-zone-item').textContent;
            let activeStoresItem = document.querySelectorAll('.uploadFiles-content-slider-item-stores ul li.active-item');
            let interestingSecondTimePeriod = document.querySelector('.interestingSecondTimePeriod').value;
            let activeStoresItemArray = [];
            for (let i = 0; i < activeStoresItem.length; i++) {
                activeStoresItemArray += activeStoresItem[i].textContent + ", ";
            }


            if (createNewSection.length > 4) {
                if (createNewSection.indexOf(' ') !== -1) {
                    document.querySelector('.validationDirectoryFalseWhiteSpace').click();
                } else {
                    fetch(process.env.REACT_APP_API_SERVER + 'create-new-section', {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            nameSection: createNewSection,
                            podsName: activePodsItem,
                            zonesName: activeZoneItem,
                            storesName: activeStoresItemArray,
                            timePeriod: interestingSecondTimePeriod,
                            switchDayTime: uploadNewDirectoryInner
                        })
                    });


                    let uploadContainerDir = document.querySelector('#valueUploadDir');
                    const para = document.createElement("span");
                    para.classList.add('valueUploadDirItem')
                    const node = document.createTextNode(activePodsItem + ' / ' + activeZoneItem + ' / ' + activeStoresItemArray.replace(/,\s*$/, ""));
                    para.appendChild(node);

                    const para2 = document.createElement("span");
                    para2.classList.add('valueUploadDirItemFile')
                    const node2 = document.createTextNode(createNewSection);
                    para2.appendChild(node2);

                    const paraThird = document.createElement("div");
                    paraThird.classList.add('valueUploadDirItemRow')

                    paraThird.prepend(para);
                    paraThird.prepend(para2);

                    uploadContainerDir.prepend(paraThird);


                    document.querySelector('.validationDirectoryTrue').click();
                }


            } else {
                document.querySelector('.validationDirectoryFalse').click();
            }
        }

        return (
            <div className='upload-new-directory'>
                <div className='upload-new-directory_inner'>
                    <h6>New section</h6>
                    <p>You will be able to delete the section after it has been created.</p>
                    <input className={'createNewSection'} placeholder={'Section Name'} />
                    {/* <FormGroup className="default-switch">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>
                                <Switch
                                    onChange={onChangeDirectory}
                                    value="checked"
                                />
                            </Grid>
                            <Grid item>Daytime / Late Night</Grid>
                        </Grid>
                    </FormGroup> */}
                    <div className={'upload-new-directory_btn_group'}>
                        <button className={'create-section-close'} onClick={this.props.closePopup}>Cancel</button>
                        <button onClick={createNewSection}>Save</button>
                    </div>
                    <button className="validationDirectoryTrue" onClick={validationDirectoryTrue}>Notify!</button>
                    <button className="validationDirectoryFalse" onClick={validationDirectoryFalse}>Notify!</button>
                    <button className="validationDirectoryFalseWhiteSpace"
                        onClick={validationDirectoryFalseWhiteSpace}>Notify!
                    </button>

                </div>
            </div>
        );
    }
}


export class PopupWindowsCreateFile extends Component {
    render() {
        const onChangeDriveThrough = (event) => {
            let switchMenuBoards = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.upload-new-directory_inner-create-file-carousel')
            let heightContainer = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.upload-new-directory_inner-create-file');
            let colorSwitcher = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector('.upload-file-switch .MuiSwitch-track');
            if (event.target.checked === true) {
                switchMenuBoards.classList.add('active');
                heightContainer.style.height = '415px';
                colorSwitcher.classList.add('active-thumb');
            } else {
                switchMenuBoards.classList.remove('active');
                heightContainer.style.height = '260px';
                colorSwitcher.classList.remove('active-thumb');
            }
        }

        const onChangeDriveThroughTime = (event) => {
            let colorSwitcher = document.querySelector('.onChangeDriveThroughTime .MuiSwitch-track')
            if (event.target.checked === true) {
                colorSwitcher.classList.add('active-thumb');
            } else {
                colorSwitcher.classList.remove('active-thumb');
            }
        }

        let onChaneTags = (event) => {
            let containerTags = document.querySelector('.container-tags')
            console.log(containerTags)
            if (event.target.checked === true) {
                containerTags.style.display = 'flex';
                document.querySelector('.upload-new-directory_inner-create-file').style.height = '550px';

            } else {
                containerTags.style.display = 'none';
                document.querySelector('.upload-new-directory_inner-create-file').style.height = '270px';
            }
        }


        let activePodsItem = document.querySelector('.active-pods-item').textContent;
        let activeZoneItem = document.querySelector('.active-zone-item').textContent;
        let activeStoresItem = document.querySelectorAll('.uploadFiles-content-slider-item-stores ul li.active-item');


        let interestingSecondTimePeriod = document.querySelector('.interestingSecondTimePeriod').value;

        let activeStoresItemArray = [];
        for (let i = 0; i < activeStoresItem.length; i++) {
            activeStoresItemArray += activeStoresItem[i].textContent + ", ";
        }
        let previusPodsSelectionSplit = activeStoresItemArray.split(",")
        previusPodsSelectionSplit.slice(0, -1);


        function GridCardFirstStyle() {
            let gridFirstStyle = document.querySelector('.upload-new-directory_inner-create-file-carousel .upload-new-directory_inner-create-file-carousel-col')
            gridFirstStyle.classList.toggle('active')
        }

        function GridCardSecondStyle() {
            let gridSecondStyle = document.querySelector('.upload-new-directory_inner-create-file-carousel-row')

            gridSecondStyle.classList.toggle('active')
        }

        return (
            <div className='upload-new-directory upload-new-directory-create-file active'>
                <div className='upload-new-directory_inner upload-new-directory_inner-create-file'>
                    <h6>Upload Files</h6>
                    <p>You can upload one or several files.</p>
                    <FileUpload
                        pods={activePodsItem}
                        zones={activeZoneItem}
                        stores={activeStoresItemArray}
                        screens={this.props.screens}
                        timePeriod={interestingSecondTimePeriod}
                        mediaType={this.props.type}
                    />

                    <div className={'container-tags'} style={{ display: 'none' }}>
                    </div>

                    <div className={'upload-new-directory_inner-create-file-carousel'}>

                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.5028 4.47797C24.7678 3.74297 23.5828 3.74297 22.8478 4.47797L10.3828 16.943C9.79777 17.528 9.79777 18.473 10.3828 19.058L22.8478 31.523C23.5828 32.258 24.7678 32.258 25.5028 31.523C26.2378 30.788 26.2378 29.603 25.5028 28.868L14.6428 17.993L25.5178 7.11797C26.2378 6.39797 26.2378 5.19797 25.5028 4.47797Z"
                                fill="#808080" />
                        </svg>
                        <div onClick={GridCardFirstStyle}
                            className={'upload-new-directory_inner-create-file-carousel-col'}>
                            <div className={'upload-new-directory_inner-create-file-carousel-col-file-group'}>
                                <span />
                                <span>File_01</span>
                            </div>
                            <div className={'upload-new-directory_inner-create-file-carousel-col-file-group'}>
                                <span />
                                <span>File_02</span>
                            </div>
                        </div>
                        <div onClick={GridCardSecondStyle}
                            className={'upload-new-directory_inner-create-file-carousel-col upload-new-directory_inner-create-file-carousel-row'}>
                            <div className={'upload-new-directory_inner-create-file-carousel-col-file-group'}>
                                <span />
                                <span>File_01</span>
                            </div>
                            <div className={'upload-new-directory_inner-create-file-carousel-col-file-group'}>
                                <span />
                                <span>File_02</span>
                            </div>
                            <div className={'upload-new-directory_inner-create-file-carousel-col-file-group'}>
                                <span />
                                <span>File_03</span>
                            </div>
                        </div>

                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.4972 31.522C11.2322 32.257 12.4172 32.257 13.1522 31.522L25.6172 19.057C26.2022 18.472 26.2022 17.527 25.6172 16.942L13.1522 4.47703C12.4172 3.74203 11.2322 3.74203 10.4972 4.47703C9.76223 5.21203 9.76223 6.39703 10.4972 7.13203L21.3572 18.007L10.4822 28.882C9.76223 29.602 9.76223 30.802 10.4972 31.522Z"
                                fill="#808080" />
                        </svg>

                    </div>
                    <div className={'upload-new-directory_btn_group'}>
                        <button onClick={this.props.closePopup}>Close</button>
                    </div>

                </div>
            </div>
        );
    }
}

export class PodsUploadSecondStep extends Component {
    continue = e => {
        e.preventDefault();
        let selectFileUploadText = document.querySelector('.select-file-upload-text').innerText
        let uploadFilesContentSliderItemContent = document.querySelector('.uploadFiles-content-slider-item-pods ul li');
        let activeZoneItem = document.querySelector('.uploadFiles-content-slider-item-zones ul li')
        let activeStoreItem = document.querySelectorAll('.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul li')
        let name;
        if (selectFileUploadText === 'Select a PODS to display the current location.') {
            if (uploadFilesContentSliderItemContent.classList.contains('active-pods-item')) {
            } else {
                document.querySelector('.hiddenNotifyBtnPassword').click();
            }
        } else if (selectFileUploadText === 'Select a Zones to display the current location.') {
            if (activeZoneItem.classList.contains('active-zone-item')) {
            } else {
                document.querySelector('.hiddenNotifyBtnPasswordSecond').click();
            }
        } else if (selectFileUploadText === 'Zones, Location and Save') {
            let activeStoreItemCount;
            activeStoreItem.forEach(element => {
                if (element.classList.contains('active-item')) {
                    activeStoreItemCount = true;
                }
            })

            console.log(activeStoreItemCount)

            if (activeStoreItemCount === true) {
                let popup = document.querySelector('.new-compaign-popup-content')
                let popupStyle = document.querySelector('.new-compaign-popup__popup')
                popup.style.width = '707px'
                popup.style.height = '501px'
                popup.style.overflow = 'hidden'
                popup.style.setProperty('top', '24%', 'important');
                popupStyle.style.height = '100%';
                this.props.nextStep();
            } else {
                document.querySelector('.hiddenNotifyBtnPasswordThird').click();
            }

        }

    };
    back = e => {
        e.preventDefault();
        let selectFileUploadText = document.querySelector('.select-file-upload-text').innerText
        let uploadFilesContentSliderItemContent = document.querySelector('.uploadFiles-content-slider-item-pods');
        let activeZoneItem = document.querySelector('.uploadFiles-content-slider-item-zones')
        let activeStoreItem = document.querySelector('.uploadFiles-content-slider-item-stores')

        if (selectFileUploadText === 'Select a PODS to display the current location.') {
            this.props.prevStep();
        } else if (selectFileUploadText === 'Select a Zones to display the current location.') {
            document.querySelector('.select-file-upload-text').innerText = 'Select a PODS to display the current location.'
            uploadFilesContentSliderItemContent.classList.remove('active-zones');
            activeZoneItem.classList.remove('disable-pods');
            let uploadFilesContentSlider = document.querySelector('.breadcrumbs');
            uploadFilesContentSlider.innerHTML = '';
        } else if (selectFileUploadText === 'Zones, Location and Save') {
            document.querySelector('.select-file-upload-text').innerText = 'Select a Zones to display the current location.'
            activeZoneItem.classList.remove('active-stores')
            activeStoreItem.classList.remove('disable-zones');
            let uploadContainer = document.querySelector('.uploadFiles-content-slider-item-stores-upload')
            uploadContainer.classList.remove('active')
            document.querySelector('.button-group').style.paddingRight = '0';
            let uploadFilesContentSlider = document.querySelector('.breadcrumbs');
            let activePodsItem = document.querySelector('.active-pods-item').textContent
            uploadFilesContentSlider.innerHTML = activePodsItem + ' / ';
            document.querySelectorAll('.uploadFiles-content-slider-item-stores li').forEach(n => n.remove());
            document.querySelector('.switch-all-store').classList.remove('active');

            if (document.querySelector('.switch-all-store .MuiButtonBase-root').classList.contains('Mui-checked')) {
                document.querySelector('.switch-all-store .default-switch .MuiSwitch-root').click();
            }
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            searchString: "",
            searchStringZones: "",
            searchStringStores: "",
            users: [],
            zones: [],
            stores: [],
            showPopup: false,
            showPopupCreate: false,
            showPopupCreateMB01: false,
            showPopupCreateMB02: false,
            showPopupCreateMB03: false,
            showPopupCreateMB04: false,
            showPopupCreateMB05: false,
            showPopupCreateDT01: false,
            showPopupCreateDT02: false,
            showPopupCreateDT03: false,
            showPopupCreateDT04: false,
            showPopupCreateDT05: false,
            showPopupCreatePR01: false,
            showPopupCreatePR02: false,
            showPopupCreatePR03: false,
            showPopupCreatePR04: false,
            showPopupCreatePR05: false,
            showPopupCreateKB01: false,
            showPopupCreateKB02: false,
            showPopupCreateKB03: false,
            showPopupCreateKB04: false,
            showPopupCreateKB05: false,
            showPopupCreateAl01: false,
            showPopupCreateAl02: false,
            showPopupCreateAl03: false,
            showPopupCreateAl04: false,
            showPopupCreateAl05: false
        };
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }


    togglePopupCreate() {
        this.setState({
            showPopupCreate: !this.state.showPopupCreate
        });
    }

    togglePopupCreateMB01() {
        this.setState({
            showPopupCreateMB01: !this.state.showPopupCreateMB01
        });
    }

    togglePopupCreateMB02() {
        this.setState({
            showPopupCreateMB02: !this.state.showPopupCreateMB02
        });
    }

    togglePopupCreateMB03() {
        this.setState({
            showPopupCreateMB03: !this.state.showPopupCreateMB03
        });
    }

    togglePopupCreateMB04() {
        this.setState({
            showPopupCreateMB04: !this.state.showPopupCreateMB04
        });
    }

    togglePopupCreateMB05() {
        this.setState({
            showPopupCreateMB05: !this.state.showPopupCreateMB05
        });
    }


    togglePopupCreateDT01() {
        this.setState({
            showPopupCreateDT01: !this.state.showPopupCreateDT01
        });
    }

    togglePopupCreateDT02() {
        this.setState({
            showPopupCreateDT02: !this.state.showPopupCreateDT02
        });
    }

    togglePopupCreateDT03() {
        this.setState({
            showPopupCreateDT03: !this.state.showPopupCreateDT03
        });
    }

    togglePopupCreateDT04() {
        this.setState({
            showPopupCreateDT04: !this.state.showPopupCreateDT04
        });
    }

    togglePopupCreateDT05() {
        this.setState({
            showPopupCreateDT05: !this.state.showPopupCreateDT05
        });
    }

    togglePopupCreatePR01() {
        this.setState({
            showPopupCreatePR01: !this.state.showPopupCreatePR01
        });
    }

    togglePopupCreatePR02() {
        this.setState({
            showPopupCreatePR02: !this.state.showPopupCreatePR02
        });
    }

    togglePopupCreatePR03() {
        this.setState({
            showPopupCreatePR03: !this.state.showPopupCreatePR03
        });
    }

    togglePopupCreatePR04() {
        this.setState({
            showPopupCreatePR04: !this.state.showPopupCreatePR04
        });
    }

    togglePopupCreatePR05() {
        this.setState({
            showPopupCreatePR05: !this.state.showPopupCreatePR05
        });
    }

    togglePopupCreateKB01() {
        this.setState({
            showPopupCreateKB01: !this.state.showPopupCreateKB01
        });
    }

    togglePopupCreateKB02() {
        this.setState({
            showPopupCreateKB02: !this.state.showPopupCreateKB02
        });
    }

    togglePopupCreateKB03() {
        this.setState({
            showPopupCreateKB03: !this.state.showPopupCreateKB03
        });
    }

    togglePopupCreateKB04() {
        this.setState({
            showPopupCreateKB04: !this.state.showPopupCreateKB04
        });
    }

    togglePopupCreateKB05() {
        this.setState({
            showPopupCreateKB05: !this.state.showPopupCreateKB05
        });
    }

    togglePopupCreateAl01() {
        this.setState({
            showPopupCreateAl01: !this.state.showPopupCreateAl01
        });
    }

    togglePopupCreateAl02() {
        this.setState({
            showPopupCreateAl02: !this.state.showPopupCreateAl02
        });
    }

    togglePopupCreateAl03() {
        this.setState({
            showPopupCreateAl03: !this.state.showPopupCreateAl03
        });
    }

    togglePopupCreateAl04() {
        this.setState({
            showPopupCreateAl04: !this.state.showPopupCreateAl04
        });
    }

    togglePopupCreateAl05() {
        this.setState({
            showPopupCreateAl05: !this.state.showPopupCreateAl05
        });
    }

    componentDidMount() {
        let users = []
        let PropsInteresting = this.props.interesting;

        let previusPodsSelectionSplit = PropsInteresting.split(",")

        previusPodsSelectionSplit.forEach(element =>
            users.push(element)
        );

        let zones = ['Broken Hill', 'Yum Zone 1', 'Yum Zone 3', 'ZC1', 'ZC2', 'ZC3', 'ZC5', 'ZC6', 'ZC11', 'ZC12', 'ZC13']

        const stores = [
            {
                "label": "401 Swanston Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Aberfoyle Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Acacia Ridge",
                "priceZone": "ZC1"
            },
            {
                "label": "Airport West",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Albany",
                "priceZone": "ZC12"
            },
            {
                "label": "Albany Creek",
                "priceZone": "ZC1"
            },
            {
                "label": "Albion Park 2",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Albury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Alice Springs",
                "priceZone": "ZC11"
            },
            {
                "label": "Altona North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Annandale",
                "priceZone": "ZC3"
            },
            {
                "label": "Annerley",
                "priceZone": "ZC1"
            },
            {
                "label": "Ararat",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Armadale",
                "priceZone": "ZC11"
            },
            {
                "label": "Armidale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Arndale Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Artarmon",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Arundel",
                "priceZone": "ZC2"
            },
            {
                "label": "Ascot",
                "priceZone": "ZC11"
            },
            {
                "label": "Ashburton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ashfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ashfield Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ashmore",
                "priceZone": "ZC2"
            },
            {
                "label": "Aspley",
                "priceZone": "ZC1"
            },
            {
                "label": "Asquith",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Atherton",
                "priceZone": "ZC3"
            },
            {
                "label": "Atwell",
                "priceZone": "ZC11"
            },
            {
                "label": "Australia Fair",
                "priceZone": "ZC6"
            },
            {
                "label": "Ayr",
                "priceZone": "ZC3"
            },
            {
                "label": "Bacchus Marsh",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bagot Road",
                "priceZone": "ZC13"
            },
            {
                "label": "Bairnsdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Baldivis",
                "priceZone": "ZC11"
            },
            {
                "label": "Ballan Service Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ballarat",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ballina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ballina Travel Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Banksia Grove",
                "priceZone": "ZC11"
            },
            {
                "label": "Bankstown South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bankstown Square Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bateau Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Batemans Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bathurst",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Baulkham Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bayswater",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Beaudesert",
                "priceZone": "ZC2"
            },
            {
                "label": "Beechboro",
                "priceZone": "ZC11"
            },
            {
                "label": "Beenleigh",
                "priceZone": "ZC1"
            },
            {
                "label": "Bega",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Belconnen Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Beldon",
                "priceZone": "ZC11"
            },
            {
                "label": "Belmont",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Belmont Forum",
                "priceZone": "ZC11"
            },
            {
                "label": "Belmont VIC",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Benalla",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bendigo",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bendigo Marketplace",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bennets Green",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Benowa",
                "priceZone": "ZC2"
            },
            {
                "label": "Berrinba",
                "priceZone": "ZC1"
            },
            {
                "label": "Berwick South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bethania",
                "priceZone": "ZC1"
            },
            {
                "label": "Biloela",
                "priceZone": "ZC3"
            },
            {
                "label": "Birkdale",
                "priceZone": "ZC1"
            },
            {
                "label": "Blacktown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Blacktown Mega Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bligh Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bomaderry",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bondi Junction Eastgate",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bonnyrigg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Booval",
                "priceZone": "ZC1"
            },
            {
                "label": "Bourke Street Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "BP Caboolture North",
                "priceZone": "ZC6"
            },
            {
                "label": "BP Caboolture South",
                "priceZone": "ZC6"
            },
            {
                "label": "Brassall",
                "priceZone": "ZC1"
            },
            {
                "label": "Bridgewater",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Brighton TAS",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Brimbank",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Brisbane Airport",
                "priceZone": "ZC1"
            },
            {
                "label": "Broadmeadow (Drive Thru Only)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broadmeadows",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broadmeadows Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broadway",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broken Hill",
                "priceZone": "Broken Hill"
            },
            {
                "label": "Browns Plains",
                "priceZone": "ZC1"
            },
            {
                "label": "Buderim",
                "priceZone": "ZC2"
            },
            {
                "label": "Bunbury Forum",
                "priceZone": "ZC12"
            },
            {
                "label": "Bunbury South",
                "priceZone": "ZC12"
            },
            {
                "label": "Bundaberg",
                "priceZone": "ZC3"
            },
            {
                "label": "Bundaberg East",
                "priceZone": "ZC3"
            },
            {
                "label": "Bundoora",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burleigh Waters",
                "priceZone": "ZC2"
            },
            {
                "label": "Burnie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burnley",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burpengary",
                "priceZone": "ZC2"
            },
            {
                "label": "Burwood East",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burwood Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Busselton",
                "priceZone": "ZC12"
            },
            {
                "label": "Butler",
                "priceZone": "ZC11"
            },
            {
                "label": "Byford",
                "priceZone": "ZC11"
            },
            {
                "label": "Caboolture",
                "priceZone": "ZC2"
            },
            {
                "label": "Caboolture City",
                "priceZone": "ZC2"
            },
            {
                "label": "Cairns",
                "priceZone": "ZC3"
            },
            {
                "label": "Cairns Central Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Calamvale",
                "priceZone": "ZC1"
            },
            {
                "label": "Calder Inbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Calder Outbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Caloundra",
                "priceZone": "ZC2"
            },
            {
                "label": "Cameron Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Campbelltown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Campbelltown Mall Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Campsie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Canadian",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Canberra City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Capalaba",
                "priceZone": "ZC1"
            },
            {
                "label": "Capalaba Central Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Capalaba Park Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Carindale",
                "priceZone": "ZC1"
            },
            {
                "label": "Carindale Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Carlingford Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Carlton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Carnes Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Caroline Springs",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Carrum Downs",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Casino",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Castle Towers Level 1",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Casuarina",
                "priceZone": "ZC13"
            },
            {
                "label": "Casuarina Food Court",
                "priceZone": "ZC13"
            },
            {
                "label": "Caulfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Centenary",
                "priceZone": "ZC1"
            },
            {
                "label": "Cessnock",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chadstone Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Charlestown Square",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chatswood Chase",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chatswood Interchange",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chatswood Westfield Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chelsea Heights",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chermside Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Chinchilla",
                "priceZone": "ZC5"
            },
            {
                "label": "Chinderah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chinderah North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chirnside Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "City Cross",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Claremont",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Clarkson",
                "priceZone": "ZC11"
            },
            {
                "label": "Clayfield",
                "priceZone": "ZC1"
            },
            {
                "label": "Clayton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cleveland",
                "priceZone": "ZC1"
            },
            {
                "label": "Coburg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coffs Harbour Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coffs Harbour South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Colac",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Colonnades",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Concord",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coolalinga",
                "priceZone": "ZC13"
            },
            {
                "label": "Cooma",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coomera Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Corio",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cowra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Craigieburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cranbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cranbourne North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cranbrook",
                "priceZone": "ZC3"
            },
            {
                "label": "Cranebrook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Crossroads",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Crown Casino Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dalby",
                "priceZone": "ZC5"
            },
            {
                "label": "Dandenong Market",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dandenong Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dandenong South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dapto",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Darling Harbour Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Darlinghurst",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Deagon",
                "priceZone": "ZC1"
            },
            {
                "label": "Deception Bay",
                "priceZone": "ZC1"
            },
            {
                "label": "Dee Why",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Deer Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Derwent Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Devonport",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dickson",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dogswamp",
                "priceZone": "ZC11"
            },
            {
                "label": "Doncaster",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Doreen",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dubbo",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dubbo East",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dulwich Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dural",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eagleby",
                "priceZone": "ZC1"
            },
            {
                "label": "Eaglehawk",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Earlville",
                "priceZone": "ZC3"
            },
            {
                "label": "Earlville Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Earlwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Brighton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Lismore",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Maitland",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Wodonga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastgardens Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastlands Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastlink Northbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastlink Southbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Echuca",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Edmonton",
                "priceZone": "ZC3"
            },
            {
                "label": "Edwardstown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eight Mile Plains",
                "priceZone": "ZC1"
            },
            {
                "label": "Elizabeth",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Elizabeth Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Elizabeth Street Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ellenbrook",
                "priceZone": "ZC11"
            },
            {
                "label": "Eltham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Emerald",
                "priceZone": "ZC5"
            },
            {
                "label": "Emerton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Emu Plains",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Endeavour Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Enfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Engadine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Epping",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Epping Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Erina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Erina Fair",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ermington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Everard Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Everton Park",
                "priceZone": "ZC1"
            },
            {
                "label": "Fairfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fairy Meadow",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Falcon",
                "priceZone": "ZC11"
            },
            {
                "label": "Fawkner",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ferntree Gully",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Five Dock",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Flemington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Flowerdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Footscray",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Forbes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Forrestfield",
                "priceZone": "ZC11"
            },
            {
                "label": "Forster",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fountain Gate Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Frankston Bayside Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Frankston South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Frenchs Forest",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fulham Gardens",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fyshwick",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Garbutt",
                "priceZone": "ZC3"
            },
            {
                "label": "Garden City FC Qld",
                "priceZone": "ZC6"
            },
            {
                "label": "Garden City FC WA",
                "priceZone": "ZC11"
            },
            {
                "label": "Gatton",
                "priceZone": "ZC2"
            },
            {
                "label": "Gawler",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "George Street Sydney",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Geraldton",
                "priceZone": "ZC12"
            },
            {
                "label": "Gladesville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gladstone",
                "priceZone": "ZC3"
            },
            {
                "label": "Glen Innes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glen Waverley",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glen Waverley Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glendale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glenelg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glenmore Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Golden Grove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Goulburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Goulburn South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grafton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grafton Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grand Central",
                "priceZone": "ZC2"
            },
            {
                "label": "Grand Plaza Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Granville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Green Hills Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Green Square",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greenacre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greensborough",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greensborough Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greenslopes",
                "priceZone": "ZC1"
            },
            {
                "label": "Griffith",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grovedale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grovely",
                "priceZone": "ZC1"
            },
            {
                "label": "Gundagai",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gungahlin",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gunnedah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gympie",
                "priceZone": "ZC3"
            },
            {
                "label": "Hallam",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hamilton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Harrisdale",
                "priceZone": "ZC11"
            },
            {
                "label": "Hastings",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hawker",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hawthorn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Haymarket",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Haynes",
                "priceZone": "ZC11"
            },
            {
                "label": "Heidelberg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Helensvale",
                "priceZone": "ZC2"
            },
            {
                "label": "Helensvale Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Hermit Park",
                "priceZone": "ZC3"
            },
            {
                "label": "Hervey Bay",
                "priceZone": "ZC3"
            },
            {
                "label": "Highett",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Highfields",
                "priceZone": "ZC2"
            },
            {
                "label": "Highpoint - Level 3",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Highpoint II",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hillcrest",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hindley Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hinkler Place Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Hoppers Crossing",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hoppers Crossing 2",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hornsby Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Horsham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hoxton Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hurstville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hurstville Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Inala",
                "priceZone": "ZC1"
            },
            {
                "label": "Indooroopilly",
                "priceZone": "ZC1"
            },
            {
                "label": "Indooroopilly Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Ingham",
                "priceZone": "ZC3"
            },
            {
                "label": "Ingleburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Innaloo",
                "priceZone": "ZC11"
            },
            {
                "label": "Innisfail",
                "priceZone": "ZC3"
            },
            {
                "label": "Inverell",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Irymple",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Jerrabomberra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Jesmond",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Jimboomba",
                "priceZone": "ZC2"
            },
            {
                "label": "Joondalup",
                "priceZone": "ZC11"
            },
            {
                "label": "Kalgoorlie",
                "priceZone": "ZC12"
            },
            {
                "label": "Kallangur",
                "priceZone": "ZC1"
            },
            {
                "label": "Kangaroo Flat",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kangaroo Point",
                "priceZone": "ZC1"
            },
            {
                "label": "Karawara",
                "priceZone": "ZC11"
            },
            {
                "label": "Karingal",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Karratha",
                "priceZone": "Yum Zone 3"
            },
            {
                "label": "Karrinyup",
                "priceZone": "ZC11"
            },
            {
                "label": "Kawana Food Court",
                "priceZone": "ZC2"
            },
            {
                "label": "Kedron",
                "priceZone": "ZC1"
            },
            {
                "label": "Keilor Downs",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kelmscott",
                "priceZone": "ZC11"
            },
            {
                "label": "Kelvin Grove",
                "priceZone": "ZC1"
            },
            {
                "label": "Kempsey",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Keperra",
                "priceZone": "ZC1"
            },
            {
                "label": "Kew",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Keysborough",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kilburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kilmore",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kincumber",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kingaroy",
                "priceZone": "ZC2"
            },
            {
                "label": "Kings Cross",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kings Meadow",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kings Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kingston",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kingsway",
                "priceZone": "ZC11"
            },
            {
                "label": "Kirwan",
                "priceZone": "ZC3"
            },
            {
                "label": "Knox City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Knox City Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kogarah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kooragang",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kotara",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kurri Kurri",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kwinana",
                "priceZone": "ZC11"
            },
            {
                "label": "Lakehaven",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lakehaven Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lakelands",
                "priceZone": "ZC11"
            },
            {
                "label": "Lakemba",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lakes Entrance",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Launceston",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Laverton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lavington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lavington Square Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Legana",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Leongatha",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Leppington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Leppington Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lidcombe",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lilydale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lindfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lithgow",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Liverpool",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Liverpool Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Livingston",
                "priceZone": "ZC11"
            },
            {
                "label": "Logan Hyperdome",
                "priceZone": "ZC6"
            },
            {
                "label": "Loganholme",
                "priceZone": "ZC1"
            },
            {
                "label": "Lucas",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lynbrook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "MacArthur Central Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Macarthur Shopping Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mackay",
                "priceZone": "ZC3"
            },
            {
                "label": "Mackay Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Mackay North",
                "priceZone": "ZC3"
            },
            {
                "label": "Macquarie Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mandurah",
                "priceZone": "ZC11"
            },
            {
                "label": "Manly Vale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Marangaroo",
                "priceZone": "ZC11"
            },
            {
                "label": "Marden",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mareeba",
                "priceZone": "ZC3"
            },
            {
                "label": "Marion",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Marion Mall Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Maroochydore",
                "priceZone": "ZC2"
            },
            {
                "label": "Marsden",
                "priceZone": "ZC1"
            },
            {
                "label": "Marsden Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Marulan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Maryborough QLD",
                "priceZone": "ZC3"
            },
            {
                "label": "Maryborough VIC",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mawson Lakes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mayfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Maylands",
                "priceZone": "ZC11"
            },
            {
                "label": "Mcgraths Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Meadowbrook",
                "priceZone": "ZC1"
            },
            {
                "label": "Mean Fiddler",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melbourne Central Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melton Caltex",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melton Phoenix",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melton South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melville",
                "priceZone": "ZC11"
            },
            {
                "label": "Menai",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mentone",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mermaid Beach",
                "priceZone": "ZC2"
            },
            {
                "label": "Merrylands",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Merrylands Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Miami",
                "priceZone": "ZC2"
            },
            {
                "label": "Midland",
                "priceZone": "ZC11"
            },
            {
                "label": "Midvale",
                "priceZone": "ZC11"
            },
            {
                "label": "Mildura",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mill Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Milperra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Minchinbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mingara",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Minto",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Miranda",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Miranda Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mirrabooka",
                "priceZone": "ZC11"
            },
            {
                "label": "Mitcham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mittagong Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Modbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Moe",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mona Vale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Moonee Ponds",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mooroolbark",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Moranbah",
                "priceZone": "ZC5"
            },
            {
                "label": "Morayfield Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Moree",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Morisset",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Morley",
                "priceZone": "ZC11"
            },
            {
                "label": "Morningside",
                "priceZone": "ZC1"
            },
            {
                "label": "Mornington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Morwell",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mowbray",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Annan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Barker",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Druitt",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Druitt Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Gambier City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Gambier Market Place (Drive Thru Only)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Isa",
                "priceZone": "ZC5"
            },
            {
                "label": "Mt Pleasant",
                "priceZone": "ZC3"
            },
            {
                "label": "Mudgee",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mundaring",
                "priceZone": "ZC11"
            },
            {
                "label": "Munno Para",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Murray Bridge",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Murwillumbah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Muswellbrook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Myer Centre",
                "priceZone": "ZC6"
            },
            {
                "label": "Myer Centre Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nambour",
                "priceZone": "ZC2"
            },
            {
                "label": "Nambucca Heads",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narellan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narellan Town Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narrabri",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narre Warren",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nathan Plaza",
                "priceZone": "ZC3"
            },
            {
                "label": "Nerang",
                "priceZone": "ZC2"
            },
            {
                "label": "Newcomb",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nicholls",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Niddrie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Noarlunga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Noble Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Noosaville",
                "priceZone": "ZC2"
            },
            {
                "label": "North Geelong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "North Lakes",
                "priceZone": "ZC1"
            },
            {
                "label": "North Lakes Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "North Ryde",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "North Wagga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "North Wyong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Northam",
                "priceZone": "ZC11"
            },
            {
                "label": "Northgate",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Northland Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Northmead",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nowra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nunawading",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Oakleigh",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ocean Grove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Officer Arena",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Officer Inbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Officer Outbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Orange",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ormeau",
                "priceZone": "ZC2"
            },
            {
                "label": "Ormond",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Oxenford",
                "priceZone": "ZC2"
            },
            {
                "label": "Pacific Fair",
                "priceZone": "ZC6"
            },
            {
                "label": "Pagewood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pakenham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Palais",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Palmerston",
                "priceZone": "ZC13"
            },
            {
                "label": "Paralowie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Park Ridge",
                "priceZone": "ZC1"
            },
            {
                "label": "Parkes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Parklea",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Parramatta L1 Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Parramatta L5 Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penlink Inbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penlink Outbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pennant Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penrith Leagues",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penrith Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penrith South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pimpama",
                "priceZone": "ZC2"
            },
            {
                "label": "Pinjarra North",
                "priceZone": "ZC11"
            },
            {
                "label": "Pinjarra South",
                "priceZone": "ZC11"
            },
            {
                "label": "Plainland",
                "priceZone": "ZC2"
            },
            {
                "label": "Playford",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Plenty Valley Westfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Plumpton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Point Cook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Adelaide",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Augusta",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Lincoln",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Macquarie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Macquarie II",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Pirie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Portland",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Prahran",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Preston",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Prospect",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pulteney Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Punchbowl",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Queanbeyan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Randwick",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rasmussen",
                "priceZone": "ZC2"
            },
            {
                "label": "Raymond Terrace",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Redbank",
                "priceZone": "ZC1"
            },
            {
                "label": "Redbank Plains",
                "priceZone": "ZC1"
            },
            {
                "label": "Redcliffe",
                "priceZone": "ZC1"
            },
            {
                "label": "Redlynch",
                "priceZone": "ZC3"
            },
            {
                "label": "Renmark",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Reservoir",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Revesby",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Reynella",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Richmond South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ringwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Riverdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Riverlink Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Riverstone",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Riverwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Robina Drive Thru",
                "priceZone": "ZC2"
            },
            {
                "label": "Robina Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Rockdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rockhampton Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Rockhampton North",
                "priceZone": "ZC3"
            },
            {
                "label": "Rockhampton South",
                "priceZone": "ZC3"
            },
            {
                "label": "Rockingham Beach",
                "priceZone": "ZC11"
            },
            {
                "label": "Roma",
                "priceZone": "ZC5"
            },
            {
                "label": "Rosebud",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rosehill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Roselands Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rosny Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rouse Hill Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rowville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Roxburgh Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Runaway Bay",
                "priceZone": "ZC2"
            },
            {
                "label": "Rutherford",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Salamander Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Salisbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sans Souci",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Seaford Rise",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sebastopol",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Seven Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Seymour",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sheidow Park Hallets Cove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shellharbour",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shellharbour Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shepparton City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shepparton North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shepparton South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Singleton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sippy Downs",
                "priceZone": "ZC2"
            },
            {
                "label": "Smithfield",
                "priceZone": "ZC3"
            },
            {
                "label": "Sorell",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "South Lake",
                "priceZone": "ZC11"
            },
            {
                "label": "South Perth",
                "priceZone": "ZC11"
            },
            {
                "label": "Southland Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Southport",
                "priceZone": "ZC2"
            },
            {
                "label": "Spearwood",
                "priceZone": "ZC11"
            },
            {
                "label": "Spring Farm",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Springfield",
                "priceZone": "ZC1"
            },
            {
                "label": "Springfield Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Springfield Parkway",
                "priceZone": "ZC1"
            },
            {
                "label": "Springvale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Springwood",
                "priceZone": "ZC1"
            },
            {
                "label": "St Clair",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "St Kilda",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "St Leonards",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "St Peters",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Strath Village",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Strathpine",
                "priceZone": "ZC1"
            },
            {
                "label": "Strathpine Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Sturt Mall Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Subiaco",
                "priceZone": "ZC11"
            },
            {
                "label": "Sunbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sunnybank",
                "priceZone": "ZC1"
            },
            {
                "label": "Sunshine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sunshine Market Place",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Surfers Paradise",
                "priceZone": "ZC2"
            },
            {
                "label": "Swan Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sydenham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sydney Airport",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sydney Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tahmoor",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tamworth",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tamworth South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tannum Sands",
                "priceZone": "ZC3"
            },
            {
                "label": "Taree",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Taree Service Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tarneit",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Taylors Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tea Tree Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "The Entrance",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "The Pines",
                "priceZone": "ZC2"
            },
            {
                "label": "Thomastown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Thornbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Thornlie",
                "priceZone": "ZC11"
            },
            {
                "label": "Thornton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tingalpa",
                "priceZone": "ZC1"
            },
            {
                "label": "Toormina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Toowoomba",
                "priceZone": "ZC2"
            },
            {
                "label": "Toowoomba East",
                "priceZone": "ZC2"
            },
            {
                "label": "Top Ryde Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Toronto",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Torrensville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Traralgon",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Traralgon East",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Treendale",
                "priceZone": "ZC12"
            },
            {
                "label": "Truganina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tuggerah Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tuggeranong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tullamarine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tumut",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tweed City Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Tweed Heads",
                "priceZone": "ZC2"
            },
            {
                "label": "Ulladulla",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ulverstone",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Unanderra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Upper Coomera",
                "priceZone": "ZC2"
            },
            {
                "label": "Urangan",
                "priceZone": "ZC3"
            },
            {
                "label": "Valley Metro",
                "priceZone": "ZC6"
            },
            {
                "label": "Vermont",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Victor Harbor",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Victoria Gardens Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Victoria Point",
                "priceZone": "ZC1"
            },
            {
                "label": "Villawood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wagga Homebase",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wallan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wallan 1 (Southbound)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wallan 2 (Northbound)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wangaratta",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wantirna",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warnbro",
                "priceZone": "ZC11"
            },
            {
                "label": "Warner",
                "priceZone": "ZC1"
            },
            {
                "label": "Warners Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warragul",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warragul Queen Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warrawong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warrnambool City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warwick",
                "priceZone": "ZC2"
            },
            {
                "label": "Warwick Farm",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warwick WA",
                "priceZone": "ZC11"
            },
            {
                "label": "Waterfront City Docklands",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Watergardens",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Welland Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wellington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wendouree",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wentworthville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Werribee",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Werribee Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Werrington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "West End Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "West Gosford",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "West Lakes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Westfield Geelong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Westgate Port Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Westpoint Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wetherill Park (Outside)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wetherill Park Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Whitfords",
                "priceZone": "ZC11"
            },
            {
                "label": "Whyalla",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Willetton",
                "priceZone": "ZC11"
            },
            {
                "label": "William Street",
                "priceZone": "ZC11"
            },
            {
                "label": "Wilsonton",
                "priceZone": "ZC2"
            },
            {
                "label": "Woden",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wodonga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wollongong Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wonthaggi",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Woodbine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Woodgrove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Woodlands",
                "priceZone": "ZC3"
            },
            {
                "label": "Woodridge",
                "priceZone": "ZC1"
            },
            {
                "label": "Woy Woy",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wurtulla",
                "priceZone": "ZC2"
            },
            {
                "label": "Wyndham Vale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wynnum",
                "priceZone": "ZC1"
            },
            {
                "label": "Wyoming",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yagoona",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yamanto",
                "priceZone": "ZC1"
            },
            {
                "label": "Yarraville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yarrawonga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yass",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yeppoon",
                "priceZone": "ZC3"
            },
            {
                "label": "Young",
                "priceZone": "Yum Zone 1"
            }
        ]

        this.setState({
            users: users,
            zones: zones,
            stores: stores
        });
    }


    handleChangeSearch() {
        this.setState({
            searchString: this.refs.search.value
        });
    }

    handleChangeSearchZones() {
        this.setState({
            searchStringZones: this.refs.searchZones.value
        });
    }


    handleChangeSearchStores() {
        this.setState({
            searchStringStores: this.refs.searchStores.value
        });
    }


    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };


    render() {
        const { isOpen, haveText } = this.state;
        const { values, handleChange } = this.props;
        let _users = this.state.users;
        let search = this.state.searchString.trim().toLowerCase();

        let _zones = this.state.zones;
        let searchZones = this.state.searchStringZones.trim().toLowerCase();

        let _stores = this.state.stores;
        let searchStores = this.state.searchStringStores.trim().toLowerCase();

        if (search.length > 0) {
            _users = _users.filter(function (user) {
                return user.toLowerCase().match(search);
            });
        }

        if (searchZones.length > 0) {
            _zones = _zones.filter(function (zones) {
                return zones.toLowerCase().match(searchZones);
            });
        }

        if (searchStores.length > 0) {
            _stores = _stores.filter(function (stores) {
                return stores.label.toLowerCase().match(searchStores);
            });
        }

        function slideToZones(e) {

            document.querySelector('.select-file-upload-text').innerText = 'Select a Zones to display the current location.'

            e.target.classList.add('active-pods-item')
            let podsContainer = document.querySelector('.uploadFiles-content-slider-item-pods')
            let zonesContainer = document.querySelector('.uploadFiles-content-slider-item-zones')
            let uploadFilesContentSlider = document.querySelector('.upload-content-container');

            let activePodsItem = document.querySelector('.active-pods-item').textContent

            const para = document.createElement("p");
            const node = document.createTextNode(activePodsItem + " /");
            para.classList.add('breadcrumbs')
            para.appendChild(node);
            uploadFilesContentSlider.prepend(para);

            podsContainer.classList.add('active-zones')
            zonesContainer.classList.add('disable-pods')
        }

        const optionsLocation = [
            {
                "label": "401 Swanston Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Aberfoyle Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Acacia Ridge",
                "priceZone": "ZC1"
            },
            {
                "label": "Airport West",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Albany",
                "priceZone": "ZC12"
            },
            {
                "label": "Albany Creek",
                "priceZone": "ZC1"
            },
            {
                "label": "Albion Park 2",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Albury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Alice Springs",
                "priceZone": "ZC11"
            },
            {
                "label": "Altona North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Annandale",
                "priceZone": "ZC3"
            },
            {
                "label": "Annerley",
                "priceZone": "ZC1"
            },
            {
                "label": "Ararat",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Armadale",
                "priceZone": "ZC11"
            },
            {
                "label": "Armidale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Arndale Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Artarmon",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Arundel",
                "priceZone": "ZC2"
            },
            {
                "label": "Ascot",
                "priceZone": "ZC11"
            },
            {
                "label": "Ashburton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ashfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ashfield Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ashmore",
                "priceZone": "ZC2"
            },
            {
                "label": "Aspley",
                "priceZone": "ZC1"
            },
            {
                "label": "Asquith",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Atherton",
                "priceZone": "ZC3"
            },
            {
                "label": "Atwell",
                "priceZone": "ZC11"
            },
            {
                "label": "Australia Fair",
                "priceZone": "ZC6"
            },
            {
                "label": "Ayr",
                "priceZone": "ZC3"
            },
            {
                "label": "Bacchus Marsh",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bagot Road",
                "priceZone": "ZC13"
            },
            {
                "label": "Bairnsdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Baldivis",
                "priceZone": "ZC11"
            },
            {
                "label": "Ballan Service Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ballarat",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ballina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ballina Travel Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Banksia Grove",
                "priceZone": "ZC11"
            },
            {
                "label": "Bankstown South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bankstown Square Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bateau Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Batemans Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bathurst",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Baulkham Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bayswater",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Beaudesert",
                "priceZone": "ZC2"
            },
            {
                "label": "Beechboro",
                "priceZone": "ZC11"
            },
            {
                "label": "Beenleigh",
                "priceZone": "ZC1"
            },
            {
                "label": "Bega",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Belconnen Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Beldon",
                "priceZone": "ZC11"
            },
            {
                "label": "Belmont",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Belmont Forum",
                "priceZone": "ZC11"
            },
            {
                "label": "Belmont VIC",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Benalla",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bendigo",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bendigo Marketplace",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bennets Green",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Benowa",
                "priceZone": "ZC2"
            },
            {
                "label": "Berrinba",
                "priceZone": "ZC1"
            },
            {
                "label": "Berwick South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bethania",
                "priceZone": "ZC1"
            },
            {
                "label": "Biloela",
                "priceZone": "ZC3"
            },
            {
                "label": "Birkdale",
                "priceZone": "ZC1"
            },
            {
                "label": "Blacktown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Blacktown Mega Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bligh Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bomaderry",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bondi Junction Eastgate",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Bonnyrigg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Booval",
                "priceZone": "ZC1"
            },
            {
                "label": "Bourke Street Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "BP Caboolture North",
                "priceZone": "ZC6"
            },
            {
                "label": "BP Caboolture South",
                "priceZone": "ZC6"
            },
            {
                "label": "Brassall",
                "priceZone": "ZC1"
            },
            {
                "label": "Bridgewater",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Brighton TAS",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Brimbank",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Brisbane Airport",
                "priceZone": "ZC1"
            },
            {
                "label": "Broadmeadow (Drive Thru Only)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broadmeadows",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broadmeadows Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broadway",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Broken Hill",
                "priceZone": "Broken Hill"
            },
            {
                "label": "Browns Plains",
                "priceZone": "ZC1"
            },
            {
                "label": "Buderim",
                "priceZone": "ZC2"
            },
            {
                "label": "Bunbury Forum",
                "priceZone": "ZC12"
            },
            {
                "label": "Bunbury South",
                "priceZone": "ZC12"
            },
            {
                "label": "Bundaberg",
                "priceZone": "ZC3"
            },
            {
                "label": "Bundaberg East",
                "priceZone": "ZC3"
            },
            {
                "label": "Bundoora",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burleigh Waters",
                "priceZone": "ZC2"
            },
            {
                "label": "Burnie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burnley",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burpengary",
                "priceZone": "ZC2"
            },
            {
                "label": "Burwood East",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Burwood Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Busselton",
                "priceZone": "ZC12"
            },
            {
                "label": "Butler",
                "priceZone": "ZC11"
            },
            {
                "label": "Byford",
                "priceZone": "ZC11"
            },
            {
                "label": "Caboolture",
                "priceZone": "ZC2"
            },
            {
                "label": "Caboolture City",
                "priceZone": "ZC2"
            },
            {
                "label": "Cairns",
                "priceZone": "ZC3"
            },
            {
                "label": "Cairns Central Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Calamvale",
                "priceZone": "ZC1"
            },
            {
                "label": "Calder Inbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Calder Outbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Caloundra",
                "priceZone": "ZC2"
            },
            {
                "label": "Cameron Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Campbelltown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Campbelltown Mall Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Campsie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Canadian",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Canberra City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Capalaba",
                "priceZone": "ZC1"
            },
            {
                "label": "Capalaba Central Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Capalaba Park Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Carindale",
                "priceZone": "ZC1"
            },
            {
                "label": "Carindale Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Carlingford Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Carlton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Carnes Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Caroline Springs",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Carrum Downs",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Casino",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Castle Towers Level 1",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Casuarina",
                "priceZone": "ZC13"
            },
            {
                "label": "Casuarina Food Court",
                "priceZone": "ZC13"
            },
            {
                "label": "Caulfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Centenary",
                "priceZone": "ZC1"
            },
            {
                "label": "Cessnock",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chadstone Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Charlestown Square",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chatswood Chase",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chatswood Interchange",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chatswood Westfield Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chelsea Heights",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chermside Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Chinchilla",
                "priceZone": "ZC5"
            },
            {
                "label": "Chinderah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chinderah North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Chirnside Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "City Cross",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Claremont",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Clarkson",
                "priceZone": "ZC11"
            },
            {
                "label": "Clayfield",
                "priceZone": "ZC1"
            },
            {
                "label": "Clayton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cleveland",
                "priceZone": "ZC1"
            },
            {
                "label": "Coburg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coffs Harbour Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coffs Harbour South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Colac",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Colonnades",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Concord",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coolalinga",
                "priceZone": "ZC13"
            },
            {
                "label": "Cooma",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Coomera Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Corio",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cowra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Craigieburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cranbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cranbourne North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Cranbrook",
                "priceZone": "ZC3"
            },
            {
                "label": "Cranebrook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Crossroads",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Crown Casino Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dalby",
                "priceZone": "ZC5"
            },
            {
                "label": "Dandenong Market",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dandenong Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dandenong South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dapto",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Darling Harbour Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Darlinghurst",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Deagon",
                "priceZone": "ZC1"
            },
            {
                "label": "Deception Bay",
                "priceZone": "ZC1"
            },
            {
                "label": "Dee Why",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Deer Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Derwent Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Devonport",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dickson",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dogswamp",
                "priceZone": "ZC11"
            },
            {
                "label": "Doncaster",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Doreen",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dubbo",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dubbo East",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dulwich Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Dural",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eagleby",
                "priceZone": "ZC1"
            },
            {
                "label": "Eaglehawk",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Earlville",
                "priceZone": "ZC3"
            },
            {
                "label": "Earlville Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Earlwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Brighton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Lismore",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Maitland",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "East Wodonga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastgardens Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastlands Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastlink Northbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastlink Southbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eastwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Echuca",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Edmonton",
                "priceZone": "ZC3"
            },
            {
                "label": "Edwardstown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Eight Mile Plains",
                "priceZone": "ZC1"
            },
            {
                "label": "Elizabeth",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Elizabeth Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Elizabeth Street Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ellenbrook",
                "priceZone": "ZC11"
            },
            {
                "label": "Eltham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Emerald",
                "priceZone": "ZC5"
            },
            {
                "label": "Emerton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Emu Plains",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Endeavour Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Enfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Engadine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Epping",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Epping Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Erina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Erina Fair",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ermington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Everard Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Everton Park",
                "priceZone": "ZC1"
            },
            {
                "label": "Fairfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fairy Meadow",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Falcon",
                "priceZone": "ZC11"
            },
            {
                "label": "Fawkner",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ferntree Gully",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Five Dock",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Flemington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Flowerdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Footscray",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Forbes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Forrestfield",
                "priceZone": "ZC11"
            },
            {
                "label": "Forster",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fountain Gate Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Frankston Bayside Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Frankston South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Frenchs Forest",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fulham Gardens",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Fyshwick",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Garbutt",
                "priceZone": "ZC3"
            },
            {
                "label": "Garden City FC Qld",
                "priceZone": "ZC6"
            },
            {
                "label": "Garden City FC WA",
                "priceZone": "ZC11"
            },
            {
                "label": "Gatton",
                "priceZone": "ZC2"
            },
            {
                "label": "Gawler",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "George Street Sydney",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Geraldton",
                "priceZone": "ZC12"
            },
            {
                "label": "Gladesville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gladstone",
                "priceZone": "ZC3"
            },
            {
                "label": "Glen Innes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glen Waverley",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glen Waverley Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glendale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glenelg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Glenmore Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Golden Grove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Goulburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Goulburn South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grafton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grafton Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grand Central",
                "priceZone": "ZC2"
            },
            {
                "label": "Grand Plaza Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Granville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Green Hills Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Green Square",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greenacre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greensborough",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greensborough Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Greenslopes",
                "priceZone": "ZC1"
            },
            {
                "label": "Griffith",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grovedale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Grovely",
                "priceZone": "ZC1"
            },
            {
                "label": "Gundagai",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gungahlin",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gunnedah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Gympie",
                "priceZone": "ZC3"
            },
            {
                "label": "Hallam",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hamilton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Harrisdale",
                "priceZone": "ZC11"
            },
            {
                "label": "Hastings",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hawker",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hawthorn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Haymarket",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Haynes",
                "priceZone": "ZC11"
            },
            {
                "label": "Heidelberg",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Helensvale",
                "priceZone": "ZC2"
            },
            {
                "label": "Helensvale Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Hermit Park",
                "priceZone": "ZC3"
            },
            {
                "label": "Hervey Bay",
                "priceZone": "ZC3"
            },
            {
                "label": "Highett",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Highfields",
                "priceZone": "ZC2"
            },
            {
                "label": "Highpoint - Level 3",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Highpoint II",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hillcrest",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hindley Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hinkler Place Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Hoppers Crossing",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hoppers Crossing 2",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hornsby Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Horsham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hoxton Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hurstville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Hurstville Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Inala",
                "priceZone": "ZC1"
            },
            {
                "label": "Indooroopilly",
                "priceZone": "ZC1"
            },
            {
                "label": "Indooroopilly Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Ingham",
                "priceZone": "ZC3"
            },
            {
                "label": "Ingleburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Innaloo",
                "priceZone": "ZC11"
            },
            {
                "label": "Innisfail",
                "priceZone": "ZC3"
            },
            {
                "label": "Inverell",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Irymple",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Jerrabomberra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Jesmond",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Jimboomba",
                "priceZone": "ZC2"
            },
            {
                "label": "Joondalup",
                "priceZone": "ZC11"
            },
            {
                "label": "Kalgoorlie",
                "priceZone": "ZC12"
            },
            {
                "label": "Kallangur",
                "priceZone": "ZC1"
            },
            {
                "label": "Kangaroo Flat",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kangaroo Point",
                "priceZone": "ZC1"
            },
            {
                "label": "Karawara",
                "priceZone": "ZC11"
            },
            {
                "label": "Karingal",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Karratha",
                "priceZone": "Yum Zone 3"
            },
            {
                "label": "Karrinyup",
                "priceZone": "ZC11"
            },
            {
                "label": "Kawana Food Court",
                "priceZone": "ZC2"
            },
            {
                "label": "Kedron",
                "priceZone": "ZC1"
            },
            {
                "label": "Keilor Downs",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kelmscott",
                "priceZone": "ZC11"
            },
            {
                "label": "Kelvin Grove",
                "priceZone": "ZC1"
            },
            {
                "label": "Kempsey",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Keperra",
                "priceZone": "ZC1"
            },
            {
                "label": "Kew",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Keysborough",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kilburn",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kilmore",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kincumber",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kingaroy",
                "priceZone": "ZC2"
            },
            {
                "label": "Kings Cross",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kings Meadow",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kings Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kingston",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kingsway",
                "priceZone": "ZC11"
            },
            {
                "label": "Kirwan",
                "priceZone": "ZC3"
            },
            {
                "label": "Knox City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Knox City Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kogarah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kooragang",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kotara",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kurri Kurri",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Kwinana",
                "priceZone": "ZC11"
            },
            {
                "label": "Lakehaven",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lakehaven Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lakelands",
                "priceZone": "ZC11"
            },
            {
                "label": "Lakemba",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lakes Entrance",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Launceston",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Laverton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lavington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lavington Square Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Legana",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Leongatha",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Leppington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Leppington Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lidcombe",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lilydale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lindfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lithgow",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Liverpool",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Liverpool Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Livingston",
                "priceZone": "ZC11"
            },
            {
                "label": "Logan Hyperdome",
                "priceZone": "ZC6"
            },
            {
                "label": "Loganholme",
                "priceZone": "ZC1"
            },
            {
                "label": "Lucas",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Lynbrook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "MacArthur Central Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Macarthur Shopping Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mackay",
                "priceZone": "ZC3"
            },
            {
                "label": "Mackay Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Mackay North",
                "priceZone": "ZC3"
            },
            {
                "label": "Macquarie Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mandurah",
                "priceZone": "ZC11"
            },
            {
                "label": "Manly Vale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Marangaroo",
                "priceZone": "ZC11"
            },
            {
                "label": "Marden",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mareeba",
                "priceZone": "ZC3"
            },
            {
                "label": "Marion",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Marion Mall Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Maroochydore",
                "priceZone": "ZC2"
            },
            {
                "label": "Marsden",
                "priceZone": "ZC1"
            },
            {
                "label": "Marsden Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Marulan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Maryborough QLD",
                "priceZone": "ZC3"
            },
            {
                "label": "Maryborough VIC",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mawson Lakes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mayfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Maylands",
                "priceZone": "ZC11"
            },
            {
                "label": "Mcgraths Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Meadowbrook",
                "priceZone": "ZC1"
            },
            {
                "label": "Mean Fiddler",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melbourne Central Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melton Caltex",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melton Phoenix",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melton South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Melville",
                "priceZone": "ZC11"
            },
            {
                "label": "Menai",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mentone",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mermaid Beach",
                "priceZone": "ZC2"
            },
            {
                "label": "Merrylands",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Merrylands Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Miami",
                "priceZone": "ZC2"
            },
            {
                "label": "Midland",
                "priceZone": "ZC11"
            },
            {
                "label": "Midvale",
                "priceZone": "ZC11"
            },
            {
                "label": "Mildura",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mill Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Milperra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Minchinbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mingara",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Minto",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Miranda",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Miranda Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mirrabooka",
                "priceZone": "ZC11"
            },
            {
                "label": "Mitcham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mittagong Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Modbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Moe",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mona Vale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Moonee Ponds",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mooroolbark",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Moranbah",
                "priceZone": "ZC5"
            },
            {
                "label": "Morayfield Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Moree",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Morisset",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Morley",
                "priceZone": "ZC11"
            },
            {
                "label": "Morningside",
                "priceZone": "ZC1"
            },
            {
                "label": "Mornington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Morwell",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mowbray",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Annan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Barker",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Druitt",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Druitt Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Gambier City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Gambier Market Place (Drive Thru Only)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mt Isa",
                "priceZone": "ZC5"
            },
            {
                "label": "Mt Pleasant",
                "priceZone": "ZC3"
            },
            {
                "label": "Mudgee",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Mundaring",
                "priceZone": "ZC11"
            },
            {
                "label": "Munno Para",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Murray Bridge",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Murwillumbah",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Muswellbrook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Myer Centre",
                "priceZone": "ZC6"
            },
            {
                "label": "Myer Centre Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nambour",
                "priceZone": "ZC2"
            },
            {
                "label": "Nambucca Heads",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narellan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narellan Town Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narrabri",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Narre Warren",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nathan Plaza",
                "priceZone": "ZC3"
            },
            {
                "label": "Nerang",
                "priceZone": "ZC2"
            },
            {
                "label": "Newcomb",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nicholls",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Niddrie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Noarlunga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Noble Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Noosaville",
                "priceZone": "ZC2"
            },
            {
                "label": "North Geelong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "North Lakes",
                "priceZone": "ZC1"
            },
            {
                "label": "North Lakes Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "North Ryde",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "North Wagga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "North Wyong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Northam",
                "priceZone": "ZC11"
            },
            {
                "label": "Northgate",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Northland Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Northmead",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nowra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Nunawading",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Oakleigh",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ocean Grove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Officer Arena",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Officer Inbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Officer Outbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Orange",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ormeau",
                "priceZone": "ZC2"
            },
            {
                "label": "Ormond",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Oxenford",
                "priceZone": "ZC2"
            },
            {
                "label": "Pacific Fair",
                "priceZone": "ZC6"
            },
            {
                "label": "Pagewood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pakenham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Palais",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Palmerston",
                "priceZone": "ZC13"
            },
            {
                "label": "Paralowie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Park Ridge",
                "priceZone": "ZC1"
            },
            {
                "label": "Parkes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Parklea",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Parramatta L1 Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Parramatta L5 Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penlink Inbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penlink Outbound",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pennant Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penrith Leagues",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penrith Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Penrith South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pimpama",
                "priceZone": "ZC2"
            },
            {
                "label": "Pinjarra North",
                "priceZone": "ZC11"
            },
            {
                "label": "Pinjarra South",
                "priceZone": "ZC11"
            },
            {
                "label": "Plainland",
                "priceZone": "ZC2"
            },
            {
                "label": "Playford",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Plenty Valley Westfield",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Plumpton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Point Cook",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Adelaide",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Augusta",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Lincoln",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Macquarie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Macquarie II",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Port Pirie",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Portland",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Prahran",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Preston",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Prospect",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Pulteney Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Punchbowl",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Queanbeyan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Randwick",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rasmussen",
                "priceZone": "ZC2"
            },
            {
                "label": "Raymond Terrace",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Redbank",
                "priceZone": "ZC1"
            },
            {
                "label": "Redbank Plains",
                "priceZone": "ZC1"
            },
            {
                "label": "Redcliffe",
                "priceZone": "ZC1"
            },
            {
                "label": "Redlynch",
                "priceZone": "ZC3"
            },
            {
                "label": "Renmark",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Reservoir",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Revesby",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Reynella",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Richmond South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ringwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Riverdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Riverlink Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Riverstone",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Riverwood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Robina Drive Thru",
                "priceZone": "ZC2"
            },
            {
                "label": "Robina Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Rockdale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rockhampton Food Court",
                "priceZone": "ZC3"
            },
            {
                "label": "Rockhampton North",
                "priceZone": "ZC3"
            },
            {
                "label": "Rockhampton South",
                "priceZone": "ZC3"
            },
            {
                "label": "Rockingham Beach",
                "priceZone": "ZC11"
            },
            {
                "label": "Roma",
                "priceZone": "ZC5"
            },
            {
                "label": "Rosebud",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rosehill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Roselands Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rosny Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rouse Hill Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Rowville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Roxburgh Park",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Runaway Bay",
                "priceZone": "ZC2"
            },
            {
                "label": "Rutherford",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Salamander Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Salisbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sans Souci",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Seaford Rise",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sebastopol",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Seven Hills",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Seymour",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sheidow Park Hallets Cove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shellharbour",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shellharbour Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shepparton City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shepparton North",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Shepparton South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Singleton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sippy Downs",
                "priceZone": "ZC2"
            },
            {
                "label": "Smithfield",
                "priceZone": "ZC3"
            },
            {
                "label": "Sorell",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "South Lake",
                "priceZone": "ZC11"
            },
            {
                "label": "South Perth",
                "priceZone": "ZC11"
            },
            {
                "label": "Southland Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Southport",
                "priceZone": "ZC2"
            },
            {
                "label": "Spearwood",
                "priceZone": "ZC11"
            },
            {
                "label": "Spring Farm",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Springfield",
                "priceZone": "ZC1"
            },
            {
                "label": "Springfield Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Springfield Parkway",
                "priceZone": "ZC1"
            },
            {
                "label": "Springvale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Springwood",
                "priceZone": "ZC1"
            },
            {
                "label": "St Clair",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "St Kilda",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "St Leonards",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "St Peters",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Strath Village",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Strathpine",
                "priceZone": "ZC1"
            },
            {
                "label": "Strathpine Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Sturt Mall Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Subiaco",
                "priceZone": "ZC11"
            },
            {
                "label": "Sunbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sunnybank",
                "priceZone": "ZC1"
            },
            {
                "label": "Sunshine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sunshine Market Place",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Surfers Paradise",
                "priceZone": "ZC2"
            },
            {
                "label": "Swan Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sydenham",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sydney Airport",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Sydney Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tahmoor",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tamworth",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tamworth South",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tannum Sands",
                "priceZone": "ZC3"
            },
            {
                "label": "Taree",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Taree Service Centre",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tarneit",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Taylors Hill",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tea Tree Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "The Entrance",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "The Pines",
                "priceZone": "ZC2"
            },
            {
                "label": "Thomastown",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Thornbury",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Thornlie",
                "priceZone": "ZC11"
            },
            {
                "label": "Thornton",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tingalpa",
                "priceZone": "ZC1"
            },
            {
                "label": "Toormina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Toowoomba",
                "priceZone": "ZC2"
            },
            {
                "label": "Toowoomba East",
                "priceZone": "ZC2"
            },
            {
                "label": "Top Ryde Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Toronto",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Torrensville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Traralgon",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Traralgon East",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Treendale",
                "priceZone": "ZC12"
            },
            {
                "label": "Truganina",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tuggerah Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tuggeranong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tullamarine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tumut",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Tweed City Food Court",
                "priceZone": "ZC6"
            },
            {
                "label": "Tweed Heads",
                "priceZone": "ZC2"
            },
            {
                "label": "Ulladulla",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Ulverstone",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Unanderra",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Upper Coomera",
                "priceZone": "ZC2"
            },
            {
                "label": "Urangan",
                "priceZone": "ZC3"
            },
            {
                "label": "Valley Metro",
                "priceZone": "ZC6"
            },
            {
                "label": "Vermont",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Victor Harbor",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Victoria Gardens Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Victoria Point",
                "priceZone": "ZC1"
            },
            {
                "label": "Villawood",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wagga Homebase",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wallan",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wallan 1 (Southbound)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wallan 2 (Northbound)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wangaratta",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wantirna",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warnbro",
                "priceZone": "ZC11"
            },
            {
                "label": "Warner",
                "priceZone": "ZC1"
            },
            {
                "label": "Warners Bay",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warragul",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warragul Queen Street",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warrawong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warrnambool City",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warwick",
                "priceZone": "ZC2"
            },
            {
                "label": "Warwick Farm",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Warwick WA",
                "priceZone": "ZC11"
            },
            {
                "label": "Waterfront City Docklands",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Watergardens",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Welland Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wellington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wendouree",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wentworthville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Werribee",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Werribee Plaza",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Werrington",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "West End Plaza Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "West Gosford",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "West Lakes",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Westfield Geelong",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Westgate Port Melbourne",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Westpoint Food Court",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wetherill Park (Outside)",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wetherill Park Mall",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Whitfords",
                "priceZone": "ZC11"
            },
            {
                "label": "Whyalla",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Willetton",
                "priceZone": "ZC11"
            },
            {
                "label": "William Street",
                "priceZone": "ZC11"
            },
            {
                "label": "Wilsonton",
                "priceZone": "ZC2"
            },
            {
                "label": "Woden",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wodonga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wollongong Central",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wonthaggi",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Woodbine",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Woodgrove",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Woodlands",
                "priceZone": "ZC3"
            },
            {
                "label": "Woodridge",
                "priceZone": "ZC1"
            },
            {
                "label": "Woy Woy",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wurtulla",
                "priceZone": "ZC2"
            },
            {
                "label": "Wyndham Vale",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Wynnum",
                "priceZone": "ZC1"
            },
            {
                "label": "Wyoming",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yagoona",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yamanto",
                "priceZone": "ZC1"
            },
            {
                "label": "Yarraville",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yarrawonga",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yass",
                "priceZone": "Yum Zone 1"
            },
            {
                "label": "Yeppoon",
                "priceZone": "ZC3"
            },
            {
                "label": "Young",
                "priceZone": "Yum Zone 1"
            }
        ]

        function slideToStores(e) {
            document.querySelector('.select-file-upload-text').innerText = 'Zones, Location and Save';
            e.target.classList.add('active-zone-item');
            let zonesContainer = document.querySelector('.uploadFiles-content-slider-item-zones')
            let storesContainer = document.querySelector('.uploadFiles-content-slider-item-stores')
            zonesContainer.classList.add('active-stores')
            storesContainer.classList.add('disable-zones')
            let activePodsItem = document.querySelector('.active-pods-item').textContent;
            let activeZoneItem = document.querySelector('.active-zone-item').textContent;
            let uploadFilesContentSlider = document.querySelector('.breadcrumbs');
            uploadFilesContentSlider.innerHTML = activePodsItem + " / " + activeZoneItem;

            let sliderContent = document.querySelector('.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul');

            const largerThanSixty = optionsLocation.filter(number => {
                return number.priceZone === e.target.innerHTML;
            })

            for (let i = 0; i < largerThanSixty.length; i++) {
                var li = document.createElement("li");

                var link = document.createElement('a');


                link.appendChild(document.createTextNode(largerThanSixty[i].label));

                li.appendChild(link)
                li.onclick = function (e) {
                    slideToUploadFiles(e);
                };


                sliderContent.appendChild(li);
            }

        }

        function slideToUploadFiles(e) {
            if (e.target.nodeName === 'LI') {
                e.target.classList.toggle('active-item')
            } else if (e.target.nodeName === 'A') {
                e.target.parentElement.classList.toggle('active-item')
            }

            document.querySelector('.switch-all-store').classList.add('active');
            let mainContainer = document.querySelector('.uploadFiles-content-slider');
            mainContainer.classList.add('uploadFiles-content-slider-upload');
            let uploadContainer = document.querySelector('.uploadFiles-content-slider-item-stores-upload')
            uploadContainer.classList.add('active')
            let extraColumn = document.querySelector('.upload-content-container');
            extraColumn.classList.add('active');
            document.querySelector('.button-group').style.paddingRight = '62px';
            let activePodsItem = document.querySelector('.active-pods-item').textContent;
            let activeZoneItem = document.querySelector('.active-zone-item').textContent;


            let uploadFilesContentSlider = document.querySelector('.breadcrumbs');
            if (document.querySelectorAll('.active-item').length <= 0) {
                uploadFilesContentSlider.innerHTML = activePodsItem + " / " + activeZoneItem;
                document.querySelector('.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul').classList.remove('active-item-list');

                let allBtnUpload = document.querySelectorAll('.upload-files-btn');

                allBtnUpload.forEach(element => {
                    element.style.pointerEvents = 'none'
                })
                document.querySelector('.hiddenNotifyBtnPasswordFourth').click();

            } else if (document.querySelectorAll('.active-item').length <= 1) {
                uploadFilesContentSlider.innerHTML = activePodsItem + " / " + activeZoneItem + " / " + document.querySelectorAll('.active-item')[0].textContent;
                document.querySelector('.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul').classList.add('active-item-list');

                let allBtnUpload = document.querySelectorAll('.upload-files-btn');

                allBtnUpload.forEach(element => {
                    element.style.pointerEvents = 'auto'
                })


            } else {
                uploadFilesContentSlider.innerHTML = activePodsItem + " / " + activeZoneItem + " / " + 'Stores (' + document.querySelectorAll('.active-item').length + ')';
                document.querySelector('.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul').classList.add('active-item-list');
                let allBtnUpload = document.querySelectorAll('.upload-files-btn');

                allBtnUpload.forEach(element => {
                    element.style.pointerEvents = 'auto'
                })

            }

            let nestedListSecond = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content')
            if (document.querySelectorAll('.active-item').length === 0) {
                nestedListSecond.classList.remove('multiSelectedActive');
            } else if (document.querySelectorAll('.active-item').length === 1) {
                nestedListSecond.classList.remove('multiSelectedActive');
            } else {
                nestedListSecond.classList.add('multiSelectedActive');
            }

        }

        function openNestedList() {
            let nesterListItem = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:first-child');
            let nesterList = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li ul');
            nesterList.classList.toggle('active');
            let openItem = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:first-child > span');
            let transformIcon = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content > ul > li > span');

            if (nesterList.classList.contains("active")) {
                nesterList.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.paddingBottom = '10px';
                transformIcon.classList.add('rotate-icon');
                nesterListItem.classList.add('active-li');
            } else {
                openItem.style.borderBottom = '1px solid #EEEEEE';
                transformIcon.classList.remove('rotate-icon');
                nesterListItem.classList.remove('active-li');
            }
        }

        function openNestedListDriveThrough() {
            let nesterList = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(2) ul');
            nesterList.classList.toggle('active');
            let openItem = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(2) > span');
            let transformIcon = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content > ul > li:nth-child(2) > span');

            if (nesterList.classList.contains("active")) {
                openItem.style.border = '0';
                nesterList.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.paddingBottom = '10px';
                transformIcon.classList.add('rotate-icon');

            } else {
                openItem.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.borderBottom = '0';
                transformIcon.classList.remove('rotate-icon');
            }
        }

        function openNestedListPreReaders() {
            let nesterList = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(3) ul');
            nesterList.classList.toggle('active');
            let openItem = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(3) > span');
            let transformIcon = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content > ul > li:nth-child(3) > span');

            if (nesterList.classList.contains("active")) {
                openItem.style.border = '0';
                nesterList.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.paddingBottom = '10px';
                transformIcon.classList.add('rotate-icon');

            } else {
                openItem.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.borderBottom = '0';
                transformIcon.classList.remove('rotate-icon');
            }
        }

        function openNestedListKrusherblades() {
            let nesterList = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(4) ul');
            nesterList.classList.toggle('active');
            let openItem = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(4) > span');
            let transformIcon = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content > ul > li:nth-child(4) > span');

            if (nesterList.classList.contains("active")) {
                openItem.style.border = '0';
                nesterList.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.paddingBottom = '10px';
                transformIcon.classList.add('rotate-icon');

            } else {
                openItem.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.borderBottom = '0';
                transformIcon.classList.remove('rotate-icon');
            }
        }

        function openNestedListAloha() {
            let nesterList = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(5) ul');
            nesterList.classList.toggle('active');
            let openItem = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content ul li:nth-child(5) > span');
            let transformIcon = document.querySelector('.uploadFiles-content-slider-item-stores-upload.active .uploadFiles-content-slider-item-stores-upload-content > ul > li:nth-child(5) > span');

            if (nesterList.classList.contains("active")) {
                openItem.style.border = '0';
                nesterList.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.paddingBottom = '10px';
                transformIcon.classList.add('rotate-icon');

            } else {
                openItem.style.borderBottom = '1px solid #EEEEEE';
                nesterList.style.borderBottom = '0';
                transformIcon.classList.remove('rotate-icon');
            }
        }

        function myFunction() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("myInput");
            filter = input.value.toUpperCase();
            ul = document.querySelector(".uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                } else {
                    li[i].style.display = "none";
                }
            }
        }

        function DeleteAllFilesInFolder(e) {
            let activePodsItem = document.querySelector('.active-pods-item').textContent;
            let activeZoneItem = document.querySelector('.active-zone-item').textContent;
            let activeStoresItem = document.querySelectorAll('.uploadFiles-content-slider-item-stores ul li.active-item');
            let interestingSecondTimePeriod = document.querySelector('.interestingSecondTimePeriod').value;
            let activeStoresItemArray = [];
            for (let i = 0; i < activeStoresItem.length; i++) {
                activeStoresItemArray += activeStoresItem[i].textContent + ", ";
            }

            console.log('Time period: ' + interestingSecondTimePeriod);
            console.log('Pods: ' + activePodsItem);
            console.log('Zones: ' + activeZoneItem);
            console.log('Stores: ' + activeStoresItemArray);
            console.log('mediaType: ' + e.target.closest('ul').parentElement.querySelector('span').innerText);
            console.log('Current screen: ' + e.target.closest("li").querySelector('span').innerText)
        }

        let interestingSecondTimePeriod = this.props.interestingSecond;

        function switchAllStoreItems(e) {
            if (e.target.checked === true) {
                let allCurrentLiElements = document.querySelectorAll('.uploadFiles-content-slider-item-stores .active-item-list li')
                allCurrentLiElements.forEach(element => element.classList.add('active-item'))

                let uploadFilesContentSlider = document.querySelector('.breadcrumbs');
                let activePodsItem = document.querySelector('.active-pods-item').textContent;
                let activeZoneItem = document.querySelector('.active-zone-item').textContent;

                uploadFilesContentSlider.innerHTML = activePodsItem + " / " + activeZoneItem + " / " + 'All stores';
            } else {
                let allCurrentLiElements = document.querySelectorAll('.uploadFiles-content-slider-item-stores .active-item-list li')
                allCurrentLiElements.forEach(element => element.classList.remove('active-item'))
                let uploadFilesContentSlider = document.querySelector('.breadcrumbs');
                let activePodsItem = document.querySelector('.active-pods-item').textContent;
                let activeZoneItem = document.querySelector('.active-zone-item').textContent;

                uploadFilesContentSlider.innerHTML = activePodsItem + " / " + activeZoneItem + " / " + 'Select stores';
            }
        }


        return (
            <Fragment>
                <div className="second-step">
                    <h1>02 / 02</h1>
                    <h4>Selected Zones & Location?</h4>
                    <p className={'select-file-upload-text'}>Select a PODS to display the current location.</p>
                </div>
                <div className={'upload-content-container'}>
                    <div>
                        <input className={'interestingSecondTimePeriod'} value={interestingSecondTimePeriod} />
                        <div className={'uploadFiles-content-slider'}>
                            <div className={'uploadFiles-content-slider-item-pods'}>
                                <div className={'uploadFiles-content-slider-item-header'}>
                                    <span>PODS</span>
                                    <input
                                        type="text"
                                        value={this.state.searchString}
                                        ref="search"
                                        onChange={this.handleChangeSearch.bind(this)}
                                        placeholder="Search"
                                    />
                                </div>
                                <div className={'uploadFiles-content-slider-item-content'}>
                                    <ul>
                                        {_users.map(l => {
                                            return (
                                                <li onClick={slideToZones}>
                                                    {l}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className={'uploadFiles-content-slider-item-zones'}>
                                <div className={'uploadFiles-content-slider-item-header'}>
                                    <span>Zones</span>
                                    <input
                                        type="text"
                                        value={this.state.searchStringZones}
                                        ref="searchZones"
                                        onChange={this.handleChangeSearchZones.bind(this)}
                                        placeholder="Search"
                                    />
                                </div>
                                <div className={'uploadFiles-content-slider-item-content'}>
                                    <ul>
                                        {_zones.map(l => {
                                            return (
                                                <li onClick={slideToStores}>
                                                    {l}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className={'uploadFiles-content-slider-item-stores'}>
                                <div className={'uploadFiles-content-slider-item-header'}>
                                    <span>Stores</span>
                                    <input type="text" id="myInput" onKeyUp={myFunction}
                                        placeholder="Search for names.." title="Type in a name" />
                                </div>
                                <div className={'uploadFiles-content-slider-item-content'}>
                                    <ul>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={'uploadFiles-content-slider-item-stores-upload'}>
                            <div className={'uploadFiles-content-slider-item-stores-upload-header'}>
                                <span>Media Type</span>
                                <button style={{ cursor: 'pointer' }} onClick={this.togglePopup.bind(this)}>Add section
                                </button>
                                {this.state.showPopup ?
                                    <PopupWindows
                                        text='Close Me'
                                        closePopup={this.togglePopup.bind(this)}
                                    />
                                    : null
                                }
                            </div>
                            <div className={'uploadFiles-content-slider-item-stores-upload-content'}>
                                <ul>
                                    <li>
                                        <span onClick={openNestedList}>Menu Boards</span>
                                        <ul>
                                            <li>
                                                <span>Screen 01</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    {(() => {
                                                        if (document.querySelector('.uploadFiles-content-slider-item-content ul')) {
                                                            return (
                                                                <>
                                                                    <a
                                                                        onClick={this.togglePopupCreateMB01.bind(this)}
                                                                        className={'upload-files-btn'}>
                                                                        <svg width="20" height="20" viewBox="0 0 20 20"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                                fill="#808080" />
                                                                        </svg>
                                                                        Upload Files</a>
                                                                    {this.state.showPopupCreateMB01 ?
                                                                        <PopupWindowsCreateFile
                                                                            text='Close Me'
                                                                            type={'Menu-Boards'}
                                                                            screens={'Screen-01'}
                                                                            closePopup={this.togglePopupCreateMB01.bind(this)}
                                                                        />
                                                                        : null
                                                                    }
                                                                </>
                                                            )
                                                        } else {
                                                            return (
                                                                <div>catch all</div>
                                                            )
                                                        }
                                                    })()}

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 02</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateMB02.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateMB02 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            type={'Menu-Boards'}
                                                            screens={'Screen-02'}
                                                            closePopup={this.togglePopupCreateMB02.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 03</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateMB03.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateMB03 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-03'}
                                                            type={'Menu-Boards'}
                                                            closePopup={this.togglePopupCreateMB03.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 04</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateMB04.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateMB04 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-04'}
                                                            type={'Menu-Boards'}
                                                            closePopup={this.togglePopupCreateMB04.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 05</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateMB05.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateMB05 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-05'}
                                                            type={'Menu-Boards'}
                                                            closePopup={this.togglePopupCreateMB05.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><span onClick={openNestedListDriveThrough}>Drive Through</span>
                                        <ul>
                                            <li>
                                                <span>Screen 01</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateDT01.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateDT01 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-01'}
                                                            type={'Drive-Through'}
                                                            closePopup={this.togglePopupCreateDT01.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 02</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateDT02.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateDT02 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-02'}
                                                            type={'Drive-Through'}
                                                            closePopup={this.togglePopupCreateDT02.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 03</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateDT03.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateDT03 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-03'}
                                                            type={'Drive-Through'}
                                                            closePopup={this.togglePopupCreateDT03.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 04</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateDT04.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateDT04 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-04'}
                                                            type={'Drive-Through'}
                                                            closePopup={this.togglePopupCreateDT04.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                            <li>
                                                <span>Screen 05</span>
                                                <div className={'view-navigation-bar'}>
                                                    <div>
                                                        <span />
                                                        <span />
                                                    </div>
                                                    <span>2 MEDIA FILES</span>
                                                </div>
                                                <div className={'upload-navigation-bar'}>

                                                    <a data-tip="Edit">
                                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a data-tip="Download">
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M11.88 7.79L10 9.67V1C10 0.45 9.55 0 9 0C8.45 0 8 0.45 8 1V9.67L6.11 7.79C5.72 7.4 5.09 7.4 4.7 7.79C4.51275 7.97683 4.40751 8.23048 4.40751 8.495C4.40751 8.75952 4.51275 9.01317 4.7 9.2L8.29 12.79C8.68 13.18 9.31 13.18 9.7 12.79L13.29 9.2C13.68 8.81 13.68 8.18 13.29 7.79C12.9 7.4 12.27 7.4 11.88 7.79ZM16 15V10C16 9.45 16.45 9 17 9C17.55 9 18 9.45 18 10V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V10C0 9.45 0.45 9 1 9C1.55 9 2 9.45 2 10V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a onClick={DeleteAllFilesInFolder} data-tip="Delete">
                                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                fill="#808080" />
                                                        </svg>
                                                    </a>
                                                    <a
                                                        onClick={this.togglePopupCreateDT05.bind(this)}
                                                        className={'upload-files-btn'}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                                fill="#808080" />
                                                        </svg>
                                                        Upload Files</a>
                                                    {this.state.showPopupCreateDT05 ?
                                                        <PopupWindowsCreateFile
                                                            text='Close Me'
                                                            screens={'Screen-05'}
                                                            type={'Drive-Through'}
                                                            closePopup={this.togglePopupCreateDT05.bind(this)}
                                                        />
                                                        : null
                                                    }

                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><span onClick={openNestedListPreReaders}>Pre Readers</span>
                                        <ul>
                                            <li className={'upload-file-single-screen'}><a
                                                onClick={this.togglePopupCreatePR01.bind(this)}
                                                className={'upload-files-btn'}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                        fill="#808080" />
                                                </svg>
                                                Upload Files</a>
                                                {this.state.showPopupCreatePR01 ?
                                                    <PopupWindowsCreateFile
                                                        text='Close Me'
                                                        screens={'Screen-01'}
                                                        type={'Pre-Readers'}
                                                        closePopup={this.togglePopupCreatePR01.bind(this)}
                                                    />
                                                    : null
                                                }</li>
                                        </ul>
                                    </li>
                                    <li><span onClick={openNestedListKrusherblades}>Krusher Blades</span>
                                        <ul>
                                            <li className={'upload-file-single-screen'}>
                                                <a
                                                    onClick={this.togglePopupCreateKB01.bind(this)}
                                                    className={'upload-files-btn'}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                            fill="#808080" />
                                                    </svg>
                                                    Upload Files</a>
                                                {this.state.showPopupCreateKB01 ?
                                                    <PopupWindowsCreateFile
                                                        text='Close Me'
                                                        screens={'Screen-01'}
                                                        type={'Krusher-Blades'}
                                                        closePopup={this.togglePopupCreateKB01.bind(this)}
                                                    />
                                                    : null
                                                }
                                            </li>
                                        </ul>
                                    </li>
                                    <li><span onClick={openNestedListAloha}>Aloha</span>
                                        <ul>
                                            <li className={'upload-file-single-screen'}>
                                                <a
                                                    onClick={this.togglePopupCreateAl01.bind(this)}
                                                    className={'upload-files-btn'}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2C5.59 2 2 5.59 2 10Z"
                                                            fill="#808080" />
                                                    </svg>
                                                    Upload Files</a>
                                                {this.state.showPopupCreateAl01 ?
                                                    <PopupWindowsCreateFile
                                                        text='Close Me'
                                                        screens={'Screen-01'}
                                                        type={'Aloha'}
                                                        closePopup={this.togglePopupCreateAl01.bind(this)}
                                                    />
                                                    : null
                                                }
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="hiddenNotifyBtnPassword"
                    onClick={passwordChecknotify}>Notify!
                </button>
                <button className="hiddenNotifyBtnPasswordFourth"
                    onClick={passwordChecknotifyFourth}>Notify!
                </button>
                <button className="hiddenNotifyBtnPasswordSecond"
                    onClick={passwordChecknotifySecond}>Notify!
                </button>
                <button className="hiddenNotifyBtnPasswordThird"
                    onClick={passwordChecknotifyThird}>Notify!
                </button>

                <div className={'switch-all-store'}>
                    {/* <FormGroup className="default-switch">
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>
                                <Switch
                                    onChange={switchAllStoreItems}
                                    value="checked"
                                />
                            </Grid>
                            <Grid item>Select all stores</Grid>
                        </Grid>
                    </FormGroup> */}
                </div>
                <div className="button-group">
                    <a
                        label="Back"
                        onClick={this.back}
                        className="cancel-btn"
                    >
                        Back
                    </a>
                    <a
                        label="Next"
                        onClick={this.continue}
                        className="next-btn"
                    >
                        Next
                    </a>
                </div>
            </Fragment>
        );
    }

    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleText = (ev) => {
        this.setState({
            haveText: ev.currentTarget.textContent
        })
    }
}

export default PodsUploadSecondStep;
