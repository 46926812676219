import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import DataTable from 'react-data-table-component';
import { useQuery, gql } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { PageDescription } from '../../utils/PageDescription';
import { TabTitle } from '../../utils/TabTitle';
import './Pods.scss';
import UploadPods from './UploadPods';
import TestMarket from './TestMarket';
import SettingsPods from './SettingsPods';

function Pods({ title }) {
  const [podsDataCurrent, setPodsDataCurrent] = useState([]);
  const [podsDataAll, setPodsDataAll] = useState([]);
  const [activeTimeZone, setActiveTimeZone] = useState({ value: '2022-2023', label: 'YEAR 2022-2023' });
  PageDescription('PODS', 'Addtional information');
  TabTitle(title)

  const StartEndDate = ({ row }) => {
    return (
      <div className='pods__title--black-purple'>
        <span className='hover-white'>{row.startDate}</span>
        <span className='hover-white'>{row.endDate}</span>
      </div>
    )
  };

  const BriefDate = ({ row }) => {
    return (
      <div className='pods__title--black-grey'>
        <span className='hover-white'>Plan: {row.briefDatePlan}</span>
        <span className='hover-white'>Acutal: {row.briefDateActual}</span>
      </div>
    )
  };

  const DesignSignOff = ({ row }) => {
    return (
      <div className='pods__title--black-grey'>
        <span className='hover-white'>Plan: {row.designSignOffPlan}</span>
        <span className='hover-white'>Acutal: {row.designSignOffActual}</span>
      </div>
    )
  };

  const FitLegalSignOff = ({ row }) => {
    return (
      <div className='pods__title--black-grey'>
        <span className='hover-white'>Plan: {row.fitAndLegalSignOffPlan}</span>
        <span className='hover-white'>Acutal: {row.fitAndLegalSignOffActual}</span>
      </div>
    )
  };

  const Pricing = ({ row }) => {
    return (
      <div className='pods__title--black-grey'>
        <span className='hover-white'>Plan: {row.pricingPlan}</span>
        <span className='hover-white'>Acutal: {row.pricingActual}</span>
      </div>
    )
  };

  const openPopup = (e) => {
    e.target.parentElement.querySelector('.info-popup').classList.toggle('active')
  }

  const Status = ({ row }) => {
    return (
      <div className='pods__status'>
        <button>{row.status}</button>
        <button onClick={openPopup}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z" fill="black" />
            <path d="M10 4C10 5.10457 10.8954 6 12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4Z" fill="black" />
            <path d="M10 20C10 21.1046 10.8954 22 12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20Z" fill="black" />
          </svg>
        </button>
        <div className='info-popup'>
          <button>LIVE</button>
          <button>Uploading</button>
          <button>Legal Approval</button>
          <button>Design Development</button>
          <button>Planning</button>
          <button>None-Active</button>
          <hr />
          <button>Delete</button>
        </div>
      </div>
    )
  };

  const columns = [
    {
      name: 'ID',
      selector: row => row.id,
      omit: true
    },
    {
      name: 'Pods',
      selector: row => <Link to={`/pods/${row.title.toLowerCase()}/${activeTimeZone.value}/zones`} className='pods__title hover-white'>{row.title}</Link>,
      sortable: true,
      maxWidth: '50px',
    },
    {
      name: 'Start/End Date',
      selector: row => <StartEndDate row={row} />,
      sortable: true
    },
    {
      name: 'Brief Date',
      selector: row => <BriefDate row={row} />,
      sortable: true
    },
    {
      name: 'Design Sign Off',
      selector: row => <DesignSignOff row={row} />,
      sortable: true
    },
    {
      name: 'Fit & Legal Sign Off',
      selector: row => <FitLegalSignOff row={row} />,
      sortable: true
    },
    {
      name: 'Pricing',
      selector: row => <Pricing row={row} />,
      sortable: true
    },
    {
      name: 'Status',
      selector: row => <Status row={row} />,
      sortable: true,
    }
  ];

  const customStyles = {
    responsiveWrapper: {
      style: {
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      }
    },
    head: {
      style: {
        minHeight: '90px',
        borderRadius: '20px'
      }
    },
    rows: {
      style: {
        paddingLeft: '25px',
        minHeight: '90px',
      },
    },
    headCells: {
      style: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#000000',
      },
    },
    cells: {
      style: {
        fontWeight: 600,
        fontSize: '16px',
        color: '#000000',
      },
    },
  };

  let customSortIcon = (
    <div className="custom-sort-icons">
      <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.49951 18.75L8.99951 23.25L13.4995 18.75" fill="#171B1E" />
        <path d="M13.4995 11.25L8.99951 6.75L4.49951 11.25" fill="#171B1E" />
      </svg>
    </div>
  );

  const FETCH_PODS_QUERY = gql`
    query getPods {
      getPods {
          id
          title
          startDate
          endDate
          briefDatePlan
          briefDateActual
          designSignOffPlan
          designSignOffActual
          fitAndLegalSignOffPlan
          fitAndLegalSignOffActual
          pricingPlan
          pricingActual
          status
          timePeriod
        }
      }
  `;

  const { loading, error, data } = useQuery(FETCH_PODS_QUERY,
    {
      onCompleted: (data) => {
        let dataArr = [...data.getPods]
        dataArr.sort((a, b) => a.title > b.title ? 1 : -1);
        let dataArrCurrent = dataArr.filter(function (el) { return el.timePeriod === "2022-2023" });
        setPodsDataCurrent(dataArrCurrent)
        setPodsDataAll(dataArr)
      }
    });

  if (loading) return <div className='preloader'><div id="loader" /></div>;
  if (error) return <p>Error :</p>;

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '233px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '700',
      fontSize: '16px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 20px',
      cursor: 'pointer',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 20px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '233px',
      minHeight: '60px',
      height: '60px',
      borderRadius: '10px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '10px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '10px',
      fontWeight: '700',
      fontSize: '16px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    }),
    singleValue: base => ({
      ...base,
      margin: 0
    })
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const options = [
    { value: '2022-2023', label: 'YEAR 2022-2023' },
    { value: '2021-2022', label: 'YEAR 2021-2022' }
  ]

  const changeTimePeriod = (e) => {
    let changeTimePeriod = podsDataAll.filter(function (el) { return el.timePeriod === e.value });
    setPodsDataCurrent(changeTimePeriod)
    setActiveTimeZone({ value: e.value, label: e.label })
    toast.success("Time period changed", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const uplodFilesToPods = (e) => {}

  return (
    <div className='pods'>
      <div style={{ marginBottom: '22px' }} className='pods__bar'>
        <Select
          options={options}
          styles={style}
          isSearchable={false}
          defaultValue={options[0]}
          onChange={changeTimePeriod}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator
          }}
        />
        <div className='pods__bar__settings'>
          {/* <button onClick={uplodFilesToPods} className='btn__upload-files'>
            <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.5 19H7C3.685 19 1 16.315 1 13C1 9.685 3.685 7 7 7H8.065C9.055 3.535 12.22 1 16 1C20.56 1 24.25 4.69 24.25 9.25V10H26.5C28.99 10 31 12.01 31 14.5C31 16.99 28.99 19 26.5 19Z" fill="white" stroke="#707579" strokeWidth="1.5"></path>
            </svg>
            Upload Files
          </button> */}
          <UploadPods />
          <TestMarket />
          {/* <button className='btn__test-market'>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.1109 6.99966C17.1109 5.74109 16.3541 4.60648 15.1903 4.12524C14.028 3.64397 12.6907 3.90941 11.8011 4.80046C10.91 5.69006 10.6446 7.02736 11.1258 8.18968C11.6071 9.35345 12.7417 10.1103 14.0003 10.1103C14.8257 10.1103 15.6161 9.78365 16.1995 9.19885C16.7843 8.61551 17.1109 7.82507 17.1109 6.99966ZM12.4442 6.99966C12.4442 6.3711 12.8233 5.80381 13.4052 5.56316C13.9857 5.32107 14.6551 5.45524 15.0999 5.90004C15.5447 6.34483 15.6788 7.01423 15.4367 7.59466C15.1961 8.17655 14.6288 8.55571 14.0002 8.55571C13.1413 8.55571 12.4442 7.85861 12.4442 6.99964L12.4442 6.99966ZM17.6082 17.2825C19.2664 13.4327 21.0003 8.94364 21.0003 6.99966C21.0003 4.49858 19.666 2.18843 17.5003 0.937371C15.3346 -0.312457 12.6659 -0.312457 10.5002 0.937371C8.3345 2.18866 7.00011 4.49869 7.00011 6.99966C7.00011 8.94364 8.73409 13.4323 10.3908 17.2825C5.05614 17.8105 0 19.5838 0 22.556C0 26.0954 7.20993 28 14.0002 28C20.7905 28 28.0005 26.0939 28.0005 22.556C28.0005 19.5838 22.9443 17.8104 17.6097 17.2825H17.6082ZM14.0002 1.55562C15.444 1.55562 16.8294 2.12877 17.8501 3.14959C18.8708 4.17042 19.4441 5.55588 19.4441 6.99947C19.4441 9.33284 15.9834 17.2588 14 21.513C12.0167 17.2588 8.55601 9.33284 8.55601 6.99947C8.55601 5.55569 9.12916 4.17027 10.15 3.14959C11.1708 2.12892 12.5563 1.55562 13.9999 1.55562H14.0002ZM14.0002 26.4454C6.29263 26.4454 1.55608 24.182 1.55608 22.556C1.55608 21.0932 5.1728 19.282 11.0456 18.792C12.1262 21.2259 13.0596 23.1712 13.3017 23.6685L13.3003 23.6671C13.43 23.9354 13.7027 24.106 14.0003 24.106C14.2978 24.106 14.5705 23.9354 14.7003 23.6671C14.9409 23.1698 15.8742 21.2243 16.9564 18.7905C22.829 19.2805 26.4459 21.1239 26.4459 22.5545C26.4444 24.1806 21.7079 26.444 14.0003 26.444L14.0002 26.4454Z" fill="#707579"></path>
            </svg>
            Test Market
          </button> */}
          <SettingsPods />
          {/* <button className='btn__settings'>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14 5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5V1C12 0.45 12.45 0 13 0C13.55 0 14 0.45 14 1V2H17C17.55 2 18 2.45 18 3C18 3.55 17.55 4 17 4H14V5ZM1 4C0.45 4 0 3.55 0 3C0 2.45 0.45 2 1 2H10V4H1ZM1 16C0.45 16 0 15.55 0 15C0 14.45 0.45 14 1 14H6V16H1ZM10 17V16H17C17.55 16 18 15.55 18 15C18 14.45 17.55 14 17 14H10V13C10 12.45 9.55 12 9 12C8.45 12 8 12.45 8 13V17C8 17.55 8.45 18 9 18C9.55 18 10 17.55 10 17ZM4 8V7C4 6.45 4.45 6 5 6C5.55 6 6 6.45 6 7V11C6 11.55 5.55 12 5 12C4.45 12 4 11.55 4 11V10H1C0.45 10 0 9.55 0 9C0 8.45 0.45 8 1 8H4ZM18 9C18 8.45 17.55 8 17 8H8V10H17C17.55 10 18 9.55 18 9Z" fill="#808080"></path>
            </svg>
          </button> */}
        </div>
      </div>
      <DataTable
        columns={columns}
        data={podsDataCurrent}
        customStyles={customStyles}
        sortIcon={customSortIcon}
      />
    </div>
  )
}

export default Pods