import { useRef, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Select, { components } from "react-select";
import { v4 as uuid } from 'uuid';
import Popup from 'reactjs-popup';
import SendEmailModal from '../../../components/Modal/SendEmailModal';
import { AuthContext } from '../../../context/auth'
import {successNotify, errorNotify, emailProgress } from './notifications';
import EditableSidebar, { DropdownStyle, DropdownIndicator } from '../../../components/LSM/LSMCard/EditableSidebar';

import CouponList from '../../../components/ContentEditors/Coupon/CouponList';

import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import Description from '../components/Description/Description';
import LSMList from '../../../components/LSM/LSMList/LSMList';

import './Coupon.scss';

function Coupon() {
    const { user } = useContext(AuthContext);
    const [buttonPopupEmail, setButtonPopupEmail] = useState(false);

    let locationState = useLocation().state;

    TabTitle('Create Your Coupon');
    PageDescription('Create Your Coupon', '');
    return (
        <div className="lsm">
            <Description locationState={locationState} />
            <LSMList
                enablePopup={false}
                enableTags={false}
                enableEmailFile={true}
                enableEmailPrintQuote={false}
                enableHires={true}
                enableLowRes={false}
            >
                <CouponList />
            </LSMList>
        </div>
    )
}

export default Coupon