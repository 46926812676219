import React, {Component, Fragment} from "react";
import Select, {components} from 'react-select';

export class CompaingFormFifth extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    constructor() {
        super();
        this.state = {
            disabled: false
        };
    }

    handleChange = selectedOption => {
        this.setState({selectedOption});
    };


    render() {
        const {isOpen, haveText} = this.state;
        const {values, handleChange} = this.props;
        const options = [
            {value: 'select_store', label: 'The saved store Name'},
        ];

        const testMarkets = [
            {
                storeName: 'Abercrombie Caves, NSW',
                storeIndex: '0012314'
            },
            {
                storeName: 'Abercrombie Caves, NSW 2',
                storeIndex: '0012314'
            },
            {
                storeName: 'Abercrombie Caves, NSW 3',
                storeIndex: '0012314'
            }
        ];

        const mediaTypeItems = [
            {
                mediaTypeItem: 'Screen 1 Internal'
            },
            {
                mediaTypeItem: 'Screen 2 Internal'
            },
            {
                mediaTypeItem: 'Screen 3 Internal'
            },
            {
                mediaTypeItem: 'Screen 4 Internal'
            },
            {
                mediaTypeItem: 'Screen 5 Internal'
            },
            {
                mediaTypeItem: 'LE end panel'
            },
            {
                mediaTypeItem: 'HE end panel'
            },
            {
                mediaTypeItem: 'Krusher Blade 1'
            },
            {
                mediaTypeItem: 'Krusher Blade 2'
            },
            {
                mediaTypeItem: 'Aloha'
            },
            {
                mediaTypeItem: 'Pre Reader'
            },
            {
                mediaTypeItem: 'DTM 1'
            },
            {
                mediaTypeItem: 'DTM 2 (LE/HE)'
            },
            {
                mediaTypeItem: 'DTM 3'
            }
        ];

        function moveElementFirstLevel(e) {
            var newItem = document.createElement("LI");
            var newItemSecond = document.createElement("a");
            newItem.classList.add('MoveElementsToLocationRemove');
            newItem.onclick = function() {
                this.remove();
            };
            newItem.appendChild(newItemSecond);

            var textnode = document.createTextNode(e.target.text);
            newItemSecond.appendChild(textnode);

            var list = document.getElementById("MoveElementsToTest");
            list.insertBefore(newItem, list.childNodes[0]);
        }

        function testMarketsListsItem(e) {
            e.target.parentElement.remove()
        }

        return (
            <Fragment>

                <div className="fifth-step sixth-step__compaing">
                    <h1>05 / 05</h1>
                    <h4>Selected Media Types?</h4>
                    <p>Market, Media Type and Save</p>
                    <div className="popup__success popup__success__five-steps">
                        <div className="popup__widgets">
                            <div className="widgets-col">
                                <div className="widget__single">
                                    <div className="widget__pod__info">
                                        <div className="widget__pod__info__successes">
                                            <div className="widget__pod__info__successes--search">
                                                <span>New Test Market </span>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className={'testMarketsLists'}>
                                        {testMarkets.map(function (item, i) {
                                            return (
                                                <li onClick={testMarketsListsItem}>
                                                    <a>{item.storeName}</a>
                                                    <span>{item.storeIndex}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="widgets-col">
                                <div className="widget__single">
                                    <div className="widget__pod__info">
                                        <div className="widget__pod__info__successes">
                                            <div className="widget__pod__info__successes--search">
                                                <span>Media Type </span>
                                                <input placeholder="Search" />
                                            </div>
                                        </div>
                                    </div>
                                    <ul className={'mediaTypeItemList'}>
                                        {mediaTypeItems.map(function (item, i) {
                                            return (
                                                <li onClick={moveElementFirstLevel}>
                                                    <a>{item.mediaTypeItem}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="widgets-col">
                                <div className="widget__single">
                                    <div className="widget__pod__info">
                                        <div className="widget__pod__info__successes widget__pod__info__successes---test">
                                            <div className="widget__pod__info__successes--search">
                                                <h5>New Media Test</h5>
                                            </div>
                                            <a className="widget__pod__info__successes--btn widget__pod__info__successes--btn--blue"
                                               href="#">Save </a>
                                        </div>

                                    </div>
                                  <ul className={'mediaTypeItemListRemove'} id="MoveElementsToTest" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group">
                    <a
                        label="Back"
                        onClick={this.back}
                        className="cancel-btn"
                    >
                        Back
                    </a>
                    <a
                        label="Next"
                        onClick={this.continue}
                        className="next-btn"
                    >
                        Next
                    </a>
                </div>
            </Fragment>
        );
    }

    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleText = (ev) => {
        this.setState({
            haveText: ev.currentTarget.textContent
        })
    }
}

export default CompaingFormFifth;
