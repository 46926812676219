import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import File from '../../../utils/File';

export default function Placeholder({asset}) {
    // console.log('PLACEHOLDER >> ',asset);
    const [src, setSrc] = useState(false);
    const [placeholderIcon, setPlaceholderIcon] = useState();

    useEffect(() => {
        if (!src) {
            return;
        }
        let thisSrc = src;
        if (src.substring(0,1) !== '/')
        {
            thisSrc = `/${src}`;
            setSrc(thisSrc);
        }
    },[src]);

    useEffect(() => {
        setSrc(asset.data?.placeholder2 || asset.data?.placeholder || false);
    },[asset]);

    const onError = () => {
        let placeholder;
        console.log(asset.fileName);
        if (File.isPdf(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-pdf" />;
        }
        else if (File.isAI(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-ai" />;
        }
        else if (File.isZip(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-zip" />;
        }
        else if (File.isEps(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-eps" />;
        }
        else if (File.isJpeg(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-jpg" />;
        }
        else if (File.isPng(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-png" />;
        }
        else if (File.isPpt(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-ppt" />;
        }
        else if (File.isDoc(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-doc" />;
        }
        else if (File.isVideo(asset.fileName)) {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-mov" />;
        }
        else {
            placeholder = <PlaceholderIcon className="fiv-viv fiv-icon-blank" />;
        }
        
        setPlaceholderIcon(placeholder);
        setSrc(false);
    }

    if (src)
    {
        return <img alt="" src={`/static${src}`} onError={onError} />;
    }
    else if (placeholderIcon)
    {
        return placeholderIcon;
    }
    else if (File.isJpeg(asset.fileName)
    || File.isPng(asset.fileName))
    {
        return <img alt={asset.name} src={`/static/${asset.file}`} onError={onError} />;
    }
    else {
        onError();
    }
}


export const PlaceholderIcon = styled.span`
    width: 128px;
    height: 128px;
    display: inline-block;
`;