import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import UserInfoPopup from './UserInfoPopup/UserInfoPopup';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/ArrowRightIcon.svg';
import { AuthContext } from '../../../context/auth';
import userAvatarPlaceholder from './icons/userAvatarPlaceholder.svg';
import { USER_TYPE } from '../../../defs/User';

function UserInfo() {
  const { user, logout } = useContext(AuthContext);
  const [buttonPopup, setButtonPopup] = useState(false);

  let openPopup = () => {
    if (buttonPopup === false) {
      setButtonPopup(true)
    } else {
      setButtonPopup(false)
    }
  }

  const logoutGlobalSession = (e) => {
    fetch(process.env.REACT_APP_API_SERVER + 'user/logout', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        logout();
        window.location.href = data.link;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <>
      <div className={'header__user-info'}>
        {/* <div className='header__user-info__container'>
          <h5 className={'header__user-info__full-name'}>
            {user.firstName} {user.lastName}
          </h5>
          <span className={'header__user-info__user-role'}>
            {user.userRole === USER_TYPE.ADMIN ? 'Super Admin' : user.userRole === USER_TYPE.MARKETER ? 'Marketing' : 'Business Owner'}
          </span>
        </div> */}
        <div className="btn-user-info-popup" onClick={openPopup}>
          <img src={user.avatar ? user.avatar : userAvatarPlaceholder} alt={user.username} />
        </div>
        <UserInfoPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <div className='user-info-popup__header'>
            <img src={user.avatar ? user.avatar : userAvatarPlaceholder} alt={user.username} />
            <div className='user-info-popup__header__container'>
              <h5>{user.firstName} {user.lastName}</h5>
              <span>{user.userRole === USER_TYPE.ADMIN ? 'Super Admin' : user.userRole === USER_TYPE.MARKETER ? 'Marketing' : 'Business Owner'}</span>
            </div>
          </div>
          <div className='user-info-popup__body'>
            <ul>
              <li><Link className='profile-link' to={'/profile'}>Profile -&nbsp;<span>({user.firstName} {user.lastName})</span> <ArrowRightIcon /></Link></li>
              <li><a className='logout' onClick={logoutGlobalSession}>Logout</a></li>
            </ul>
          </div>
        </UserInfoPopup>
      </div>
    </>
  )
}

export default UserInfo