import { useState, useEffect, useCallback } from 'react';

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = localStorage.getItem('jwtToken');

  const fetcher = useCallback(async (options) => {
    setIsLoading(true);
    try {
      
      const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      };
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          ...headers
        },
      });
      const json = await response.json();
      setData(json);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  });

  return { data, isLoading, error, fetcher };
};

export default useFetch;