import { useContext } from 'react';
import { AuthContext } from '../../context/auth';
import styled from '@emotion/styled';

const WrapperDiv = styled.div`
    .wmde-markdown {
        li {
            list-style-type: disc;
        }
    }
`;

function Wrapper({ children }) {
    const { user } = useContext(AuthContext);

    return (
        <WrapperDiv className={user ? 'wrapper' : 'wrapper wrapper__auth'}>
            {children}
        </WrapperDiv>
    )
}

export default Wrapper