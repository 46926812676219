import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import GQL from '../../../gql';

import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import Description from '../components/Description/Description';
import LSMList from '../../../components/LSM/LSMList/LSMList';
import LSMCards from '../../../components/LSM/LSMCard/LSMCard';
import './Categories.scss';

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

function Categories() {
  const { '*': uri } = useParams();
  // const [lsmCard, setLsmCard] = useState([]);

  let path = uri;
  if (uri.match((/\/asset\//)))
  {
    path = path.split('/asset/')[0];
  }
  const category = path.split('/').pop();

  const { loading, error, data, refetch } = useQuery
  (
      GQL.Category.GET_CATEGORIES_AND_ASSETS_BY_URI,
      {
          variables: { uri: path, uriEnd: category }
      }
  );

  if (loading)
  {
    return <Description title={`Loading...`} />;
  }
  if (error)
  {
    return <Description title={`Error! ${error}`} />;
  }
  const categoryName = data.getCategoryByURI.name;

  TabTitle(categoryName);
  // PageDescription(categoryName);

  let enableEmailPrintQuote = true;
  if (path.includes('product-images')) {
    enableEmailPrintQuote = false;
  }

  return (
    <div className="lsm">
      <Description
        title={categoryName}
        category={data.getCategoryByURI}
        description={data.getCategoryByURI.description}
        onUpload={refetch}
      />
      <LSMList
        refresh={refetch}
        enablePopup={true}
        enableTags={true}
        enableSave={true}
        enableEmailFile={true}
        enableEmailPrintQuote={enableEmailPrintQuote}
        enableHires={true}
        enableReplace={true}
        enableDelete={true}
        data={data.getAssetsByCategoryURI}
      />
    </div>
  )
}

export default Categories