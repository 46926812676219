import { toast } from 'react-toastify';

export const successNotify = (title, id) => toast.success(title, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
});

export const errorNotify = (title, id) => toast.error(title, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
});

export const emailProgress = (promise) => toast.promise(promise, {
    pending: 'Sending email...',
    success: 'Email Sent!',
    error: 'Error sending email!',
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'lsm_send_email'
});

