import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const ADD_TODO = gql`
  mutation approveRequestLogin($userId: ID!) {
    approveRequestLogin(userId: $userId)
  }
`;

function RequestLoginApprove({ userData, setDataTable, dataTable }) {
    const [approveRequestLogin, { data, loading, error }] = useMutation(ADD_TODO);

    if (loading) toast.success("User approve successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'forgot-username-success'
    });
    if (error) toast.error(error.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'forgot-username-error'
    });

    let approveUser = (e) => {
        approveRequestLogin({ variables: { userId: userData[0].id } })
        setDataTable(dataTable.filter(item => item.id !== userData[0].id));
    }

    return (
        <button onClick={approveUser}>Approve</button>
    )
}

export default RequestLoginApprove