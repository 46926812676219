import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const ADD_TODO = gql`
  mutation deleteRequestLogin($userId: ID!) {
    deleteRequestLogin(userId: $userId)
  }
`;

function RequestLoginRemove({ userData, setDataTable, dataTable }) {
  const [deleteRequestLogin, { data, loading, error }] = useMutation(ADD_TODO);

  if (loading) toast.success("User deleted successfully", {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'forgot-username-success'
  });
  if (error) toast.error(error.message, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'forgot-username-error'
  });

  let removeCurrentUser = (e) => {
    deleteRequestLogin({ variables: { userId: userData[0].id } })
    setDataTable(dataTable.filter(item => item.id !== userData[0].id));
  }

  return (
    <button onClick={removeCurrentUser}>Delete</button>
  );
}

export default RequestLoginRemove;