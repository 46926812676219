import React from 'react';
import Popup from './Popup';

export default function Emailpopup(props) {

    const { trigger, setTrigger, style, popupStyle, closeBtn, customClass } = props;
    const popupProps = {trigger, setTrigger, style, popupStyle, closeBtn, customClass};

    const onCancel = () => {
        if (typeof setTrigger == 'function')
        {
            setTrigger(false);
        }
    }

    return (
        <Popup {...popupProps} style={{maxWidth:'500px'}}>
            <div
                className="lsm-popup__email">
                <h5>EMAIL TO</h5>
                <p>Enter the recipient's email address</p>
                <input type="email" id="sender-email" placeholder={'Email'} />
                <div className={'btn-group'}>
                    <button onClick={onCancel}>Cancel</button>
                    <button onClick={props.onSendEmail}>SEND</button>
                </div>
            </div>
        </Popup>
    );
}
