import React, { useState } from 'react'
import Select, { components } from "react-select";

export const DropdownStyle = {
    menu: (provided, state) => ({
        ...provided,
        width: '100%',
        padding: 0,
        marginBottom: 0,
        marginTop: '0',
        boxShadow: 'none',
        border: '1px solid #B3B3B3',
        borderTop: '0',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        transition: 'all .2s ease-in-out',
        borderRadius: '0',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    }),
    menuList: (provided, state) => ({
        ...provided,
        padding: '0',
        borderRadius: '0',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: 'black',
        backgroundColor: '#fff',
        padding: '10px 10px',
        cursor: 'pointer',
        height: '33px',
        display: 'flex',
        alignItems: 'center',
        transition: 'all .2s ease-in-out',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#FA3535'
        }
    }),
    control: (base, state) => ({
        ...base,
        border: '1px solid #B3B3B3',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #B3B3B3',
        boxShadow: '0 !important',
        cursor: 'pointer',
        width: '80%',
        height: '40px',
        borderRadius: '4px',
        borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
        borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        transition: '.2s easy-in-out',
        '&:hover': {
            borderColor: '1px solid #EEEEEE !important',
            borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #B3B3B3',
        }
    }),
};

export const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
            </svg>
        </components.DropdownIndicator>
    );
};

const EditableSidebar = ({storeData, onSelectStore, onDateChange}) =>
{
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="lsm-sos__inputs lsm-popup__sidebar__input-group--editable">
            <h5>Select your Business</h5>
            <div>
                <label htmlFor="businessName">Store Name</label>
                <Select
                    defaultValue={{
                        value: 'Store_Name',
                        label: 'Select your Store Name'
                    }}
                    isSearchable={true}
                    styles={DropdownStyle}
                    onChange={onSelectStore}
                    isClearable={false}
                    options={storeData}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: DropdownIndicator
                    }}
                />
            </div>
            <div>
                <label htmlFor="businesDate">Date</label>
                <input onChange={onDateChange} type="date" name="businesDate" />
            </div>
        </div>
    );
}
export default EditableSidebar;