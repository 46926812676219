import styled from '@emotion/styled';
import { 
    DragDropContext,
    Droppable,
    Draggable,
    
} from 'react-beautiful-dnd';

export const couponData = [
    {
        "id": "item-1",
        "src": "/static/General/icons/coupon-1.png"
    },
    {
        "id": "item-2",
        "src": "/static/General/icons/coupon-2.png"
    },
    {
        "id": "item-3",
        "src": "/static/General/icons/coupon-3.png"
    },
    {
        "id": "item-4",
        "src": "/static/General/icons/coupon-4.png"
    },
    {
        "id": "item-5",
        "src": "/static/General/icons/coupon-5.png"
    },
    {
        "id": "item-6",
        "src": "/static/General/icons/coupon-6.png"
    },
    {
        "id": "item-7",
        "src": "/static/General/icons/coupon-7.png"
    },
    {
        "id": "item-8",
        "src": "/static/General/icons/coupon-8.png"
    },
    {
        "id": "item-9",
        "src": "/static/General/icons/coupon-9.png"
    },
    {
        "id": "item-10",
        "src": "/static/General/icons/coupon-10.png"
    },
    {
        "id": "item-11",
        "src": "/static/General/icons/coupon-11.png"
    },
];

export const InnerVoucherContainer = styled.div`
    height: 662px;
    width: 487px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 10px;
    overflow-y: auto;
`;

export const CouponImg = styled.img`
    width: 146px;
    height: 146px;
`;

const CouponListDropZoneWrapper = styled.div`
    width: 518px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const CouponListDropZone = (props) => (
    <CouponListDropZoneWrapper>
        <Droppable
            droppableId="couponList"
            // type="coupon"
            direction="vertical"
            ignoreContainerClipping={false}
            isCombineEnabled={false}
        >
            {(provided, snapshot) => (
                <InnerVoucherContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    {props.children}
                    {provided.placeholder}
                </InnerVoucherContainer>
            )}
        </Droppable>
    </CouponListDropZoneWrapper>
);

