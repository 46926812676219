import React, {Component} from 'react';
import Popup from 'reactjs-popup';
import CompaingFormFirst from "./Compaing/CompaingFormFirstStep";
import CompaingFormSecond from "./Compaing/CompaingFormSecondStep";
import CompaingFormThird from "./Compaing/CompaingFormThirdStep";
import CompaingFormFourth from "./Compaing/CompaingFormFourthStep";
import CompaingFormFifth from './Compaing/CompaingFormFifthStep';
import CompaingFormSixth from './Compaing/CompaingFormSixthStep';

export class TestMarket extends Component {

    state = {
        step: 1,
        title: "",
        pods: "",
        email: "",
        occupation: "",
        city: "",
        bio: ""
    };

    nextStep = () => {
        const {step} = this.state;
        this.setState({
            step: step + 1
        });
    };

    prevStep = () => {
        const {step} = this.state;
        this.setState({
            step: step - 1
        });
    };
    firstStep = () => {
        this.setState({
            step: 1
        });
    };

    handleChange = input => e => {
        this.setState({[input]: e.target.value});
    };

    render() {
        const {step} = this.state;
        const {title, pods, email, occupation, city, bio} = this.state;
        const values = {title, pods, email, occupation, city, bio};

        return (
            <Popup className="new-compaign-popup"
                   trigger={<button className="btn__compaing">
                       <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                               d="M17.1109 6.99966C17.1109 5.74109 16.3541 4.60648 15.1903 4.12524C14.028 3.64397 12.6907 3.90941 11.8011 4.80046C10.91 5.69006 10.6446 7.02736 11.1258 8.18968C11.6071 9.35345 12.7417 10.1103 14.0003 10.1103C14.8257 10.1103 15.6161 9.78365 16.1995 9.19885C16.7843 8.61551 17.1109 7.82507 17.1109 6.99966ZM12.4442 6.99966C12.4442 6.3711 12.8233 5.80381 13.4052 5.56316C13.9857 5.32107 14.6551 5.45524 15.0999 5.90004C15.5447 6.34483 15.6788 7.01423 15.4367 7.59466C15.1961 8.17655 14.6288 8.55571 14.0002 8.55571C13.1413 8.55571 12.4442 7.85861 12.4442 6.99964L12.4442 6.99966ZM17.6082 17.2825C19.2664 13.4327 21.0003 8.94364 21.0003 6.99966C21.0003 4.49858 19.666 2.18843 17.5003 0.937371C15.3346 -0.312457 12.6659 -0.312457 10.5002 0.937371C8.3345 2.18866 7.00011 4.49869 7.00011 6.99966C7.00011 8.94364 8.73409 13.4323 10.3908 17.2825C5.05614 17.8105 0 19.5838 0 22.556C0 26.0954 7.20993 28 14.0002 28C20.7905 28 28.0005 26.0939 28.0005 22.556C28.0005 19.5838 22.9443 17.8104 17.6097 17.2825H17.6082ZM14.0002 1.55562C15.444 1.55562 16.8294 2.12877 17.8501 3.14959C18.8708 4.17042 19.4441 5.55588 19.4441 6.99947C19.4441 9.33284 15.9834 17.2588 14 21.513C12.0167 17.2588 8.55601 9.33284 8.55601 6.99947C8.55601 5.55569 9.12916 4.17027 10.15 3.14959C11.1708 2.12892 12.5563 1.55562 13.9999 1.55562H14.0002ZM14.0002 26.4454C6.29263 26.4454 1.55608 24.182 1.55608 22.556C1.55608 21.0932 5.1728 19.282 11.0456 18.792C12.1262 21.2259 13.0596 23.1712 13.3017 23.6685L13.3003 23.6671C13.43 23.9354 13.7027 24.106 14.0003 24.106C14.2978 24.106 14.5705 23.9354 14.7003 23.6671C14.9409 23.1698 15.8742 21.2243 16.9564 18.7905C22.829 19.2805 26.4459 21.1239 26.4459 22.5545C26.4444 24.1806 21.7079 26.444 14.0003 26.444L14.0002 26.4454Z"
                               fill="#707579"/>
                       </svg>
                       Test Market</button>} position="center center">
                {close => (
                    <div className="new-compaign-popup__popup">
                        <a className="cancel" onClick={close}>
                            <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.9181 0.00138263C13.6856 0.0116368 13.4669 0.114174 13.3114 0.286781C13.1541 0.457678 13.0738 0.684977 13.0841 0.917394C13.0943 1.14981 13.1969 1.36854 13.3695 1.52408C13.5404 1.6813 13.7677 1.76162 14.0001 1.75137C20.7761 1.75137 26.2501 7.22537 26.2501 14.0014C26.2501 20.7774 20.7761 26.2514 14.0001 26.2514C7.22409 26.2514 1.75009 20.7774 1.75009 14.0014C1.75009 8.21806 5.74057 3.38674 11.1152 2.09306C11.5869 1.98368 11.8808 1.51201 11.7715 1.04034C11.6621 0.568675 11.1904 0.274719 10.7188 0.384094C4.57144 1.86407 0 7.41122 0 14.0013C0 21.7223 6.279 28.0013 14 28.0013C21.721 28.0013 28 21.7223 28 14.0013C28 6.28028 21.721 0.00128166 14 0.00128166C13.9727 -0.000427219 13.9453 -0.000427219 13.918 0.00128166L13.9181 0.00138263ZM9.09199 8.30032C8.7519 8.33279 8.46138 8.5618 8.3503 8.88478C8.2375 9.20949 8.32466 9.56837 8.57246 9.80423L12.7697 14.0015L8.57246 18.1988C8.37935 18.3543 8.26143 18.585 8.24775 18.8328C8.23408 19.0806 8.32637 19.3233 8.50239 19.4993C8.67842 19.6753 8.92109 19.7676 9.16888 19.754C9.41668 19.7403 9.64742 19.6224 9.8029 19.4292L14.0002 15.232L18.1975 19.4292C18.353 19.6224 18.5837 19.7403 18.8315 19.754C19.0793 19.7676 19.322 19.6753 19.498 19.4993C19.674 19.3233 19.7663 19.0806 19.7526 18.8328C19.739 18.585 19.621 18.3543 19.4279 18.1988L15.2306 14.0015L19.4279 9.80423C19.6877 9.55301 19.7663 9.1702 19.6262 8.83696C19.4877 8.50541 19.1596 8.29179 18.799 8.30032C18.57 8.30887 18.3547 8.40799 18.1975 8.57376L14.0002 12.771L9.80289 8.57376C9.63883 8.40115 9.41153 8.30203 9.17398 8.30032C9.14664 8.29861 9.11929 8.29861 9.09195 8.30032H9.09199Z"
                                    fill="black"/>
                            </svg>
                        </a>
                        {(() => {
                            switch (step) {
                                default:
                                    return <h1>User Forms not working. Enable Javascript!</h1>;
                                case 1:
                                    return (
                                        <div className="first_popup_content">
                                            <CompaingFormFirst
                                                nextStep={this.nextStep}
                                                handleChange={this.handleChange}
                                                values={values}
                                            />
                                        </div>
                                    );
                                case 2:
                                    return (
                                        <div className="first_popup_content">
                                            <CompaingFormSecond
                                                nextStep={this.nextStep}
                                                prevStep={this.prevStep}
                                                handleChange={this.handleChange}
                                                values={values}
                                            />
                                        </div>
                                    );
                                case 3:
                                    return (
                                        <div className="first_popup_content">
                                            <CompaingFormThird
                                                nextStep={this.nextStep}
                                                prevStep={this.prevStep}
                                                values={values}
                                            />
                                        </div>
                                    );
                                //
                                case 4:
                                    return (
                                        <div className="first_popup_content">
                                            <CompaingFormFourth
                                                nextStep={this.nextStep}
                                                prevStep={this.prevStep}
                                                values={values}
                                            />
                                        </div>
                                    );
                                case 5:
                                    return (
                                        <div className="first_popup_content">
                                            <CompaingFormFifth
                                                nextStep={this.nextStep}
                                                prevStep={this.prevStep}
                                                values={values}
                                            />
                                        </div>
                                    );
                                case 6:
                                    return (
                                        <div className="first_popup_content last_popup_content">
                                            <CompaingFormSixth
                                                nextStep={this.nextStep}
                                                prevStep={this.prevStep}
                                                values={values}
                                            />
                                        </div>
                                    );
                            }
                        })()}
                    </div>
                )}
            </Popup>
        )
    }
}

export default TestMarket
