import { useEffect } from "react";

export const PageDescription = (title: string, description: string) => {
    const pageTitle = document.querySelector('.page-description h5') as HTMLElement | null;
    const pageDescription = document.querySelector('.page-description p') as HTMLElement | null;

    // useEffect(() => {
    //     if (pageTitle != null && pageDescription != null) {
    //         pageTitle.innerText = title;
    //         pageDescription.innerText = description;
    //     }
    // });
    if (pageTitle != null && pageDescription != null) {
            pageTitle.innerText = title;
            // pageDescription.innerText = description;
        }
}