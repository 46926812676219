import { gql } from '@apollo/client';


export const GET_ALL_CATEGORIES = gql`
query GetCategories {
    getCategories {
      id
      parentId
      name
      uri
      description
      weight
      permissions
      createdAt
    }
  }
`;

export const GET_CATEGORY_BY_URI = gql`
query GetCategoryByURI($uri: String!) {
    getCategoryByURI(URI: $uri) {
      id
      parentId
      name
      uri
      description
      weight
      permissions
      createdAt
    }
  }
`;

export const GET_CATEGORIES_AND_ASSETS_BY_URI = gql`
query GetCategoryAndAssetsByURI($uri: String!, $uriEnd: String!) {
  getCategoryByURI(URI: $uriEnd) {
    id
    parentId
    name
    uri
    description
    weight
    permissions
    createdAt
  },
  getAssetsByCategoryURI(URI: $uri) {
    id
    name
    fileName
    safeName
    category
    tags
    file
    editorType
    uri
    pinned
    weight
    data
    permissions
    createdAt
  }
}
`;

export const CREATE_CATEGORY = gql`
mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput) {
  updateCategory(updateCategoryInput: $updateCategoryInput) {
    parentId
    name
    uri
    description
    weight
    permissions
  }
}
`;

export const UPDATE_CATEGORY = gql`
mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput) {
  updateCategory(updateCategoryInput: $updateCategoryInput) {
    id
    parentId
    name
    uri
    description
    weight
    permissions
  }
}
`;