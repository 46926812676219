import { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import { toast } from "react-toastify";

import { TabTitle } from '../../utils/TabTitle';
import StoreOwners from './sections/StoreOwners/StoreOwners';
import GoLive from './sections/GoLive/GoLive';
import StoreConfiguation from './sections/StoreConfiguation/StoreConfiguation';
import MediaConfiguation from './sections/MediaConfiguation/MediaConfiguation';
import { ReactComponent as SearchIcon } from './icons/searchIcon.svg';
import './style.scss';
import { PageDescription } from '../../utils/PageDescription';

const style = {
  container: base => ({
    ...base,
    width: '100%',
    zIndex: 99
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    padding: 0,
    marginBottom: 0,
    marginTop: '0',
    boxShadow: 'none',
    border: '1px solid #eeeeee',
    borderTop: '0',
    fontWeight: '600',
    fontSize: '14px',
    transition: 'all .2s ease-in-out',
    borderRadius: '0',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: '0',
    borderRadius: '0',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: '#fff',
    padding: '0 20px',
    cursor: 'pointer',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#F40027'
    }
  }),
  control: (base, state) => ({
    ...base,
    padding: '0 20px',
    border: '1px solid #eeeeee',
    borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #eeeeee',
    boxShadow: '0 !important',
    cursor: 'pointer',
    width: '100%',
    height: '60px',
    borderRadius: '10px',
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '10px',
    borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '10px',
    fontWeight: '400',
    fontSize: '14px',
    transition: '.2s easy-in-out',
    '&:hover': {
      borderColor: '1px solid #EEEEEE !important',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #eeeeee',
    }
  }),
  valueContainer: base => ({
    ...base,
    padding: '0',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '0'
  })
};
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      Loading…
    </components.NoOptionsMessage>
  );
};

const successNotify = (title, id) => toast.success(title, {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastId: id
});

const errorNotify = (title, id) => toast.error(title, {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastId: id
});

function AddEditStores(props) {
  TabTitle(props.title || 'KFC LSM');
  PageDescription('Add / Edit Stores', 'Additional information');
  const [zones, setZones] = useState([]);
  const [stores, setStores] = useState([]);

  useEffect(() => {
    fetch('https://3.137.141.92:3525/list-zones', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setZones(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    fetch('https://3.137.141.92:3525/list-stores', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        setStores(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  let storeOwnersChange = (e) => {
    let restaurantName = e.value;
    fetch('https://3.137.141.92:3525/store-search', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        restaurantName
      })
    })
      .then(response => response.json())
      .then(data => {
        document.querySelector('#storeName').value = data.restaurantName;
        document.querySelector('#usn').value = data.No;
        document.querySelector('#zonesSelected > div > div:first-child > div').innerText = data.priceZone;
        document.querySelector('#menuBoards > div > div:first-child > div').innerText = data.contentPlanSchedule;
        document.querySelector('#address').value = data.address;
        document.querySelector('#postcode').value = data.Postcode;
        document.querySelector('#state > div > div:first-child > div').innerText = data.state;
      })
      .catch(error => {
        console.error('Error:', error);
      });

    successNotify('Locate store found', 'locate_store_found')
  }


  return (
    <div className={'add-edit-stores'}>
      <div className={'add-edit-stores__locate-store'}>
        <h5>Locate store</h5>
        <p>Deatils to be added</p>
        <hr />
        <div className='add-edit-stores__locate-store__search-bar'>
          <Select
            defaultValue={{ label: "Search for store" }}
            options={stores.zones}
            styles={style}
            isSearchable={true}
            id={"storeFind"}
            onChange={storeOwnersChange}
            components={{
              NoOptionsMessage,
              DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: () => null
            }}
          />
        </div>
      </div>
      <div className='add-edit-stores__row'>
        <div className='add-edit-stores__col'>
          <StoreOwners />
          <GoLive />
        </div>
        <div className='add-edit-stores__col'>
          <StoreConfiguation />
        </div>
        <div className='add-edit-stores__col'>
          <MediaConfiguation />
        </div>
      </div>
    </div>
  )
}

export default AddEditStores