import { useState } from 'react';
import { toast } from "react-toastify";

import './style.scss';
import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import Popup from '../../../../components/Popup/Popup';

function MediaConfiguation() {
    const [buttonPopup, setButtonPopup] = useState(false);

    const optionsCount = [
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '29', label: '29' },
        { value: '30', label: '30' },
        { value: '31', label: '31' },
        { value: '32', label: '32' },
        { value: '33', label: '33' },
        { value: '34', label: '34' },
        { value: '35', label: '35' },
        { value: '36', label: '36' },
        { value: '37', label: '37' },
        { value: '38', label: '38' },
        { value: '39', label: '39' },
        { value: '40', label: '40' },
        { value: '41', label: '41' },
        { value: '42', label: '42' },
        { value: '43', label: '43' },
        { value: '44', label: '44' },
        { value: '45', label: '45' },
        { value: '46', label: '46' },
        { value: '47', label: '47' },
        { value: '48', label: '48' },
        { value: '49', label: '49' },
        { value: '50', label: '50' },
        { value: '51', label: '51' },
        { value: '52', label: '52' },
        { value: '53', label: '53' },
        { value: '54', label: '54' },
        { value: '55', label: '55' },
        { value: '56', label: '56' },
        { value: '57', label: '57' },
        { value: '58', label: '58' },
        { value: '59', label: '59' },
        { value: '60', label: '60' },
        { value: '61', label: '61' },
        { value: '62', label: '62' },
        { value: '63', label: '63' },
        { value: '64', label: '64' },
        { value: '65', label: '65' },
        { value: '66', label: '66' },
        { value: '67', label: '67' },
        { value: '68', label: '68' },
        { value: '69', label: '69' },
        { value: '70', label: '70' },
        { value: '71', label: '71' },
        { value: '72', label: '72' },
        { value: '73', label: '73' },
        { value: '74', label: '74' },
        { value: '75', label: '75' },
        { value: '76', label: '76' },
        { value: '77', label: '77' },
        { value: '78', label: '78' },
        { value: '79', label: '79' },
        { value: '80', label: '80' },
        { value: '81', label: '81' },
        { value: '82', label: '82' },
        { value: '83', label: '83' },
        { value: '84', label: '84' },
        { value: '85', label: '85' },
        { value: '86', label: '86' },
        { value: '87', label: '87' },
        { value: '88', label: '88' },
        { value: '89', label: '89' },
        { value: '90', label: '90' },
        { value: '91', label: '91' },
        { value: '92', label: '92' },
        { value: '93', label: '93' },
        { value: '94', label: '94' },
        { value: '95', label: '95' },
        { value: '96', label: '96' },
        { value: '97', label: '97' },
        { value: '98', label: '98' },
        { value: '99', label: '99' },
        { value: '100', label: '100' },
    ]

    const optionMenuBoards = [
        { value: 'pod-01', label: '3PL FSDT' },
        { value: 'pod-01', label: '3PL MSP' },
        { value: 'pod-01', label: '4PL FSDT' },
        { value: 'pod-01', label: '5PL FSDT' },
        { value: 'pod-01', label: '5PP FSDT / MSC' },
        { value: 'pod-01', label: '4PL MSC' },
        { value: 'pod-01', label: '4PL MSP' },
        { value: 'pod-01', label: 'SBX' },
        { value: 'pod-01', label: 'DTO' },
        { value: 'pod-01', label: '5PL MSC' },
        { value: 'pod-01', label: '4PP FSDT' }
    ]

    const errorNotify = (title, id) => toast.error(title, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: id
    });

    const successNotify = (title, id) => toast.success(title, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: id
    });

    const saveStore = (e) => {
        let storeOwners = document.querySelector('#storeOwners > div > div:first-child > div');
        let podsName = document.querySelector('#podsName > div > div:first-child > div');
        let storeName = document.querySelector('#storeName');
        let usn = document.querySelector('#usn');
        let zonesSelected = document.querySelector('#zonesSelected > div > div:first-child > div');
        let Postcode = document.querySelector('#postcode');
        let state = document.querySelector('#state > div > div:first-child > div');
        let address = document.querySelector('#address');

        let menuBoards = document.querySelector('#menuBoards > div > div:first-child > div');
        let driveThrough = document.querySelector('#driveThrough > div > div:first-child > div');
        let preReaders = document.querySelector('#preReaders > div > div:first-child > div');
        let Blades = document.querySelector('#Blades > div > div:first-child > div');
        let Till = document.querySelector('#Till > div > div:first-child > div');
        let Posters = document.querySelector('#Posters > div > div:first-child > div');
        let Banners = document.querySelector('#Banners > div > div:first-child > div');
        let tableTags = document.querySelector('#tableTags > div > div:first-child > div');

        if (storeOwners.innerText === 'Add Name') {
            storeOwners.style.color = 'red';
            storeOwners.parentElement.parentElement.style.borderColor = 'red';
            storeOwners.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            storeOwners.style.color = '#202020';
            storeOwners.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            storeOwners.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor';
        }
        if (podsName.innerText === 'Select POD') {
            podsName.style.color = 'red';
            podsName.parentElement.parentElement.style.borderColor = 'red';
            podsName.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            podsName.style.color = '#202020';
            podsName.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            podsName.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (!storeName.value) {
            storeName.style.color = 'red';
            storeName.classList.add('redPlaceholder');
            storeName.style.borderColor = 'red';
        } else {
            storeName.style.color = '#202020';
            storeName.classList.remove('redPlaceholder');
            storeName.style.borderColor = 'transparent';
        }
        if (!usn.value) {
            usn.style.color = 'red';
            usn.classList.add('redPlaceholder');
            usn.style.borderColor = 'red';
        } else {
            usn.style.color = '#202020';
            usn.classList.remove('redPlaceholder');
            usn.style.borderColor = 'transparent';
        }
        if (zonesSelected.innerText === 'Select Zones') {
            zonesSelected.style.color = 'red';
            zonesSelected.parentElement.parentElement.style.borderColor = 'red';
            zonesSelected.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            zonesSelected.style.color = '#202020';
            zonesSelected.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            zonesSelected.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (state.innerText === 'State') {
            state.style.color = 'red';
            state.parentElement.parentElement.style.borderColor = 'red';
            state.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            state.style.color = '#202020';
            state.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            state.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (!Postcode.value) {
            Postcode.style.color = 'red';
            Postcode.classList.add('redPlaceholder');
            Postcode.style.borderColor = 'red';
        } else {
            Postcode.style.color = '#202020';
            Postcode.classList.remove('redPlaceholder');
            Postcode.style.borderColor = 'transparent';
        }
        if (!address.value) {
            address.style.color = 'red';
            address.classList.add('redPlaceholder');
            address.style.borderColor = 'red';
        } else {
            address.style.color = '#202020';
            address.classList.remove('redPlaceholder');
            address.style.borderColor = 'transparent';
        }
        if (menuBoards.innerText === 'Select') {
            menuBoards.style.color = 'red';
            menuBoards.parentElement.parentElement.style.borderColor = 'red';
            menuBoards.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            menuBoards.style.color = '#202020';
            menuBoards.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            menuBoards.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (driveThrough.innerText === '0') {
            driveThrough.style.color = 'red';
            driveThrough.parentElement.parentElement.style.borderColor = 'red';
            driveThrough.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            driveThrough.style.color = '#202020';
            driveThrough.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            driveThrough.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (preReaders.innerText === '0') {
            preReaders.style.color = 'red';
            preReaders.parentElement.parentElement.style.borderColor = 'red';
            preReaders.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            preReaders.style.color = '#202020';
            preReaders.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            preReaders.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (Blades.innerText === '0') {
            Blades.style.color = 'red';
            Blades.parentElement.parentElement.style.borderColor = 'red';
            Blades.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            Blades.style.color = '#202020';
            Blades.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            Blades.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (Till.innerText === '0') {
            Till.style.color = 'red';
            Till.parentElement.parentElement.style.borderColor = 'red';
            Till.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            Till.style.color = '#202020';
            Till.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            Till.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (Posters.innerText === '0') {
            Posters.style.color = 'red';
            Posters.parentElement.parentElement.style.borderColor = 'red';
            Posters.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            Posters.style.color = '#202020';
            Posters.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            Posters.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (Banners.innerText === '0') {
            Banners.style.color = 'red';
            Banners.parentElement.parentElement.style.borderColor = 'red';
            Banners.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            Banners.style.color = '#202020';
            Banners.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            Banners.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }
        if (tableTags.innerText === '0') {
            tableTags.style.color = 'red';
            tableTags.parentElement.parentElement.style.borderColor = 'red';
            tableTags.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'red'
        } else {
            tableTags.style.color = '#202020';
            tableTags.parentElement.parentElement.style.borderColor = 'hsl(0, 0%, 80%)';
            tableTags.parentElement.parentElement.querySelector('div:nth-child(2) svg path').style.fill = 'currentColor'
        }

        if (storeOwners.innerText === 'Add Name' || podsName.innerText === 'Select POD' || !storeName.value || !usn.value || zonesSelected.innerText === 'Select Zones' || state.innerText === 'State' || !Postcode.value || !address.value || menuBoards.innerText === 'Select' || driveThrough.innerText === '0' || preReaders.innerText === '0' || Blades.innerText === '0' || Till.innerText === '0' || Posters.innerText === '0' || Banners.innerText === '0' || tableTags.innerText === '0') {
            errorNotify('Please fill in all the required fields', 'field-required');
        } else {

            fetch(process.env.REACT_APP_API_SERVER + 'created-store', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    storeOwners: storeOwners.innerText,
                    podsName: podsName.innerText,
                    storeName: storeName.innerText,
                    usn: usn.innerText,
                    address: address.innerText,
                    zonesSelected: zonesSelected.innerText,
                    menuBoards: menuBoards.innerText,
                    driveThrough: driveThrough.innerText,
                    preReaders: preReaders.innerText,
                    Blades: Blades.innerText,
                    Till: Till.innerText,
                    Posters: Posters.innerText,
                    Postcode: Postcode.innerText,
                    state: state.innerText,
                    Banners: Banners.innerText,
                    tableTags: tableTags.innerText
                }),
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data.result)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            successNotify();
        }
    }


    return (
        <div className='add-edit-stores__media-configuration'>
            <h3>Media Configuation</h3>
            <p>Deatils to be added</p>
            <h4>Store Name</h4>
            <h5>Menu Boards</h5>
            <CustomSelect id={"menuBoards"} width={'100%'} variation={'small'} data={optionMenuBoards} />
            <h5>Drive Through</h5>
            <CustomSelect id={"driveThrough"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <h5>Pre Readers</h5>
            <CustomSelect id={"preReaders"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <h5>Blades</h5>
            <CustomSelect id={"Blades"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <h5>Till</h5>
            <CustomSelect id={"Till"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <h5>Posters</h5>
            <CustomSelect id={"Posters"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <h5>Banners</h5>
            <CustomSelect id={"Banners"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <h5>Tabel Tags</h5>
            <CustomSelect id={"tableTags"} width={'100%'} variation={'small'} defaultValue={optionsCount[0]} data={optionsCount} />
            <div className='add-edit-stores__media-configuration__btn-group'>
                <button onClick={saveStore} className='add-edit-stores__media-configuration__btn--save'>SAVE</button>
                <button className='add-edit-stores__media-configuration__btn--cancel'>CANCEL</button>
                <button className='add-edit-stores__media-configuration__btn--delete'>DELETE</button>
            </div>
            <Popup popupStyle={{ zIndex: '99' }} style={{ maxWidth: '550px' }} trigger={buttonPopup} setTrigger={setButtonPopup}>
                <div className={'save-store__container'}>
                    <h6>New Store</h6>
                    <p>Store successfully created</p>
                    <hr />
                    <div className={'save-store__container__link'}>
                        <span>New store name</span>
                        {/* <a target={'_blank'} href={`/pods/${document.querySelector('#podsName > div > div:first-child > div').innerText.toLowerCase()}/2022-2023/zones/${document.querySelector('#zonesSelected > div > div:first-child > div').innerText.toLowerCase().replaceAll(' ', '-')}/stores/${document.querySelector('#storeName').value.toLowerCase().replaceAll(' ', '-')}`}>Open Link</a> */}
                    </div>
                    {/* <button onClick={openDetails}>Open details</button> */}


                    {/* <div className={'save-store-details'}>
                        <div className={'save-store-details__container'}>
                            <h6>Store details</h6>
                            <p>Details to be added</p>
                            <hr />
                            <div className={'row'}>
                                <div className={'col'}>
                                    <div className={'save-store-details__user'}>
                                        <h6>Business Owner</h6>
                                        <p className={'save-store-details__user__username'}>Username</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>POD</h6>
                                        <p className={'save-store-details__user__pod'}>POD NAME</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Zone</h6>
                                        <p className={'save-store-details__user__zone'}>Zone NAME</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Store name</h6>
                                        <p className={'save-store-details__user__store'}>Store NAME</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>USN -unique store number</h6>
                                        <p className={'save-store-details__user__usn'}>124124</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Address</h6>
                                        <p className={'save-store-details__user__address'}>142 Bellarine
                                            Highway</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Postcode</h6>
                                        <p className={'save-store-details__user__postcode'}>Postcode</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>State</h6>
                                        <p className={'save-store-details__user__state'}>State</p>
                                    </div>
                                </div>
                                <div className={'col'}>
                                    <div className={'save-store-details__user'}>
                                        <h6>Menu Boards</h6>
                                        <p className={'save-store-details__user__menu-boards'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Drive Through</h6>
                                        <p className={'save-store-details__user__drive-through'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Pre Readers</h6>
                                        <p className={'save-store-details__user__pre-readers'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Blades</h6>
                                        <p className={'save-store-details__user__blades'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Till</h6>
                                        <p className={'save-store-details__user__till'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Posters</h6>
                                        <p className={'save-store-details__user__posters'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Banners</h6>
                                        <p className={'save-store-details__user__banners'}>1</p>
                                    </div>
                                    <div className={'save-store-details__user'}>
                                        <h6>Table Tags</h6>
                                        <p className={'save-store-details__user__table-tags'}>1</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Popup>
        </div>
    )
}

export default MediaConfiguation