import ApolloProvider from './context/ApolloProvider';
import GoogleTagManagerProvider from './context/GoogleTagManagerProvider';
import Router from './routes';

export default function() {
    
    //TODO: Fetch GTM config from config.
    return (
       <ApolloProvider>
            <Router />
            {/* <GoogleTagManagerProvider
                gtmId="G-XLJVKKMGD1"
                preview="env-1"
                auth="Di9Bt21OZFxJFknv8zZwbQ"
                dataLayerName="TopDataLayer"
            >
                <Router />
            </GoogleTagManagerProvider> */}
       </ApolloProvider>
    );
}