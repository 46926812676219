import { useContext, useEffect } from 'react';
import { useParams, useNavigate, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';



function Callback(props)
{
    const context = useContext(AuthContext);
    const navigateTo = useNavigate();
    const { token } = useParams();
    useEffect(
        () => {
            fetch(`${process.env.REACT_APP_API_SERVER}user/token/verify`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify({token})
            })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.success) {
                context.login(data.user);
                navigateTo('/');
                }
                navigateTo('/login');
            })
            .catch((error) => {
                console.error('Error:', error);
                navigateTo('/login');
            });
        }
    );
    return <Outlet />;
}

export default Callback;