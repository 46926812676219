import React, { useEffect } from "react";

export default function useCaptureKeypress(callback) {
    useEffect(() => {
        function handKeydown(event) {
            if (typeof callback == 'function') {
                callback(event);
            }
        }
        document.addEventListener("keydown", handKeydown);
        return () => {
            document.removeEventListener("keydown", handKeydown);
        };
    });
}