import styled from '@emotion/styled';
import { 
    DragDropContext,
    Droppable,
    Draggable,
    
} from 'react-beautiful-dnd';
import DraggableCoupon from './DraggableCoupon';
// import backView from '../../../pages/LSM/Categories/TaskList/coupon_back.png';

export const DropZoneGrid = styled.div`
    width: 770px;
    height: 100%;
    padding-left: 5px;
    position: relative;
    background-image: url(${props => props.background});
    background-size: 762px;
    background-repeat: no-repeat;
`;

export const DropZoneGridContainer = styled.div`
    width: 229px;
    height: 229px;
    position: absolute;
    top: ${props => props.top ? props.top: 0}px;
    bottom: ${props => props.bottom ? props.bottom: 0}px;
    left: ${props => props.left ? props.left: 0}px;
    right: ${props => props.right ? props.right: 0}px;
`;

export const CouponImgVoucher = styled.img`
    width: 229px;
    height: 229px;
    position: absolute;
    top: 0px;
    left: 0px;
`;

export const CouponImgVoucherSmaller = styled.img`
    width: 157px;
    height: 158px;
    position: absolute;
    top: 0px;
    left: 0px;
`;

export const InnerVoucherDropZone = styled.div`
    width: 100%;
    height: 100%;
`;

export const VoucherDropZone = (props) => (
    <Droppable
        droppableId={props.id}
        // type="coupon2"
        direction="vertical"
        ignoreContainerClipping={false}
        isCombineEnabled={false}
    >
        {(provided, snapshot) => (
            <InnerVoucherDropZone
                ref={provided.innerRef}
                style={{
                    backgroundColor: snapshot.isDraggingOver ? 'lightblue' : ''
                }}
                {...provided.droppableProps}
            >
                {props.children}
                {provided.placeholder}
            </InnerVoucherDropZone>
        )}
    </Droppable>
);

export default (props) => (
    <DropZoneGrid background={props.background}>
        <DropZoneGridContainer top={26} left={267}>
            <VoucherDropZone id="coupon-1.1">
                {props.voucherList.xx && (
                    <DraggableCoupon
                        id={props.voucherList.xx.id}
                        zoneId="coupon-1.1"
                        index={0}
                        closeButton={true}
                        onRemove={props.onRemoveCoupon}
                        tncText={props.tncText}
                    >
                        <CouponImgVoucher src={props.voucherList.xx.src} />
                    </DraggableCoupon>
                )}
            </VoucherDropZone>
        </DropZoneGridContainer>
        <DropZoneGridContainer top={26} left={508}>
            <VoucherDropZone id="coupon-1.2">
                {props.voucherList.xy && (
                    <DraggableCoupon
                        id={props.voucherList.xy.id}
                        zoneId="coupon-1.2"
                        index={0}
                        closeButton={true}
                        onRemove={props.onRemoveCoupon}
                        tncText={props.tncText}
                    >
                        <CouponImgVoucher src={props.voucherList.xy.src} />
                    </DraggableCoupon>
                )}
            </VoucherDropZone>
        </DropZoneGridContainer>
        <DropZoneGridContainer top={265} left={267}>
            <VoucherDropZone id="coupon-2.1">
                {props.voucherList.yx && (
                    <DraggableCoupon
                        id={props.voucherList.yx.id}
                        zoneId="coupon-2.1"
                        index={0}
                        closeButton={true}
                        onRemove={props.onRemoveCoupon}
                        tncText={props.tncText}
                    >
                        <CouponImgVoucher src={props.voucherList.yx.src} />
                    </DraggableCoupon>
                )}
            </VoucherDropZone>
        </DropZoneGridContainer>
        <DropZoneGridContainer top={265} left={508}>
            <VoucherDropZone id="coupon-2.2">
                {props.voucherList.yy && (
                    <DraggableCoupon
                        id={props.voucherList.yy.id}
                        zoneId="coupon-2.2"
                        index={0}
                        closeButton={true}
                        onRemove={props.onRemoveCoupon}
                        tncText={props.tncText}
                    >
                        <CouponImgVoucher src={props.voucherList.yy.src} />
                    </DraggableCoupon>
                )}
            </VoucherDropZone>
        </DropZoneGridContainer>
    </DropZoneGrid>
);