import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Main from '../components/Main/Main';
import Sidebar from '../components/Sidebar/Sidebar';
import Wrapper from '../components/Wrapper';

function Layout() {
  return (
    <>
      <Header />
      <Wrapper>
        <Sidebar />
        <Main>
          <Outlet />
        </Main>
      </Wrapper>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default Layout