import { useState, useContext } from 'react';
import Select, { components } from 'react-select';
import { useQuery, gql } from '@apollo/client';

import { AuthContext } from '../../context/auth';
import Popup from '../../components/Popup/Popup';
import { TabTitle } from '../../utils/TabTitle';
import './style.scss';
import PlaceholderAvatar from './avatar-placeholder.png';
import PlaceholderCover from './cover-placeholder.png';
import { USER_TYPE } from '../../defs/User';


function Profile({ title }) {
  TabTitle(title || 'KFC LSM');

  const { user } = useContext(AuthContext);

  const [userData, setUserData] = useState([])
  const [buttonConfirmPopup, setButtonConfirmPopup] = useState(false);
  const [buttonRemovePopup, setButtonRemovePopup] = useState(false);

  let options = [
    { value: 'intoto', label: 'Inototo' },
    { value: 'kfc', label: 'KFC' },
    { value: 'krispy-kreme', label: 'Krispy Kreme' }
  ]

  let ssoCheck = user.sso ? true : false;

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '379px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '400',
      fontSize: '14px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 10px',
      cursor: 'pointer',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 10px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '379px',
      minHeight: '36px',
      height: '36px',
      borderRadius: '4px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
      fontWeight: '400',
      fontSize: '14px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    }),
    singleValue: base => ({
      ...base,
      margin: 0
    })
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const DropdownIndicatorLight = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M15.9634 6.95454C16.2075 7.19862 16.2075 7.59435 15.9634 7.83843L10.7551 13.0468C10.511 13.2908 10.1153 13.2908 9.87121 13.0468L4.66287 7.83843C4.4188 7.59435 4.4188 7.19862 4.66287 6.95454C4.90695 6.71046 5.30268 6.71046 5.54676 6.95454L10.3132 11.7209L15.0795 6.95454C15.3236 6.71046 15.7193 6.71046 15.9634 6.95454Z" fill="#24292E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const FETCH_USER_QUERY = gql`
    query($userId: ID!) {
      getUser(userId: $userId) {
        id
        firstName
        lastName
        email
        phone
        username
        createdAt
        userRole
        company
        address
        secondaryAddress
        activation
        avatar
        cover
        state
        country
      }
    }
  `;

  const { loading, error, data } = useQuery(FETCH_USER_QUERY,
    {
      variables: { userId: user.id || user._id },
      onCompleted: (data) => setUserData(data.getUser)
    });


  if (loading) return <div className='preloader'><div id="loader" /></div>;
  if (error) return <p>Error :</p>;

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  };

  return (
    <div className={'profile'}>
      <div className={'row'}>
        <div className={"col"}>
          <div className={"user-avatar"}>
            <div className={"user-avatar__upload"}>
              <img src={userData.avatar ? userData.avatar : PlaceholderAvatar} alt={""} />
              <div className="user-avatar__upload__container">
                <label htmlFor={"user-avatar"} style={{display:'none'}}>Edit</label>
                <input name={"user-avatar"} id={"user-avatar"} type={"file"} />
              </div>
            </div>
            <div className={"user-avatar__info"}>
              <h3>{userData.firstName && userData.lastName ? <>{userData.firstName} {userData.lastName}</> : "Name"}</h3>
              <h4>{userData.company ? userData.company : "Company"}</h4>
              <p>{userData.address ? userData.address : "Address"}</p>
            </div>
          </div>
          <hr style={{ marginTop: '15px', marginBottom: '15px' }} />
          <h5>Full Name</h5>
          <p>Details to be added</p>
          <hr style={{ marginTop: '11px', marginBottom: '16px' }} />

          <div className={'profile-details'}>
            <label htmlFor="username">Username<span>*</span></label>
            <div className='profile-details__check-username'>
              <input disabled type="text" name={'username'} id={"username"} placeholder={"Add your username..."} value={userData.username} />
              {ssoCheck === false &&
                <button className='btn-save' style={{display:'none'}}>CHECK</button>
              }
            </div>
          </div>

          <hr style={{ marginTop: '25px', marginBottom: '17px' }} />
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="firstName">First Name<span>*</span></label>
            <input disabled type="text" name={'firstName'} id={"firstName"} placeholder={"Add your name..."} value={userData.firstName} />
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="lastName">2nd Name<span>*</span></label>
            <input disabled type="text" name={'lastName'} id={"lastName"} placeholder={"Add 2nd Name"} value={userData.lastName} />
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="company">Company<span>*</span></label>
            <input disabled type="text" name={'company'} id={"company"} placeholder={"Company"} value={userData.company} />
          </div>
          <h5 style={{ marginTop: '37px' }}>Contact Details</h5>
          <p>Details to be added</p>
          <hr style={{ marginTop: '9px', marginBottom: '17px' }} />
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="email">Email<span>*</span></label>
            <input disabled type="email" name={'email'} id={"email"} placeholder={"Add Email..."} value={userData.email} />
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="phone">Mobile<span>*</span></label>
            <input disabled type="phone" name={'phone'} id={"phone"} placeholder={"Add Mobile"} value={userData.phone} />
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="address">Address<span>*</span></label>
            <input disabled type="text" name={'address'} id={"address"} placeholder={"Address"} value={userData.address} />
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="addressSecond">Address Line 2<span>*</span></label>
            <input disabled type="text" name={'addressSecond'} id={"addressSecond"} placeholder={"Address"} value={userData.secondaryAddress} />
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="state">State<span>*</span></label>
            {userData.state || 'Not Selected'}
            {/* <Select
              options={options}
              styles={style}
              isSearchable={false}
              placeholder={userData.state ? <div className='profile-details__placeholder--value'>{userData.state}</div> : <div className='profile-details__placeholder'>State</div>}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicatorLight
              }}
            /> */}
          </div>
          <div style={{ marginBottom: '15px' }} className={'profile-details'}>
            <label htmlFor="country">Country<span>*</span></label>
            {userData.country || 'Not Selected'}
            {/* <Select
              options={options}
              styles={style}
              isSearchable={false}
              placeholder={userData.country ? <div className='profile-details__placeholder--value'>{userData.country}</div> : <div className='profile-details__placeholder'>Country</div>}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicatorLight
              }}
            /> */}
          </div>
        </div>
        <div style={{display:'none'}} className={"col"}>
          <div className={'user-cover'}>
            <div className='user-cover__preview'>
              <img src={userData.cover ? userData.cover : PlaceholderCover} alt={""} />
            </div>
            <div className="user-cover__upload__container">
              <label htmlFor={"user-cover"}>Edit</label>
              <input name={"user-cover"} id={"user-cover"} type={"file"} />
            </div>
          </div>
          <h5 style={{ marginTop: '58px' }}>Role</h5>
          <p>This helps us design the admin portal for you.</p>
          <hr style={{ marginTop: '11px', marginBottom: '16px' }} />
          <div className={'profile-details'}>
            <label htmlFor="userRole">Role<span>*</span></label>
            <Select
              defaultValue={{ "value": userData.userRole, "label": userData.userRole === USER_TYPE.BUSINESS_OWNER ? 'Super Admin' : userData.userRole === USER_TYPE.MARKETER ? 'Marketing' : 'Business Owner' }}
              options={options}
              styles={style}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
              }}
            />
          </div>
          {ssoCheck === false &&
            <>
              <h5 style={{ marginTop: '41px' }}>Password</h5>
              <p>Make sure this is secure</p>
              <hr style={{ marginTop: '9px', marginBottom: '17px' }} />
              <div style={{ marginBottom: '15px' }} className={'profile-details'}>
                <label htmlFor="password">Password<span>*</span></label>
                <input disabled type="password" name={'password'} id={"password"} placeholder={"Password"}
                  autoComplete={"new-password"} />
              </div>
              <div className={'profile-details'}>
                <label htmlFor="repeatPassword">Retype Password<span>*</span></label>
                <input disabled type="password" name={'repeatPassword'} id={"repeatPassword"}
                  placeholder={"Retype Password"} />
              </div>
            </>
          }
          <div style={{ marginTop: '21px' }} className={'profile-details__submit'}>
            <div className='btn-group'>
              <button style={{ marginRight: '10px' }} onClick={() => { setButtonConfirmPopup(true) }} className='btn-save'>SAVE</button>
              <button className='btn-cancel'>CANCEL</button>
            </div>
            <Popup style={{ maxWidth: '400px', height: '323px' }} closeBtn={false} trigger={buttonConfirmPopup} setTrigger={setButtonConfirmPopup}>
              <div className='popup__success'>
                <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.5 0C16.4242 0 0 16.4242 0 36.5C0 56.5757 16.4242 73 36.5 73C56.5757 73 73 56.5757 73 36.5C73 16.4242 56.5757 0 36.5 0V0ZM15.6421 26.0736C15.6421 23.2042 17.9885 20.8579 20.8579 20.8579C23.7273 20.8579 26.0736 23.2042 26.0736 26.0736C26.0736 28.943 23.7273 31.2893 20.8579 31.2893C17.9885 31.2844 15.6421 28.9381 15.6421 26.0736ZM53.9654 52.4012C49.2727 57.0938 42.7569 59.6992 36.5 59.6992C30.2431 59.6992 23.7273 57.3529 19.0346 52.4012C17.9934 51.36 17.9934 49.7958 19.0346 48.7497C20.0758 47.7085 21.64 47.7085 22.6861 48.7497C30.2481 56.3117 42.7619 56.3117 50.3189 48.7497C51.3601 47.7085 52.9242 47.7085 53.9704 48.7497C55.0116 49.7957 55.0116 51.3599 53.9655 52.4012H53.9654ZM52.1421 31.2843C49.2727 31.2843 46.9264 28.938 46.9264 26.0686C46.9264 23.1992 49.2727 20.8528 52.1421 20.8528C55.0115 20.8528 57.3579 23.1992 57.3579 26.0686C57.3579 28.938 55.0115 31.2843 52.1421 31.2843V31.2843Z" fill="#FFB30F" />
                </svg>
                <h3>Success</h3>
                <p>You now have full access. We can now send you Notification to your mobile.</p>
                <div className='btn-group'>
                  <button className='btn-save'>CONFIRM</button>
                  <button onClick={(e) => { e.preventDefault(); setButtonConfirmPopup(false) }} className='btn-cancel'>CANCEL</button>
                </div>
              </div>
            </Popup>
          </div>
          <div className={'profile-details__footer'}>
            <p>Delete this account mean that the user will not have access to any function on the desk and all content posted by them will be removed as well.</p>
            <div className='profile-details__footer__delete-account'>
              <button className='delete-link' onClick={() => { setButtonRemovePopup(true) }}>DELETE</button>
              <Popup style={{ maxWidth: '400px' }} closeBtn={false} trigger={buttonRemovePopup} setTrigger={setButtonRemovePopup}>
                <div className='popup__delete'>
                  <h3>Delete account</h3>
                  <p>Are you sure you want to delete your account?</p>
                  <p>Please type <strong>DELETE</strong> to confirm.</p>
                  <input disabled type="text" placeholder='Type here' />
                  <div className='btn-group'>
                    <button className='btn-save'>CONFIRM</button>
                    <button onClick={(e) => { e.preventDefault(); setButtonRemovePopup(false) }} className='btn-cancel'>CANCEL</button>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Profile