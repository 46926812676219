import { useState } from 'react';
import './style.scss';
import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import Popup from '../../../../components/Popup/Popup';

function StoreConfiguation() {
    const [buttonPopup, setButtonPopup] = useState(false);

    const [zones, setZones] = useState([
        { value: 'Broken Hill', label: 'Broken Hill' },
        { value: 'Yum Zone 1', label: 'Yum Zone 1' },
        { value: 'Yum Zone 3', label: 'Yum Zone 3' },
        { value: 'ZC1', label: 'ZC1' },
        { value: 'ZC2', label: 'ZC2' },
        { value: 'ZC3', label: 'ZC3' },
        { value: 'ZC5', label: 'ZC5' },
        { value: 'ZC6', label: 'ZC6' },
        { value: 'ZC11', label: 'ZC11' },
        { value: 'ZC12', label: 'ZC12' },
        { value: 'ZC13', label: 'ZC13' }
    ]);

    const options = [
        { value: 'State', label: 'State' }
    ]
    const optionsZone = [
        { value: 'select-zone', label: 'Select Zones' }
    ]

    const optionState = [
        { value: 'act', label: 'ACT' },
        { value: 'nsw', label: 'NSW' },
        { value: 'nsw_vic', label: 'NSW VIC' },
        { value: 'sa', label: 'SA' },
        { value: 'sa_vic', label: 'SA VIC' },
        { value: 'vic', label: 'VIC' },
        { value: 'nt', label: 'NT' },
        { value: 'qld', label: 'QLD' },
        { value: 'tas', label: 'TAS' },
        { value: 'wa', label: 'WA' }
    ]

    const addZone = (e) => {
        let zoneTitle = document.querySelector('#zoneTitle').value;
        setZones([...zones, { value: zoneTitle, label: zoneTitle }]);
        setButtonPopup(false);
    }

    return (
        <div className='add-edit-stores__store-configuration'>
            <h3>Outlet/Store Configuation</h3>
            <p>Deatils to be added</p>
            <hr />
            <div className='add-edit-stores__store-configuration__store-name'>
                <h4>Store Name</h4>
                <input id={"storeName"} placeholder='Add Name' />
            </div>
            <div className='add-edit-stores__store-configuration__usn'>
                <h4>USN -unique store number</h4>
                <input id={"usn"} placeholder='0' />
            </div>
            <div className='add-edit-stores__store-configuration__address'>
                <h4>Address</h4>
                <input id={"address"} placeholder='Address' />
            </div>
            <div className='add-edit-stores__store-configuration__row'>
                <div className='add-edit-stores__store-configuration__col'>
                    <h4>Postcode</h4>
                    <input id={"postcode"} placeholder='Postcode' />
                </div>
                <div className='add-edit-stores__store-configuration__col'>
                    <h4>State</h4>
                    <CustomSelect id={"state"} width={'100%'} variation={'small'} data={optionState} />
                </div>
            </div>
            <div className='add-edit-stores__store-configuration__zone'>
                <h4>Zone</h4>
                <CustomSelect id={"zonesSelected"} width={'100%'} variation={'small'} data={zones} />
            </div>
            <button onClick={() => setButtonPopup(true)} className='add-edit-stores__store-configuration__add-zone'>Add Extra Zone</button>


            <Popup popupStyle={{ zIndex: '99' }} style={{ maxWidth: '554px' }} trigger={buttonPopup} setTrigger={setButtonPopup}>
                <div className="add-extra-zones__container">
                    <h6>Created Zone</h6>
                    <p>Create a title for the new Zone.</p>
                    <input id="zoneTitle" placeholder="Zone name" />
                    <button onClick={addZone} style={{ marginTop: '15px' }}>Create</button>
                </div>
            </Popup>
        </div>
    )
}

export default StoreConfiguation