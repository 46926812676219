import React, { useContext, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useTable } from 'react-table';
import GQL from '../../gql';
import DataTable from '../../components/DataTable/DataTable';

export default function CategoryManager()
{
    const { loading, error, data } = useQuery(GQL.Category.GET_ALL_CATEGORIES);
    const [createCategoryMutation, createCategoryState] = useMutation(GQL.Category.CREATE_CATEGORY);
    const [updateCategoryMutation, updateCategoryState] = useMutation(GQL.Category.UPDATE_CATEGORY);

    const columns = useMemo(
        () => [
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'URI',
            accessor: 'uri',
          },
        ],
        []
    );

    const onRecordChange = (record) => {
      let rec = record.record;
      delete rec.createdAt;
      if (record.isNew)
      {
        createCategoryMutation({
          variables: {
            createCategoryInput: rec
          }
        });
      }
      else
      {
        updateCategoryMutation({
          variables: {
            updateCategoryInput: rec
          }
        });
      }
    }

    if (loading)
    {
        return <p>Loading...</p>;
    }
    if (error)
    {
        return <p>Error! {error}</p>;
    }

    return (
        <DataTable data={data.getCategories} columns={columns} onRecordChange={onRecordChange}></DataTable>
    );
}