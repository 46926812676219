import React, { Component, Fragment } from "react";
import Select, { components } from 'react-select';

const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].value}`}</p>
                <p className="desc">Feb 20th, 2021</p>
            </div>
        );
    }

    return null;
};

export class CompaingFormSixth extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    cancel = e => {
        e.preventDefault();
        document.querySelector(".cancel").click();
      };

    constructor() {
        super();
        this.state = {
            disabled: false
        };
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };


    render() {
        const { isOpen, haveText } = this.state;
        const { values, handleChange } = this.props;

        document.querySelector('.new-compaign-popup-content').style.width = "707px";
        document.querySelector('.new-compaign-popup-content').style.maxHeight = "500px";
        document.querySelector('.new-compaign-popup-content').style.marginTop = "2%";
        document.querySelector('.new-compaign-popup-content .cancel svg').style.paddingRight = "20px";
        document.querySelector('.new-compaign-popup-content .cancel').style.display = "none";
        document.querySelector('.new-compaign-popup__popup').style.height = "auto";
        
        return (
            <Fragment>
                <div className="popup__success popup__success__six-steps  popup__success__six-steps--new-compaing">
                    <h5>New Campaign Name</h5>
                    <span>By saving this Media Test you can select it later on.</span>
                    <hr />
                    <div>
                        <h5>Step 01</h5>
                        <div>
                            <span>Promotional Name</span>
                            <span>
                                <strong>{values.title}</strong>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5>Step 02</h5>
                        <div>
                            <span>POD selected</span>
                            <span>
                                <strong>POD5</strong>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5>Step 03</h5>
                        <div>
                            <span>Campaign type</span>
                            <span>
                                <strong>None Nation</strong>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5>Step 04</h5>
                        <div>
                            <span>Test Market</span>
                            <span>
                                <strong>3748 Location Effected</strong>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5>Step 05</h5>
                        <div>
                            <span>Selected Location</span>
                            <span>
                                <strong>3748 Location Effected</strong>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h5>Step 06</h5>
                        <div>
                            <span>Selected Media </span>
                            <span>
                                <strong>17,000 Media</strong>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div className="button-group button-group-last">
                    <a
                        label="Back"
                        className="cancel-btn"
                        onClick={this.cancel}
                    >
                        Cancel
                    </a>
                    <a
                        label="Next"
                        className="next-btn"
                        onClick={this.cancel}
                    >
                        Save
                    </a>
                </div>
                </div >
            </Fragment >
        );
    }
    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleText = (ev) => {
        this.setState({
            haveText: ev.currentTarget.textContent
        })
    }
}

export default CompaingFormSixth;
