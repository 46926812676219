import React, { useState, useRef } from 'react';
import { Global, css } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { MagnifyingGlass } from  'react-loader-spinner';
import reactStringReplace from 'react-string-replace';
import useInput from '../../../hooks/useInput';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import useCaptureKeypress from '../../../hooks/useCaptureKeypress';
import { v4 as uuid } from 'uuid';

import './style.scss'

const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: flex-start;

    svg {
        margin-left: 10px;
        margin-top: 10px;
    }

    input {
        flex: 1 auto;
        padding: 2px 2px 2px 30px;
        color: #717579;
        font-size: 18px
    }

    button {
        padding: 0 10px;
        font-size: 20px;
        background-color: #fa3535;
        color: #fff;
        cursor: pointer;
        border: 0px;
        border-left: 1px solid #a0a0a0;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        &:hover {
            background-color: #dfdcdc;
            color: #000;
        }
    }
`;

const SearchResultsBarContainer = styled.ul`
    position: absolute;
    z-index: 10000;
    top: 35px;
    width: calc(100% + 2px);
    margin: 16px 0 0 -1px;

    border: 1px solid #a0a0a0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const SearchBarResult = styled.li`
    border: 1px solid #a0a0a0;
    border-bottom: 0px;
    background-color: #fff;
    cursor: pointer;
    height: 35px;

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        padding: 6px 0 0 10px;
        color: #717579;
        
        background-color: #fff;
        &:hover {
            background: #fa3535;
            color: #fff;
        }

        svg {
            margin-left: 20px;
        }
    }

    &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        a {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
`;

const CloseIcon = styled.svg`
    margin-left: -25px;
    padding-top: 5px;
`;

const SearchIcon = styled.svg`
    margin-right: -20px;
    z-index: 2;
`;

const CloseButtonWrapper = styled.a`
    margin-right: 10px;
`;

export default function SearchBar(
    placeholder = "Search...",
    searchURL,
    onSearch
)
{
    const [resultsContainerVisible, setResultsContainerVisible] = useState(false);
    const [isFetchingResults, setIsFetchingResults] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm, searchInput] = useInput('text',() => {
        if (searchResults.length)
        {
            setResultsContainerVisible(true);
        }
    },
    (e) => {
        if (e.target.value.length >= 2) {
            doSearch(e.target.value);
        }
    });
    const searchBarRef = useRef(null);
    const navigateTo = useNavigate();

    useOutsideAlerter(searchBarRef, () => {
        if (resultsContainerVisible && !isFetchingResults)
        {
            setResultsContainerVisible(false);
        }
    });

    useCaptureKeypress(event => {
        if (event.code == 'Escape'
        || event.code == 'Enter'
        || event.code == 'NumpadEnter'
        && (resultsContainerVisible && !isFetchingResults))
        {
            setResultsContainerVisible(false);
        }
    });

    const onSearchButtonClick = (e) => {
        e.preventDefault();
        if (searchTerm.trim() == '')
        {
            return;
        }
        if (isFetchingResults)
        {
            abortController.abort();
        }
        navigateTo(`/search-results/${searchTerm}`);
    }

    const abortController = new AbortController();
    const doSearch = (searchTerm) => {
        if (isFetchingResults)
        {
            abortController.abort();
        }
        setIsFetchingResults(true);
        setResultsContainerVisible(true);
        fetch(process.env.REACT_APP_API_SERVER + 'lsm-search-asset-autocomplete', {
            method: 'POST',
            signal: abortController.signal,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                searchQuery: searchTerm
            })
        })
        .then(response => response.json())
        .then(data => {
            setSearchResults(data);
            setIsFetchingResults(false);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const clearResults = (e) => {
        e.preventDefault();
        setSearchTerm('');
        setResultsContainerVisible(false);
        setSearchResults([]);
    }

    const shouldDisplay = (state) => {
        return {
            display: state ? '' : 'none'
        };
    }

    const onResultClick = (e) => {
        setResultsContainerVisible(false);
    }

    return (
        <form ref={searchBarRef} className="header__search-bar">
            <SearchBarContainer>
                <SearchIcon width="40" height="40" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                </SearchIcon>
                {searchInput}
                <CloseButtonWrapper href="#" onClick={clearResults}>
                    <CloseIcon style={shouldDisplay(searchTerm)} width="30" height="30" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.58 12 5 17.58 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </CloseIcon>
                </CloseButtonWrapper>
                <button onClick={onSearchButtonClick}>Search</button>
            </SearchBarContainer>
            <SearchResultsBarContainer style={shouldDisplay(resultsContainerVisible)}>
                <SearchBarResult key={uuid()} style={shouldDisplay(isFetchingResults)}>
                    <MagnifyingGlass
                        visible={true}
                        height="40"
                        width="40"
                        ariaLabel="MagnifyingGlass-loading"
                        wrapperStyle={{}}
                        wrapperClass="MagnifyingGlass-wrapper"
                        glassColor = '#c0efff'
                        color = '#e15b64'
                    />
                </SearchBarResult>
                <SearchBarResult key={uuid()} style={shouldDisplay(resultsContainerVisible && !isFetchingResults && !searchResults.length)}>No Results...</SearchBarResult>
                {searchResults.map((record) => (
                    <SearchBarResult key={uuid()}>
                        <Link to={record.uri} onClick={onResultClick}>
                            {reactStringReplace(record.name,searchTerm,(match, i) => (
                                <strong>{match}</strong>
                            ))}
                        </Link>
                    </SearchBarResult>
                ))}
            </SearchResultsBarContainer>
        </form>
    )
}