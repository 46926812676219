import { LineChart, Line, CartesianGrid, ResponsiveContainer } from 'recharts';

import './style.scss';
import { ReactComponent as Icon } from './icon.svg';


function FilesWidget({ data, title }) {
  return (
    <div className='widget__files'>
      <div className='widget__files__header'>
        <div>
          <h6>{title.replaceAll('-', ' ').toUpperCase()} - 70%</h6>
          <span>Files: 15,100 - 17,000</span>
        </div>
        <Icon />
      </div>
      <div className='widget__files__body'>
        {data.length > 0 &&
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} >
              <CartesianGrid stroke="#D7D7D7" strokeWidth={1} />
              <Line type="monotone" dataKey="pv" stroke="#FB6D3B" strokeWidth={6} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        }
      </div>
      <div className='widget__files__footer'>
        <span>11/01/22</span>
        <span>11/01/22</span>
      </div>
    </div>
  )
}

export default FilesWidget