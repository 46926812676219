import { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";

import LSMList from '../../components/LSM/LSMList/LSMList'

import { TabTitle } from '../../utils/TabTitle';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import './style.scss';
import { AuthContext } from '../../context/auth';
import { USER_TYPE } from '../../defs/User';

function Search(props) {
  TabTitle(props.title || 'KFC LSM');

  let optionsCoutry = [
    { value: 'all', label: 'All' },
    { value: 'australia', label: 'Australia' },
    { value: 'usa', label: 'USA' },
    { value: 'china', label: 'China' }
  ]

  let optionsState = [
    { value: 'all', label: 'All' },
    { value: 'NSW', label: 'NSW' },
    { value: 'ACT', label: 'ACT' },
    { value: 'SA', label: 'SA' },
    { value: 'VIC', label: 'VIC' },
    { value: 'NT', label: 'NT' },
    { value: 'TAS', label: 'TAS' },
    { value: 'WA', label: 'WA' }
  ]

  const optionsZones = [
    { value: 'Broken Hill', label: 'Broken Hill' },
    { value: 'Yum Zone 1', label: 'Yum Zone 1' },
    { value: 'Yum Zone 3', label: 'Yum Zone 3' },
    { value: 'ZC1', label: 'ZC1' },
    { value: 'ZC2', label: 'ZC2' },
    { value: 'ZC3', label: 'ZC3' },
    { value: 'ZC5', label: 'ZC5' },
    { value: 'ZC6', label: 'ZC6' },
    { value: 'ZC11', label: 'ZC11' },
    { value: 'ZC12', label: 'ZC12' },
    { value: 'ZC13', label: 'ZC13' }
  ]

  const optionsStores = [
    {
      "label": "401 Swanston Street",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Aberfoyle Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Acacia Ridge",
      "priceZone": "ZC1"
    },
    {
      "label": "Airport West",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Albany",
      "priceZone": "ZC12"
    },
    {
      "label": "Albany Creek",
      "priceZone": "ZC1"
    },
    {
      "label": "Albion Park 2",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Albury",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Alice Springs",
      "priceZone": "ZC11"
    },
    {
      "label": "Altona North",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Annandale",
      "priceZone": "ZC3"
    },
    {
      "label": "Annerley",
      "priceZone": "ZC1"
    },
    {
      "label": "Ararat",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Armadale",
      "priceZone": "ZC11"
    },
    {
      "label": "Armidale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Arndale Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Artarmon",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Arundel",
      "priceZone": "ZC2"
    },
    {
      "label": "Ascot",
      "priceZone": "ZC11"
    },
    {
      "label": "Ashburton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ashfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ashfield Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ashmore",
      "priceZone": "ZC2"
    },
    {
      "label": "Aspley",
      "priceZone": "ZC1"
    },
    {
      "label": "Asquith",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Atherton",
      "priceZone": "ZC3"
    },
    {
      "label": "Atwell",
      "priceZone": "ZC11"
    },
    {
      "label": "Australia Fair",
      "priceZone": "ZC6"
    },
    {
      "label": "Ayr",
      "priceZone": "ZC3"
    },
    {
      "label": "Bacchus Marsh",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bagot Road",
      "priceZone": "ZC13"
    },
    {
      "label": "Bairnsdale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Baldivis",
      "priceZone": "ZC11"
    },
    {
      "label": "Ballan Service Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ballarat",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ballina",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ballina Travel Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Banksia Grove",
      "priceZone": "ZC11"
    },
    {
      "label": "Bankstown South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bankstown Square Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bateau Bay",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Batemans Bay",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bathurst",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Baulkham Hills",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bayswater",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Beaudesert",
      "priceZone": "ZC2"
    },
    {
      "label": "Beechboro",
      "priceZone": "ZC11"
    },
    {
      "label": "Beenleigh",
      "priceZone": "ZC1"
    },
    {
      "label": "Bega",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Belconnen Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Beldon",
      "priceZone": "ZC11"
    },
    {
      "label": "Belmont",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Belmont Forum",
      "priceZone": "ZC11"
    },
    {
      "label": "Belmont VIC",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Benalla",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bendigo",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bendigo Marketplace",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bennets Green",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Benowa",
      "priceZone": "ZC2"
    },
    {
      "label": "Berrinba",
      "priceZone": "ZC1"
    },
    {
      "label": "Berwick South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bethania",
      "priceZone": "ZC1"
    },
    {
      "label": "Biloela",
      "priceZone": "ZC3"
    },
    {
      "label": "Birkdale",
      "priceZone": "ZC1"
    },
    {
      "label": "Blacktown",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Blacktown Mega Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bligh Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bomaderry",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bondi Junction Eastgate",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Bonnyrigg",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Booval",
      "priceZone": "ZC1"
    },
    {
      "label": "Bourke Street Melbourne",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "BP Caboolture North",
      "priceZone": "ZC6"
    },
    {
      "label": "BP Caboolture South",
      "priceZone": "ZC6"
    },
    {
      "label": "Brassall",
      "priceZone": "ZC1"
    },
    {
      "label": "Bridgewater",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Brighton TAS",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Brimbank",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Brisbane Airport",
      "priceZone": "ZC1"
    },
    {
      "label": "Broadmeadow (Drive Thru Only)",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Broadmeadows",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Broadmeadows Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Broadway",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Broken Hill",
      "priceZone": "Broken Hill"
    },
    {
      "label": "Browns Plains",
      "priceZone": "ZC1"
    },
    {
      "label": "Buderim",
      "priceZone": "ZC2"
    },
    {
      "label": "Bunbury Forum",
      "priceZone": "ZC12"
    },
    {
      "label": "Bunbury South",
      "priceZone": "ZC12"
    },
    {
      "label": "Bundaberg",
      "priceZone": "ZC3"
    },
    {
      "label": "Bundaberg East",
      "priceZone": "ZC3"
    },
    {
      "label": "Bundoora",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Burleigh Waters",
      "priceZone": "ZC2"
    },
    {
      "label": "Burnie",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Burnley",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Burpengary",
      "priceZone": "ZC2"
    },
    {
      "label": "Burwood East",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Burwood Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Busselton",
      "priceZone": "ZC12"
    },
    {
      "label": "Butler",
      "priceZone": "ZC11"
    },
    {
      "label": "Byford",
      "priceZone": "ZC11"
    },
    {
      "label": "Caboolture",
      "priceZone": "ZC2"
    },
    {
      "label": "Caboolture City",
      "priceZone": "ZC2"
    },
    {
      "label": "Cairns",
      "priceZone": "ZC3"
    },
    {
      "label": "Cairns Central Food Court",
      "priceZone": "ZC3"
    },
    {
      "label": "Calamvale",
      "priceZone": "ZC1"
    },
    {
      "label": "Calder Inbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Calder Outbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Caloundra",
      "priceZone": "ZC2"
    },
    {
      "label": "Cameron Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Campbelltown",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Campbelltown Mall Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Campsie",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Canadian",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Canberra City",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Capalaba",
      "priceZone": "ZC1"
    },
    {
      "label": "Capalaba Central Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Capalaba Park Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Carindale",
      "priceZone": "ZC1"
    },
    {
      "label": "Carindale Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Carlingford Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Carlton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Carnes Hill",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Caroline Springs",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Carrum Downs",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Casino",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Castle Towers Level 1",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Casuarina",
      "priceZone": "ZC13"
    },
    {
      "label": "Casuarina Food Court",
      "priceZone": "ZC13"
    },
    {
      "label": "Caulfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Centenary",
      "priceZone": "ZC1"
    },
    {
      "label": "Cessnock",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chadstone Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Charlestown Square",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chatswood Chase",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chatswood Interchange",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chatswood Westfield Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chelsea Heights",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chermside Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Chinchilla",
      "priceZone": "ZC5"
    },
    {
      "label": "Chinderah",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chinderah North",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Chirnside Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "City Cross",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Claremont",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Clarkson",
      "priceZone": "ZC11"
    },
    {
      "label": "Clayfield",
      "priceZone": "ZC1"
    },
    {
      "label": "Clayton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Cleveland",
      "priceZone": "ZC1"
    },
    {
      "label": "Coburg",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Coffs Harbour Plaza",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Coffs Harbour South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Colac",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Colonnades",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Concord",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Coolalinga",
      "priceZone": "ZC13"
    },
    {
      "label": "Cooma",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Coomera Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Corio",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Cowra",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Craigieburn",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Cranbourne",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Cranbourne North",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Cranbrook",
      "priceZone": "ZC3"
    },
    {
      "label": "Cranebrook",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Crossroads",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Crown Casino Melbourne",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dalby",
      "priceZone": "ZC5"
    },
    {
      "label": "Dandenong Market",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dandenong Plaza",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dandenong South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dapto",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Darling Harbour Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Darlinghurst",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Deagon",
      "priceZone": "ZC1"
    },
    {
      "label": "Deception Bay",
      "priceZone": "ZC1"
    },
    {
      "label": "Dee Why",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Deer Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Derwent Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Devonport",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dickson",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dogswamp",
      "priceZone": "ZC11"
    },
    {
      "label": "Doncaster",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Doreen",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dubbo",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dubbo East",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dulwich Hill",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Dural",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eagleby",
      "priceZone": "ZC1"
    },
    {
      "label": "Eaglehawk",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Earlville",
      "priceZone": "ZC3"
    },
    {
      "label": "Earlville Food Court",
      "priceZone": "ZC3"
    },
    {
      "label": "Earlwood",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "East Brighton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "East Lismore",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "East Maitland",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "East Wodonga",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eastgardens Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eastlands Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eastlink Northbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eastlink Southbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eastwood",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Echuca",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Edmonton",
      "priceZone": "ZC3"
    },
    {
      "label": "Edwardstown",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Eight Mile Plains",
      "priceZone": "ZC1"
    },
    {
      "label": "Elizabeth",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Elizabeth Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Elizabeth Street Melbourne",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ellenbrook",
      "priceZone": "ZC11"
    },
    {
      "label": "Eltham",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Emerald",
      "priceZone": "ZC5"
    },
    {
      "label": "Emerton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Emu Plains",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Endeavour Hills",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Enfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Engadine",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Epping",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Epping Plaza Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Erina",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Erina Fair",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ermington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Everard Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Everton Park",
      "priceZone": "ZC1"
    },
    {
      "label": "Fairfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Fairy Meadow",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Falcon",
      "priceZone": "ZC11"
    },
    {
      "label": "Fawkner",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ferntree Gully",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Five Dock",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Flemington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Flowerdale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Footscray",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Forbes",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Forrestfield",
      "priceZone": "ZC11"
    },
    {
      "label": "Forster",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Fountain Gate Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Frankston Bayside Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Frankston South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Frenchs Forest",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Fulham Gardens",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Fyshwick",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Garbutt",
      "priceZone": "ZC3"
    },
    {
      "label": "Garden City FC Qld",
      "priceZone": "ZC6"
    },
    {
      "label": "Garden City FC WA",
      "priceZone": "ZC11"
    },
    {
      "label": "Gatton",
      "priceZone": "ZC2"
    },
    {
      "label": "Gawler",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "George Street Sydney",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Geraldton",
      "priceZone": "ZC12"
    },
    {
      "label": "Gladesville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Gladstone",
      "priceZone": "ZC3"
    },
    {
      "label": "Glen Innes",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Glen Waverley",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Glen Waverley Central",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Glendale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Glenelg",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Glenmore Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Golden Grove",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Goulburn",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Goulburn South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Grafton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Grafton Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Grand Central",
      "priceZone": "ZC2"
    },
    {
      "label": "Grand Plaza Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Granville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Green Hills Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Green Square",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Greenacre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Greensborough",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Greensborough Plaza Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Greenslopes",
      "priceZone": "ZC1"
    },
    {
      "label": "Griffith",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Grovedale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Grovely",
      "priceZone": "ZC1"
    },
    {
      "label": "Gundagai",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Gungahlin",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Gunnedah",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Gympie",
      "priceZone": "ZC3"
    },
    {
      "label": "Hallam",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hamilton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Harrisdale",
      "priceZone": "ZC11"
    },
    {
      "label": "Hastings",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hawker",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hawthorn",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Haymarket",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Haynes",
      "priceZone": "ZC11"
    },
    {
      "label": "Heidelberg",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Helensvale",
      "priceZone": "ZC2"
    },
    {
      "label": "Helensvale Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Hermit Park",
      "priceZone": "ZC3"
    },
    {
      "label": "Hervey Bay",
      "priceZone": "ZC3"
    },
    {
      "label": "Highett",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Highfields",
      "priceZone": "ZC2"
    },
    {
      "label": "Highpoint - Level 3",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Highpoint II",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hillcrest",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hindley Street",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hinkler Place Food Court",
      "priceZone": "ZC3"
    },
    {
      "label": "Hoppers Crossing",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hoppers Crossing 2",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hornsby Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Horsham",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hoxton Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hurstville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Hurstville Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Inala",
      "priceZone": "ZC1"
    },
    {
      "label": "Indooroopilly",
      "priceZone": "ZC1"
    },
    {
      "label": "Indooroopilly Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Ingham",
      "priceZone": "ZC3"
    },
    {
      "label": "Ingleburn",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Innaloo",
      "priceZone": "ZC11"
    },
    {
      "label": "Innisfail",
      "priceZone": "ZC3"
    },
    {
      "label": "Inverell",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Irymple",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Jerrabomberra",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Jesmond",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Jimboomba",
      "priceZone": "ZC2"
    },
    {
      "label": "Joondalup",
      "priceZone": "ZC11"
    },
    {
      "label": "Kalgoorlie",
      "priceZone": "ZC12"
    },
    {
      "label": "Kallangur",
      "priceZone": "ZC1"
    },
    {
      "label": "Kangaroo Flat",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kangaroo Point",
      "priceZone": "ZC1"
    },
    {
      "label": "Karawara",
      "priceZone": "ZC11"
    },
    {
      "label": "Karingal",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Karratha",
      "priceZone": "Yum Zone 3"
    },
    {
      "label": "Karrinyup",
      "priceZone": "ZC11"
    },
    {
      "label": "Kawana Food Court",
      "priceZone": "ZC2"
    },
    {
      "label": "Kedron",
      "priceZone": "ZC1"
    },
    {
      "label": "Keilor Downs",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kelmscott",
      "priceZone": "ZC11"
    },
    {
      "label": "Kelvin Grove",
      "priceZone": "ZC1"
    },
    {
      "label": "Kempsey",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Keperra",
      "priceZone": "ZC1"
    },
    {
      "label": "Kew",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Keysborough",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kilburn",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kilmore",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kincumber",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kingaroy",
      "priceZone": "ZC2"
    },
    {
      "label": "Kings Cross",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kings Meadow",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kings Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kingston",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kingsway",
      "priceZone": "ZC11"
    },
    {
      "label": "Kirwan",
      "priceZone": "ZC3"
    },
    {
      "label": "Knox City",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Knox City Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kogarah",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kooragang",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kotara",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kurri Kurri",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Kwinana",
      "priceZone": "ZC11"
    },
    {
      "label": "Lakehaven",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lakehaven Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lakelands",
      "priceZone": "ZC11"
    },
    {
      "label": "Lakemba",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lakes Entrance",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Launceston",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Laverton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lavington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lavington Square Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Legana",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Leongatha",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Leppington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Leppington Central",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lidcombe",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lilydale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lindfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lithgow",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Liverpool",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Liverpool Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Livingston",
      "priceZone": "ZC11"
    },
    {
      "label": "Logan Hyperdome",
      "priceZone": "ZC6"
    },
    {
      "label": "Loganholme",
      "priceZone": "ZC1"
    },
    {
      "label": "Lucas",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Lynbrook",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "MacArthur Central Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Macarthur Shopping Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mackay",
      "priceZone": "ZC3"
    },
    {
      "label": "Mackay Food Court",
      "priceZone": "ZC3"
    },
    {
      "label": "Mackay North",
      "priceZone": "ZC3"
    },
    {
      "label": "Macquarie Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mandurah",
      "priceZone": "ZC11"
    },
    {
      "label": "Manly Vale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Marangaroo",
      "priceZone": "ZC11"
    },
    {
      "label": "Marden",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mareeba",
      "priceZone": "ZC3"
    },
    {
      "label": "Marion",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Marion Mall Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Maroochydore",
      "priceZone": "ZC2"
    },
    {
      "label": "Marsden",
      "priceZone": "ZC1"
    },
    {
      "label": "Marsden Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Marulan",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Maryborough QLD",
      "priceZone": "ZC3"
    },
    {
      "label": "Maryborough VIC",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mawson Lakes",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mayfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Maylands",
      "priceZone": "ZC11"
    },
    {
      "label": "Mcgraths Hill",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Meadowbrook",
      "priceZone": "ZC1"
    },
    {
      "label": "Mean Fiddler",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Melbourne Central Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Melton Caltex",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Melton Phoenix",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Melton South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Melville",
      "priceZone": "ZC11"
    },
    {
      "label": "Menai",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mentone",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mermaid Beach",
      "priceZone": "ZC2"
    },
    {
      "label": "Merrylands",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Merrylands Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Miami",
      "priceZone": "ZC2"
    },
    {
      "label": "Midland",
      "priceZone": "ZC11"
    },
    {
      "label": "Midvale",
      "priceZone": "ZC11"
    },
    {
      "label": "Mildura",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mill Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Milperra",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Minchinbury",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mingara",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Minto",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Miranda",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Miranda Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mirrabooka",
      "priceZone": "ZC11"
    },
    {
      "label": "Mitcham",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mittagong Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Modbury",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Moe",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mona Vale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Moonee Ponds",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mooroolbark",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Moranbah",
      "priceZone": "ZC5"
    },
    {
      "label": "Morayfield Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Moree",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Morisset",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Morley",
      "priceZone": "ZC11"
    },
    {
      "label": "Morningside",
      "priceZone": "ZC1"
    },
    {
      "label": "Mornington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Morwell",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mowbray",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Annan",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Barker",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Druitt",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Druitt Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Gambier City",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Gambier Market Place (Drive Thru Only)",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mt Isa",
      "priceZone": "ZC5"
    },
    {
      "label": "Mt Pleasant",
      "priceZone": "ZC3"
    },
    {
      "label": "Mudgee",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Mundaring",
      "priceZone": "ZC11"
    },
    {
      "label": "Munno Para",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Murray Bridge",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Murwillumbah",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Muswellbrook",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Myer Centre",
      "priceZone": "ZC6"
    },
    {
      "label": "Myer Centre Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Nambour",
      "priceZone": "ZC2"
    },
    {
      "label": "Nambucca Heads",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Narellan",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Narellan Town Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Narrabri",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Narre Warren",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Nathan Plaza",
      "priceZone": "ZC3"
    },
    {
      "label": "Nerang",
      "priceZone": "ZC2"
    },
    {
      "label": "Newcomb",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Nicholls",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Niddrie",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Noarlunga",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Noble Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Noosaville",
      "priceZone": "ZC2"
    },
    {
      "label": "North Geelong",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "North Lakes",
      "priceZone": "ZC1"
    },
    {
      "label": "North Lakes Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "North Ryde",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "North Wagga",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "North Wyong",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Northam",
      "priceZone": "ZC11"
    },
    {
      "label": "Northgate",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Northland Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Northmead",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Nowra",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Nunawading",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Oakleigh",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ocean Grove",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Officer Arena",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Officer Inbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Officer Outbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Orange",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ormeau",
      "priceZone": "ZC2"
    },
    {
      "label": "Ormond",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Oxenford",
      "priceZone": "ZC2"
    },
    {
      "label": "Pacific Fair",
      "priceZone": "ZC6"
    },
    {
      "label": "Pagewood",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Pakenham",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Palais",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Palmerston",
      "priceZone": "ZC13"
    },
    {
      "label": "Paralowie",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Park Ridge",
      "priceZone": "ZC1"
    },
    {
      "label": "Parkes",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Parklea",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Parramatta L1 Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Parramatta L5 Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Penlink Inbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Penlink Outbound",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Pennant Hills",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Penrith Leagues",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Penrith Plaza Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Penrith South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Pimpama",
      "priceZone": "ZC2"
    },
    {
      "label": "Pinjarra North",
      "priceZone": "ZC11"
    },
    {
      "label": "Pinjarra South",
      "priceZone": "ZC11"
    },
    {
      "label": "Plainland",
      "priceZone": "ZC2"
    },
    {
      "label": "Playford",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Plenty Valley Westfield",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Plumpton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Point Cook",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Port Adelaide",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Port Augusta",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Port Lincoln",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Port Macquarie",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Port Macquarie II",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Port Pirie",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Portland",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Prahran",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Preston",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Prospect",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Pulteney Street",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Punchbowl",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Queanbeyan",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Randwick",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Rasmussen",
      "priceZone": "ZC2"
    },
    {
      "label": "Raymond Terrace",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Redbank",
      "priceZone": "ZC1"
    },
    {
      "label": "Redbank Plains",
      "priceZone": "ZC1"
    },
    {
      "label": "Redcliffe",
      "priceZone": "ZC1"
    },
    {
      "label": "Redlynch",
      "priceZone": "ZC3"
    },
    {
      "label": "Renmark",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Reservoir",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Revesby",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Reynella",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Richmond South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ringwood",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Riverdale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Riverlink Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Riverstone",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Riverwood",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Robina Drive Thru",
      "priceZone": "ZC2"
    },
    {
      "label": "Robina Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Rockdale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Rockhampton Food Court",
      "priceZone": "ZC3"
    },
    {
      "label": "Rockhampton North",
      "priceZone": "ZC3"
    },
    {
      "label": "Rockhampton South",
      "priceZone": "ZC3"
    },
    {
      "label": "Rockingham Beach",
      "priceZone": "ZC11"
    },
    {
      "label": "Roma",
      "priceZone": "ZC5"
    },
    {
      "label": "Rosebud",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Rosehill",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Roselands Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Rosny Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Rouse Hill Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Rowville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Roxburgh Park",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Runaway Bay",
      "priceZone": "ZC2"
    },
    {
      "label": "Rutherford",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Salamander Bay",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Salisbury",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sans Souci",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Seaford Rise",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sebastopol",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Seven Hills",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Seymour",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sheidow Park Hallets Cove",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Shellharbour",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Shellharbour Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Shepparton City",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Shepparton North",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Shepparton South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Singleton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sippy Downs",
      "priceZone": "ZC2"
    },
    {
      "label": "Smithfield",
      "priceZone": "ZC3"
    },
    {
      "label": "Sorell",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "South Lake",
      "priceZone": "ZC11"
    },
    {
      "label": "South Perth",
      "priceZone": "ZC11"
    },
    {
      "label": "Southland Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Southport",
      "priceZone": "ZC2"
    },
    {
      "label": "Spearwood",
      "priceZone": "ZC11"
    },
    {
      "label": "Spring Farm",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Springfield",
      "priceZone": "ZC1"
    },
    {
      "label": "Springfield Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Springfield Parkway",
      "priceZone": "ZC1"
    },
    {
      "label": "Springvale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Springwood",
      "priceZone": "ZC1"
    },
    {
      "label": "St Clair",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "St Kilda",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "St Leonards",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "St Peters",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Strath Village",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Strathpine",
      "priceZone": "ZC1"
    },
    {
      "label": "Strathpine Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Sturt Mall Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Subiaco",
      "priceZone": "ZC11"
    },
    {
      "label": "Sunbury",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sunnybank",
      "priceZone": "ZC1"
    },
    {
      "label": "Sunshine",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sunshine Market Place",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Surfers Paradise",
      "priceZone": "ZC2"
    },
    {
      "label": "Swan Hill",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sydenham",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sydney Airport",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Sydney Central",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tahmoor",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tamworth",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tamworth South",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tannum Sands",
      "priceZone": "ZC3"
    },
    {
      "label": "Taree",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Taree Service Centre",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tarneit",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Taylors Hill",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tea Tree Plaza Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "The Entrance",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "The Pines",
      "priceZone": "ZC2"
    },
    {
      "label": "Thomastown",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Thornbury",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Thornlie",
      "priceZone": "ZC11"
    },
    {
      "label": "Thornton",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tingalpa",
      "priceZone": "ZC1"
    },
    {
      "label": "Toormina",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Toowoomba",
      "priceZone": "ZC2"
    },
    {
      "label": "Toowoomba East",
      "priceZone": "ZC2"
    },
    {
      "label": "Top Ryde Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Toronto",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Torrensville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Traralgon",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Traralgon East",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Treendale",
      "priceZone": "ZC12"
    },
    {
      "label": "Truganina",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tuggerah Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tuggeranong",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tullamarine",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tumut",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Tweed City Food Court",
      "priceZone": "ZC6"
    },
    {
      "label": "Tweed Heads",
      "priceZone": "ZC2"
    },
    {
      "label": "Ulladulla",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Ulverstone",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Unanderra",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Upper Coomera",
      "priceZone": "ZC2"
    },
    {
      "label": "Urangan",
      "priceZone": "ZC3"
    },
    {
      "label": "Valley Metro",
      "priceZone": "ZC6"
    },
    {
      "label": "Vermont",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Victor Harbor",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Victoria Gardens Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Victoria Point",
      "priceZone": "ZC1"
    },
    {
      "label": "Villawood",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wagga Homebase",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wallan",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wallan 1 (Southbound)",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wallan 2 (Northbound)",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wangaratta",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wantirna",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warnbro",
      "priceZone": "ZC11"
    },
    {
      "label": "Warner",
      "priceZone": "ZC1"
    },
    {
      "label": "Warners Bay",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warragul",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warragul Queen Street",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warrawong",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warrnambool City",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warwick",
      "priceZone": "ZC2"
    },
    {
      "label": "Warwick Farm",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Warwick WA",
      "priceZone": "ZC11"
    },
    {
      "label": "Waterfront City Docklands",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Watergardens",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Welland Plaza",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wellington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wendouree",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wentworthville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Werribee",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Werribee Plaza",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Werrington",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "West End Plaza Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "West Gosford",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "West Lakes",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Westfield Geelong",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Westgate Port Melbourne",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Westpoint Food Court",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wetherill Park (Outside)",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wetherill Park Mall",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Whitfords",
      "priceZone": "ZC11"
    },
    {
      "label": "Whyalla",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Willetton",
      "priceZone": "ZC11"
    },
    {
      "label": "William Street",
      "priceZone": "ZC11"
    },
    {
      "label": "Wilsonton",
      "priceZone": "ZC2"
    },
    {
      "label": "Woden",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wodonga",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wollongong Central",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wonthaggi",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Woodbine",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Woodgrove",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Woodlands",
      "priceZone": "ZC3"
    },
    {
      "label": "Woodridge",
      "priceZone": "ZC1"
    },
    {
      "label": "Woy Woy",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wurtulla",
      "priceZone": "ZC2"
    },
    {
      "label": "Wyndham Vale",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Wynnum",
      "priceZone": "ZC1"
    },
    {
      "label": "Wyoming",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Yagoona",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Yamanto",
      "priceZone": "ZC1"
    },
    {
      "label": "Yarraville",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Yarrawonga",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Yass",
      "priceZone": "Yum Zone 1"
    },
    {
      "label": "Yeppoon",
      "priceZone": "ZC3"
    },
    {
      "label": "Young",
      "priceZone": "Yum Zone 1"
    }
  ]

  const [searchQuery, setSearchQuery] = useState([])
  const [searchQueryPods, setSearchQueryPods] = useState([])

  const location = useLocation();
  console.log(location.state)

  useEffect(() => {
    console.log(location.state)
    fetch(process.env.REACT_APP_API_SERVER + 'lsm-search-list', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        searchQuery: location.state,
      })
    })
      .then(response => response.json())
      .then(result => {
        setSearchQuery(result)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [location]);

  const { user, logout } = useContext(AuthContext);

  return (
    <div className={'search'}>

      {user.userRole === USER_TYPE.BUSINESS_OWNER ? (
        <></>
      ) : (
        <div className={'search__filter-list'}>
          <CustomSelect
            defaultValue={{ label: 'Country' }}
            width={'221px'}
            data={optionsCoutry}
          />
          <CustomSelect
            defaultValue={{ label: 'State' }}
            width={'115px'}
            data={optionsState}
          />
          <CustomSelect
            defaultValue={{ label: 'Zones' }}
            width={'167px'}
            data={optionsZones}
          />
          <CustomSelect
            defaultValue={{ label: 'Stores' }}
            width={'206px'}
            data={optionsStores}
          />
          <button>Search</button>
        </div>
      )
      }
      <div className='search__result'>
        {(() => {
          if (location.state) {
            return (
              <div className={'search__body-query'}>

                <h5 className={'search-body-title'}>LSM</h5>

                {(() => {
                  if (searchQuery.length > 0) {
                    return (
                      <LSMList
                        enablePopup={true}
                        enableTags={false}
                        enableEmailFile={true}
                        enableEmailPrintQuote={false}
                        enableHires={true}
                        enableLowRes={false}
                        data={searchQuery}
                      />
                    )
                  } else {
                    return (
                      <span className={'search-body-no-result'}>No results</span>
                    )
                  }
                })()}
              </div>
            )
          } else {
            return (
              <span>No results</span>
            )
          }
        })()}
      </div>
    </div >
  )
}

export default Search