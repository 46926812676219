import React from 'react';
import Select, { components } from 'react-select';

import { PageDescription } from '../../../utils/PageDescription';
import { TabTitle } from '../../../utils/TabTitle';
import './Email.scss';

function Email({ title }) {
  PageDescription('Email', 'Settings');
  TabTitle(title)

  let options = [
    { value: 'registration', label: 'Registration' },
    { value: 'forgot-password', label: 'Forgot Password' },
    { value: 'forgot-username', label: 'Forgot Username' },
    { value: 'request-login', label: 'Request Login' }
  ]

  const style = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      maxWidth: '379px',
      padding: 0,
      marginBottom: 0,
      marginTop: '0',
      boxShadow: 'none',
      border: '1px solid #EEEEEE',
      borderTop: '0',
      fontWeight: '600',
      fontSize: '16px',
      transition: 'all .2s ease-in-out',
      borderRadius: '0',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      borderRadius: '0',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: '#fff',
      padding: '0 20px',
      cursor: 'pointer',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#F40027'
      }
    }),
    control: (base, state) => ({
      ...base,
      padding: '0 20px',
      border: '1px solid #EEEEEE',
      borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      boxShadow: '0 !important',
      cursor: 'pointer',
      width: '100%',
      maxWidth: '379px',
      height: '60px',
      borderRadius: '10px',
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '10px',
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '10px',
      fontWeight: '600',
      fontSize: '16px',
      transition: '.2s easy-in-out',
      '&:hover': {
        borderColor: '1px solid #EEEEEE !important',
        borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0'
    })
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
        </svg>
      </components.DropdownIndicator>
    );
  };

  let changeEmailTemplate = (e) => {
    console.log(e.value);
    let title = document.querySelector('.email-body__content--title');
    let code = document.querySelector('.email-body__content--code');
    let bottom = document.querySelector('.email-body__content--bottom');

    if(e.value === 'registration') {
      title.placeholder = 'Email verification';
      code.innerText = 'Verification code';
      bottom.placeholder = "Didn't register on the website? You can ignore this message.";
    } else if(e.value === 'forgot-password') {
      title.placeholder = 'Forgot Password';
      code.innerText = 'Link';
      bottom.placeholder = "Didn't forgot password on the website? You can ignore this message.";
    } else if(e.value === 'forgot-username') {
      title.placeholder = 'Forgot Username';
      code.innerText = 'Username';
      bottom.placeholder = "Didn't forgot username on the website? You can ignore this message.";
    } else if(e.value === 'request-login') {
      title.placeholder = 'Request Login';
      code.innerText = 'Your profile has been successfully verified.';
      bottom.placeholder = "Didn't request login on the website? You can ignore this message.";
    }

  }

  return (
    <div className='settings__email'>
      <Select
        defaultValue={options[0]}
        options={options}
        styles={style}
        isSearchable={false}
        onChange={changeEmailTemplate}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: DropdownIndicator
        }}
      />
      <div className="email-body">
        <div className="email-body__content">
          <input type="text" className="email-body__content--title" placeholder="Email verification" />
          <span className="email-body__content--code">Verification code</span>
          <input className="email-body__content--bottom" type="text" placeholder="Didn't register on the website? You can ignore this message." />
        </div>
      </div>
    </div>
  )
}

export default Email