import './style.scss';
import LSMCard from '../LSMCard/LSMCard';


function isAudio(filename) {
    return (filename.split('.').pop() === 'wav'
    || filename.split('.').pop() === 'mp3');
}

function isVideo(filename) {
    return (filename.split('.').pop() === 'mp4'
    || filename.split('.').pop() === 'mkv');
}

function isPdf(filename) {
    return filename.split('.').pop() === 'pdf';
}

function isImage(filename) {
    return (filename.split('.').pop() === 'jpg'
    || filename.split('.').pop() === 'png');
}


function LSMList({
    refresh,
    children,
    data = [],
    enablePopup = true,
    enableTags = true,
    enableEmailFile = true,
    enableEmailPrintQuote = true,
    enableHires = true,
    enableSave = false,
    enableReplace = true,
    enableDelete = true,
    enableEditName = true
}) {
    let inner = null;
    const doRefresh = refresh;

    if (data.length)
    {
        inner = data.map
        (
            (card, index) => {
                const url = `/static/${card.file}`;
                let placeholder;
                if (card.data?.placeholder)
                {
                    placeholder = `/static/${card.data.placeholder}`;
                    // placeholder = null;
                }
                //Force null for PDF so that LSMCard will genereate a thumbnail.
                else if (isPdf(card.fileName))
                {
                    placeholder = null;
                }
                else if (isAudio(card.fileName)
                || isVideo(card.fileName)
                || isImage(card.fileName))
                {
                    placeholder = url;
                }

                return (
                    <LSMCard
                        key={index}
                        asset={card}
                        name={card.name}
                        safeName={card.safeName}
                        assetId={card.id}
                        hiResUrl={url}
                        placeholder={placeholder}
                        editorType={card.editorType}
                        enablePopup={enablePopup}
                        enableTags={enableTags}
                        enableEmailFile={enableEmailFile}
                        enableEmailPrintQuote={enableEmailPrintQuote}
                        enableHires={enableHires}
                        enableReplace={enableReplace}
                        enableDelete={enableDelete}
                        enableSave={enableSave}
                        enableEditName={enableEditName}
                        tags={card.tags}
                        onDelete={doRefresh}
                    />
                )}
        );
    }
    else
    {
        inner = children;
    }

    return (
        <div className='lsm-cards'>
            {inner}
        </div>
    )
}

export default LSMList