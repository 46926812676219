import {Link} from "react-router-dom";

import {ReactComponent as RequestLoginIcon} from "../../assets/icons/RequestLoginBanner/RequestLoginIcon.svg";
import './RequestLoginBanner.scss';

function RequestLoginBanner() {
    return (
        <div className={'request-login-banner'}>
            <h4>Want an Account?</h4>
            <Link to={'/request-login'}>
                <RequestLoginIcon/>
            </Link>
        </div>
    )
}

export default RequestLoginBanner