import React, { useState } from 'react';
import Select, { components } from "react-select";
import styled from '@emotion/styled';
import EditableSidebar, { DropdownStyle, DropdownIndicator } from '../LSM/LSMCard/EditableSidebar';
import Popup from './Popup';
import StoreSelector from '../Select/StoreSelector';

const Form = styled.form`
flex: auto;
display: flex;
flex-direction: row;
gap: 20px;
/* justify-content: space-evenly; */
.column {
    width: 350px;
}
.field {
    margin-bottom: 10px;
    h6 {
        font-weight: 700;
        font-size: 16px;
        color: #171b1e;
        text-align: left;
        margin-bottom: 5px;
    }
    p {
        font-size: 14px;
        color: #777373;
    }
    input[type=text],input[type=number],input[type=date] {
        width: 300px;
        display: flex;
        align-items: center;
        border: 1px solid #eee;
        border-radius: 10px;
        height: 40px;
        box-sizing: border-box;
        padding: 10px;
        padding-left: 15px;
        outline: none;
        font-size: 16px;
        color: #171b1e;
        &.invalid {
            border: 1px solid #fb6d3b;
            &::placeholder {
                color: #fb6d3b;
            }
        }
        &::placeholder {
            color: #171b1e;
        }
        &.ReactTags__tagInputField {
            background-color: transparent;
        }
    }
    input[type=number],input[type=date] {
        width: 200px;
    }
    input[type=checkbox] + label {
        margin-left: 5px;
        color: #717579;
    }
    textarea {
        width: 300px;
        border: 1px solid #eee;
        border-radius: 10px;
        height: 100px;
        box-sizing: border-box;
        padding: 10px;
        padding-left: 15px;
        outline: none;
        font-size: 16px;
        color: #171b1e;
    }
    .react-tags-wrapper {
        height: 100px;
    }
}
`;

export default function RequestPrintPopup(props) {

    const { trigger, setTrigger, style, popupStyle, closeBtn, customClass, onSendEmail } = props;
    const popupProps = {trigger, setTrigger, style, popupStyle, closeBtn, customClass};

    const [name, setName] = useState();
    const [contactNumber, setContactNumber] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [deliveryDate, setDeliveryDate] = useState();
    const [store, setStore] = useState();
    const [quantity, setQuantity] = useState();

    const onCancel = () => {
        if (typeof setTrigger == 'function')
        {
            setTrigger(false);
        }
    }
    
    const onSend = () => {
        onSendEmail({
            message,
            deliveryDate,
            store,
            quantity,
            name,
            contactNumber,
            email
        });
    }

    const onSelectStore = (selected) => {
        setStore(selected);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        return false;
    }

    return (
        <Popup {...popupProps} style={{maxWidth:'700px'}}>
            <Form onSubmit={onSubmit}>
                <div className="column">
                    <p>Enter your contact information incase the printer needs to get in touch with you.</p>
                    <div className="field">
                        <h6>Name</h6>
                        <input type="text" name="name" placeholder="Your Name" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                    </div>
                    <div className="field">
                        <h6>Contact Number</h6>
                        <input type="text" name="contactNumber" placeholder="Your Contact Number" value={contactNumber} onChange={(e) => setContactNumber(e.currentTarget.value)} />
                    </div>
                    <div className="field">
                        <h6>Email</h6>
                        <input type="text" name="email" placeholder="Your Email Address" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                    </div>
                    <div className="field">
                        <h6>Message</h6>
                        <p>Enter a message to the Printer</p>
                        <textarea name="message" placeholder="Type message here..." value={message} onChange={(e) => setMessage(e.currentTarget.value)}></textarea>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
                        <h6>Store</h6>
                        <StoreSelector
                            value={store}
                            onChange={onSelectStore}
                        />
                        {/* <Select
                            defaultValue={{
                                value: 'Store_Name',
                                label: 'Select your Store Name'
                            }}
                            isSearchable={true}
                            styles={DropdownStyle}
                            onChange={onSelectStore}
                            options={storeNameOptions}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: DropdownIndicator
                            }}
                        /> */}
                    </div>
                    <div className="field">
                        <h6>Insert QTY</h6>
                        <input type="number" name="insert-qty" placeholder="Print Quantity" onChange={(e) => setQuantity(e.currentTarget.value)} value={quantity} />
                    </div>
                    <div className="field">
                        <h6>Delived by</h6>
                        <input type="date" onChange={(e) => setDeliveryDate(e.currentTarget.value)} value={deliveryDate} />
                    </div>
                    <div className="button-group end">
                        <button className="common strong" onClick={onCancel}>Cancel</button>
                        <button className="common" onClick={onSend}>Send</button>
                    </div>
                </div>
            </Form>
        </Popup>
    );
}
