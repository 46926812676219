import React, { useState, useContext } from 'react'
import { AuthContext } from '../../../../context/auth';
import UploadPopup from '../../../../components/Popup/UploadPopup'
import { USER_TYPE } from '../../../../defs/User';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/UploadIcon.svg';
import MDEditor from '@uiw/react-md-editor';

function Description({
  title,
  description,
  category,
  locationState,
  onUpload
}) {
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const { user } = useContext(AuthContext);

  return (
    <div className="lsm__placeholder">
      <div>
        <h3>{title}</h3>
        <MDEditor.Markdown source={description} />
      </div>
      <div className="lsm__upload">
        {(user.userRole === USER_TYPE.ADMIN) && (
          <button className="btn-upload" onClick={() => setShowUploadPopup(true)} style={{ marginLeft: 'auto' }}>
            <UploadIcon />Upload Files
          </button>
        )}
        <UploadPopup
          category={category}
          trigger={showUploadPopup}
          setTrigger={setShowUploadPopup}
          onUpload={onUpload}
        />
      </div>
    </div>
  )
}

export default Description