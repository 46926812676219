import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useQuery, gql } from '@apollo/client';

const Container = styled.div`
    width: 800px;
    margin: 40px;

    button {
        padding: 5px;
        background-color: #ccccaa;
        color: #000;
        font-size: 18px;
    }
`;

const OutputContainer = styled.div`
    width: 10)%;
    display: flex;
    flex-direction: column;
`;

export default function Builder()
{
    const [output, setOutput] = useState('');

    const appendOutput = (text) => setOutput(
        <>
            {output}
            <p>{text}</p>
        </>
    );

    const onRun = () => {
        fetch(process.env.REACT_APP_API_SERVER + 'builder/sync-filesystem', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success)
            {
                appendOutput(data.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    
    const onTestCatMap = () => {
        fetch(process.env.REACT_APP_API_SERVER + 'builder/test-catmap', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            if (data.success)
            {
                appendOutput(data.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    // useEffect(() => {
    //     const query = gql`
    //         query($priceZoneId: ID!) {
    //             getStores(priceZoneId: $priceZoneId) {
    //                 title
    //                 contentPlanSchedule
    //             }
    //         }
    //     `;
    //     const { loading, error, data } = useQuery(query,
    //     {
    //         variables: { priceZoneId: str },
    //         onCompleted: (data) => setStoresData(data.getStores)
    //     });
    // });

    return (
        <Container>
            <button onClick={onRun}>Run</button>
            <button onClick={onTestCatMap}>Test Category Map</button>
            <OutputContainer>
                {output}
            </OutputContainer>
        </Container>
    )
}