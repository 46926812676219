import { useReducer, createContext } from 'react';
import TagManager from 'react-gtm-module';


const GoogleTagManagerContext = createContext();

function GoogleTagManagerProvider(props) {
    const { children,
        dataLayerName,
        gtmId,
        events = undefined,
        auth,
        preview
     } = props;

     TagManager.initialize({
        ...props,
    });

    const gtmSend = function(dataLayerName, dataLayer) {
        TagManager.dataLayer({
            dataLayer,
            dataLayerName
        });
    }

    return (
        <GoogleTagManagerContext.Provider value={{gtmSend}}>
            {children}
        </GoogleTagManagerContext.Provider>
    );
}

export default GoogleTagManagerProvider;