import { useState } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { v4 as uuid } from 'uuid';
import { DragDropContext } from 'react-beautiful-dnd';
import { CouponListDropZone } from '../../Sandbox/CouponList';
import VoucherGrid from '../../Sandbox/VoucherGrid';
import DraggableCoupon from '../../Sandbox/DraggableCoupon';

const couponData = [
    // {
    //     "id": "item-1",
    //     "src": "/static/General/icons/coupon-1.png"
    // },
    {
        "id": "item-2",
        "src": "/static/General/icons/coupon-2.png"
    },
    {
        "id": "item-3",
        "src": "/static/General/icons/coupon-3.png"
    },
    {
        "id": "item-4",
        "src": "/static/General/icons/coupon-4.png"
    },
    {
        "id": "item-5",
        "src": "/static/General/icons/coupon-5.png"
    },
    {
        "id": "item-6",
        "src": "/static/General/icons/coupon-6.png"
    },
    {
        "id": "item-7",
        "src": "/static/General/icons/coupon-7.png"
    },
    {
        "id": "item-8",
        "src": "/static/General/icons/coupon-8.png"
    },
    {
        "id": "item-9",
        "src": "/static/General/icons/coupon-9.png"
    },
    {
        "id": "item-10",
        "src": "/static/General/icons/coupon-10.png"
    },
    {
        "id": "item-11",
        "src": "/static/General/icons/coupon-11.png"
    },
    {
        "id": "item-12",
        "src": "/static/General/icons/coupon-12.jpg"
    },
    {
        "id": "item-SA1",
        "src": "/static/General/icons/coupon-SA1.png"
    },
    {
        "id": "item-SA2",
        "src": "/static/General/icons/coupon-SA2.png"
    },
];


const WrapperContainer = styled.div`
  height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
`;

export const CouponImg = styled.img`
    width: 146px;
    height: 146px;
`;

export default function CouponEditor(props)
{
    const [couponList, setCouponList] = useState(couponData);
    const [voucherList, setVoucherList] = useState(
                                                {
                                                    xx: null,
                                                    xy: null,
                                                    yx: null,
                                                    yy: null,
                                                }
                                            );

    const dropZones = [
        'coupon-1.1',
        'coupon-1.2',
        'coupon-2.1',
        'coupon-2.2',
    ];

    const dropZoneMap = {
        'coupon-1.1':   'xx',
        'coupon-1.2':   'xy',
        'coupon-2.1':   'yx',
        'coupon-2.2':   'yy',
    };

    const onDragEnd = (result) =>
    {
        if (!result.destination) {
            return;
        }

        const { source, destination, draggableId } = result;

        if (source.droppableId === destination.droppableId
        && source.index === destination.index)
        {
            return;
        }
        // From coupon list to voucher grid.
        if (source.droppableId === 'couponList'
        && dropZones.includes(destination.droppableId))
        {
            const thisCoupon = couponList.find(item=>item.id===draggableId);
            let newCouponList = couponList.filter(item=>item!==thisCoupon);

            let container = null;
            switch (destination.droppableId)
            {
                case 'coupon-1.1':  container = 'xx'; break;
                case 'coupon-1.2':  container = 'xy'; break;
                case 'coupon-2.1':  container = 'yx'; break;
                case 'coupon-2.2':  container = 'yy'; break;
            }

            if (voucherList[container])
            {
                newCouponList.push(voucherList[container]);
            }
            voucherList[container] = thisCoupon;

            setCouponList(newCouponList);
            setVoucherList(voucherList);
        }
        
        // From voucher grid to coupon list.
        if (dropZones.includes(source.droppableId)
        && destination.droppableId === 'couponList')
        {
            switch (source.droppableId)
            {
                case 'coupon-1.1':
                {
                    couponList.push(voucherList.xx);
                    voucherList.xx = null;
                    break;
                }
                case 'coupon-1.2':
                {
                    couponList.push(voucherList.xy);
                    voucherList.xy = null;
                    break;
                }
                case 'coupon-2.1':
                {
                    couponList.push(voucherList.yx);
                    voucherList.yx = null;
                    break;
                }
                case 'coupon-2.2':
                {
                    couponList.push(voucherList.yy);
                    voucherList.yy = null;
                    break;
                }
            }
            setCouponList(JSON.parse(JSON.stringify(couponList)));
            setVoucherList(voucherList);
        }

        // From voucher grid to voucher grid.
        if (dropZones.includes(source.droppableId)
        && dropZones.includes(destination.droppableId))
        {
            const draggingCoupon = voucherList[dropZoneMap[source.droppableId]];
            const destinationCoupon = voucherList[dropZoneMap[destination.droppableId]];

            //Does the destination have a coupon?
            if (destinationCoupon)
            {
                voucherList[dropZoneMap[destination.droppableId]] = draggingCoupon;
                voucherList[dropZoneMap[source.droppableId]] = destinationCoupon;
            }
            else
            {
                voucherList[dropZoneMap[destination.droppableId]] = draggingCoupon;
                voucherList[dropZoneMap[source.droppableId]] = null;
            }
            setVoucherList(JSON.parse(JSON.stringify(voucherList)));
        }
    }

    const onRemoveCoupon = (id,zoneId) => {
        const thisCoupon = voucherList[dropZoneMap[zoneId]];

        couponList.push(thisCoupon);
        voucherList[dropZoneMap[zoneId]] = null;
        setCouponList(JSON.parse(JSON.stringify(couponList)));
        setVoucherList(JSON.parse(JSON.stringify(voucherList)));
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <WrapperContainer>
                {/* Grid to drop dragable coupons into. */}
                <VoucherGrid
                    background={props.background}
                    voucherList={voucherList}
                    onRemoveCoupon={onRemoveCoupon}
                    tncText={props.tncText}
                />
                {/* List of Coupons to drag from. */}
                <CouponListDropZone>
                    {couponList.map((coupon, index) => (
                        <DraggableCoupon
                            id={coupon.id}
                            index={index}
                            key={uuid()}
                            closeButton={false}
                            tncText={props.tncText}
                        >
                            <CouponImg src={coupon.src} />
                        </DraggableCoupon>
                    ))}
                </CouponListDropZone>
            </WrapperContainer>
        </DragDropContext>
    );
}