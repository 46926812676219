import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled';

const WrapperContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabsContainer = styled.div`
    height: 26px;
    border-bottom: 1px solid #a4a4a4;
`;

const TabHeadA = styled.a`
    border: 1px solid #a4a4a4;
    background: transparent;
    font-family: "Mulish";
    font-weight: 700;
    font-size: 14px;
    color: #4f4f4f;
    padding: 5px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &.first {
        border-top-left-radius: 4px 4px;
        border-right: 0px;
    }
    
    &.last {
        border-top-right-radius: 4px 4px;
    }

    &:hover {
        background-color: #fa3535;
        color: #fff;
    }

    &.active {
        background-color: #fa3535;
        color: #fff;
    }
`;

const TabContentContainer = styled.div`
    margin: 5px;
`;

export default function TabContainer({children}) {
    const [tabs, setTabs] = useState(() => {
        return children.map((tab,id) => (
            {
                id,
                first: (id === 0),
                last: (id === children.length-1),
                ...tab.props
            }
        ))
    });

    const onActivateTab = (id) => {
        setTabs(() => {
            return tabs.map((tab) => (
                {
                    ...tab,
                    active: (tab.id === id)
                }
            ))
        })
    }
    return (
        <WrapperContainer>
            <TabsContainer>
                {tabs.map((tab,i) => (
                    <TabHead
                        key={`tab-${tab.id}`}
                        onActivate={()=>{onActivateTab(i)}}
                        active={tab.active}
                        first={tab.first}
                        last={tab.last}
                    >
                        {tab.title}
                    </TabHead>
                ))}
            </TabsContainer>
            <TabContentContainer>
                {children.map((tab,id) => (
                    <TabBody key={`tab-body-${id}`} active={tabs[id].active}>
                        {tab}
                    </TabBody>
                ))}
            </TabContentContainer>
        </WrapperContainer>
    );
}

export function Tab({title,children}) {
    return (
        <>{children}</>
    );
}

export function TabHead({children, active, first, last, onActivate}) {
    const onClick = (e) => {
        e.preventDefault();
        onActivate(e);
    }
    return (
        <TabHeadA
            className={`${active?'active':''} ${first?'first':''} ${last?'last':''}`}
            href="#"
            onClick={onClick}
        >
            {children}
        </TabHeadA>
    );
}

export function TabBody({active, children}) {
    return (
        <div style={{display:active?'':'none'}} >{children}</div>
    );
}
