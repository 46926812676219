import { useContext, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../../../context/auth';
import { useForm } from '../../../hooks/useForm';
import { TabTitle } from "../../../utils/TabTitle";
import ForgotCredentials from "../../../components/ForgotCredentials/ForgotCredentials";
import RequestLoginBanner from "../../../components/RequestLoginBanner/RequestLoginBanner";
import './Login.scss';

function Login({ title }) {
  TabTitle(title  || 'KFC LSM');

  let navigate = useNavigate();
  const context = useContext(AuthContext);

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    username: '',
    password: ''
  });

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(_, { data: { login: userData } }) {
      context.login(userData);
      navigate('/');
    },
    onError(err) {
      let errMsg = err.graphQLErrors[0].message;
      validateError(errMsg);
    },
    variables: values
  });

  async function loginUserCallback() {
    await loginUser();
  }

  let notifyError = (title, id) => toast.error(title, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
  });

  let validateError = (err) => {
    let username = document.getElementById('username');
    let password = document.getElementById('password');

    if (username.value === "") {
      notifyError('Username must not be empty', 'username-empty-error');
      username.classList.add('invalid');
    }

    if (password.value === "") {
      notifyError('Password must not be empty', 'password-empty-error');
      password.classList.add('invalid');
    }

    if (err === 'User not found') {
      notifyError(err, 'user-not-found');
      username.classList.add('invalid');
      password.classList.add('invalid');
    }

    if (err === 'Wrong crendetials') {
      notifyError(err, 'wrong-credentials');
      username.classList.add('invalid');
      password.classList.add('invalid');
    }

  }

  let passwordView = (e) => {
    e.preventDefault();
    let passwordField = document.querySelector('#password')
    passwordField.type === "password" ? passwordField.type = "text" : passwordField.type = "password";
    e.target.classList.toggle('active');
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_SERVER}user/check`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.success) {
          context.login(data);
          navigate('/') 
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const loginSSO = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.REACT_APP_API_SERVER}user/login/sso`;
  }
  return (
    <div className={'login'}>
      <form onSubmit={onSubmit} className={'login__form'}>
        <RequestLoginBanner />
        <h3 style={{ marginBottom: '21px' }}>Login</h3>
        <input type={'text'} name='username' id="username" placeholder='Username' value={values.username} onChange={onChange} />
        <div className={'password-view'}>
          <input type={'password'} name='password' id="password" placeholder='Password' value={values.password} onChange={onChange} />
          <button onClick={passwordView} />
        </div>
        <div className='row'>
          <button style={{marginRight: '10px'}} className='btn-sumbit' type={'submit'}>Enter</button>
          <button onClick={loginSSO} className='btn-sumbit'>SSO Login</button>
        </div>
        <ForgotCredentials />
      </form>
    </div>
  )
}

const LOGIN_USER = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      email
      username
      createdAt
      firstName
      lastName
      userRole
      avatar
      token
    }
  }
`;

export default Login