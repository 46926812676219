import { Component, Fragment } from "react";
import { toast } from 'react-toastify';

const notifyLenght = () => toast.info('Minimum name length 5 characters', {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});
const passwordChecknotify = () => toast.error('invalid Name', {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export class CompaingFormFirst extends Component {
  back = e => {
    e.preventDefault();
    document.querySelector(".cancel").click();
  };

  continue = e => {
    e.preventDefault();
    let titleCompany = document.querySelector('.titleCompany');

    if(titleCompany.value.length > 5) {
      titleCompany.classList.remove('input-valid');
      this.props.nextStep();
    } else {
      titleCompany.classList.add('input-valid');
      document.querySelector('.hiddenNotifyBtnPasswordLength').click();
      document.querySelector('.hiddenNotifyBtnPassword').click();
    }
  };

  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { values, handleChange } = this.props;
    return (
      <Fragment>
        <div className="first-step">
          <h1>01 / 05</h1>
          <h4>Adding New Campaign</h4>
          <p>Please fill out the information below</p>
          <h6>Step 01. Name</h6>
          <span>Add a name that can be searched for in the future</span>
          <input
              className={'titleCompany'}
              placeholder="Add Campaign Name"
              onChange={handleChange("title")}
              defaultValue={values.firstName}
          />
          <button className="hiddenNotifyBtnPassword" onClick={passwordChecknotify}>Notify!</button>
          <button className="hiddenNotifyBtnPasswordLength" onClick={notifyLenght}>Notify!</button>
        </div>
        <div className="button-group">
          <button
            label="Cancel"
            onClick={this.back}
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            label="Next"
            onClick={this.continue}
            className="next-btn"
          >
            Next
          </button>
        </div>
      </Fragment>
    );
  }
}

export default CompaingFormFirst;
