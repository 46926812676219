import * as AssetGQL from './asset';
import * as CategoryGQL from './category';
import * as StoreGQL from './store';



export default {
    Asset: {
        ...AssetGQL
    },
    Category: {
        ...CategoryGQL
    },
    Store: {
        ...StoreGQL
    }
}