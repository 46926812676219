import { useContext } from 'react';
import { AuthContext } from '../../../context/auth';
import { NavLink } from 'react-router-dom';
import { USER_TYPE } from '../../../defs/User';
import './style.scss';

function NavItem(props) {
    const { user } = useContext(AuthContext);
    const allowedRoles = props.roles || [
        USER_TYPE.ADMIN,
        USER_TYPE.MARKETER,
        USER_TYPE.BUSINESS_OWNER,
        USER_TYPE.DEVELOPER
    ];

    //Does the user have permission to view this?                                                                                                                                                                                    .8
    if (allowedRoles.includes(user.userRole))
    {
        if (props.open)
        {
            return (
                <>
                    <li onClick={props.open}>
                        <a href="#" onClick={(e)=>e.preventDefault()}>{props.children}</a>
                    </li>
                </>
            );
        }
        else
        {
            return (
                <>
                    <li onClick={props.open}>
                        <NavLink
                            end
                            to={props.to}
                            state={props.children[1]}
                            className={({ isActive }) =>
                                isActive ? 'active' : undefined
                            }
                        >
                            {props.children}
                        </NavLink>
                    </li>
                </>
            );
        }
    }
    return null;
}

export default NavItem