import React, {Component, Fragment} from "react";
import Select, {components} from 'react-select';
import './style.scss';

const CustomTooltip = ({active, payload, label}) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].value}`}</p>
                <p className="desc">Feb 20th, 2021</p>
            </div>
        );
    }

    return null;
};


export class CompaingFormFourth extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    constructor() {
        super();
        this.state = {
            disabled: false
        };
    }

    handleChange = selectedOption => {
        this.setState({selectedOption});
    };


    render() {
        const {isOpen, haveText} = this.state;
        const {values, handleChange} = this.props;
        const options = [
            {value: 'select_market', label: 'The Select Markets'},
        ];
        const DropdownIndicator = props => {
            return (
                <components.DropdownIndicator {...props}>
                    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E"/>
                    </svg>
                </components.DropdownIndicator>
            );
        };

        const style = {
            menu: (provided, state) => ({
              ...provided,
              width: '100%',
              padding: 0,
              marginBottom: 0,
              marginTop: '0',
              boxShadow: 'none',
              border: '1px solid #EEEEEE',
              borderTop: '0',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '19px',
              transition: 'all .2s ease-in-out',
              borderRadius: '0',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }),
            menuList: (provided, state) => ({
              ...provided,
              padding: '0',
              borderRadius: '0',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }),
            option: (provided, state) => ({
              ...provided,
              color: 'black',
              backgroundColor: '#fff',
              padding: '10px 10px',
              cursor: 'pointer',
              height: '33px',
              display: 'flex',
              alignItems: 'center',
              transition: 'all .2s ease-in-out',
              '&:hover': {
                color: '#fff',
                backgroundColor: '#FA3535'
              }
            }),
            control: (base, state) => ({
              ...base,
              border: '1px solid #EEEEEE',
              borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
              boxShadow: '0 !important',
              cursor: 'pointer',
              width: '100%',
              height: '60px',
              borderRadius: '4px',
              borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : '4px',
              borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : '4px',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '19px',
              transition: '.2s easy-in-out',
              '&:hover': {
                borderColor: '1px solid #EEEEEE !important',
                borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : '1px solid #EEEEEE',
              }
            }),
          };
        const location = [
            {
                storeItems: "1102, KFC Hurstville Mall, NSW"
            },
            {
                storeItems: "1104, KFC Nicholls, ACT"
            },
            {
                storeItems: "1105, KFC Hillcrest, SA"
            },
            {
                storeItems: "1107, KFC Ballan Service Centre, VIC"
            },
            {
                storeItems: "1108, KFC Bacchus Marsh, VIC"
            },
            {
                storeItems: "1110, KFC Mt Druitt Mall, NSW"
            },
            {
                storeItems: "1111, KFC Highett, VIC"
            },
            {
                storeItems: "1115, KFC Nambucca Heads, NSW"
            },
            {
                storeItems: "1118, KFC Coburg, VIC"
            },
            {
                storeItems: "1119, KFC Hamilton, VIC"
            },
            {
                storeItems: "1120, KFC Horsham, VIC"
            },
            {
                storeItems: "1121, KFC Portland, VIC"
            },
            {
                storeItems: "1122, KFC Warrnambool City, VIC"
            },
            {
                storeItems: "1123, KFC Wodonga, VIC"
            },
            {
                storeItems: "1124, KFC Belmont VIC, VIC"
            },
            {
                storeItems: "1125, KFC Keysborough, VIC"
            },
            {
                storeItems: "1128, KFC Altona North, VIC"
            },
            {
                storeItems: "1129, KFC Deer Park, VIC"
            },
            {
                storeItems: "1130, KFC Manly Vale, NSW"
            },
            {
                storeItems: "1131, KFC Lilydale, VIC"
            },
            {
                storeItems: "1132, KFC Seymour, VIC"
            },
            {
                storeItems: "1133, KFC Wangaratta, VIC"
            },
            {
                storeItems: "1134, KFC Rowville, VIC"
            },
            {
                storeItems: "1135, KFC Mentone, VIC"
            },
            {
                storeItems: "1136, KFC Dee Why, NSW"
            },
            {
                storeItems: "1137, KFC Bayswater, VIC"
            },
            {
                storeItems: "1138, KFC Forster, NSW"
            },
            {
                storeItems: "1139, KFC Niddrie, VIC"
            },
            {
                storeItems: "1140, KFC Ormond, VIC"
            },
            {
                storeItems: "1141, KFC Clayton, VIC"
            },
            {
                storeItems: "1142, KFC Springvale, VIC"
            },
            {
                storeItems: "1143, KFC Noble Park, VIC"
            },
            {
                storeItems: "1147, KFC Narre Warren, VIC"
            },
            {
                storeItems: "1148, KFC Point Cook, VIC"
            },
            {
                storeItems: "1150, KFC Benalla, VIC"
            },
            {
                storeItems: "1151, KFC Echuca, VIC"
            },
            {
                storeItems: "1154, KFC Grovedale, VIC"
            },
            {
                storeItems: "1156, KFC Eaglehawk, VIC"
            },
            {
                storeItems: "1157, KFC Chadstone Mall, VIC"
            },
            {
                storeItems: "1158, KFC Newcomb, VIC"
            },
            {
                storeItems: "1159, KFC Shepparton City, VIC"
            },
            {
                storeItems: "1160, KFC Swan Hill, VIC"
            },
            {
                storeItems: "1161, KFC Brimbank, VIC"
            },
            {
                storeItems: "1162, KFC Woodgrove, VIC"
            },
            {
                storeItems: "1408, Mildura, VIC"
            },
            {
                storeItems: "1164, KFC Truganina, VIC"
            },
            {
                storeItems: "1165, KFC Lavington, NSW VIC"
            },
            {
                storeItems: "1166, KFC Sunbury, VIC"
            },
            {
                storeItems: "1168, KFC Melton Phoenix, VIC"
            },
            {
                storeItems: "1170, KFC Mudgee, NSW"
            },
            {
                storeItems: "1172, KFC Chinderah North, NSW"
            },
            {
                storeItems: "1173, KFC Eastgardens Food Court, NSW"
            },
            {
                storeItems: "1175, KFC Cowra, NSW"
            },
            {
                storeItems: "1177, KFC Tullamarine, VIC"
            },
            {
                storeItems: "1178, KFC Cooma, ACT"
            },
            {
                storeItems: "1179, KFC Darling Harbour Food Court, NSW"
            },
            {
                storeItems: "1180, KFC Southland Mall, VIC"
            },
            {
                storeItems: "1181, KFC Young, NSW"
            },
            {
                storeItems: "1182, KFC Batemans Bay, NSW"
            },
            {
                storeItems: "1183, KFC Rosebud, VIC"
            },
            {
                storeItems: "1186, KFC Port Macquarie, NSW"
            },
            {
                storeItems: "1188, KFC Warragul, VIC"
            },
            {
                storeItems: "1189, KFC Toronto, NSW"
            },
            {
                storeItems: "1191, KFC Inverell, NSW"
            },
            {
                storeItems: "1192, KFC Treendale, WA"
            },
            {
                storeItems: "1193, KFC Murwillumbah, NSW"
            },
            {
                storeItems: "1310, Lindfield, NSW"
            },
            {
                storeItems: "1195, KFC Colac, VIC"
            },
            {
                storeItems: "1197, KFC Knox City, VIC"
            },
            {
                storeItems: "1198, KFC Renmark, SA VIC"
            },
            {
                storeItems: "1199, KFC Lakehaven, NSW"
            },
            {
                storeItems: "1202, KFC Baulkham Hills, NSW"
            },
            {
                storeItems: "1205, KFC Dural, NSW"
            },
            {
                storeItems: "1206, KFC Bendigo, VIC"
            },
            {
                storeItems: "1207, KFC Kangaroo Flat, VIC"
            },
            {
                storeItems: "1208, KFC Woodbine, NSW"
            },
            {
                storeItems: "1209, KFC Mooroolbark, VIC"
            },
            {
                storeItems: "1210, KFC Hoppers Crossing, VIC"
            },
            {
                storeItems: "1211, KFC Werribee, VIC"
            },
            {
                storeItems: "1212, KFC Footscray, VIC"
            },
            {
                storeItems: "1213, KFC Keilor Downs, VIC"
            },
            {
                storeItems: "1216, KFC Sunshine, VIC"
            },
            {
                storeItems: "1217, KFC Bairnsdale, VIC"
            },
            {
                storeItems: "1218, KFC Sale, VIC"
            },
            {
                storeItems: "1219, KFC Traralgon, VIC"
            },
            {
                storeItems: "1220, KFC Artarmon, NSW"
            },
            {
                storeItems: "1221, KFC Woy Woy, NSW"
            },
            {
                storeItems: "1222, KFC Ringwood, VIC"
            },
            {
                storeItems: "1223, KFC Greensborough, VIC"
            },
            {
                storeItems: "1224, KFC Oakleigh, VIC"
            },
            {
                storeItems: "1225, KFC Cranbourne, VIC"
            },
            {
                storeItems: "1226, KFC Chelsea Heights, VIC"
            },
            {
                storeItems: "1227, KFC Karingal, VIC"
            },
            {
                storeItems: "1228, KFC Mornington, VIC"
            },
            {
                storeItems: "1230, KFC Chatswood Westfield Food Court, NSW"
            },
            {
                storeItems: "1231, KFC Corio, VIC"
            },
            {
                storeItems: "1235, KFC Doncaster, VIC"
            },
            {
                storeItems: "1237, KFC Vermont, VIC"
            },
            {
                storeItems: "1238, KFC Laverton, VIC"
            },
            {
                storeItems: "1239, KFC Bankstown Square Food Court, NSW"
            },
            {
                storeItems: "1241, KFC Belconnen Food Court, ACT"
            },
            {
                storeItems: "1242, KFC Bega, NSW"
            },
            {
                storeItems: "1244, KFC Dickson, ACT"
            },
            {
                storeItems: "1245, KFC Goulburn, ACT"
            },
            {
                storeItems: "1246, KFC Fyshwick, ACT"
            },
            {
                storeItems: "1247, KFC Tuggeranong, ACT"
            },
            {
                storeItems: "1248, KFC Queanbeyan, ACT"
            },
            {
                storeItems: "1249, KFC Hawker, ACT"
            },
            {
                storeItems: "1250, KFC Chirnside Park, VIC"
            },
            {
                storeItems: "1251, KFC North Wagga, NSW VIC"
            },
            {
                storeItems: "1253, KFC Narellan, NSW"
            },
            {
                storeItems: "1254, KFC Fairfield, NSW"
            },
            {
                storeItems: "1256, KFC Bourke Street Melbourne, VIC"
            },
            {
                storeItems: "1258, KFC Tumut, NSW VIC"
            },
            {
                storeItems: "1260, KFC Moonee Ponds, VIC"
            },
            {
                storeItems: "1261, KFC Fawkner, VIC"
            },
            {
                storeItems: "1263, KFC Broken Hill, NSW VIC"
            },
            {
                storeItems: "1265, KFC East Lismore, NSW"
            },
            {
                storeItems: "1266, KFC Grafton, NSW"
            },
            {
                storeItems: "1267, KFC Glen Innes, NSW"
            },
            {
                storeItems: "1268, KFC Ballina, NSW"
            },
            {
                storeItems: "1269, KFC Narrabri, NSW"
            },
            {
                storeItems: "1270, KFC Gunnedah, NSW"
            },
            {
                storeItems: "1271, KFC Moree, NSW"
            },
            {
                storeItems: "1272, KFC Tamworth, NSW"
            },
            {
                storeItems: "1274, KFC Kempsey, NSW"
            },
            {
                storeItems: "1275, KFC Taree, NSW"
            },
            {
                storeItems: "1276, KFC Armidale, NSW"
            },
            {
                storeItems: "1277, KFC Dulwich Hill, NSW"
            },
            {
                storeItems: "1278, KFC Earlwood, NSW"
            },
            {
                storeItems: "1279, KFC Yagoona, NSW"
            },
            {
                storeItems: "1280, KFC Enfield, NSW"
            },
            {
                storeItems: "1281, KFC Bankstown South, NSW"
            },
            {
                storeItems: "1282, KFC Punchbowl, NSW"
            },
            {
                storeItems: "1285, KFC Campsie, NSW"
            },
            {
                storeItems: "1287, KFC Revesby, NSW"
            },
            {
                storeItems: "1288, KFC Riverwood, NSW"
            },
            {
                storeItems: "1290, KFC Five Dock, NSW"
            },
            {
                storeItems: "1291, KFC Concord, NSW"
            },
            {
                storeItems: "1292, KFC St Peters, NSW"
            },
            {
                storeItems: "1293, KFC Sans Souci, NSW"
            },
            {
                storeItems: "1295, KFC Miranda, NSW"
            },
            {
                storeItems: "1297, KFC Hurstville, NSW"
            },
            {
                storeItems: "1299, KFC Miranda Food Court, NSW"
            },
            {
                storeItems: "1300, KFC Pagewood, NSW"
            },
            {
                storeItems: "1301, KFC George Street Sydney, NSW"
            },
            {
                storeItems: "1304, KFC Randwick, NSW"
            },
            {
                storeItems: "1306, KFC Frankston Bayside Food Court, VIC"
            },
            {
                storeItems: "1308, KFC Mt Gambier City, SA VIC"
            },
            {
                storeItems: "2333, KFC St Leonards, NSW"
            },
            {
                storeItems: "1311, KFC Pakenham, VIC"
            },
            {
                storeItems: "1314, KFC Ulladulla, NSW"
            },
            {
                storeItems: "1315, KFC Burwood Food Court, NSW"
            },
            {
                storeItems: "1316, KFC Coffs Harbour South, NSW"
            },
            {
                storeItems: "1317, KFC Hallam, VIC"
            },
            {
                storeItems: "1318, KFC Hoppers Crossing 2, VIC"
            },
            {
                storeItems: "1319, KFC Woden, ACT"
            },
            {
                storeItems: "1320, KFC Airport West, VIC"
            },
            {
                storeItems: "1322, KFC Leongatha, VIC"
            },
            {
                storeItems: "1327, KFC Roselands Food Court, NSW"
            },
            {
                storeItems: "1329, KFC West End Plaza Food Court, NSW"
            },
            {
                storeItems: "1330, KFC Ermington, NSW"
            },
            {
                storeItems: "1332, KFC Ingleburn, NSW"
            },
            {
                storeItems: "1333, KFC Marulan, ACT"
            },
            {
                storeItems: "1334, KFC Fountain Gate Food Court, VIC"
            },
            {
                storeItems: "1335, KFC Wagga Homebase, NSW VIC"
            },
            {
                storeItems: "1336, KFC Casino, NSW"
            },
            {
                storeItems: "1337, KFC Menai, NSW"
            },
            {
                storeItems: "1338, KFC Sydenham, VIC"
            },
            {
                storeItems: "1340, KFC Hastings, VIC"
            },
            {
                storeItems: "1341, KFC Flemington, VIC"
            },
            {
                storeItems: "1342, KFC Broadway, NSW"
            },
            {
                storeItems: "1343, KFC Ashfield Mall, NSW"
            },
            {
                storeItems: "1345, KFC Mt Annan, NSW"
            },
            {
                storeItems: "1346, KFC Frankston South, VIC"
            },
            {
                storeItems: "1348, KFC North Ryde, NSW"
            },
            {
                storeItems: "1350, KFC Berwick South, VIC"
            },
            {
                storeItems: "1351, KFC Gungahlin, ACT"
            },
            {
                storeItems: "1352, KFC Endeavour Hills, VIC"
            },
            {
                storeItems: "1353, KFC Sunshine Market Place, VIC"
            },
            {
                storeItems: "1355, KFC Eltham, VIC"
            },
            {
                storeItems: "1356, KFC Carrum Downs, VIC"
            },
            {
                storeItems: "1357, KFC North Geelong, VIC"
            },
            {
                storeItems: "1359, KFC Alice Springs, NT"
            },
            {
                storeItems: "1363, KFC Rockdale, NSW"
            },
            {
                storeItems: "1364, KFC Mona Vale, NSW"
            },
            {
                storeItems: "1366, KFC Sturt Mall Food Court, NSW VIC"
            },
            {
                storeItems: "1367, KFC Erina Fair, NSW"
            },
            {
                storeItems: "1368, KFC Melbourne Central Food Court, VIC"
            },
            {
                storeItems: "1371, KFC Tahmoor, NSW"
            },
            {
                storeItems: "1373, KFC Burwood East, VIC"
            },
            {
                storeItems: "1374, KFC Ashfield, NSW"
            },
            {
                storeItems: "1375, KFC Lakemba, NSW"
            },
            {
                storeItems: "1377, KFC Werribee Plaza, VIC"
            },
            {
                storeItems: "1378, KFC Knox City Food Court, VIC"
            },
            {
                storeItems: "1379, KFC Liverpool Food Court, NSW"
            },
            {
                storeItems: "1380, KFC Riverstone, NSW"
            },
            {
                storeItems: "1381, KFC Caulfield, VIC"
            },
            {
                storeItems: "1382, KFC Melton Caltex, VIC"
            },
            {
                storeItems: "1383, KFC Yarraville, VIC"
            },
            {
                storeItems: "1384, KFC Albury, NSW VIC"
            },
            {
                storeItems: "1385, KFC Nunawading, VIC"
            },
            {
                storeItems: "1386, KFC Wonthaggi, VIC"
            },
            {
                storeItems: "1388, KFC Ferntree Gully, VIC"
            },
            {
                storeItems: "1389, KFC Maryborough., VIC"
            },
            {
                storeItems: "1390, KFC Macarthur Shopping Centre, NSW"
            },
            {
                storeItems: "1391, KFC Ocean Grove, VIC"
            },
            {
                storeItems: "1392, KFC Highpoint - Level 3, VIC"
            },
            {
                storeItems: "1393, KFC Dandenong Market, VIC"
            },
            {
                storeItems: "1394, KFC Lavington Square Food Court, NSW"
            },
            {
                storeItems: "1395, KFC Wantirna, VIC"
            },
            {
                storeItems: "1396, KFC Lynbrook, VIC"
            },
            {
                storeItems: "1397, KFC 401 Swanston Street, VIC"
            },
            {
                storeItems: "1398, KFC Wallan, VIC"
            },
            {
                storeItems: "1399, KFC Mittagong Food Court, NSW"
            },
            {
                storeItems: "1400, KFC Lakehaven Food Court, NSW"
            },
            {
                storeItems: "1401, KFC North Wyong, NSW"
            },
            {
                storeItems: "1402, KFC Yarrawonga, VIC"
            },
            {
                storeItems: "1403, KFC Calder Inbound, VIC"
            },
            {
                storeItems: "1404, KFC Calder Outbound, VIC"
            },
            {
                storeItems: "1405, KFC Wyndham Vale, VIC"
            },
            {
                storeItems: "1407, KFC Engadine, NSW"
            },
            {
                storeItems: "9946, KFC Livingston, WA"
            },
            {
                storeItems: "1409, KFC Tarneit, VIC"
            },
            {
                storeItems: "1410, KFC Kurri Kurri, NSW"
            },
            {
                storeItems: "1411, KFC Port Macquarie II, NSW"
            },
            {
                storeItems: "1412, KFC Crossroads, NSW"
            },
            {
                storeItems: "1413, KFC Dandenong Plaza, VIC"
            },
            {
                storeItems: "1415, KFC Watergardens, VIC"
            },
            {
                storeItems: "1416, KFC Rouse Hill Food Court, NSW"
            },
            {
                storeItems: "1417, KFC East Wodonga, VIC"
            },
            {
                storeItems: "1418, KFC Lakes Entrance, VIC"
            },
            {
                storeItems: "1419, KFC Taree Service Centre, NSW"
            },
            {
                storeItems: "1420, KFC Morisset, NSW"
            },
            {
                storeItems: "1421, KFC Westfield Geelong, VIC"
            },
            {
                storeItems: "1422, KFC Mcgraths Hill, NSW"
            },
            {
                storeItems: "1423, KFC Tamworth South, NSW"
            },
            {
                storeItems: "1424, KFC Waterfront City Docklands, VIC"
            },
            {
                storeItems: "1425, KFC Wallan 1 (Southbound), VIC"
            },
            {
                storeItems: "1426, KFC Wallan 2 (Northbound), VIC"
            },
            {
                storeItems: "1428, KFC Grafton Food Court, NSW"
            },
            {
                storeItems: "1429, KFC Campbelltown Mall Food Court, NSW"
            },
            {
                storeItems: "1430, KFC Gladesville, NSW"
            },
            {
                storeItems: "1431, KFC Carlton, VIC"
            },
            {
                storeItems: "1432, KFC Kew, VIC"
            },
            {
                storeItems: "1433, KFC Sydney Central, NSW"
            },
            {
                storeItems: "1434, KFC Bendigo Marketplace, VIC"
            },
            {
                storeItems: "1435, KFC Gundagai, NSW"
            },
            {
                storeItems: "1436, KFC East Brighton, VIC"
            },
            {
                storeItems: "1437, KFC Narellan Town Centre, NSW"
            },
            {
                storeItems: "1438, KFC Victoria Gardens Food Court, VIC"
            },
            {
                storeItems: "1439, KFC Northland Mall, VIC"
            },
            {
                storeItems: "1440, KFC Wellington, NSW"
            },
            {
                storeItems: "1442, KFC Elizabeth Street Melbourne, VIC"
            },
            {
                storeItems: "1443, KFC Haymarket, NSW"
            },
            {
                storeItems: "1445, KFC Liverpool, NSW"
            },
            {
                storeItems: "1447, KFC Crown Casino Melbourne, VIC"
            },
            {
                storeItems: "1449, KFC Toormina, NSW"
            },
            {
                storeItems: "1450, KFC Glenmore Park, NSW"
            },
            {
                storeItems: "1452, KFC Coffs Harbour Plaza, NSW"
            },
            {
                storeItems: "1453, KFC Bateau Bay, NSW"
            },
            {
                storeItems: "1454, KFC Top Ryde Food Court, NSW"
            },
            {
                storeItems: "1455, KFC Myer Centre Food Court, SA"
            },
            {
                storeItems: "1456, KFC Caroline Springs, VIC"
            },
            {
                storeItems: "1457, KFC Greensborough Plaza Food Court, VIC"
            },
            {
                storeItems: "1458, KFC Burnley, VIC"
            },
            {
                storeItems: "1459, KFC Merrylands Mall, NSW"
            },
            {
                storeItems: "1461, KFC Officer Inbound, VIC"
            },
            {
                storeItems: "1462, KFC Officer Outbound, VIC"
            },
            {
                storeItems: "1463, KFC Strath Village, VIC"
            },
            {
                storeItems: "1464, KFC Chinderah, NSW"
            },
            {
                storeItems: "1465, KFC Highpoint II, VIC"
            },
            {
                storeItems: "1466, KFC Griffith, NSW VIC"
            },
            {
                storeItems: "1467, KFC Shepparton South, VIC"
            },
            {
                storeItems: "1468, KFC Milperra, NSW"
            },
            {
                storeItems: "1469, KFC Everard Park, SA"
            },
            {
                storeItems: "1470, KFC Arndale Food Court, SA"
            },
            {
                storeItems: "1471, KFC Plenty Valley Westfield, VIC"
            },
            {
                storeItems: "1472, KFC Salamander Bay, NSW"
            },
            {
                storeItems: "1473, KFC Eastlink Northbound, VIC"
            },
            {
                storeItems: "1474, KFC Eastlink Southbound, VIC"
            },
            {
                storeItems: "1475, KFC Carlingford Food Court, NSW"
            },
            {
                storeItems: "1476, KFC Wetherill Park Mall, NSW"
            },
            {
                storeItems: "1477, KFC Taylors Hill, VIC"
            },
            {
                storeItems: "1478, KFC Hornsby Food Court, NSW"
            },
            {
                storeItems: "1479, KFC Mean Fiddler, NSW"
            },
            {
                storeItems: "1480, KFC Parramatta L5 Food Court, NSW"
            },
            {
                storeItems: "1481, KFC Parramatta L1 Food Court, NSW"
            },
            {
                storeItems: "1482, KFC Eastlands Food Court, VIC"
            },
            {
                storeItems: "1484, KFC City Cross, SA"
            },
            {
                storeItems: "1485, KFC Hindley Street, SA"
            },
            {
                storeItems: "1486, KFC Seaford Rise, SA"
            },
            {
                storeItems: "1500, KFC Aberfoyle Park, SA"
            },
            {
                storeItems: "1501, KFC Fulham Gardens, SA"
            },
            {
                storeItems: "1504, KFC Colonnades, SA"
            },
            {
                storeItems: "1505, KFC Mitcham, SA"
            },
            {
                storeItems: "1514, KFC Tea Tree Plaza Food Court, SA"
            },
            {
                storeItems: "1515, KFC Emu Plains, NSW"
            },
            {
                storeItems: "1516, KFC Prahran, VIC"
            },
            {
                storeItems: "1528, KFC Leppington, NSW"
            },
            {
                storeItems: "1725, KFC Bligh Park, NSW"
            },
            {
                storeItems: "1726, KFC Warragul Queen Street, VIC"
            },
            {
                storeItems: "1727, KFC Raymond Terrace, NSW"
            },
            {
                storeItems: "1728, KFC Kilmore, VIC"
            },
            {
                storeItems: "1729, KFC Craigieburn, VIC"
            },
            {
                storeItems: "1731, KFC Riverdale, VIC"
            },
            {
                storeItems: "1732, KFC Traralgon East, VIC"
            },
            {
                storeItems: "1733, KFC Thornton, NSW"
            },
            {
                storeItems: "1734, KFC Spring Farm, NSW"
            },
            {
                storeItems: "1735, KFC Cranbourne North, VIC"
            },
            {
                storeItems: "1901, KFC Hawthorn, VIC"
            },
            {
                storeItems: "1902, KFC St Kilda, VIC"
            },
            {
                storeItems: "1903, KFC Pinjarra North, WA"
            },
            {
                storeItems: "1973, KFC Pinjarra South, WA"
            },
            {
                storeItems: "2200, KFC Westgate Port Melbourne, VIC"
            },
            {
                storeItems: "2201, KFC Karratha, WA"
            },
            {
                storeItems: "2202, KFC Lithgow, NSW"
            },
            {
                storeItems: "2203, KFC Morwell, VIC"
            },
            {
                storeItems: "2204, KFC Ashburton, VIC"
            },
            {
                storeItems: "2205, KFC Glen Waverley, VIC"
            },
            {
                storeItems: "2206, KFC Reservoir, VIC"
            },
            {
                storeItems: "2207, KFC Thornbury, VIC"
            },
            {
                storeItems: "2208, KFC Preston, VIC"
            },
            {
                storeItems: "2209, KFC Heidelberg, VIC"
            },
            {
                storeItems: "2210, KFC Moe, VIC"
            },
            {
                storeItems: "2211, KFC Northmead, NSW"
            },
            {
                storeItems: "2212, KFC Parkes, NSW"
            },
            {
                storeItems: "2213, KFC Penrith Leagues, NSW"
            },
            {
                storeItems: "2214, KFC Wentworthville, NSW"
            },
            {
                storeItems: "2215, KFC Shellharbour, NSW"
            },
            {
                storeItems: "2216, KFC Merrylands, NSW"
            },
            {
                storeItems: "2217, KFC Orange, NSW"
            },
            {
                storeItems: "2218, KFC Seven Hills, NSW"
            },
            {
                storeItems: "2220, KFC Dubbo, NSW"
            },
            {
                storeItems: "2221, KFC Nowra, NSW"
            },
            {
                storeItems: "2222, KFC Bathurst, NSW"
            },
            {
                storeItems: "2223, KFC Blacktown Mega Centre, NSW"
            },
            {
                storeItems: "2224, KFC Westpoint Food Court, NSW"
            },
            {
                storeItems: "2225, KFC Penrith Plaza Food Court, NSW"
            },
            {
                storeItems: "2226, KFC Yass, ACT"
            },
            {
                storeItems: "2227, KFC Shellharbour Food Court, NSW"
            },
            {
                storeItems: "2228, KFC Albion Park 2, NSW"
            },
            {
                storeItems: "2229, KFC Bomaderry, NSW"
            },
            {
                storeItems: "2230, KFC Dubbo East, NSW"
            },
            {
                storeItems: "2232, KFC Chatswood Chase, NSW"
            },
            {
                storeItems: "2233, KFC Blacktown, NSW"
            },
            {
                storeItems: "2234, KFC Broadmeadows, VIC"
            },
            {
                storeItems: "2235, KFC Broadmeadows Food Court, VIC"
            },
            {
                storeItems: "2236, KFC Roxburgh Park, VIC"
            },
            {
                storeItems: "2237, KFC Prospect, SA"
            },
            {
                storeItems: "2238, KFC Torrensville, SA"
            },
            {
                storeItems: "2239, KFC Glenelg, SA"
            },
            {
                storeItems: "2240, KFC Eastwood, SA"
            },
            {
                storeItems: "2241, KFC Edwardstown, SA"
            },
            {
                storeItems: "2242, KFC Kilburn, SA"
            },
            {
                storeItems: "2243, KFC Warwick Farm, NSW"
            },
            {
                storeItems: "2244, KFC Villawood, NSW"
            },
            {
                storeItems: "2246, KFC Lidcombe, NSW"
            },
            {
                storeItems: "2248, KFC Carnes Hill, NSW"
            },
            {
                storeItems: "2249, KFC Asquith, NSW"
            },
            {
                storeItems: "2250, KFC Sydney Airport, NSW"
            },
            {
                storeItems: "2252, KFC Forrestfield, WA"
            },
            {
                storeItems: "2253, KFC Haynes, WA"
            },
            {
                storeItems: "2254, KFC Ellenbrook, WA"
            },
            {
                storeItems: "2255, KFC Kwinana, WA"
            },
            {
                storeItems: "2256, KFC Doreen, VIC"
            },
            {
                storeItems: "2257, KFC Mill Park, VIC"
            },
            {
                storeItems: "2258, KFC Bundoora, VIC"
            },
            {
                storeItems: "2259, KFC Belmont, NSW"
            },
            {
                storeItems: "2260, KFC Tuggerah Food Court, NSW"
            },
            {
                storeItems: "2261, KFC The Entrance, NSW"
            },
            {
                storeItems: "2262, KFC Wyoming, NSW"
            },
            {
                storeItems: "2263, KFC Warners Bay, NSW"
            },
            {
                storeItems: "2264, KFC Charlestown Square, NSW"
            },
            {
                storeItems: "2265, KFC Wendouree, VIC"
            },
            {
                storeItems: "2266, KFC Ballarat, VIC"
            },
            {
                storeItems: "2267, KFC Sebastopol, VIC"
            },
            {
                storeItems: "2268, KFC Singleton, NSW"
            },
            {
                storeItems: "2269, KFC Muswellbrook, NSW"
            },
            {
                storeItems: "2270, KFC Bridgewater, TAS"
            },
            {
                storeItems: "2271, KFC Kingston, TAS"
            },
            {
                storeItems: "2272, KFC Epping, VIC"
            },
            {
                storeItems: "2273, KFC Launceston, TAS"
            },
            {
                storeItems: "2274, KFC Sorell, TAS"
            },
            {
                storeItems: "2275, KFC Epping Plaza Food Court, VIC"
            },
            {
                storeItems: "2276, KFC Legana, TAS"
            },
            {
                storeItems: "2277, KFC Claremont, TAS"
            },
            {
                storeItems: "2278, KFC Ulverstone, TAS"
            },
            {
                storeItems: "2279, KFC Derwent Park, TAS"
            },
            {
                storeItems: "2280, KFC Kings Meadow, TAS"
            },
            {
                storeItems: "2281, KFC Mowbray, TAS"
            },
            {
                storeItems: "2282, KFC Burnie, TAS"
            },
            {
                storeItems: "2283, KFC Devonport, TAS"
            },
            {
                storeItems: "2284, KFC Rosny Park, TAS"
            },
            {
                storeItems: "2285, KFC Thomastown, VIC"
            },
            {
                storeItems: "2286, KFC Northgate, TAS"
            },
            {
                storeItems: "2287, KFC Marion, SA"
            },
            {
                storeItems: "2288, KFC Port Pirie, SA"
            },
            {
                storeItems: "2289, KFC Port Augusta, SA"
            },
            {
                storeItems: "2290, KFC Noarlunga, SA"
            },
            {
                storeItems: "2291, KFC Port Lincoln, SA"
            },
            {
                storeItems: "2292, KFC Marion Mall Food Court, SA"
            },
            {
                storeItems: "2293, KFC Victor Harbor, SA"
            },
            {
                storeItems: "2294, KFC Welland Plaza, SA"
            },
            {
                storeItems: "2295, KFC Whyalla, SA"
            },
            {
                storeItems: "2296, KFC West Lakes, SA"
            },
            {
                storeItems: "2297, KFC Byford, WA"
            },
            {
                storeItems: "2298, KFC Armadale, WA"
            },
            {
                storeItems: "2299, KFC Green Hills Food Court, NSW"
            },
            {
                storeItems: "2300, KFC Wollongong Central, NSW"
            },
            {
                storeItems: "2301, KFC Bondi Junction Eastgate, NSW"
            },
            {
                storeItems: "2302, KFC Canadian, VIC"
            },
            {
                storeItems: "2303, KFC Lucas, VIC"
            },
            {
                storeItems: "2305, KFC Darlinghurst, NSW"
            },
            {
                storeItems: "2306, KFC Goulburn South, NSW"
            },
            {
                storeItems: "2307, KFC Jerrabomberra, ACT"
            },
            {
                storeItems: "2308, KFC Canberra City, ACT"
            },
            {
                storeItems: "2309, KFC Castle Towers Level 1, NSW"
            },
            {
                storeItems: "2310, KFC Glen Waverley Central, VIC"
            },
            {
                storeItems: "2311, KFC Pulteney Street, SA"
            },
            {
                storeItems: "2312, KFC Kings Cross, NSW"
            },
            {
                storeItems: "2313, KFC Dandenong South, VIC"
            },
            {
                storeItems: "2314, KFC Kooragang, NSW"
            },
            {
                storeItems: "2315, KFC Mt Gambier Market Place (Drive Thru Only), SA VIC"
            },
            {
                storeItems: "2316, KFC Pennant Hills, NSW"
            },
            {
                storeItems: "2317, KFC Forbes, NSW"
            },
            {
                storeItems: "2318, KFC Cranebrook, NSW"
            },
            {
                storeItems: "2319, KFC Green Square, NSW"
            },
            {
                storeItems: "2320, KFC Shepparton North, VIC"
            },
            {
                storeItems: "2322, KFC Officer Arena , VIC"
            },
            {
                storeItems: "2323, KFC Ballina Travel Centre, VIC"
            },
            {
                storeItems: "2324, KFC Kogarah, NSW"
            },
            {
                storeItems: "2326, KFC Greenacre, NSW"
            },
            {
                storeItems: "2327, KFC Northam, WA"
            },
            {
                storeItems: "2328, KFC Leppington Central, NSW"
            },
            {
                storeItems: "2329, KFC Melton South, VIC"
            },
            {
                storeItems: "2330, KFC Cameron Park, NSW"
            },
            {
                storeItems: "2331, KFC Bennets Green, VIC"
            },
            {
                storeItems: "2332, KFC Chatswood Interchange, NSW"
            },
            {
                storeItems: "1163, KFC Irymple, VIC"
            },
            {
                storeItems: "2335, KFC Sheidow Park Hallets Cove, SA"
            },
            {
                storeItems: "2336, KFC Penlink Outbound, VIC"
            },
            {
                storeItems: "2337, KFC Penlink Inbound, VIC"
            },
            {
                storeItems: "2340, KFC Brighton TAS, TAS"
            },
            {
                storeItems: "6510, KFC Rutherford, NSW"
            },
            {
                storeItems: "6513, KFC Hoxton Park, NSW"
            },
            {
                storeItems: "6514, KFC Glendale, NSW"
            },
            {
                storeItems: "6517, KFC Plumpton, NSW"
            },
            {
                storeItems: "6538, KFC Rosehill, NSW"
            },
            {
                storeItems: "6550, KFC Richmond South, NSW"
            },
            {
                storeItems: "6553, KFC Penrith South, NSW"
            },
            {
                storeItems: "6554, KFC Minto, NSW"
            },
            {
                storeItems: "6555, KFC Bonnyrigg, NSW"
            },
            {
                storeItems: "6556, KFC Kings Park, NSW"
            },
            {
                storeItems: "6558, KFC Werrington, NSW"
            },
            {
                storeItems: "6571, KFC Macquarie Centre, NSW"
            },
            {
                storeItems: "6592, KFC East Maitland, NSW"
            },
            {
                storeItems: "6601, KFC Jesmond, NSW"
            },
            {
                storeItems: "6602, KFC Minchinbury, NSW"
            },
            {
                storeItems: "6617, KFC Fairy Meadow, NSW"
            },
            {
                storeItems: "6618, KFC Unanderra, NSW"
            },
            {
                storeItems: "6619, KFC Warrawong, NSW"
            },
            {
                storeItems: "6636, KFC Kotara, NSW"
            },
            {
                storeItems: "6640, KFC Mt Druitt, NSW"
            },
            {
                storeItems: "6643, KFC St Clair, NSW"
            },
            {
                storeItems: "6668, KFC Campbelltown, NSW"
            },
            {
                storeItems: "6689, KFC Wetherill Park (Outside), NSW"
            },
            {
                storeItems: "6693, KFC Dapto, NSW"
            },
            {
                storeItems: "6703, KFC Frenchs Forest, NSW"
            },
            {
                storeItems: "6705, KFC Flowerdale, NSW"
            },
            {
                storeItems: "6709, KFC Emerton, NSW"
            },
            {
                storeItems: "6719, KFC Kincumber, NSW"
            },
            {
                storeItems: "6721, KFC Mingara, NSW"
            },
            {
                storeItems: "6727, KFC West Gosford, NSW"
            },
            {
                storeItems: "6729, KFC Mayfield, NSW"
            },
            {
                storeItems: "6732, KFC Granville, NSW"
            },
            {
                storeItems: "6734, KFC Palais, NSW"
            },
            {
                storeItems: "6744, KFC Broadmeadow (Drive Thru Only), NSW"
            },
            {
                storeItems: "6753, KFC Parklea, NSW"
            },
            {
                storeItems: "6754, KFC Erina, NSW"
            },
            {
                storeItems: "6758, KFC Marsden Park, NSW"
            },
            {
                storeItems: "7810, KFC Elizabeth, SA"
            },
            {
                storeItems: "7812, KFC Reynella, SA"
            },
            {
                storeItems: "7824, KFC Modbury, SA"
            },
            {
                storeItems: "7830, KFC Golden Grove, SA"
            },
            {
                storeItems: "7832, KFC Murray Bridge, SA"
            },
            {
                storeItems: "7836, KFC Port Adelaide, SA"
            },
            {
                storeItems: "7838, KFC Mt Barker, SA"
            },
            {
                storeItems: "7840, KFC Marden, SA"
            },
            {
                storeItems: "7842, KFC Mawson Lakes, SA"
            },
            {
                storeItems: "7843, KFC Salisbury, SA"
            },
            {
                storeItems: "7846, KFC Gawler, SA"
            },
            {
                storeItems: "7850, KFC Elizabeth Mall, SA"
            },
            {
                storeItems: "7855, KFC Paralowie, SA"
            },
            {
                storeItems: "7856, KFC Munno Para, SA"
            },
            {
                storeItems: "7858, KFC Playford, SA"
            },
            {
                storeItems: "9739, KFC Edmonton, QLD"
            },
            {
                storeItems: "9740, KFC Pimpama, QLD"
            },
            {
                storeItems: "9741, KFC Ascot, WA"
            },
            {
                storeItems: "9742, KFC Meadowbrook, QLD"
            },
            {
                storeItems: "9743, KFC Toowoomba East, QLD"
            },
            {
                storeItems: "9744, KFC Butler, WA"
            },
            {
                storeItems: "9745, KFC Baldivis, WA"
            },
            {
                storeItems: "9746, KFC Ormeau, QLD"
            },
            {
                storeItems: "9747, KFC Caboolture City, QLD"
            },
            {
                storeItems: "9748, KFC Victoria Point, QLD"
            },
            {
                storeItems: "9749, KFC Brisbane Airport, QLD"
            },
            {
                storeItems: "9750, KFC Yamanto, QLD"
            },
            {
                storeItems: "9751, KFC Kedron, QLD"
            },
            {
                storeItems: "9755, KFC Greenslopes, QLD"
            },
            {
                storeItems: "9756, KFC Morningside, QLD"
            },
            {
                storeItems: "9757, KFC Clayfield, QLD"
            },
            {
                storeItems: "9758, KFC Booval, QLD"
            },
            {
                storeItems: "9759, KFC Kangaroo Point, QLD"
            },
            {
                storeItems: "9761, KFC Kelvin Grove, QLD"
            },
            {
                storeItems: "9764, KFC Aspley, QLD"
            },
            {
                storeItems: "9766, KFC Bundaberg, QLD"
            },
            {
                storeItems: "9767, KFC Miami, QLD"
            },
            {
                storeItems: "9769, KFC Cairns, QLD"
            },
            {
                storeItems: "9770, KFC Indooroopilly, QLD"
            },
            {
                storeItems: "9771, KFC Mackay, QLD"
            },
            {
                storeItems: "9772, KFC Springwood, QLD"
            },
            {
                storeItems: "9773, KFC Mt Isa, QLD"
            },
            {
                storeItems: "9774, KFC Garbutt, QLD"
            },
            {
                storeItems: "9775, KFC Wynnum, QLD"
            },
            {
                storeItems: "9776, KFC Gladstone, QLD"
            },
            {
                storeItems: "9777, KFC Maryborough, QLD"
            },
            {
                storeItems: "9778, KFC Maroochydore, QLD"
            },
            {
                storeItems: "9779, KFC Gympie, QLD"
            },
            {
                storeItems: "9780, KFC Biloela, QLD"
            },
            {
                storeItems: "9781, KFC Roma, QLD"
            },
            {
                storeItems: "9782, KFC Innisfail, QLD"
            },
            {
                storeItems: "9783, KFC Strathpine, QLD"
            },
            {
                storeItems: "9785, KFC Everton Park, QLD"
            },
            {
                storeItems: "9787, KFC Wilsonton, QLD"
            },
            {
                storeItems: "9788, KFC Ayr, QLD"
            },
            {
                storeItems: "9789, KFC Acacia Ridge, QLD"
            },
            {
                storeItems: "9790, KFC Dalby, QLD"
            },
            {
                storeItems: "9792, KFC Caloundra, QLD"
            },
            {
                storeItems: "9793, KFC Deagon, QLD"
            },
            {
                storeItems: "9797, KFC Nambour, QLD"
            },
            {
                storeItems: "9798, KFC Mt Pleasant, QLD"
            },
            {
                storeItems: "9799, KFC Woodridge, QLD"
            },
            {
                storeItems: "9800, KFC Cranbrook, QLD"
            },
            {
                storeItems: "9801, KFC Rockhampton South, QLD"
            },
            {
                storeItems: "9802, KFC Rockhampton North, QLD"
            },
            {
                storeItems: "9803, KFC Redbank, QLD"
            },
            {
                storeItems: "9804, KFC Annerley, QLD"
            },
            {
                storeItems: "9805, KFC Caboolture, QLD"
            },
            {
                storeItems: "9806, KFC Mermaid Beach, QLD"
            },
            {
                storeItems: "9807, KFC Runaway Bay, QLD"
            },
            {
                storeItems: "9808, KFC Toowoomba, QLD"
            },
            {
                storeItems: "9809, KFC Ingham, QLD"
            },
            {
                storeItems: "9810, KFC Sunnybank, QLD"
            },
            {
                storeItems: "9811, KFC Logan Hyperdome, QLD"
            },
            {
                storeItems: "9813, KFC The Pines, QLD"
            },
            {
                storeItems: "9814, KFC Marsden, QLD"
            },
            {
                storeItems: "9815, KFC Indooroopilly Food Court, QLD"
            },
            {
                storeItems: "9816, KFC Earlville, QLD"
            },
            {
                storeItems: "9817, KFC Grovely, QLD"
            },
            {
                storeItems: "9819, KFC Nerang, QLD"
            },
            {
                storeItems: "9820, KFC Centenary, QLD"
            },
            {
                storeItems: "9821, KFC Brassall, QLD"
            },
            {
                storeItems: "9822, KFC Cleveland, QLD"
            },
            {
                storeItems: "9823, KFC Myer Centre, QLD"
            },
            {
                storeItems: "9824, KFC Kallangur, QLD"
            },
            {
                storeItems: "9827, KFC Australia Fair, QLD"
            },
            {
                storeItems: "9828, KFC Benowa, QLD"
            },
            {
                storeItems: "9829, KFC Pacific Fair, QLD"
            },
            {
                storeItems: "9830, KFC Mareeba, QLD"
            },
            {
                storeItems: "9831, KFC Emerald, QLD"
            },
            {
                storeItems: "9833, KFC Deception Bay, QLD"
            },
            {
                storeItems: "9835, KFC Kingaroy, QLD"
            },
            {
                storeItems: "9838, KFC Helensvale, QLD"
            },
            {
                storeItems: "9840, KFC Browns Plains, QLD"
            },
            {
                storeItems: "9841, KFC Smithfield, QLD"
            },
            {
                storeItems: "9842, KFC Earlville Food Court, QLD"
            },
            {
                storeItems: "9843, KFC Moranbah, QLD"
            },
            {
                storeItems: "9845, KFC Atherton, QLD"
            },
            {
                storeItems: "9846, KFC Gatton, QLD"
            },
            {
                storeItems: "9848, KFC Tannum Sands, QLD"
            },
            {
                storeItems: "9849, KFC Garden City FC Qld, QLD"
            },
            {
                storeItems: "9851, KFC Strathpine Food Court, QLD"
            },
            {
                storeItems: "9852, KFC Carindale Food Court, QLD"
            },
            {
                storeItems: "9853, KFC Albany Creek, QLD"
            },
            {
                storeItems: "9854, KFC Carindale, QLD"
            },
            {
                storeItems: "9855, KFC Chermside Food Court, QLD"
            },
            {
                storeItems: "9856, KFC Loganholme, QLD"
            },
            {
                storeItems: "9857, KFC Grand Plaza Food Court, QLD"
            },
            {
                storeItems: "9858, KFC Tweed Heads, NSW"
            },
            {
                storeItems: "9859, KFC Annandale, QLD"
            },
            {
                storeItems: "9860, KFC BP Caboolture North, QLD"
            },
            {
                storeItems: "9861, KFC Nathan Plaza, QLD"
            },
            {
                storeItems: "9862, KFC Kawana Food Court, QLD"
            },
            {
                storeItems: "9863, KFC BP Caboolture South, QLD"
            },
            {
                storeItems: "9864, KFC MacArthur Central Food Court, QLD"
            },
            {
                storeItems: "9866, KFC Inala, QLD"
            },
            {
                storeItems: "9867, KFC Grand Central, QLD"
            },
            {
                storeItems: "9868, KFC Hermit Park, QLD"
            },
            {
                storeItems: "9869, KFC Springfield Food Court, QLD"
            },
            {
                storeItems: "9870, KFC Riverlink Food Court, QLD"
            },
            {
                storeItems: "9871, KFC North Lakes Food Court, QLD"
            },
            {
                storeItems: "9872, KFC Hervey Bay, QLD"
            },
            {
                storeItems: "9874, KFC Capalaba Central Food Court, QLD"
            },
            {
                storeItems: "9875, KFC Woodlands, QLD"
            },
            {
                storeItems: "9876, KFC Plainland, QLD"
            },
            {
                storeItems: "9877, KFC Valley Metro, QLD"
            },
            {
                storeItems: "9878, KFC Mackay Food Court, QLD"
            },
            {
                storeItems: "9879, KFC Upper Coomera, QLD"
            },
            {
                storeItems: "9880, KFC Beaudesert, QLD"
            },
            {
                storeItems: "9881, KFC Coomera Food Court, QLD"
            },
            {
                storeItems: "9882, KFC Chinchilla, QLD"
            },
            {
                storeItems: "9883, KFC North Lakes, QLD"
            },
            {
                storeItems: "9884, KFC Wurtulla, QLD"
            },
            {
                storeItems: "9885, KFC Jimboomba, QLD"
            },
            {
                storeItems: "9886, KFC Cairns Central Food Court, QLD"
            },
            {
                storeItems: "9887, KFC Helensvale Food Court, QLD"
            },
            {
                storeItems: "9888, KFC Yeppoon, QLD"
            },
            {
                storeItems: "9889, KFC Hinkler Place Food Court, QLD"
            },
            {
                storeItems: "9890, KFC Capalaba Park Food Court, QLD"
            },
            {
                storeItems: "9891, KFC Robina Drive Thru, QLD"
            },
            {
                storeItems: "9892, KFC Tweed City Food Court, NSW"
            },
            {
                storeItems: "9893, KFC Beenleigh, QLD"
            },
            {
                storeItems: "9894, KFC Capalaba, QLD"
            },
            {
                storeItems: "9895, KFC Robina Food Court, QLD"
            },
            {
                storeItems: "9896, KFC Surfers Paradise, QLD"
            },
            {
                storeItems: "9897, KFC Rockhampton Food Court, QLD"
            },
            {
                storeItems: "9898, KFC Warwick, QLD"
            },
            {
                storeItems: "9899, KFC Springfield, QLD"
            },
            {
                storeItems: "9901, KFC Albany, WA"
            },
            {
                storeItems: "9903, KFC Bagot Road, NT"
            },
            {
                storeItems: "9905, KFC Beechboro, WA"
            },
            {
                storeItems: "9906, KFC Beldon, WA"
            },
            {
                storeItems: "9908, KFC Belmont Forum, WA"
            },
            {
                storeItems: "9910, KFC Bunbury Forum, WA"
            },
            {
                storeItems: "9911, KFC Busselton, WA"
            },
            {
                storeItems: "9913, KFC Casuarina, NT"
            },
            {
                storeItems: "9914, KFC William Street, WA"
            },
            {
                storeItems: "9916, KFC Dogswamp, WA"
            },
            {
                storeItems: "9918, KFC Geraldton, WA"
            },
            {
                storeItems: "9920, KFC Innaloo, WA"
            },
            {
                storeItems: "9921, KFC Joondalup, WA"
            },
            {
                storeItems: "9922, KFC Kalgoorlie, WA"
            },
            {
                storeItems: "9923, KFC Karawara, WA"
            },
            {
                storeItems: "9924, KFC Kelmscott, WA"
            },
            {
                storeItems: "9926, KFC Mandurah, WA"
            },
            {
                storeItems: "9927, KFC Marangaroo, WA"
            },
            {
                storeItems: "9928, KFC Maylands, WA"
            },
            {
                storeItems: "9929, KFC Melville, WA"
            },
            {
                storeItems: "9930, KFC Midland, WA"
            },
            {
                storeItems: "9931, KFC Mirrabooka, WA"
            },
            {
                storeItems: "9932, KFC Morley, WA"
            },
            {
                storeItems: "9933, KFC Mundaring, WA"
            },
            {
                storeItems: "9935, KFC Palmerston, NT"
            },
            {
                storeItems: "9937, KFC South Lake, WA"
            },
            {
                storeItems: "9938, KFC South Perth, WA"
            },
            {
                storeItems: "9939, KFC Spearwood, WA"
            },
            {
                storeItems: "9940, KFC Subiaco, WA"
            },
            {
                storeItems: "9941, KFC Thornlie, WA"
            },
            {
                storeItems: "9942, KFC Whitfords, WA"
            },
            {
                storeItems: "9943, KFC Willetton, WA"
            },
            {
                storeItems: "9944, KFC Sippy Downs, QLD"
            },
            {
                storeItems: "9945, KFC Redcliffe, QLD"
            },
            {
                storeItems: "1194, KFC Ararat, VIC"
            },
            {
                storeItems: "9947, KFC Clarkson, WA"
            },
            {
                storeItems: "9948, KFC Casuarina Food Court, NT"
            },
            {
                storeItems: "9950, KFC Garden City FC WA, WA"
            },
            {
                storeItems: "9951, KFC Warwick WA, WA"
            },
            {
                storeItems: "9952, KFC Warnbro, WA"
            },
            {
                storeItems: "9954, KFC Bunbury South, WA"
            },
            {
                storeItems: "9957, KFC Falcon, WA"
            },
            {
                storeItems: "9958, KFC Ashmore, QLD"
            },
            {
                storeItems: "9959, KFC Rockingham Beach, WA"
            },
            {
                storeItems: "9960, KFC Banksia Grove, WA"
            },
            {
                storeItems: "9961, KFC Calamvale, QLD"
            },
            {
                storeItems: "9962, KFC Harrisdale, WA"
            },
            {
                storeItems: "9963, KFC Atwell, WA"
            },
            {
                storeItems: "9964, KFC Kingsway, WA"
            },
            {
                storeItems: "9965, KFC Lakelands, WA"
            },
            {
                storeItems: "9966, KFC Coolalinga, NT"
            },
            {
                storeItems: "9967, KFC Kirwan, QLD"
            },
            {
                storeItems: "9968, KFC Keperra, QLD"
            },
            {
                storeItems: "9969, KFC Mackay North, QLD"
            },
            {
                storeItems: "9970, KFC Tingalpa, QLD"
            },
            {
                storeItems: "9971, KFC Bundaberg East, QLD"
            },
            {
                storeItems: "9972, KFC Morayfield Food Court, QLD"
            },
            {
                storeItems: "9973, KFC Birkdale, QLD"
            },
            {
                storeItems: "9974, KFC Arundel, QLD"
            },
            {
                storeItems: "9975, KFC Springfield Parkway, QLD"
            },
            {
                storeItems: "9976, KFC Karrinyup, WA"
            },
            {
                storeItems: "9977, KFC Redlynch, QLD"
            },
            {
                storeItems: "9978, KFC Southport, QLD"
            },
            {
                storeItems: "9979, KFC Buderim, QLD"
            },
            {
                storeItems: "9980, KFC Urangan, QLD"
            },
            {
                storeItems: "9981, KFC Bethania, QLD"
            },
            {
                storeItems: "9982, KFC Highfields, QLD"
            },
            {
                storeItems: "9983, KFC Midvale, WA"
            },
            {
                storeItems: "9984, KFC Burleigh Waters, QLD"
            },
            {
                storeItems: "9985, KFC Eight Mile Plains, QLD"
            },
            {
                storeItems: "9986, KFC Berrinba, QLD"
            },
            {
                storeItems: "9987, KFC Rasmussen, QLD"
            },
            {
                storeItems: "9989, KFC Burpengary, QLD"
            },
            {
                storeItems: "9990, KFC Eagleby, QLD"
            },
            {
                storeItems: "9993, KFC Warner, QLD"
            },
            {
                storeItems: "9994, KFC Noosaville, QLD"
            },
            {
                storeItems: "9995, KFC Oxenford, QLD"
            },
            {
                storeItems: "9996, KFC Redbank Plains, QLD"
            }
        ];

        function moveElementFirstLevel(e) {
            let newItem = document.createElement("LI");
            let newItemSecond = document.createElement("a");
            newItem.classList.add('MoveElementsToLocationRemove');
            newItem.onclick = function() {
                this.remove();
            };
            newItem.appendChild(newItemSecond);

            var textnode = document.createTextNode(e.target.text);
            newItemSecond.appendChild(textnode);

            var list = document.getElementById("MoveElementsToLocation");
            list.insertBefore(newItem, list.childNodes[0]);
        }

        return (
            <Fragment>

                <div className="fourth-step fourth-step--new-compaing">
                    <h1>04 / 05</h1>
                    <h4>Select your your locations</h4>
                    <p>Select the fields need to refine your Select Store.</p>

                    <div className="popup__success">
                        <Select
                            defaultValue={options[0]}
                            className="dropdown dropdowLocationStep"
                            onChange={this.handleChange}
                            options={options}
                            styles={style}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator: DropdownIndicator
                              }}
                        />
                        <div className="popup__widgets">
                            <div className="widgets-col">
                                <div className="widget__single">
                                    <div className="widget__pod__info">
                                        <div className="widget__pod__info__successes">
                                            <div className="widget__pod__info__successes--search">
                                                <input placeholder="Search"/>
                                            </div>
                                            <a className="widget__pod__info__successes--btn" href="#">Add </a>
                                        </div>
                                    </div>
                                    <ul className={'location-list-items'}>
                                        {location.map(function (item, i) {
                                            return (
                                                <li onClick={moveElementFirstLevel}>
                                                    <a>{item.storeItems}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="widgets-col">
                                <div className="widget__single">
                                    <div className="widget__pod__info">
                                        <div className="widget__pod__info__successes">
                                            <div className="widget__pod__info__successes--search">
                                                <h5>New Market</h5>
                                            </div>
                                            <a className="widget__pod__info__successes--btn widget__pod__info__successes--btn--blue"
                                               href="#">Save </a>
                                        </div>
                                    </div>
                                    <ul className="bordered-list location-list-items-remove" id="MoveElementsToLocation"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group">
                    <a
                        label="Back"
                        onClick={this.back}
                        className="cancel-btn"
                    >
                        Back
                    </a>
                    <a
                        label="Next"
                        onClick={this.continue}
                        className="next-btn"
                    >
                        Next
                    </a>
                </div>
            </Fragment>
        );
    }

    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleText = (ev) => {
        this.setState({
            haveText: ev.currentTarget.textContent
        })
    }
}

export default CompaingFormFourth;
