import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { PDFDocument, rgb } from 'pdf-lib';
import * as pdfjs from 'pdfjs-dist';
import fontkit from '@pdf-lib/fontkit';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Container = styled.div`
    width: 927px;
    height: 1278px;
    border: 2px solid black;
    margin: 20px auto 0;
`;

// const pdfPath = 'http://localhost:3000/static/LSM/Advertising-&-Signage/LSM-offers/$2.50_GoBucket_A5_flyer/Hi-Res/$2.50_GoBucket_A5_flyer.pdf';
// const pdfPath = 'http://localhost:3000/static/LSM/Advertising-&-Signage/LSM-offers/$2.50_GoBucket_Krusher_Blade/Hi-Res/$2.50_GoBucket_Krusher_Blade.pdf';
const pdfPath = 'http://localhost:3000/static/LSM/Advertising-&-Signage/Store-openings-&-closures/A4_Poster_-_Still_Open_Trading_Hours_-_Display_Front_Door_-Editable/Hi-Res/A4_Still_Open_Trading_Hours_Display_Front_Door_Editable.pdf';
// const pdfPath = 'http://localhost:3000/static/LSM/Advertising-&-Signage/Store-openings-&-closures/A5_flyer_Now_open/Hi-Res/A5_flyer_Now_open.pdf';
const fontURL = 'https://www.kfc.com.au/static/media/National2Condensed-Bold.a091dde9.otf';

let renders = 0;

const PDFViewer = function({bytes}) {
  // const [renders, setRenders] = useState(0);
  const [pdfDocument, setPdfDocument] = useState();
  const [canvas, setCanvas] = useState();
  const [viewport, setViewport] = useState();
  const [annotationEditor, setAnnotationEditor] = useState();
  const [numPages, setNumPages] = useState();
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [currentPage, setCurrentPage] = useState(null);
  const [scale, setScale] = useState(1);
  
  const canvasRef = useRef();

  let fetchDocument = useCallback(async () =>
  {
    console.log('fetchDocument called');
    const canvas = canvasRef.current;
    setCanvas(canvas);
    const loader = await pdfjs.getDocument({data: bytes});
    const doc = await loader.promise;
    const numPages = doc._pdfInfo.numPages;
    
    if (numPages > 0)
    {
        setNumPages(doc._pdfInfo.numPages);
        const page = await doc.getPage(currentPageNum);
        setCurrentPage(page);
        const viewport = page.getViewport({ scale });
        const canvasContext = canvas.getContext('2d');
        // canvasContext.clearRect(0,0,viewport.width,viewport.height);
        
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        setViewport(viewport);
        // renderCurrentPage();
        page.render({
          canvasContext,
          viewport
        });
    }
    setPdfDocument(doc);
  }, [bytes]);

  useEffect(() => {
      console.log('fetchDocument changed');
      fetchDocument().catch(console.error);

  },[fetchDocument]);

  // useEffect(() =>{
  //   console.log('bytes changed');
  //   fetchDocument().catch(console.error);
  // },[bytes]);
  
  // const renderCurrentPage = () => {
  //   if (canvas && viewport)
  //   {
  //     console.log('render current page');
  //     const canvasContext = canvas.getContext('2d');
  //     // canvasContext.clearRect(0,0,viewport.width,viewport.height);
  //     currentPage.render({
  //       canvasContext,
  //       viewport
  //     });
  //   }
  // }

  console.log(renders);
  renders++;
  console.log('REDRAWING PDF');
  return (
    <canvas ref={canvasRef} />
  );

}

const FieldContainer = styled.div`
  
`

const PDFForm = function({children}) {

  const onSubmit = (e) => {
    e.preventDefault();
  }

  return (
    <form onSubmit={onSubmit}>
      {children}
    </form>
  );
}



const PDFField = function ({label, defaultValue, onEditField}) {
  const [inputText, setInputText] = useState(defaultValue);

  useEffect(() => {
    onEditField(inputText);
  },[inputText]);

  return (
    <FieldContainer>
      <label>{label}: </label>
      <input type="text" onChange={(e) => setInputText(e.currentTarget.value)} value={inputText} />
    </FieldContainer>
  );
}

export default function PDFEditor() {
    const [pdfDocument, setPdfDocument] = useState();
    const [pages, setPages] = useState();
    const [form, setForm] = useState();
    const [fields, setFields] = useState([]);
    const [docAsBytes, setDocAsBytes] = useState();
    const [font, setFont] = useState();
    // const [canvas, setCanvas] = useState();
    // const [viewport, setViewport] = useState();
    // const [numPages, setNumPages] = useState();
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [currentPage, setCurrentPage] = useState(null);
    // const [scale, setScale] = useState(1);
    // const [annotations, setAnnotations] = useState();
    const [inputText, setInputText] = useState('Mon 10am - 10pm');

    const canvasRef = useRef();

    const fetchDocument = useCallback(async () => {
      console.log('initial fetchDocument');
      const bytes = await fetch(pdfPath).then(res => res.arrayBuffer());
      const doc = await PDFDocument.load(bytes);
      const pages = doc.getPages();
      const form = doc.getForm();
      const fields = form.getFields();
      const currentPage = pages[0];

      doc.registerFontkit(fontkit);
      const fontBytes = await fetch(fontURL).then(res => res.arrayBuffer());
      const font = await doc.embedFont(fontBytes);

      setPdfDocument(doc);
      setPages(pages);
      setCurrentPage(currentPage);
      setForm(form);
      setFields(fields);
      setFont(font);

      console.log('font',font);

      form.updateFieldAppearances(font);

      if (fields.length) {
        console.log('update fields');
        fields.forEach(field => {
          field.updateAppearances(font);
          field.defaultUpdateAppearances(font);
        });
      }

      const pdfBytes = await doc.save();
      setDocAsBytes(pdfBytes);
    
      
      console.log('doc',doc);
      console.log('pages',pages);
      console.log('form',form);
      console.log('fields',fields);


    }, []);
    
    useEffect(() => {
        fetchDocument().catch(console.error);
    },[fetchDocument]);

    const onEditField = async (i,value) => {
      console.log('onEditField',i,value);
      fields[i].setText(value);
      const pdfBytes = await pdfDocument.save();
      setDocAsBytes(pdfBytes);
    }

    return (
      <Container>
        {docAsBytes && (
          <PDFViewer bytes={docAsBytes} currentPage={currentPage} />
        )}
        <PDFForm>
          {fields && fields.map((field, i) => (
            <PDFField key={i} label={field.getName()} defaultValue={field.getText()} onEditField={(value) => {onEditField(i, value)}} />
          ))}
        </PDFForm>
      </Container>
    )
}

