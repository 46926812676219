import React from 'react';
import Popup from './Popup';
import Button from '../Button/Button';

export default function ConfirmPopup(props) {

    const { trigger, setTrigger, style, popupStyle, closeBtn, customClass } = props;
    const popupProps = {trigger, setTrigger, style, popupStyle, closeBtn, customClass};

    const onCancel = () => {
        if (typeof setTrigger == 'function')
        {
            setTrigger(false);
        }
    }

    return (
        <Popup {...popupProps} style={{maxWidth:'500px'}}>
            <div>
                {props.children}
            </div>
            <div className="button-group end">
                <Button className="highlight" onClick={props.onConfirmButtonClick}>{props.confirmText ? props.confirmText : 'Confirm'}</Button>
                <Button className="" onClick={onCancel}>{props.cancelText ? props.cancelText : 'Cancel'}</Button>
            </div>
        </Popup>
    );
}
