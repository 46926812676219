import { Routes, Route, BrowserRouter } from "react-router-dom";

import { AuthProvider } from '../context/auth';
import AuthBoundary from './AuthBoundary';
import AuthRoute from './RequiresRole';
import Layout from '../layouts';

// Auth
import Login from '../pages/Auth/Login/Login';
import SSOCallback from '../pages/Auth/SSO/Callback';
import Registration from '../pages/Auth/Registration/Registration';
import ForgotPassword from '../pages/Auth/ForgotPassword/ForgotPassword';
import ForgotUsername from '../pages/Auth/ForgotUsername/ForgotUsername';
import RequestLogin from '../pages/Auth/RequestLogin/RequestLogin';
// Protected
import Dashboard from '../pages/Dashboard/Dashboard';
import Profile from '../pages/Profile/Profile';
import Search from '../pages/Search/Search';
import Pods from '../pages/Pods/Pods';
import Zones from '../pages/Pods/Zones/Zones';
import Stores from '../pages/Pods/Stores/Stores';
import Store from '../pages/Pods/Store/Store';
import LSM from '../pages/LSM/LSM';
import Categories from '../pages/LSM/Categories/Categories';
import BriefingTemplates from '../pages/LSM/BriefingTemplates/BriefingTemplates';
import Contacts from '../pages/LSM/Contacts/Contacts';
import Recruitment from '../pages/LSM/Recruitment/Recruitment';
import AddEditStores from '../pages/AddEditStores/AddEditStores';
import General from '../pages/Settings/General/General';
import UserList from '../pages/Settings/UserList/UserList';
import RequestLoginList from '../pages/Settings/RequestLoginList/RequestLoginList';
import Email from '../pages/Settings/Email/Email';
import AssetSettings from '../pages/Settings/Assets/AssetSettings';
// General
import NoMatch from '../pages/NoMatch/NoMatch';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import NewPassword from '../pages/Auth/NewPassword/NewPassword';
import SubCategories from '../pages/LSM/Categories/SubCategories';
import Archive from '../pages/LSM/Categories/Archive';
import Coupon from '../pages/LSM/Categories/Coupon';

import { USER_TYPE } from '../defs/User';
import RequiresRole from "./RequiresRole";
import CategoryManager from "../pages/CategoryManager";
import StoreManager from "../pages/StoreManager";


import DevSearch from "../components/Sandbox/Search";
import DevBuilder from "../components/Sandbox/Builder";
import CategoryBrowser from "../components/Sandbox/CategoryBrowser";
import PDFEditor from "../components/Sandbox/PDFEditor";
import SearchResults from "../components/SearchResults/SearchResults";
import StoreImporter from '../components/Sandbox/StoreImporter';


function Router() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<AuthBoundary requiresAuth={true} redirectTo="/login" />}>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Dashboard title={'Dashboard | KFC LSM'} />} />
                            <Route path="/profile" element={<Profile title={'Profle | KFC LSM'} />} />
                            <Route path="/search" element={<Search title={'Search | KFC LSM'} />} />
                            <Route path="/pods" element={<Pods title={'Pods | KFC LSM'} />} />
                            <Route path="/pods/:podsId/:timeZoneId/:zoneId" element={<Zones title={'Zones | KFC LSM'} />} />
                            <Route path="/pods/:podsId/:timeZoneId/zones/:zoneId/stores" element={<Stores title={'Stores | KFC LSM'} />} />
                            <Route path="/pods/:podsId/:timeZoneId/zones/:zoneId/stores/:storeId" element={<Store title={'Store | KFC LSM'} />} />
                            <Route path="/add-edit-stores" element={
                                <RequiresRole roles={[USER_TYPE.ADMIN]}>
                                    <AddEditStores title={'Add / Edit Stores | KFC LSM'} />
                                </RequiresRole>
                            } />
                            <Route path="/categories" element={
                                <RequiresRole roles={[USER_TYPE.ADMIN]}>
                                    <CategoryManager title={'Category Manager | KFC LSM'} />
                                </RequiresRole>
                            } />
                            <Route path="/stores" element={
                                <RequiresRole roles={[USER_TYPE.ADMIN]}>
                                    <StoreManager title={'Store Manager | KFC LSM'} />
                                </RequiresRole>
                            } />
                            <Route path="/settings/general" element={<General title={'General | KFC LSM'} />} />
                            <Route path="/settings/users" element={<UserList title={'Users | KFC LSM'} />} />
                            <Route path="/settings/request-login" element={<RequestLoginList title={'Request Login | KFC LSM'} />} />
                            <Route path="/settings/email" element={<Email title={'Email | KFC LSM'} />} />
                            <Route path="/settings/assets" element={<AssetSettings title={'Asset Settings | KFC LSM'} />} />
                            {/* LSM */}
                            <Route path="/lsm" element={<LSM title={'LSM | KFC LSM'} />} />
                            <Route path="/lsm/*" element={<Categories />} />
                            <Route path="/lsm/*/asset/:selectedAsset" element={<Categories />} />
                            <Route path="/search-results/:searchQuery" element={<SearchResults />} />

                            {/* <Route path="/lsm/:catId/asset/:assetId" element={<Categories />} /> */}
                            {/* <Route path="/lsm/advertising-and-signage" element={<Categories />} /> */}
                            {/* <Route path="/lsm/product-images" element={<Categories />} /> */}
                            {/* <Route path="/lsm/:catId/:subCatID" element={<Categories />} /> */}
                            {/* <Route path="/lsm/:catId/:subCatID/asset/:assetId" element={<Categories />} /> */}
                            {/* <Route path="/lsm/:catId/create-your-coupon" element={<Coupon />} /> */}
                            <Route path="/lsm/send-us-a-brief" element={<BriefingTemplates title={'Send Us A Brief | KFC LSM'} />} />
                            <Route path="/lsm/contacts" element={<Contacts title={'Key Contacts | KFC LSM'} />} />
                            {/* <Route path="/lsm/recruitment" element={<Categories title={'Recruitment | KFC LSM'} />} /> */}
                        </Route>
                    </Route>
                    <Route path="/" element={<AuthBoundary requiresAuth={false} blockAuthed={true} />}>
                        <Route path="/" element={<Layout />}>
                            <Route path="/sso/callback/:token" element={<SSOCallback />} />
                            <Route path="/login" element={<Login title={'Login | KFC LSM'} />} />
                            <Route path="/forgot-password" element={<ForgotPassword title={'Forgot Password | KFC LSM'} />} />
                            <Route path="/forgot-username" element={<ForgotUsername title={'Forgot Username | KFC LSM'} />} />
                            <Route path="/request-login" element={<RequestLogin title={'Request Login | KFC LSM'} />} />
                            <Route path="/registration" element={<Registration title={'Registration | KFC LSM'} />} />
                            <Route path="/new-password/:id/:token" element={<NewPassword title={'New Password | KFC LSM'} />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NoMatch title={'404 | KFC LSM'} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy title={'Privacy Policy | KFC LSM'} />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions title={'Terms & Conditions | KFC LSM'} />} />
                    <Route path="/dev/sandbox/search" element={<DevSearch title={'Dev Sandbox - Search'} />} />
                    <Route path="/dev/sandbox/builder" element={<DevBuilder title={'Dev Sandbox - Builder'} />} />
                    <Route path="/dev/sandbox/browser/*" element={<CategoryBrowser title={'Dev Sandbox - Category Browser'} />} />
                    <Route path="/dev/sandbox/pdf-editor" element={<PDFEditor title={'Dev Sandbox - PDF Editor'} />} />
                    <Route path="/dev/sandbox/import/stores" element={<StoreImporter title={'Dev Sandbox - Store Importer'} />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default Router