import React, { useRef, useState } from 'react';
import axios from 'axios';
import {toast, ToastContainer} from "react-toastify";

function FileUpload(props) {

    const passwordChecknotify = () => toast.success('File uploaded successfully', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const passwordChecknotifySecond = () => toast.error('Please select a file', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const [file, setFile] = useState('');
    const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0);
    const el = useRef();

    let storesItem = props.stores.slice(0, -2)
    let words = storesItem.split(",");

    const handleChange = (e) => {
        setProgess(0)
        const file = e.target.files[0]
        e.target.parentElement.querySelector('label span').innerHTML = file.name
        setFile(file)

        if(e.target.files[0]) {
            e.target.parentElement.querySelector('button').classList.remove('disabled')
        } else {
            e.target.parentElement.querySelector('button').classList.add('disabled')
        }
    }

    const uploadFile = () => {
        const formData = new FormData();
        let uploadNewDirectoryInner = document.querySelector('.upload-new-directory_inner-create-file .onChangeDriveThroughTime .MuiSwitch-track').classList.contains('active-thumb');
        formData.append('file', file)
        formData.append('pods', props.pods)
        formData.append('zones', props.zones)
        formData.append('timePeriod', props.timePeriod)
        formData.append('mediaType', props.mediaType)
        formData.append('screens', props.screens)
        formData.append('dayPeriod', uploadNewDirectoryInner)
        if(words.length <= 1) {
            let previousPodsSelectionSplit = props.stores.slice(0, -2)
            formData.append('stores', previousPodsSelectionSplit)
            formData.append('multiform', 'false')
        } else {
            let previousPodsSelectionSplit = props.stores.slice(0, -2)
            formData.append('stores', previousPodsSelectionSplit)
            formData.append('multiform', 'true')
        }


        axios.post(process.env.REACT_APP_API_SERVER + 'uploadestgseseag', formData).then(res => {
            getFile({ name: res.data.name, path: process.env.REACT_APP_API_SERVER + res.data.path })
        }).catch(err => console.log(err))

        if(file) {

            let activeItemsList = document.querySelectorAll('.uploadFiles-content-slider-item-stores .uploadFiles-content-slider-item-content ul li.active-item');
            let array = Array.from(activeItemsList)
            let uploadContainer = document.querySelector('#valueUploadFiles')
            for (let i = 0; i < array.length; i++) {

                const para = document.createElement("span");
                para.classList.add('valueUploadFilesItem')
                const node = document.createTextNode(props.pods + ' / ' + props.zones + ' / ' + array[i].innerText);
                para.appendChild(node);

                const para2 = document.createElement("span");
                para2.classList.add('valueUploadFilesItemFile')
                const node2 = document.createTextNode(file.name);
                para2.appendChild(node2);

                const paraThird = document.createElement("div");
                paraThird.classList.add('valueUploadFilesItemRow')

                paraThird.prepend(para);
                paraThird.prepend(para2);

                uploadContainer.prepend(paraThird);
            }
            document.querySelector('.hiddenNotifyBtnPassword').click();

            const fileSecond = ''
            setFile(fileSecond)

            document.querySelector('.file-upload label span').innerHTML = 'Add Files';
            document.querySelector('.upbutton').classList.add('disabled')

        } else {
            document.querySelector('.hiddenNotifyBtnPasswordSecond').click();
        }

    }

    return (
        <div>
            <div className="file-upload">
                <label htmlFor="upload-photo"><span>Add Files</span></label>
                <input id="upload-photo" type="file" ref={el} onChange={handleChange} />
                <button onClick={uploadFile} className="upbutton disabled">Upload</button>
            </div>
            <button className="hiddenNotifyBtnPassword"
                    onClick={passwordChecknotify}>Notify!
            </button>
            <button className="hiddenNotifyBtnPasswordSecond"
                    onClick={passwordChecknotifySecond}>Notify!
            </button>

        </div>
    );
}

export default FileUpload;
