import {Component, Fragment} from "react";
import {toast} from "react-toastify";

const passwordChecknotify = () => toast.error('Empty value', {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

export class CompaingFormSecond extends Component {
    continue = e => {
        e.preventDefault();
        let titleCompany = document.querySelector('.podsDropdown');

        if(titleCompany.value === 'default_pod') {
            document.querySelector('.podsDropdown').classList.add('input-valid');
            document.querySelector('.hiddenNotifyBtnPassword').click();
        } else {
            document.querySelector('.podsDropdown').classList.remove('input-valid');
            this.props.nextStep();
        }
    };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    constructor() {
        super();
        this.state = {
            disabled: false
        };
    }

    handleChange = selectedOption => {
        this.setState({selectedOption});
    };


    render() {
        const {isOpen, haveText} = this.state;
        const {values, handleChange} = this.props;

        return (
            <Fragment>

                <div className="second-step">
                    <h1>02 / 05</h1>
                    <h4>Select the duration this Campaign will run.</h4>
                    <p>Please fill out the information below</p>

                    <h6>Step 02. Duration</h6>
                    <span>If your campaign run across PODS, save and duplicate.</span>
                    <div
                        className={isOpen ? "dropdown active" : "dropdown"}
                        onClick={this.handleClick}>
                          <select className="podsDropdown" onChange={handleChange("pods")}>
                              <option value="default_pod">Select Duration / POD</option>
                              <option value="POD 01">POD 01 - Tuesday, 28 December 2021 / Monday, 24 January 2022</option>
                              <option value="POD 02">POD 02 - Tuesday, 25 January 2022 / Monday, 21 February 2022</option>
                              <option value="POD 03">POD 03 - Tuesday, 22 February 2022 / Monday, 21 March 2022</option>
                              <option value="POD 04">POD 04 - Tuesday, 22 March 2022 / Monday, 18 April 2022</option>
                              <option value="POD 05">POD 05 - Tuesday, 19 April 2022 / Monday, 16 May 2022</option>
                              <option value="POD 06">POD 06 - Tuesday, 17 May 2022 / Monday, 13 June 2022</option>
                              <option value="POD 07">POD 07 - Tuesday, 14 June 2022 / Monday, 11 July 2022</option>
                              <option value="POD 08">POD 08 - Tuesday, 12 July 2022 / Monday, 8 August 2022</option>
                              <option value="POD 09">POD 09 - Tuesday, 9 August 2022 / Monday, 5 September 2022</option>
                              <option value="POD 10">POD 10 - Tuesday, 6 September 2022 / Monday, 3 October 2022</option>
                              <option value="POD 11">POD 11 - Tuesday, 4 October 2022 / Monday, 31 October 2022</option>
                              <option value="POD 12">POD 12 - Tuesday, 1 November 2022 / Monday, 28 November 2022</option>
                              <option value="POD 13">POD 13 - Tuesday, 29 November 2022 / Monday, 26 December 2022</option>
                          </select>
                    </div>
                </div>
                <button className="hiddenNotifyBtnPassword" onClick={passwordChecknotify}>Notify!</button>
                <div className="button-group">
                    <button
                        label="Back"
                        onClick={this.back}
                        className="cancel-btn"
                    >
                        Back
                    </button>
                    <button
                        label="Next"
                        onClick={this.continue}
                        className="next-btn"
                    >
                        Next
                    </button>
                </div>
            </Fragment>
        );
    }

    handleClick = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleText = (ev) => {
        this.setState({
            haveText: ev.currentTarget.textContent
        })
    }
}

export default CompaingFormSecond;
