import { LineChart, Line, CartesianGrid, ResponsiveContainer } from 'recharts';

import './style.scss';
import { ReactComponent as Icon } from './icon.svg';

function ScreenCountWidget({ data }) {
  return (
    <div className='widget__files widget__files--screen-count'>
      <div className='widget__files__header'>
        <div>
          <h6>Screen: 7,478</h6>
          <span>Screen Count</span>
        </div>
        <Icon />
      </div>
      <div className='widget__files__body'>
        {data.length > 0 &&
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} >
              <CartesianGrid stroke="#D7D7D7" strokeWidth={1} />
              <Line type="monotone" dataKey="pv" stroke="#717579" strokeWidth={6} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        }
      </div>
      <div className='widget__files__footer'>
        <span>11/01/22</span>
        <span>11/01/22</span>
      </div>
    </div>
  )
}

export default ScreenCountWidget