import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Global, css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import GQL from '../../gql';


export default function CategoryBrowser() {
    const { '*': uri } = useParams();

    const { loading, error, data } = useQuery
    (
        GQL.Asset.GET_ASSET_BY_CATEGORY_URI,
        {
            variables: { uri }
        }
    );

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <ul>
            {data.getAssetsByCategoryURI.map(
                asset => (
                    <p key={uuid()}>{asset.name}</p>
               ))}
        </ul>
    );
}