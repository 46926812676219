import React, { Component, Fragment } from 'react';
import Popup from 'reactjs-popup';
import PodsUploadSecondStep from "./PodsUploadSecondStep";
import Select, { components } from "react-select";
import { toast, ToastContainer } from "react-toastify";

const options = [
    { value: 'pods_01', label: 'POD-01' },
    { value: 'pods_02', label: 'POD-02' },
    { value: 'pods_03', label: 'POD-03' },
    { value: 'pods_04', label: 'POD-04' },
    { value: 'pods_05', label: 'POD-05' },
    { value: 'pods_06', label: 'POD-06' },
    { value: 'pods_07', label: 'POD-07' },
    { value: 'pods_08', label: 'POD-08' },
    { value: 'pods_09', label: 'POD-09' },
    { value: 'pods_10', label: 'POD-10' },
    { value: 'pods_11', label: 'POD-11' },
    { value: 'pods_12', label: 'POD-12' },
    { value: 'pods_13', label: 'POD-13' }
]

const optionsTimePeriod = [
    { value: '2021_2022', label: '2021-2022' },
    { value: '2022_2023', label: '2022-2023' }
]

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
            </svg>
        </components.DropdownIndicator>
    );
};

const style = {
    option: provided => ({
        ...provided,
        color: '#171B1E',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#FA3535'
        }
    }),
    control: base => ({
        ...base,
        height: 60,
        minHeight: 60,
        color: '#171B1E',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: '1px solid #EEEEEE !important',
        borderRadius: '10px',
        boxShadow: '0 !important',
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid #EEEEEE !important'
        }
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#171B1E',
        }
    }
};

export class UploadPods extends Component {

    state = {
        step: 1,
        title: "",
        pods: "",
        email: "",
        occupation: "",
        city: "",
        bio: ""
    };

    handleClick = () => {
        window.scrollTo(0, 0);
        let body = document.body;
        body.classList.add("disabled-scroll");
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
        if (step === 2) {
            function cloneValueUploadFiles() {
                let valueUploadFiles = document.getElementById("valueUploadFiles");
                let valueUploadDir = document.getElementById("valueUploadDir");

                let newSection = document.querySelector(".create_new_section_container");
                let newDir = document.querySelector('.create_new_section_container_directory');


                const clone = valueUploadFiles.cloneNode(true);
                newSection.appendChild(clone);

                const cloneDir = valueUploadDir.cloneNode(true)
                newDir.appendChild(cloneDir);

            }
            setTimeout(() => { console.log("this is the first message") }, 300);
            setTimeout(() => { cloneValueUploadFiles() }, 300);
        }
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };
    firstStep = () => {
        this.setState({
            step: 1
        });
    };


    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    };


    render() {
        const { step } = this.state;
        const { title, pods, email, occupation, city, bio } = this.state;
        const values = { title, pods, email, occupation, city, bio };

        function selectedAllPods(e) {
            let userFullnames = e.map(function (element) {
                return `${element.label}`;
            })

            let choosePodsMultiInputs = document.querySelector('#choosePodsMultiInputs');

            choosePodsMultiInputs.value = userFullnames
        }

        function selectedAllYears(e) {
            let choosePodsMultiInputs = document.querySelector('#chooseYearsMultiInputs');

            choosePodsMultiInputs.value = e.label
        }

        const passwordChecknotify = () => toast.error('Choose a PODS', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        const passwordChecknotifySecond = () => toast.error('Choose a Time period', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


        function nextStepToZone(e) {
            e.preventDefault();
            let choosePodsMultiInputs = document.querySelector('#choosePodsMultiInputs')
            let chooseYearsMultiInputs = document.querySelector('#chooseYearsMultiInputs')

            if (choosePodsMultiInputs.value.length > 0) {
            } else {
                document.querySelector('.hiddenNotifyBtnPassword').click()
            }

            if (chooseYearsMultiInputs.value.length > 0) {
            } else {
                document.querySelector('.hiddenNotifyBtnPasswordSecond').click()
            }
            if (choosePodsMultiInputs.value.length > 0 && chooseYearsMultiInputs.value.length > 0) {
                document.querySelector('.next-btn-slide-second').click()
            }
        }

        const CustomButton = React.forwardRef(({ open, ...props }, ref) => (
            <button className="btn__compaing btn__compaing__upload__files" ref={ref} {...props}>
                <div onClick={this.handleClick}>
                    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M26.5 19H7C3.685 19 1 16.315 1 13C1 9.685 3.685 7 7 7H8.065C9.055 3.535 12.22 1 16 1C20.56 1 24.25 4.69 24.25 9.25V10H26.5C28.99 10 31 12.01 31 14.5C31 16.99 28.99 19 26.5 19Z"
                            fill="white" stroke="#707579" strokeWidth="1.5" />
                    </svg>
                    Upload Files
                </div>
            </button>
        ));

        function closeModal() {
            let body = document.body;

            body.classList.remove("disabled-scroll");
            window.location.href = "/pods";
        }

        return (
            <div className="new-compaign-row">
                <Popup
                    className="new-compaign-popup"
                    onClose={closeModal}

                    trigger={open => <CustomButton open={open} />}
                    position="center center">


                    {close => (
                        <div className="new-compaign-popup__popup new-compaign-popup__popup__file__upload">
                            <a className="cancel" onClick={close}>
                                <svg width="28" height="29" viewBox="0 0 28 29" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.9181 0.00138263C13.6856 0.0116368 13.4669 0.114174 13.3114 0.286781C13.1541 0.457678 13.0738 0.684977 13.0841 0.917394C13.0943 1.14981 13.1969 1.36854 13.3695 1.52408C13.5404 1.6813 13.7677 1.76162 14.0001 1.75137C20.7761 1.75137 26.2501 7.22537 26.2501 14.0014C26.2501 20.7774 20.7761 26.2514 14.0001 26.2514C7.22409 26.2514 1.75009 20.7774 1.75009 14.0014C1.75009 8.21806 5.74057 3.38674 11.1152 2.09306C11.5869 1.98368 11.8808 1.51201 11.7715 1.04034C11.6621 0.568675 11.1904 0.274719 10.7188 0.384094C4.57144 1.86407 0 7.41122 0 14.0013C0 21.7223 6.279 28.0013 14 28.0013C21.721 28.0013 28 21.7223 28 14.0013C28 6.28028 21.721 0.00128166 14 0.00128166C13.9727 -0.000427219 13.9453 -0.000427219 13.918 0.00128166L13.9181 0.00138263ZM9.09199 8.30032C8.7519 8.33279 8.46138 8.5618 8.3503 8.88478C8.2375 9.20949 8.32466 9.56837 8.57246 9.80423L12.7697 14.0015L8.57246 18.1988C8.37935 18.3543 8.26143 18.585 8.24775 18.8328C8.23408 19.0806 8.32637 19.3233 8.50239 19.4993C8.67842 19.6753 8.92109 19.7676 9.16888 19.754C9.41668 19.7403 9.64742 19.6224 9.8029 19.4292L14.0002 15.232L18.1975 19.4292C18.353 19.6224 18.5837 19.7403 18.8315 19.754C19.0793 19.7676 19.322 19.6753 19.498 19.4993C19.674 19.3233 19.7663 19.0806 19.7526 18.8328C19.739 18.585 19.621 18.3543 19.4279 18.1988L15.2306 14.0015L19.4279 9.80423C19.6877 9.55301 19.7663 9.1702 19.6262 8.83696C19.4877 8.50541 19.1596 8.29179 18.799 8.30032C18.57 8.30887 18.3547 8.40799 18.1975 8.57376L14.0002 12.771L9.80289 8.57376C9.63883 8.40115 9.41153 8.30203 9.17398 8.30032C9.14664 8.29861 9.11929 8.29861 9.09195 8.30032H9.09199Z"
                                        fill="black" />
                                </svg>
                            </a>
                            {(() => {
                                switch (step) {
                                    default:
                                        return <h1>User Forms not working. Enable Javascript!</h1>;
                                    case 1:
                                        return (
                                            <div className="first_popup_content">
                                                <Fragment>
                                                    <div className="first-step">
                                                        <h1>01 / 02</h1>
                                                        <h4>Adding New Files & Directories</h4>
                                                        <p>Please fill out the information below</p>
                                                        <h6>Step 01. Select POD</h6>
                                                        <span>You can select any amount of PODS.</span>
                                                        <input
                                                            type={'text'}
                                                            label="title"
                                                            id={'choosePodsMultiInputs'}
                                                        />
                                                        <input
                                                            type={'text'}
                                                            label="title"
                                                            id={'chooseYearsMultiInputs'}
                                                        />
                                                        <Select
                                                            placeholder={'Select POD'}
                                                            className={'choosePodsMulti'}
                                                            isSearchable={false}
                                                            styles={style}
                                                            options={options}
                                                            onChange={selectedAllPods}
                                                            components={{ DropdownIndicator }}
                                                            isMulti
                                                        />
                                                        <Select
                                                            placeholder={'Select Time period'}
                                                            className={'choosePodsMulti choosePodsMultiTimePeriod'}
                                                            isSearchable={false}
                                                            styles={style}
                                                            options={optionsTimePeriod}
                                                            onChange={selectedAllYears}
                                                            components={{ DropdownIndicator }}
                                                        />
                                                    </div>
                                                    <button className="hiddenNotifyBtnPassword"
                                                        onClick={passwordChecknotify}>Notify!
                                                    </button>
                                                    <button className="hiddenNotifyBtnPasswordSecond"
                                                        onClick={passwordChecknotifySecond}>Notify!
                                                    </button>
                                                    <div className="button-group">
                                                        <a onClick={close} className="cancel-btn">Cancel</a>


                                                        <a onClick={nextStepToZone} className="next-btn">Next</a>
                                                        <a onClick={this.nextStep}
                                                            className="next-btn next-btn-slide-second">Next</a>
                                                    </div>
                                                </Fragment>
                                            </div>
                                        );
                                    case 2:
                                        let test = document.querySelector('#choosePodsMultiInputs').value;
                                        let test2 = document.querySelector('#chooseYearsMultiInputs').value;
                                        return (
                                            <div className="first_popup_content">
                                                <PodsUploadSecondStep
                                                    nextStep={this.nextStep}
                                                    prevStep={this.prevStep}
                                                    handleChange={this.handleChange}
                                                    interesting={test}
                                                    interestingSecond={test2}
                                                    values={values}
                                                    previusPods={values.title}
                                                />
                                            </div>
                                        );
                                    case 3:
                                        return (
                                            <div className="first_popup_content third_level_popup_content">
                                                <h5>Upload Files</h5>
                                                <p>Changes were made to the structure of the PODS.</p>
                                                <hr />
                                                <div className={'third_level_popup_content_group'}>
                                                    <h6>Create new section</h6>
                                                    <div className={'create_new_section_container_directory'} />
                                                    <hr />
                                                    <h6>Upload Files</h6>
                                                    <div className={'create_new_section_container'} />
                                                </div>
                                                <a onClick={close} className="cancel-btn">Close</a>
                                            </div>
                                        );
                                }
                            })()}
                        </div>
                    )}
                </Popup>
            </div>
        )
    }
}

export default UploadPods
