import { gql } from '@apollo/client';

export const GET_ALL_STORES = gql`
query GetStores {
    getStores {
        id
        num
        name
        address
        suburb
        state
        postcode
    }
  }
`;

export const SAVE_STORE = gql`
mutation UpdateStore($updateStoreInput: UpdateStoreInput) {
  updateStore(updateStoreInput: $updateStoreInput) {
    id
    num
    name
    address
    suburb
    state
    postcode
  }
}
`;

export const DELETE_STORE = gql`
mutation DeleteStore($StoreId: ID!) {
  deleteStore(StoreId: $StoreId)
}
`;