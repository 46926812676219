import React from "react";
import Select, { components } from "react-select";

function CustomSelect({ data, id, width, variation, defaultValue, onChange }) {
    const style = {
        container: base => ({
            ...base,
            width: '100%',
            maxWidth: `${width || '250px'}`,
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '100%',
            padding: 0,
            marginBottom: 0,
            marginTop: '0',
            boxShadow: 'none',
            border: variation ? '1px solid #E0E0E0' : '1px solid #EEEEEE',
            borderTop: '0',
            fontWeight: variation ? '400' : '600',
            fontSize: variation ? '14px' : '18px',
            transition: 'all .2s ease-in-out',
            borderRadius: '0',
            borderBottomLeftRadius: variation ? '4px' : '10px',
            borderBottomRightRadius: variation ? '4px' : '10px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: '0',
            borderRadius: '0',
            borderBottomLeftRadius: variation ? '4px' : '10px',
            borderBottomRightRadius: variation ? '4px' : '10px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'black',
            backgroundColor: '#fff',
            padding: variation ? '0 10px' : '0 20px',
            cursor: 'pointer',
            height: variation ? '36px' : '60px',
            display: 'flex',
            alignItems: 'center',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                color: '#fff',
                backgroundColor: '#F40027'
            }
        }),
        control: (base, state) => ({
            ...base,
            padding: variation ? '0 10px' : '0 20px',
            border: variation ? '1px solid #E0E0E0' : '1px solid #EEEEEE',
            borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : variation ? '1px solid #E0E0E0' : '1px solid #EEEEEE',
            boxShadow: '0 !important',
            cursor: 'pointer',
            width: '100%',
            maxWidth: `${width || '250px'}`,
            height: variation ? '36px' : '60px',
            borderRadius: variation ? '4px' : '10px',
            borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : variation ? '4px' : '10px',
            borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : variation ? '4px' : '10px',
            fontWeight: variation ? '400' : '600',
            fontSize: variation ? '14px' : '18px',
            transition: '.2s easy-in-out',
            '&:hover': {
                borderColor: '1px solid #EEEEEE !important',
                borderBottom: state.selectProps.menuIsOpen ? "1px solid transparent" : variation ? '1px solid #E0E0E0' : '1px solid #EEEEEE',
            }
        }),
        valueContainer: base => ({
            ...base,
            padding: '0',
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '0'
        })
    };
    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 -5.24536e-07L6 6L12 0" fill="#171B1E" />
                </svg>
            </components.DropdownIndicator>
        );
    };
    return (
        data.length > 0 &&
        <Select
            defaultValue={defaultValue}
            options={data}
            styles={style}
            isSearchable={false}
            id={id}
            onChange={onChange}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator
            }}
        />
    )
}

export default CustomSelect