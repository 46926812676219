import React, { useContext, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTable, usePagination, useRowSelect } from 'react-table';
import MDEditor from '@uiw/react-md-editor';

const Table = styled.table`
    border: 1px solid #a0a0a0;
    border-radius: 10px;
    background-color: #fff;
    border-spacing: 0;
    width: 100%;

    thead {
        th {
            height: 35px;
            border-bottom: 1px solid #a0a0a0;
            border-right: 1px solid #a0a0a0;
            &:last-child {
                border-right: 0px;
            }
        }
        tr {
            /* background-color: #ececec; */
        }
    }

    tbody {
        td {
            padding: 5px;
            color: #717579;
            cursor: pointer;

            input {
                padding: 2px;
                margin: 0;
                border: 0;
                width: 100%;
                background-color: transparent;
            }
        }
        tr {
            height: 25px;
            &:nth-child(odd) {
                background-color: #ececec;
                td {
                    color: #1c1c1c;
                }
            }
            &:hover {
                background-color: #fa3535;
                td, input {
                    color: #fff;
                }
                input:focus {
                    outline: 1px solid #fff;
                    background-color: #a40404;
                }
            }
            input:focus {
                outline: 1px solid #fa3535;
            }
        }
    }
`;

const ActionBar = styled.div`
    background-color: #fff;
    border: 1px solid #a4a4a4;
    border-radius: 10px;
    margin: ${props => props.top ? '0 0 0 10px' : '10px 0 0 0'};
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    button, input, select {
        border: 1px solid #a4a4a4;
        border-radius: 4px;
        background: transparent;
        font-family: "Mulish";
        font-weight: 700;
        font-size: 14px;
        color: #4f4f4f;
        height: 36px;
        margin: 0 5px;
        padding: 5px;
        cursor: pointer;

        &:hover {
            background-color: #fa3535;
            color: #fff;
        }
        &.small {
            width: 40px;
            font-size: 14px;
        }
    }
    select {
        width: 200px;
        option {
            color: #4f4f4f;
            background-color: #fff;
        }
    }
`;

const Pagination = styled.div`
    &.reload {
        width: 100px;
        font-size: 14px;
    }
`;

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
        <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
        )
    }
)

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    const onChange = e => {
      setValue(e.target.value)
    }
  
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value)
    }

    const onClick = () => {
        alert('111');
    }
  
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    if (id === "description") {
        // return <input value={value} onChange={onChange} onBlur={onBlur} />
        return (
            <>
                <MDEditor
                    value={value}
                    onChange={setValue}
                    height={150}
                    onBlur={onBlur}
                    onClick={onClick}
                />
            </>
        );
    }
    else {
        return <input value={value} onChange={onChange} onBlur={onBlur} />
    }
}

const defaultColumn = {
    Cell: EditableCell,
}

const STATUS = {
    CREATING: 0,
    PENDING: 1,
}

export default function DataTable({
    data,
    columns,
    onRecordChange,
    onDeleteRecord,
    emptyRecord,
    addButtonText
})
{
    const [internalData, setInternalData] = React.useState(JSON.parse(JSON.stringify(data)))
    const [originalData] = React.useState(data);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    // const updateStatus = (rowIndex, __status) => {
    //     setInternalData(old =>
    //         old.map((row, index) => {
    //           if (index === rowIndex) {
    //             return {
    //               ...old[rowIndex],
    //               __status
    //             }
    //           }
    //           return row;
    //         })
    //     );
    // }

    const isValidRecord = (record) => {
        console.log('isValidRecord',record);
        for (let field in record)
        {
            let rec = record[field];
            if (typeof rec === 'string'
            && rec.trim() === '')
            {
                return false;
            }
        }
        return true;
    }

    const updateMyData = (rowIndex, columnId, value) => {
        const newData = JSON.parse(JSON.stringify(internalData));
        let _status = newData[rowIndex].__status;
        // We also turn on the flag to not reset the page
        setSkipPageReset(true);
        if (newData[rowIndex].id)
        {
            newData[rowIndex][columnId] = value;
            setInternalData(newData);
            delete newData[rowIndex].__typename;
            onRecordChange({
                record: newData[rowIndex],
                isNew: false
            });
        }
        else if (_status == STATUS.CREATING)
        {
            newData[rowIndex][columnId] = value;
            if (isValidRecord(newData[rowIndex]))
            {
                _status = STATUS.PENDING;
                newData[rowIndex].__status = _status;
                delete newData[rowIndex].__status;
                delete newData[rowIndex].__typename;
                setInternalData(newData);
                onRecordChange({
                    record: newData[rowIndex],
                    isNew: true
                });
            }
            else {
                setInternalData(newData);
            }
        }
        if (_status == STATUS.PENDING)
        {
            // console.log('RECORD PENDING');
        }
    }

    const addNewStore = () => {
        setInternalData([
            {
                ...emptyRecord,
                __status: 0
            },
            ...internalData]);
    }

    const deleteRecord = () => {
        onDeleteRecord();
    }

    const tableInstance = useTable(
        {
            columns,
            data: internalData,
            defaultColumn,
            autoResetPage: !skipPageReset,
            updateMyData
        },
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ])
          }
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        rows,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds },
    } = tableInstance;

    
    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
        setSkipPageReset(false);
    }, [internalData]);

    React.useEffect(() => {
        
    },[selectedRowIds]);

    const reloadData = () => setInternalData(JSON.parse(JSON.stringify(originalData)));
    return (
        <>
            <ActionBar>
                <button onClick={addNewStore}>
                    {addButtonText?addButtonText:'Add New'}
                </button>
                <button onClick={deleteRecord} disabled={Object.keys(selectedRowIds).length?false:true}>
                    Delete Selected
                </button>
            </ActionBar>
            <Table {...getTableProps()}>
                <thead>
                {// Loop over the header rows
                headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {// Loop over the headers in each row
                    headerGroup.headers.map(column => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps()}>
                        {// Render the header
                        column.render('Header')}
                        </th>
                    ))}
                    </tr>
                ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                page.map(row => {
                    // Prepare the row for display
                    prepareRow(row)
                    return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </Table>
            <ActionBar>
                <Pagination>
                    <button className="small" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>
                    <button className="small" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>
                    <button className="small" onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>
                    <button className="small" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                    <span>
                        | Go to page:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                    </select>
                    <button className='reload' onClick={reloadData}>Reload Data</button>
                </Pagination>
            </ActionBar>
        </>
    );
}