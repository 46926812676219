import React, { useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

import './TagField.scss';

const KeyCodes = {
    comma: 188,
    space: 32,
    enter: 13,
    tab: 9
};

const delimiters = [
    KeyCodes.comma,
    KeyCodes.enter,
    KeyCodes.space,
    KeyCodes.tab
];

export default function TagField({ tagsState, setTagsState }) {
    const [tags, setTags] = React.useState(tagsState || []);

    //Proxy local tags state to parent.
    useEffect(() => {
        setTagsState(tags);
    },[tags]);

    const onDeleteTag = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const onAddTag = (tag) => {
        setTags([...tags, tag.text]);
    };

    const onTagUpdate = (i, newTag) => {
        const updatedTags = tags.slice();
        updatedTags.splice(i, 1, newTag.text);
        setTags(updatedTags);
    };

    const onClearAll = () => {
        setTags([]);
    }

    const formattedTags = tags.map
    (
        tag => {
            return {
                id: tag,
                text: tag
            }
        }
    );

    return (
        <ReactTags
            ReactTagsonAddTag
            handleDelete={onDeleteTag}
            handleAddition={onAddTag}
            delimiters={delimiters}
            onClearAll={onClearAll}
            onTagUpdate={onTagUpdate}
            placeholder="Enter Tags..."
            minQueryLength={2}
            maxLength={25}
            autofocus={false}
            allowDeleteFromEmptyInput={true}
            autocomplete={true}
            readOnly={false}
            allowUnique={true}
            allowDragDrop={true}
            inline={true}
            allowAdditionFromPaste={true}
            editable={true}
            clearAll={true}
            tags={formattedTags}
        />
    )
}