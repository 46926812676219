import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { USER_TYPE } from '../../defs/User';

export default class SendEmailModal extends Component
{
    constructor(props)
	{
		super(props);
        this.state = {
            open: false
        }
    }

    open()
    {
        if (typeof this.props.onOpen == 'function') {
            if (this.props.onOpen()) {
                this.setState({open:true});
            }
        }
        else {
            this.setState({open:true});
        }
    }

    close()
    {
        this.setState({open:false});
    }

    toggleOpen()
    {
        this.setState({open:!this.state.open});
    }

    onSend(e)
    {
        this.props.onSend(this,e);
    }

    render()
    {
		return (
            <>
                <button onClick={this.open.bind(this)}>{this.props.buttonText}</button>
                <Popup open={this.state.open} closeOnDocumentClick onClose={this.close.bind(this)} className='popup--email'>
                    <div className='popup-inner'>
                        <div
                            className="lsm-popup__email">
                            <h5>EMAIL TO</h5>
                            <p>Enter the recipient's email address</p>
                            <input type={'email'} id={'sender-email'} placeholder={'Email'} />
                            <div
                                className={'btn-group'}>
                                <button onClick={this.close.bind(this)}>CLOSE</button>
                                <button onClick={this.onSend.bind(this)}>SEND</button>
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}