import React, { useContext } from 'react';
import backView from '../../../pages/LSM/Categories/TaskList/coupon_back.png';
import SendEmailModal from '../../Modal/SendEmailModal';
import VoucherGrid from '../../Sandbox/VoucherGrid';
import CouponEditor2 from './CouponEditor2';
import { AuthContext } from '../../../context/auth'
import styled from '@emotion/styled';

import moment from 'moment';

const Expires = styled.span`
    position: absolute;
    bottom: 66px;
    left: 653px;
    color: #fff;
    font-size: 10px;
    font-family: "National2";
`;

export default function(props)
{
    const { user } = useContext(AuthContext);

    const expires = moment(props.expiryDate).format('DD/MM/YYYY');
    const inputValue = `Available at ${props.selectedStore.name ? props.selectedStore.name : '<XX>'} only. No swaps please. One coupon per person per visit. Present coupon to claim your offer. Expires ${expires}`

    return (
        <>
            <div className='lsm_coupon_editor'>
                <CouponEditor2 background="/static/General/coupon_back.png" tncText={`Expires ${expires}`} />
                <input  className={'lsm__content__header__coupon--dnd__input__store'}
                        defaultValue={inputValue} />
                {/* <Expires>Expires {moment(props.expiryDate).format('DD/MM/YYYY')}</Expires> */}
                {/* <SendEmailModal buttonText="EMAIL FILE" onSend={props.onSendEmail} userRole={user.userRole} /> */}
            </div>
            <div className='lsm__content__header__coupon--dnd disable'></div>
        </>
    );
}
