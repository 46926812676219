import {useContext} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from '../context/auth';

const AuthBoundary = ({ requiresAuth = true, blockAuthed = false, redirectTo = '/' }) => {
    const { user } = useContext(AuthContext);

    if (requiresAuth && !user)
    {
        return <Navigate to={redirectTo} replace />;
    }
    else if (user && blockAuthed)
    {
        return <Navigate to={redirectTo} replace />;
    }

    return <Outlet />;
};

export default AuthBoundary;