export function isAudio(filename) {
    return (filename.split('.').pop() === 'wav'
    || filename.split('.').pop() === 'mp3');
}

export function isVideo(filename) {
    return (filename.split('.').pop() === 'mp4'
    || filename.split('.').pop() === 'mkv'
    || filename.split('.').pop() === 'mov');
}

export function isPdf(filename) {
    return filename.split('.').pop() === 'pdf';
}

export function isImage(filename) {
    return (filename.split('.').pop() === 'jpg'
    || filename.split('.').pop() === 'png');
}

export function isAI(filename) {
    return (filename.split('.').pop() === 'ai');
}

export function isZip(filename) {
    return (filename.split('.').pop() === 'zip');
}

export function isEps(filename) {
    return (filename.split('.').pop() === 'eps');
}

export function isJpeg(filename) {
    return (filename.split('.').pop() === 'jpg'
    || filename.split('.').pop() === 'jpeg');
}

export function isPng(filename) {
    return (filename.split('.').pop() === 'png');
}

export function isPpt(filename) {
    return (filename.split('.').pop() === 'ppt'
    || filename.split('.').pop() === 'pptx');
}

export function isDoc(filename) {
    return (filename.split('.').pop() === 'doc'
    || filename.split('.').pop() === 'docx');
}

export default {
    isAudio,
    isVideo,
    isPdf,
    isImage,
    isAI,
    isZip,
    isEps,
    isJpeg,
    isPng,
    isPpt,
    isDoc,
};