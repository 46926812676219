import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AuthContext } from '../../../context/auth';
import { useForm } from '../../../hooks/useForm';
import { TabTitle } from "../../../utils/TabTitle";
import ForgotCredentials from "../../../components/ForgotCredentials/ForgotCredentials";
import RequestLoginBanner from "../../../components/RequestLoginBanner/RequestLoginBanner";
import './NewPassword.scss';

function NewPassword({title}) {
  TabTitle(title  || 'KFC LSM');
  let id = useParams().id;

  const { onChange, onSubmit, values } = useForm(newPasswordserCallback, {
    id: id,
    password: ''
  });

  const [newPasswordser, { loading }] = useMutation(NEW_PASSWORD, {
    update(_, { data: { login: userData } }) {
        toast.success("Password changed successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 'new-password-success'
        });
    },
    onError(err) {
        let errMsg = err.graphQLErrors[0].message;
        toast.error(errMsg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 'new-password-error'
        });
    },
    variables: values
  });

  async function newPasswordserCallback() {
    console.log(values)
    await newPasswordser();
  }

  let notifyError = (title, id) => toast.error(title, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id
  });

  let validateError = (err) => {
    let username = document.getElementById('username');
    let password = document.getElementById('password');

    if (username.value === "") {
      notifyError('Username must not be empty', 'username-empty-error');
      username.classList.add('invalid');
    }
    
    if (password.value === "") {
      notifyError('Password must not be empty', 'password-empty-error');
      password.classList.add('invalid');
    }

    if (err === 'User not found') {
      notifyError(err, 'user-not-found');
      username.classList.add('invalid');
      password.classList.add('invalid');
    }

    if (err === 'Wrong crendetials') {
      notifyError(err, 'wrong-credentials');
      username.classList.add('invalid');
      password.classList.add('invalid');
    }

  }

  let passwordView = (e) => {
    e.preventDefault();
    let passwordField = document.querySelector('#password')
    passwordField.type === "password" ? passwordField.type = "text" : passwordField.type = "password";
    e.target.classList.toggle('active');
  };

  return (
    <div className={'new-password'}>
      <form onSubmit={onSubmit} className={'new-password__form'}>
        <RequestLoginBanner />
        <h3>New Password</h3>
        <div className={'password-view'}>
          <input type={'password'} name='password' id="password" placeholder='Password' value={values.password} onChange={onChange} />
          <button onClick={passwordView} />
        </div>
        <button type={'submit'}>Enter</button>
        <ForgotCredentials />
      </form>
    </div>
  )
}

const NEW_PASSWORD = gql`
  mutation newPassword($id: String!, $password: String!) {
    newPassword(id: $id, password: $password)
  }
`;

export default NewPassword