import React, {Component, useEffect, useState} from 'react';
import './settingsPods.scss';
import Popup from 'reactjs-popup';
import Select from "react-select";
import {ToastContainer, toast} from 'react-toastify';


function SettingsPods() {
    const [person, setPerson] = useState([])

    function truncate() {
        let listItems = document.querySelectorAll('.settings-pods__popup__content__body__list li span');
        listItems.forEach(element => {
            console.log(element)
        })
    }

    useEffect(() => {
        fetch('https://3.137.141.92:3525/get_pod_list', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                timePeriod: '2022-2023'
            }),
        })
            .then(response => response.json())
            .then(data => {
                setPerson(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [])

    setTimeout(() => {
        truncate()
    }, 2000)
    let changePods = (e) => {
        fetch('https://3.137.141.92:3525/get_pod_list', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                timePeriod: e.value
            }),
        })
            .then(response => response.json())
            .then(data => {
                setPerson(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    console.log(person)

    let openEditPopup = (e) => {
        console.log(e.target)
        document.querySelector('.EditPopup input').value = e.target.parentElement.parentElement.querySelector('span').innerText;
        document.querySelector('.EditPopup input').setAttribute('podsname', e.target.parentElement.parentElement.querySelector('span').innerText);
        document.querySelector('.EditPopup').style.display = 'flex';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup-content').style.backgroundColor = '#D7D7D7';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup__content').style.opacity = '.6';
    }
    let EditPopupCloseButton = (e) => {
        e.target.parentElement.parentElement.style.display = 'none';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup-content').style.backgroundColor = '#fff';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup__content').style.opacity = '1';
    }

    let UpdatePopupButton = (e) => {
        let podsUpdateName = document.querySelector('.EditPopup input[name="PodsNameEdit"]').value;
        let podsName = document.querySelector('.EditPopup input[name="PodsNameEdit"]').getAttribute('podsname');
        console.log(podsUpdateName)
        console.log(podsName)


        fetch('https://3.137.141.92:3525/update_pods_name', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                podsUpdateName,
                podsName
            })
        });

        document.querySelector(`.settings-pods__popup__content__body__list li[podsname="${podsName}"] span`).innerText = podsUpdateName
        document.querySelector('.successStatus').click();
        e.target.parentElement.parentElement.style.display = 'none';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup-content').style.backgroundColor = '#fff';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup__content').style.opacity = '1';
    }

    let removePod = () => {
        let podsName = document.querySelector('.podsRemove').value;
        fetch('https://3.137.141.92:3525/dalete_pod', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                podsName
            })
        });
        document.querySelector('.successStatus').click();
    }


    let UpdatePopupCloseButton = (e) => {
        let podsName = document.querySelector('.CreatePopup input[name="PodsName"]')
        let StartDate = document.querySelector('.CreatePopup input[name="StartDate"]')
        let EndDate = document.querySelector('.CreatePopup input[name="EndDate"]')
        let BriefDatePlan = document.querySelector('.CreatePopup input[name="BriefDatePlan"]')
        let BriefDateActual = document.querySelector('.CreatePopup input[name="BriefDateActual"]')
        let DesignSignOffPlan = document.querySelector('.CreatePopup input[name="DesignSignOffPlan"]')
        let DesignSignOffActual = document.querySelector('.CreatePopup input[name="DesignSignOffActual"]')
        let PricingPlan = document.querySelector('.CreatePopup input[name="PricingPlan"]')
        let PricingActual = document.querySelector('.CreatePopup input[name="PricingActual"]')
        let FitLegalSignOffActual = document.querySelector('.CreatePopup input[name="FitLegalSignOffActual"]')
        let FitLegalSignOffPlan = document.querySelector('.CreatePopup input[name="FitLegalSignOffPlan"]')
        let Status;

        if (document.querySelector('.StatusCreatedPopup').value) {
            Status = document.querySelector('.StatusCreatedPopup');
        } else {
            Status = document.querySelector('.StatusCreatedPopup').value = '0%'
        }

        let TimePeriod;

        if (document.querySelector('.TimePeriodCreatedPopup').value) {
            TimePeriod = document.querySelector('.TimePeriodCreatedPopup');
        } else {
            TimePeriod = document.querySelector('.TimePeriodCreatedPopup').value = '2022-2023'
        }

        if (podsName.value && StartDate.value && EndDate.value && BriefDatePlan.value && BriefDateActual.value && DesignSignOffPlan.value && DesignSignOffActual.value && PricingPlan.value && PricingActual.value && FitLegalSignOffActual.value && FitLegalSignOffPlan.value) {
            fetch('https://3.137.141.92:3525/create_pods', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    podsName: podsName.value,
                    StartDate: StartDate.value,
                    EndDate: EndDate.value,
                    BriefDatePlan: BriefDatePlan.value,
                    BriefDateActual: BriefDateActual.value,
                    DesignSignOffPlan: DesignSignOffPlan.value,
                    DesignSignOffActual: DesignSignOffActual.value,
                    PricingPlan: PricingPlan.value,
                    PricingActual: PricingActual.value,
                    FitLegalSignOffActual: FitLegalSignOffActual.value,
                    FitLegalSignOffPlan: FitLegalSignOffPlan.value,
                    Status: Status.value,
                    TimePeriod: TimePeriod.value
                })
            });
            document.querySelector('.successCreatePod').click();
        } else {
            document.querySelector('.failedCreatePod').click();
        }

        if (podsName.value) {
            podsName.classList.remove('invalid')
        } else {
            podsName.classList.add('invalid')
        }

        if (StartDate.value) {
            StartDate.classList.remove('invalid')
        } else {
            StartDate.classList.add('invalid')
        }

        if (EndDate.value) {
            EndDate.classList.remove('invalid')
        } else {
            EndDate.classList.add('invalid')
        }

        if (BriefDatePlan.value) {
            BriefDatePlan.classList.remove('invalid')
        } else {
            BriefDatePlan.classList.add('invalid')
        }

        if (BriefDateActual.value) {
            BriefDateActual.classList.remove('invalid')
        } else {
            BriefDateActual.classList.add('invalid')
        }

        if (DesignSignOffPlan.value) {
            DesignSignOffPlan.classList.remove('invalid')
        } else {
            DesignSignOffPlan.classList.add('invalid')
        }

        if (DesignSignOffActual.value) {
            DesignSignOffActual.classList.remove('invalid')
        } else {
            DesignSignOffActual.classList.add('invalid')
        }

        if (PricingPlan.value) {
            PricingPlan.classList.remove('invalid')
        } else {
            PricingPlan.classList.add('invalid')
        }

        if (PricingActual.value) {
            PricingActual.classList.remove('invalid')
        } else {
            PricingActual.classList.add('invalid')
        }

        if (FitLegalSignOffActual.value) {
            FitLegalSignOffActual.classList.remove('invalid')
        } else {
            FitLegalSignOffActual.classList.add('invalid')
        }

        if (FitLegalSignOffPlan.value) {
            FitLegalSignOffPlan.classList.remove('invalid')
        } else {
            FitLegalSignOffPlan.classList.add('invalid')
        }
    }

    let openDuplicatePopup = (e) => {
        let podsName = e.target.getAttribute('podsname');
        console.log(person)
        let newElement = {pods: podsName + ' duplicate'}
        setPerson([...person, newElement]);

        fetch('https://3.137.141.92:3525/duplicate_pod', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                podsName: e.target.parentElement.parentElement.querySelector('span').innerText,
            })
        });

        document.querySelector('.successStatus').click();
    }

    let openRemovePopup = (e) => {
        document.querySelector('.RemovePopup').style.display = 'flex';

        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup-content').style.backgroundColor = '#D7D7D7';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup__content').style.opacity = '.6';
        document.querySelector('.RemovePopup input').value = e.target.parentElement.parentElement.querySelector('span').innerText;
        console.log(e.target)

    }

    let CreatePopup = (e) => {
        document.querySelector('.CreatePopup').style.display = 'flex';

        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup-content').style.backgroundColor = '#D7D7D7';
        document.querySelector('.popup-overlay.settings-pods__popup-overlay .settings-pods__popup__content').style.opacity = '.6';
    }

    function myFunction() {
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.getElementById("myUL");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    const optionsStatus = [
        {value: 'None-Active', label: 'None-Active'},
        {value: 'Live', label: 'Live'},
        {value: 'Uploading', label: 'Uploading'},
        {value: 'LegalApproval', label: 'Legal Approval'},
        {value: 'DesignDevelopment', label: 'Design Development'},
        {value: 'Planning', label: 'Planning'},
    ];

    const optionsTimePeriod = [
        {value: '2022-2023', label: '2022-2023'},
        {value: '2021-2022', label: '2021-2022'}
    ];

    const selectorStyle = {
        control: base => ({
            display: 'flex',
            border: '1px solid #D7D7D7',
            borderRadius: "10px",
            cursor: 'pointer',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '40px',
            padding: '0 10px',
            fontSize: '14px',
            fontFamily: 'Mulish',
            fontWeight: '400',
            lineHeight: '15px',
            color: '#4f4f4f'
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: 'all .2s ease',
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
        })
    }

    const successStatus = () => toast.success('Successfully', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const successCreatePod = () => toast.success('Successfully created.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const failedCreatePod = () => toast.error('Fill in the required fields.', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let StatusCreatedPopup = (e) => {
        document.querySelector('.StatusCreatedPopup').value = e.label;
    }

    let TimePeriodCreatedPopup = (e) => {
        document.querySelector('.TimePeriodCreatedPopup').value = e.label;
    }

    function closeModal() {
        window.location.href = window.location.origin + '/pods';
    }


    return (
        <Popup
            className="settings-pods__popup"
            onClose={closeModal}
            trigger={<button className="settings-pods__btn">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M14 5C14 5.55 13.55 6 13 6C12.45 6 12 5.55 12 5V1C12 0.45 12.45 0 13 0C13.55 0 14 0.45 14 1V2H17C17.55 2 18 2.45 18 3C18 3.55 17.55 4 17 4H14V5ZM1 4C0.45 4 0 3.55 0 3C0 2.45 0.45 2 1 2H10V4H1ZM1 16C0.45 16 0 15.55 0 15C0 14.45 0.45 14 1 14H6V16H1ZM10 17V16H17C17.55 16 18 15.55 18 15C18 14.45 17.55 14 17 14H10V13C10 12.45 9.55 12 9 12C8.45 12 8 12.45 8 13V17C8 17.55 8.45 18 9 18C9.55 18 10 17.55 10 17ZM4 8V7C4 6.45 4.45 6 5 6C5.55 6 6 6.45 6 7V11C6 11.55 5.55 12 5 12C4.45 12 4 11.55 4 11V10H1C0.45 10 0 9.55 0 9C0 8.45 0.45 8 1 8H4ZM18 9C18 8.45 17.55 8 17 8H8V10H17C17.55 10 18 9.55 18 9Z"
                          fill="#808080"/>
                </svg>
            </button>}
            modal
        >
            {close => (
                <>
                    <div className={'settings-pods__popup__content'}>
                        <h1>
                            Settings
                        </h1>
                        <p>
                            You can edit the title, duplicate, delete and add new Pod.
                        </p>
                        <div className={'settings-pods__popup__content__container'}>
                            <div className={'settings-pods__popup__content__header'}>
                                <div className={'settings-pods__popup__content__header__search-bar'}>
                                    <input type="text" id="myInput" onKeyUp={myFunction} placeholder={'Search'}/>
                                </div>
                                <div className={'settings-pods__popup__content__header__add'}>
                                    <a onClick={CreatePopup}>Add</a>
                                </div>
                            </div>
                            <div className={'settings-pods__popup__content__body'}>
                                <Select
                                    defaultValue={optionsTimePeriod[0]}
                                    options={optionsTimePeriod}
                                    isSearchable={false}
                                    styles={selectorStyle}
                                    onChange={changePods}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                                <ul id="myUL" className={'settings-pods__popup__content__body__list'}>
                                    {
                                        person.map(function (item, i) {
                                            console.log('test');
                                            return <li podsName={item.pods} key={i}>
                                                <span>{item.pods}</span>
                                                {/* <div>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        title="Edit"
                                                    >
                                                        <a onClick={openEditPopup} className={'openEditPopup'}>
                                                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                      d="M18.7085 3.63053C19.0985 4.02053 19.0985 4.65053 18.7085 5.04053L16.8785 6.87053L13.1285 3.12053L14.9585 1.29053C15.1454 1.10328 15.399 0.998047 15.6635 0.998047C15.9281 0.998047 16.1817 1.10328 16.3685 1.29053L18.7085 3.63053ZM0.998535 18.5005V15.4605C0.998535 15.3205 1.04854 15.2005 1.14854 15.1005L12.0585 4.19053L15.8085 7.94053L4.88854 18.8505C4.79854 18.9505 4.66854 19.0005 4.53854 19.0005H1.49854C1.21854 19.0005 0.998535 18.7805 0.998535 18.5005Z"
                                                                      fill="#808080"/>
                                                            </svg>
                                                        </a>
                                                    </Tooltip>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        title="Duplicate"
                                                    >
                                                        <a podsName={item.pods} timePeriod={item.timePeriod}
                                                           onClick={openDuplicatePopup}
                                                           className={'openDuplicatePopup'}>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd"
                                                                      d="M15.5 1H4.5C3.4 1 2.5 1.9 2.5 3V16C2.5 16.55 2.95 17 3.5 17C4.05 17 4.5 16.55 4.5 16V4C4.5 3.45 4.95 3 5.5 3H15.5C16.05 3 16.5 2.55 16.5 2C16.5 1.45 16.05 1 15.5 1ZM16.09 5.59L20.92 10.42C21.29 10.79 21.5 11.3 21.5 11.83V21C21.5 22.1 20.6 23 19.5 23H8.49C7.39 23 6.5 22.1 6.5 21L6.51 7C6.51 5.9 7.4 5 8.5 5H14.67C15.2 5 15.71 5.21 16.09 5.59ZM20 12H15.5C14.95 12 14.5 11.55 14.5 11V6.5L20 12Z"
                                                                      fill="#808080"/>
                                                            </svg>
                                                        </a>
                                                    </Tooltip>
                                                    <Tooltip
                                                        TransitionComponent={Zoom}
                                                        title="Remove"
                                                    >
                                                        <a onClick={openRemovePopup}
                                                           className={'openRemovePopup'}>
                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M13.3002 0.710689C13.1134 0.523436 12.8597 0.418203 12.5952 0.418203C12.3307 0.418203 12.077 0.523436 11.8902 0.710689L7.00022 5.59069L2.11021 0.700689C1.92338 0.513436 1.66973 0.408203 1.40521 0.408203C1.1407 0.408203 0.887046 0.513436 0.700215 0.700689C0.310215 1.09069 0.310215 1.72069 0.700215 2.11069L5.59021 7.00069L0.700215 11.8907C0.310215 12.2807 0.310215 12.9107 0.700215 13.3007C1.09021 13.6907 1.72021 13.6907 2.11021 13.3007L7.00022 8.41069L11.8902 13.3007C12.2802 13.6907 12.9102 13.6907 13.3002 13.3007C13.6902 12.9107 13.6902 12.2807 13.3002 11.8907L8.41021 7.00069L13.3002 2.11069C13.6802 1.73069 13.6802 1.09069 13.3002 0.710689Z"
                                                                    fill="#808080"/>
                                                            </svg>
                                                        </a>
                                                    </Tooltip>
                                                </div> */}
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'EditPopup'}>
                        <h6>Edit title</h6>
                        <p>You can change the title or leave it unchanged.</p>
                        <button className={'successStatus'} onClick={successStatus}>Notify !</button>
                        <input name="PodsNameEdit" placeholder={'Pods name'}/>
                        <div className="upload-new-directory_btn_group">
                            <button onClick={EditPopupCloseButton}
                                    className="create-section-close edit-section-close">Cancel
                            </button>
                            <button onClick={UpdatePopupButton}>Save</button>
                        </div>
                    </div>
                    <div className={'RemovePopup'}>
                        <h6>Remove Pod</h6>
                        <p>Are you sure you want to delete this?</p>
                        <button className={'successStatus'} onClick={successStatus}>Notify !</button>
                        <input className={'podsRemove'} placeholder={'podsRemove'}/>
                        <div className="upload-new-directory_btn_group">
                            <button onClick={EditPopupCloseButton} className="create-section-close">No</button>
                            <button onClick={removePod}>Yes</button>
                        </div>
                    </div>
                    <div className={'CreatePopup'}>
                        <h6>Create Pod</h6>
                        <p>Create a title for the new Pod.</p>
                        <button className={'successCreatePod'} onClick={successCreatePod}>Notify !</button>
                        <button className={'failedCreatePod'} onClick={failedCreatePod}>Notify !</button>
                        <input name={'PodsName'} placeholder={'Pods name'}/>
                        <div className={'row'}>
                            <div className={'createPopupDetails'}>
                                <span>Status<span>*</span></span>
                                <Select
                                    defaultValue={optionsStatus[0]}
                                    options={optionsStatus}
                                    onChange={StatusCreatedPopup}
                                    isSearchable={false}
                                    styles={selectorStyle}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                                <input type={'text'} className={'StatusCreatedPopup'}/>
                            </div>
                            <div className={'createPopupDetails'}>
                                <span>Time Period<span>*</span></span>
                                <Select
                                    defaultValue={optionsTimePeriod[0]}
                                    options={optionsTimePeriod}
                                    isSearchable={false}
                                    styles={selectorStyle}
                                    onChange={TimePeriodCreatedPopup}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                                <input type={'text'} className={'TimePeriodCreatedPopup'}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'createPopupDetails'}>
                                <label for={'StartDate'}>Start Date<span>*</span></label>
                                <input type="date" name={'StartDate'}/>
                            </div>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'EndDate'}>End Date<span>*</span></label>
                                <input type="date" name={'EndDate'}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'BriefDatePlan'}>Brief Date Plan<span>*</span></label>
                                <input type="date" name={'BriefDatePlan'}/>
                            </div>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'BriefDateActual'}>Brief Date Actual<span>*</span></label>
                                <input type="date" name={'BriefDateActual'}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'DesignSignOffPlan'}>Design Sign Off Plan<span>*</span></label>
                                <input type="date" name={'DesignSignOffPlan'}/>
                            </div>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'DesignSignOffActual'}>Design Sign Off Actual<span>*</span></label>
                                <input type="date" name={'DesignSignOffActual'}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'PricingPlan'}>Pricing Plan<span>*</span></label>
                                <input type="date" name={'PricingPlan'}/>
                            </div>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'PricingActual'}>Pricing Actual<span>*</span></label>
                                <input type="date" name={'PricingActual'}/>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'FitLegalSignOffActual'}>Fit Legal Sign Off Actual<span>*</span></label>
                                <input type="date" name={'FitLegalSignOffActual'}/>
                            </div>
                            <div className={'createPopupDetails'}>
                                <label htmlFor={'FitLegalSignOffPlan'}>Fit Legal Sign Off Plan<span>*</span></label>
                                <input type="date" name={'FitLegalSignOffPlan'}/>
                            </div>
                        </div>
                        <div className="upload-new-directory_btn_group">
                            <button onClick={EditPopupCloseButton} className="create-section-close">Cancel</button>
                            <button onClick={UpdatePopupCloseButton}>Create</button>
                        </div>
                    </div>
                </>
            )}
        </Popup>
    )

}

export default SettingsPods