import React from 'react';
import styled from '@emotion/styled';
import SearchBar from './SearchBar';

const Container = styled.div`
    width: 800px;
`;

export default function Search()
{

    return (
        <Container>
            <SearchBar />
        </Container>
    )
}