import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from '../context/auth';
import { USER_TYPE } from '../defs/User';

const RequiresRole = ({ roles = [], redirectTo="/", children}) => {
    const { user, token } = useContext(AuthContext);
    const location = useLocation();
    const allowedRoles = roles || [
        USER_TYPE.ADMIN,
        USER_TYPE.MARKETER,
        USER_TYPE.BUSINESS_OWNER,
        USER_TYPE.DEVELOPER
    ];
    console.log(user,token);
    if (allowedRoles.includes(user.userRole))
    {
         return children;
    }
    if (token)
    {
        return <Navigate to="/" replace />;
    }
    return <Navigate to={redirectTo} replace />;
    // return <Navigate to={redirectTo} state={{from:location}} replace />;
};

export default RequiresRole;