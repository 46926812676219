import styled from '@emotion/styled';
import { 
    DragDropContext,
    Droppable,
    Draggable,
    
} from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { ReactComponent as ClosePopupIcon } from '../../assets/icons/ClosePopupIcon.svg';

export const InnerDraggableCoupon = styled.div`
    width: 150px;
    height: 150px;
`;

export const CloseButton = styled.button`
    position: absolute;
    z-index: 99;
    top: 6px;
    right: 6px;
    filter: invert(100%) sepia(14%) saturate(0%) hue-rotate(77deg) brightness(101%) contrast(101%);
    background-color: transparent;
    border: none;
`;

export const TnCText = styled.span`
    position: absolute;
    z-index: 98;
    bottom: 4px;
    left: 4px;
    font-size: 8px;
    color: #fff;
    width: 175px;
    font-family: "National2";
`;

export default (props) => {
    
    const onClick = () =>
    {
        props.onRemove(props.id, props.zoneId);
    }

    return (
        <Draggable draggableId={props.id} key={uuid()} index={props.index}>
            {(provided, snapshot) => {
                const style = {
                    backgroundColor: snapshot.isDragging ? 'yellow' : 'white',
                    width: '100px',
                    height: '100px',
                    ...provided.draggableProps.style,
                  };
                return (
                <InnerDraggableCoupon
                    style={style}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {props.children}
                    {props.closeButton && (
                        <CloseButton onClick={onClick}>
                            <ClosePopupIcon />
                        </CloseButton>
                    )}
                    <TnCText>{props.tncText}</TnCText>
                </InnerDraggableCoupon>
            )}}
        </Draggable>
    );
}