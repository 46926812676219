import React, { useContext, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useTable } from 'react-table';
import GQL from '../../gql';
import DataTable from '../../components/DataTable/DataTable';

const emptyRecord = {
  num: '',
  name: '',
  address: '',
  suburb: '',
  state: '',
  postcode: ''
};

export default function StoreManager()
{
    const { loading, error, data, refetch } = useQuery(GQL.Store.GET_ALL_STORES);
    const [saveStoreMutation, saveStoreState] = useMutation(GQL.Store.SAVE_STORE);

    const columns = useMemo(
        () => [
          {
            Header: 'Store Number',
            accessor: 'num'
          },
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Address',
            accessor: 'address',
          },
          {
            Header: 'Suburb',
            accessor: 'suburb',
          },
          {
            Header: 'State',
            accessor: 'state',
          },
          {
            Header: 'Postcode',
            accessor: 'postcode',
          },
        ],
        []
    );

    const onRecordChange = async ({record, isNew}) => {
      delete record?.createdAt;
      record.num = parseInt(record.num);
      record.postcode = parseInt(record.postcode);
      const response = await saveStoreMutation({
        variables: {
          updateStoreInput: record
        }
      });
      if (isNew)
      {
        refetch();
      }
    }

    const onDeleteRecord = async (records) => {
      alert('Not Finished. Check back soon!');
    }

    if (loading)
    {
        return <p>Loading...</p>;
    }
    if (error)
    {
        return <p>Error! {error}</p>;
    }
    
    return (
        <DataTable
          data={data.getStores}
          columns={columns}
          onRecordChange={onRecordChange}
          onDeleteRecord={onDeleteRecord}
          emptyRecord={emptyRecord}
          addButtonText='Add New Store'
        />
    );
}