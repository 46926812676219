import React, { useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { ReactComponent as DotsOpen } from '../../../assets/icons/dotsOpen.svg';

import './style.scss';

function PromtionWidget({ data }) {
  const [mounthChecked, setMounthChecked] = useState(false);
  const [weeklyChecked, setWeeklyChecked] = useState(true);
  const COLORS = ['#EC7171', '#DB1A1A', '#760D0D', '#A72C2C'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="#fff" fontWeight='800' fontSize={'26px'} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className={'widget__promtion'}>
      <div className={'widget__promtion__header'}>
        <div>
          <h6>Promotion</h6>
          <span>Live</span>
        </div>
        <div className='widget__promtion__header__switch'>
          <div>
            <input type="radio" id="monthly" name="radio-group" defaultChecked={mounthChecked} onChange={() => setMounthChecked(!mounthChecked)} />
            <label htmlFor="monthly">Monthly</label>
          </div>
          <div>
            <input type="radio" id="weekly" name="radio-group" defaultChecked={weeklyChecked} onChange={() => setWeeklyChecked(!weeklyChecked)} />
            <label htmlFor="weekly">Weekly</label>
          </div>
          <button className={'widget__promtion--btn-details'}>
            <DotsOpen />
          </button>
        </div>
      </div>
      <div className='widget__promtion__content'>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={"75%"}
              blendStroke
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default PromtionWidget